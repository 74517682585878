<template>
	<div class="skeleton-title">
		<div class="skeleton-line"></div>
	</div>
</template>

<style scoped lang="scss">
.skeleton-title {
	height: 25px;
	display: flex;
	align-items: center;

	.skeleton-line {
		width: 60%;
		height: 100%;
	}
}
</style>
