<script setup lang="ts">
import { ref, inject } from "vue";
import { useRanking } from "./RankingStore";

interface RankingListItem {
	route: string;
	slug: string;
	name: string;
}

const rankingList = ref(inject<RankingListItem[]>("rankingList", []));

// store
const storeRanking = useRanking();

let availableList: RankingListItem[] = [];

if (rankingList) {
  rankingList.value.forEach((item) => {
    const matchingNavItem = storeRanking.NAV_ITEM.find((navItem) => navItem.slug === item.slug);
    if (matchingNavItem) {
      matchingNavItem.route = item.route;
    }
  });
  availableList = storeRanking.NAV_ITEM.filter((item) => item.route !== "");
}

</script>

<template>
	<ul class="list">
		<li v-for="item in availableList" :key="'ranking' + item.slug" class="item">
			<a :href="item?.route" :title="item.slug" class="link">
				<span class="name">{{ item?.name }}</span>
			</a>
		</li>
	</ul>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.list {
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.item {
	width: 100%;
	display: flex;
	align-items: center;
	min-height: 24px;
	padding: 2px;
	color: var(--color-main-text);
	border-radius: 6px;
	&._active {
		background: var(--color-dop-back);
	}
}
.link {
	display: flex;
	align-items: center;
	text-decoration: none;
	font-size: 14px;
	gap: 6px;
	width: calc(100% - 25px);
}
</style>
