<script setup lang="ts">
import type { ComputedRef } from "vue";
import { computed } from "vue";
import { useSport } from "../SportStore";
import { SPORT_TABS_NAME } from "../sportConst";

import SportItem from "./sport-item/SportItem.vue";
import SportBodySkeleton from "./skeleton/SportBodySkeleton.vue";

// store
const storeSport = useSport();

// isCoef
const isCoef = computed(() => storeSport.nav === SPORT_TABS_NAME.COEFFICIENT);

// list
const list: ComputedRef<any[]> = computed(() => {
	return storeSport.lists[storeSport.selectedDate][storeSport.nav];
});

// listsIsReady
const listsIsReady = computed(() => storeSport.lists[storeSport.selectedDate].isReady);
</script>

<template>
	<ul v-if="listsIsReady && list.length" class="list">
		<SportItem v-for="item in list" :key="item.id" :item="item" :is-coef="isCoef" />
	</ul>

	<p v-else-if="listsIsReady && !list.length" class="no-results">{{ $t('sport.info_empty') }}</p>

	<SportBodySkeleton v-else />
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.list {
	display: flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 10px;
	width: 100%;
	background: var(--color-dop-back);
}
</style>
