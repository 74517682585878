<script setup>
import { onUnmounted, provide } from "vue";

import { GET_INTERVAL_DURATION } from "@app/consts.js";
import { getApi } from "@app/helpers.js";
import { useGame } from "@app/modules/game/GameStore";
import TabBtn from "@app/ui/tab-btn/TabBtn.vue";
import usei18n from '@app/i18n/i18n';

import { useDetailsCoef } from "./DetailsCoefStore.js";
import { COEF_TYPE } from "./detailsCoefConsts.js";
import DetailsCoef1x2 from "./components/DetailsCoef1x2.vue";
import DetailsCoefExactScore from "./components/DetailsCoefExactScore.vue";
import DetailsCoefBothTeamsWillScore from "./components/DetailsCoefBothTeamsWillScore.vue";
import DetailsCoefHandicap from "./components/DetailsCoefHandicap.vue";
import DetailsCoefLessAndGross from "./components/DetailsCoefLessAndGross.vue";
import DetailsCoefHtFt from "./components/DetailsCoefHtFt.vue";
import DetailsCoefDoubleOutcome from "./components/DetailsCoefDoubleOutcome.vue";
import DetailsCoefEvenOdd from "./components/DetailsCoefEvenOdd.vue";
import DetailsCoefSkeleton from "./components/DetailsCoefSkeleton.vue";

const { t } = usei18n.global;

const props = defineProps({
	api: String,
});

const COEF_NAMES = {
		[COEF_TYPE._1X2]: {
			name: t('details_coef._1X2.name' ),
			title: t('details_coef._1X2.title'),
		},
		[COEF_TYPE.EXACT_SCORE]: {
			name: t('details_coef.EXACT_SCORE.name'),
			title: t('details_coef.EXACT_SCORE.title'),
		},
		[COEF_TYPE.BOTH_TEAMS_WILL_SCORE]: {
			name: t('details_coef.BOTH_TEAMS_WILL_SCORE.name'),
			title: t('details_coef.BOTH_TEAMS_WILL_SCORE.title'),
		},
		[COEF_TYPE.HANDICAP]: {
			name: t('details_coef.HANDICAP.name'),
			title: t('details_coef.HANDICAP.title'),
		},
		[COEF_TYPE.LESS_AND_GROSS]: {
			name: t('details_coef.LESS_AND_GROSS.name'),
			title: t('details_coef.LESS_AND_GROSS.title'),
		},
		[COEF_TYPE.HTFT]: {
			name: t('details_coef.HTFT.name'),
			title: t('details_coef.HTFT.title'),
		},
		[COEF_TYPE.DOUBLE_OUTCOME]: {
			name: t('details_coef.DOUBLE_OUTCOME.name'),
			title: t('details_coef.DOUBLE_OUTCOME.title'),
		},
		[COEF_TYPE.EVEN_ODD]: {
			name: t('details_coef.EVEN_ODD.name'),
			title: t('details_coef.EVEN_ODD.title'),
		},
	};

// adfoxCode
provide("adfoxCode", {
	liga: {
		ownerId: 712301,
		containerId: "adfox_169182292821853066",
		params: {
			p1: "cyyiv",
			p2: "imtr",
			pfc: "equyr",
			pfb: "qknxv",
		},
	},
	boombet: {
		ownerId: 712301,
		containerId: "adfox_169899442190667107",
		params: {
			p1: "czyzb",
			p2: "iqzo",
			pfc: "fyjaj",
			pfb: "rncvv",
		},
	},
});

// store
const storeDetailsCoef = useDetailsCoef(),
	storeGame = useGame();

// get coef
const getDetailsCoef = () => {
	getApi(props.api).then((result) => {
		console.log(result);
		storeDetailsCoef.createCoef(result);
	});
};

if (!storeDetailsCoef.isReady && Object.keys(storeDetailsCoef.coef).length !== 0) {
	getDetailsCoef();
}

// fetch Interval
const getDetailsCoefInterval = setInterval(() => {
	if (storeGame.game?.status !== t('game_head.status.over') && !document.hidden) {
		getDetailsCoef();
	} else if (storeGame.game?.status === t('game_head.status.over')) {
		clearInterval(getDetailsCoefInterval);
	}
}, GET_INTERVAL_DURATION);

document.addEventListener("visibilitychange", () => {
	if (storeGame.game?.status !== t('game_head.status.over') && !document.hidden) {
		getDetailsCoef();
	}
});

onUnmounted(() => {
	clearInterval(getDetailsCoefInterval);
});

// component
const components = {
	[COEF_TYPE._1X2]: DetailsCoef1x2,
	[COEF_TYPE.EXACT_SCORE]: DetailsCoefExactScore,
	[COEF_TYPE.BOTH_TEAMS_WILL_SCORE]: DetailsCoefBothTeamsWillScore,
	[COEF_TYPE.HANDICAP]: DetailsCoefHandicap,
	[COEF_TYPE.LESS_AND_GROSS]: DetailsCoefLessAndGross,
	[COEF_TYPE.HTFT]: DetailsCoefHtFt,
	[COEF_TYPE.DOUBLE_OUTCOME]: DetailsCoefDoubleOutcome,
	[COEF_TYPE.EVEN_ODD]: DetailsCoefEvenOdd,
};
console.log("🚀 ~ file: DetailsCoef.vue:45 ~ components:", components);
</script>

<template>
	<div v-if="storeDetailsCoef.isReady" class="wrap">
		<div class="tabs-wrap _secondary">
			<ul class="tabs">
				<li
					v-for="(item, key) in storeDetailsCoef.coef"
					:key="'coefficient-details-nav' + key"
					class="tab__item"
				>
					<TabBtn
						v-model="storeDetailsCoef.nav"
						:tab-value="key"
						:tab-name="'coefficient-details-nav'"
						:title="COEF_NAMES[key].title"
					>
						{{ COEF_NAMES[key].name }}
					</TabBtn>
				</li>
			</ul>
		</div>

		<span class="cof-table__title">{{ COEF_NAMES[storeDetailsCoef.nav].title }}</span>
		<component :is="components[storeDetailsCoef.nav]"></component>
	</div>

	<DetailsCoefSkeleton v-else />
</template>

<style lang="scss">
.coef-table {
	th {
		font-size: 13px;
		color: var(--color-dop-text);
		padding: 8px 0;
		font-weight: 4;
		text-align: center;
	}
	tr td {
		padding: 10px 2px;
	}
	tr td,
	tr th {
		&:first-of-type {
			padding-left: 10px;
		}
		&:last-of-type {
			padding-right: 10px;
		}
	}
	.tal {
		text-align: left;
	}
	.crown {
		display: none;
	}
	tr:first-child {
		&:hover {
			background: none;
		}
	}
	tr._active {
		border: 2px solid hsla(var(--color-coef-active-hsl), 0.2);
		border-bottom-color: var(--color-coef-active);
		background: hsla(var(--color-coef-active-hsl), 0.1);

		&:after {
			display: none;
		}

		.cof-table__logo {
			position: relative;

			.crown {
				content: "";
				display: block;
				width: 26px;
				height: 20px;
				background: var(--color-coef-active);
				position: absolute;
				left: -1px;
				bottom: 0;
				border-radius: 0 14px 0 0;

				svg {
					position: absolute;
					left: 2px;
					top: 1px;
				}
			}
		}
	}
}

td.cof-table__logo {
	padding-left: 3px;

	a {
		display: block;
	}

	img {
		border-radius: 4px;
		width: auto;
		height: 30px;
	}
}

.cof-table__title {
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	text-align: center;
	color: var(--color-main-text);
	margin: 0 0 12px;
}

@media screen and (max-width: 570px) {
	td.cof-table__logo {
		img {
			width: auto;
			height: 30px;
		}
	}
}
</style>

<style scoped lang="scss">
.wrap {
	background: var(--color-dop-back);
	padding: 4px;
	border-radius: 10px;
}
</style>
