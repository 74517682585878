<script setup>
import { ref, watch } from "vue";

import { directive as vClickAway } from "vue3-click-away";

import Toggle from "@assets/app/ui/toggle/Toggle.vue";
import FavoritesCountry from "@assets/app/modules/favorites/components/FavoritesCountry.vue";
import FavoritesTeams from "@assets/app/modules/favorites/components/FavoritesTeams.vue";
import FavoritesLeague from "@assets/app/modules/favorites/components/FavoritesLeague.vue";
import { useState } from "../HeaderStore";

const stateHeader = useState();

// burger

const btnBurger = () => {
		stateHeader.burgerActive = !stateHeader.burgerActive;
		if (stateHeader.burgerActive) {
			stateHeader.presentActive = false;
			stateHeader.langActive = false;
		}
	},
	hideBurger = () => {
		stateHeader.burgerActive = false;
	};

// theme
const localStorageTheme = window.localStorage.getItem("theme_dark"),
	theme = ref(window.matchMedia("(prefers-color-scheme: dark)").matches);

const switchTheme = () => {
	document.body.classList.toggle("_dark", theme.value);
	document.body.classList.toggle("_light", !theme.value);
};

if (localStorageTheme) {
	theme.value = localStorageTheme === "true";
}

switchTheme();

watch(theme, () => {
	switchTheme();
	window.localStorage.setItem("theme_dark", theme.value);
});
</script>

<template>
	<div class="burger" v-click-away="hideBurger">
		<button
			@click.stop="btnBurger"
			:class="{ _active: stateHeader.burgerActive }"
			class="hamburger hamburger--squeeze"
		>
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>

		<transition name="list">
			<div v-if="stateHeader.burgerActive" class="burger__drop">
				<ul class="burger__list">
					<!-- <li class="burger__item">
						<a class="burger__link" href="#">
							<span class="burger__text">
								<svg class="burger__icon" width="40" height="40">
									<use xlink:href="#40-hockey" />
								</svg>
								Настройки
							</span>
							<svg class="burger__icon" width="40" height="40">
								<use xlink:href="#40-hockey" />
							</svg>
						</a>
					</li>-->
					<li class="burger__item">
						<label class="burger__link">
							<span class="burger__text">
								<svg class="burger__icon" width="30" height="30">
									<use xlink:href="#20-dark" />
								</svg>
								{{ $t("header.black") }}
							</span>
							<Toggle class="burger__toggle" v-model="theme"></Toggle>
						</label>
					</li>
				</ul>
				<div class="country">
					<h4 class="country__title">{{ $t("header.ligue") }}</h4>
					<FavoritesLeague />
				</div>
				<div class="country">
					<h4 class="country__title">{{ $t("header.team") }}</h4>
					<FavoritesTeams />
				</div>
				<div class="country">
					<h4 class="country__title">{{ $t("header.country") }}</h4>
					<FavoritesCountry />
				</div>
			</div>
		</transition>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.burger {
	position: relative;
}

.burger__drop {
	position: absolute;
	top: 100%;
	right: -14px;
	width: calc(100vw - ($block-padding-hor-m * 2));
	max-width: 355px;
	background: var(--color-dop-back);
	border-radius: 6px;
	z-index: 100;
	padding: 5px 0;
	box-shadow: 0 10px 30px var(--color-drop-shadow);
}

.burger__list {
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: 1fr;
	padding: 0 5px;
}

.burger__link {
	width: 100%;
	min-width: 270px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: var(--color-main-text);
	text-decoration: none;
	cursor: pointer;
}

.burger__toggle {
	margin-right: 6px;
}

.burger__text {
	display: flex;
	align-items: center;
}

.burger__icon {
	stroke: var(--color-main-text);
	stroke-width: 1;
	flex-shrink: 0;
	margin-right: 10px;
}

// country
.country {
	display: none;
}

// transition
.list-enter-active,
.list-leave-active {
	transition: 250ms ease-in-out;
	transition-property: opacity, transform;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateY(-10px);
}

// hamburger
.hamburger {
	width: 22px;
	height: 18px;
	padding: 11px 9px;
	display: block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	border-radius: 6px;
	margin: 0;
	overflow: visible;
	box-sizing: content-box;

	&:hover {
		opacity: 1;

		& .hamburger-inner,
		& .hamburger-inner::before,
		& .hamburger-inner::after {
			background-color: var(--color-dop-cta);
		}
	}

	&._active {
		background-color: var(--color-dop-cta);

		& .hamburger-inner,
		& .hamburger-inner::before,
		& .hamburger-inner::after {
			background-color: hsl(0, 0%, 9%);
		}
	}
}

.hamburger._active:hover {
	opacity: 1;
}

.hamburger-box {
	width: inherit;
	height: inherit;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -1px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 22px;
	height: 2px;
	background-color: hsl(0, 0%, 100%);
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block;
}
.hamburger-inner::before {
	top: -7px;
}
.hamburger-inner::after {
	bottom: -7px;
}

// Squeeze
.hamburger--squeeze .hamburger-inner {
	transition-duration: 150ms;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
	transition: top 150ms 0.12s ease, opacity 150ms ease;
}
.hamburger--squeeze .hamburger-inner::after {
	transition: bottom 150ms 0.12s ease, transform 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze._active .hamburger-inner {
	transform: rotate(45deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze._active .hamburger-inner::before {
	top: 0;
	opacity: 0;
	transition: top 150ms ease, opacity 150ms 0.12s ease;
}
.hamburger--squeeze._active .hamburger-inner::after {
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 150ms ease, transform 150ms 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

// @media screen and (max-width: 768px) {
// 	.hamburger._active .hamburger-inner,
// 	.hamburger._active .hamburger-inner::before,
// 	.hamburger._active .hamburger-inner::after {
// 		background-color: var(--color-main-text);
// 	}

// 	.hamburger-inner,
// 	.hamburger-inner::before,
// 	.hamburger-inner::after {
// 		background-color: var(--color-dop-cta);
// 	}
// }

@media screen and (max-width: 768px) {
.hamburger {
		width: 10px;
		height: 18px;
		padding: 0px 0px;
	}
	.burger__drop {
		border: 1px solid var(--color-black);
	}

	// country
	.country {
		display: block;
		min-width: 300px;
		border-top: 1px solid var(--color-black);
		padding: 10px 5px;
	}

	.country__title {
		font-size: 16px;
		font-weight: 400;
		color: var(--color-dop-cta);
		margin: 0 0 10px 8px;
	}

	.burger__drop {
		right: -6px;
	}
}
</style>
