<template>
	<div class="skeleton-btn-more">
		<div class="skeleton-line"></div>
	</div>
</template>

<style scoped lang="scss">
.skeleton-btn-more {
	display: flex;
	align-items: center;
	justify-content: center;

	.skeleton-line {
		max-width: 160px;
		width: 100%;
		height: 26px;
	}
}
</style>
