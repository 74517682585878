<script setup>
import { computed, ref } from "vue";
import usei18n from "@app/i18n/i18n";

import { useDetailsStanding } from "../DetailsStandingStore.js";

const { t } = usei18n.global;

// store
const storeDetailsStanding = useDetailsStanding();

const sortField = ref("");
const sortFieldNav = ref("asc");

const sortStandings = computed(() => {
	let result = [...storeDetailsStanding.standing.teams[2].list];
	if (sortField && result && result.length) {
		const type = sortField.value.indexOf("htFt") !== -1 ? "htFt" : sortField.value;
		result = result.sort((commandA, commandB) => {
			switch (type) {
				case "name":
					const checkBool =
						sortFieldNav.value === "asc"
							? commandA.competitor.name > commandB.competitor.name
							: commandA.competitor.name < commandB.competitor.name;
					return checkBool === true ? 1 : -1;

				case "htFt":
					const key = sortField.value.replace("htFt.", "");
					return sortFieldNav.value === "asc"
						? commandA.htFt[key] - commandB.htFt[key]
						: commandB.htFt[key] - commandA.htFt[key];

				default:
					return sortFieldNav.value === "asc"
						? commandA[sortField.value] - commandB[sortField.value]
						: commandB[sortField.value] - commandA[sortField.value];
			}
		});
	}
	return result;
});
const setSort = (fieldName) => {
	if (fieldName === "") {
		return;
	}
	if (sortField.value === fieldName) {
		sortFieldNav.value = sortFieldNav.value === "asc" ? "desc" : "asc";
	} else {
		sortFieldNav.value = "asc";
	}
	sortField.value = fieldName;
};
const headersTitle = [
	{ id: "index", name: t("details_standing.htft.name_index"), sort: true, title: t("details_standing.htft.title_index") },
  	{ id: "name", name: t("details_standing.htft.name_name"), sort: true, title: t("details_standing.htft.title_name") },
  	{ id: "played", name: t("details_standing.htft.name_played"), sort: true, title: t("details_standing.htft.title_played") },
  	{ id: "htFt.ww", name: t("details_standing.htft.name_ww"), sort: true, title: t("details_standing.htft.title_ww") },
  	{ id: "htFt.wd", name: t("details_standing.htft.name_wd"), sort: true, title: t("details_standing.htft.title_wd") },
  	{ id: "htFt.wl", name: t("details_standing.htft.name_wl"), sort: true, title: t("details_standing.htft.title_wl") },
  	{ id: "htFt.dw", name: t("details_standing.htft.name_dw"), sort: true, title: t("details_standing.htft.title_dw") },
  	{ id: "htFt.dd", name: t("details_standing.htft.name_dd"), sort: true, title: t("details_standing.htft.title_dd") },
  	{ id: "htFt.dl", name: t("details_standing.htft.name_dl"), sort: true, title: t("details_standing.htft.title_dl") },
  	{ id: "htFt.lw", name: t("details_standing.htft.name_lw"), sort: true, title: t("details_standing.htft.title_lw") },
  	{ id: "htFt.ld", name: t("details_standing.htft.name_ld"), sort: true, title: t("details_standing.htft.title_ld") },
  	{ id: "htFt.ll", name: t("details_standing.htft.name_ll"), sort: true, title: t("details_standing.htft.title_ll") },
  	{ id: "points", name: t("details_standing.htft.name_points"), sort: true, title: t("details_standing.htft.title_points") },
];
</script>

<template>
	<div class="standing-table__wrapper">
		<table class="standing main-table">
			<tbody>
				<tr class="standing-table__title">
					<th
						@click="setSort(headerTitle.sort ? headerTitle.id : '')"
						v-for="headerTitle in headersTitle"
						:key="headerTitle.id"
						:title="headerTitle.title"
						:class="{
							cursorPointer: headerTitle.sort,
							active: headerTitle.sort && sortField === headerTitle.id,
						}"
					>
						{{ headerTitle.name }}
						<span
							v-if="headerTitle.sort && sortField === headerTitle.id"
							class="arrow"
							:class="{
								arrowRotate: sortFieldNav === 'desc',
							}"
							>▲</span
						>
					</th>
				</tr>
				<tr v-for="command in sortStandings" :key="command.competitor.id" class="standing-row">
					<td>{{ command.index }}</td>
					<td>{{ command.competitor.name }}</td>
					<td>{{ command.played }}</td>
					<td v-for="(value, key) in command.htFt" :key="`${command.competitor.id}_${key}`">
						<div class="standing-column">
							<div :class="{ 'standing-column_color': value > 0 }">{{ value }}</div>
						</div>
					</td>
					<td>
						<b>{{ command.points }}</b>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<style scoped lang="scss">
.standing-table__wrapper {
	overflow: auto;
}
th {
	color: var(--color-dop-text);
	font-weight: 400;
	position: relative;
	&.active {
		color: #fff;
	}
}
.standing-table__title {
	&:hover {
		background: transparent;
	}
}
.standing {
	width: 100%;
	font-size: 12px;
	border-collapse: collapse;
}
.standing-title {
	background: var(--color-dop-cta);
	border-radius: 5px;
	color: hsl(220, 4%, 14%);
	th:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	th:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	th {
		padding: 5px;
	}
}
.standing td {
	text-align: center;
	padding: 5px;

	width: 90px;

	&:nth-child(2) {
		width: 350px;
		text-align: left;
	}
}

.standing-row {
	span {
		border-radius: 5px;
		width: 25px;
		height: 25px;
		color: white;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.h2h-status__winner {
		background-color: #00a83f;
	}
	.h2h-status__lose {
		background-color: #dc0000;
	}
	.h2h-status__draw {
		background-color: #f3a000;
	}
}
.standing-column {
	display: flex;
	align-items: center;
	justify-content: center;
}
.standing-column_color {
	background-color: #555e61;
	color: white;
	border-radius: 5px;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.h2h-status {
	position: relative;
	overflow: hidden;
	&.dop_draw::after {
		border-bottom: 12px solid transparent;
		border-right: 12px solid #f3a000;
		border-top: 12px solid transparent;
		content: "";
		height: 0;
		position: absolute;
		right: 0;
		top: 13px;
		width: 0;
	}
}
.arrow {
	font-size: 0px;
	width: 2px;
	height: 4px;
	position: absolute;
	color: transparent;
	border: 4px solid transparent;
	border-top-color: #fff;
	margin-left: 5px;
	top: 15px;
}
.arrowRotate {
	transform: rotate(180deg);
	display: inline-block;
	top: 10px;
}
.cursorPointer {
	cursor: pointer;
	&:hover {
		color: #fff;
	}
}
</style>
