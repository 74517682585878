<script setup lang="ts">
import { computed } from "vue";
import { currentLocale } from "@app/helpers.ts";
import usei18n from "@app/i18n/i18n";

// Locale
const { t } = usei18n.global;
const LOCALE = currentLocale().locale

const props = defineProps<{
	status: string | number;
	startTime?: number;
	clock?: {
		played: string | null;
		stoppage_time_played: string | null;
	};
}>();

const date = computed(() => {
	if (props.startTime) {
		const startTime = new Date(props.startTime * 1000);
		return new Intl.DateTimeFormat(LOCALE, {
			hour: "2-digit",
			minute: "2-digit",
			hour12: false,
		}).format(startTime);
	} else {
		return "-";
	}
});

// statues
const timeFormatRound = (time: any) => {
		return Math.round(time.split(/:/g).join("."));
	},
	timeFormatCeil = (time: any) => {
		return Math.ceil(time.split(/:/g).join("."));
	};

const statusTime = computed(() => {
	if (props.clock && props.status !== "Перерыв") {
		if (props.clock.stoppage_time_played) {
			return timeFormatRound(props.clock.played) + " + " + timeFormatCeil(props.clock.stoppage_time_played);
		}

		return timeFormatRound(props.clock.played);
	} else {
		if (!Number(props.status)) {
			return t(`game_head.status.${[props.status]}`);
		}
		return props.status;
	}
});

const addClassList = computed(() => {
	return {
		_live: props.status !== "Завершен",
		_completed: props.status === "Завершен",
	};
});
</script>

<template>
	<div class="status-wrap">
		<span v-if="status" :class="addClassList" class="status">
			{{ statusTime }}
			<span class="status-spin" v-if="Number.isInteger(+status)">’</span>
		</span>
		<span v-else class="status _date">{{ date }}</span>
	</div>
</template>

<style scoped lang="scss">
.status-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.status {
	font-size: 14px;
	line-height: 100%;
	color: var(--color-main-text);
	white-space: nowrap;

	&._live {
		padding: 4px 6px;
		color: hsl(220, 4%, 14%);
		background: var(--color-dop-cta);
		border-radius: 4px;
	}
}

@media screen and (max-width: 570px) {
	.status {
		&._live,
		&._date {
			margin: 0 4px;
		}
	}

	.status {
		&._completed {
			display: none;
		}
	}
}
</style>
