<script setup>
import { useDetailsGame } from "../DetailsGameStore.js";

// store
const storeDetailsGame = useDetailsGame();
</script>

<template>
	<ul class="list">
		<li v-for="(item, key) in storeDetailsGame.statistics" :key="'statistic_' + key" class="item">
			<div class="head">
				<span>{{ item.value.home }}</span>
				<span>{{ item.name }}</span>
				<span>{{ item.value.away }}</span>
			</div>
			<div class="body">
				<div
					:style="{ '--progress': item.progress.home + '%' }"
					:class="{ _active: item.progress.away < item.progress.home }"
					class="progress _home"
				></div>
				<div
					:style="{ '--progress': item.progress.away + '%' }"
					:class="{ _active: item.progress.away > item.progress.home }"
					class="progress _away"
				></div>
			</div>
		</li>
	</ul>
</template>

<style scoped lang="scss">
.list {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 18px;
	margin-bottom: 6px;
}

.item {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.head {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 6px;
	padding: 0 4px;
	font-size: 14px;
	line-height: 100%;
	text-align: center;
}

.body {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 12px;
}

.progress {
	position: relative;
	width: 100%;
	height: 8px;
	border-radius: 8px;
	background: var(--color-main-back);
	border: 1px solid var(--color-main-back);

	&::before {
		content: "";
		position: absolute;
		display: block;
		height: 100%;
		width: var(--progress);
		border-radius: 6px;
		background: var(--color-main-text);
		top: 0;
		left: 0;
	}

	&._active {
		&::before {
			background: var(--color-dop-cta);
		}
	}

	&._home {
		&::before {
			left: auto;
			right: 0;
		}
	}
}
</style>
