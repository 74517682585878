// https://stage.myscore.club
// const FETCH_PATH = (): string => (import.meta.env.MODE === "dev" ? "https://stage.myscore.club/api" : "/api"),
const FETCH_PATH = (): string => (import.meta.env.MODE === "dev" ? "/api" : "/api"),
	HEADERS_LIST = (): Headers => {
		const headers = new Headers();
		if (import.meta.env.MODE === "dev") {
			headers.set("Authorization", "Basic " + btoa("myscore:tWtKw3gLO4"));
			return headers;
		}
		return headers;
	},
	ADMIN_IMG_STATIC_PATH = (): string => {
		return import.meta.env.MODE === "prod" ? STATIC_PATH : "";
	};

const STATIC_PATH = "https://staticmy.devrepo2.ru",
	// LOCALE = "ru-RU",
	IS_OPEN_ITEM_LEAGUE = "_is_open_item_league",
	TARGET_BLANK = "_blank",
	USER_NAME = "_user_name",
	GET_INTERVAL_DURATION = 25000;

const LANGUAGE: Record<string, Record<string, string>> = {
		"?locale=ru": { codeLang: "ru", flagIcon: "ru", name: "Русский", locale: "ru-RU" },
		"?locale=es": { codeLang: "es", flagIcon: "es", name: "Español", locale: "es-ES" },
		"?locale=en": { codeLang: "en", flagIcon: "gb-eng", name: "English", locale: "en-EN"},
	},
	GAME_STATUS_LIST: Record<string, string> = {
		VICTORY: "victory",
		DEFEAT: "defeat",
		DRAW: "draw",
	},
	SPORT_TYPE_NAME: Record<string, string> = {
		FOOTBALL: "football",
		HOCKEY: "hockey",
		BOXING: "boxing",
		MMA_UFC: "mma-ufc",
		TENNIS: "tennis",
		BASKETBALL: "basketball",
	},
	PERIOD_TYPE: Record<string, string> = {
		REGULAR_PERIOD: "regular_period",
		OVERTIME: "overtime",
		PENALTIES: "penalties",
	};

enum FAVORITE_TYPE {
	LEAGUE = "league",
	TEAM = "teams",
}

export {
	FETCH_PATH,
	HEADERS_LIST,
	ADMIN_IMG_STATIC_PATH,
	LANGUAGE,
	STATIC_PATH,
	// LOCALE,
	IS_OPEN_ITEM_LEAGUE,
	TARGET_BLANK,
	USER_NAME,
	GET_INTERVAL_DURATION,
	GAME_STATUS_LIST,
	SPORT_TYPE_NAME,
	PERIOD_TYPE,
	FAVORITE_TYPE,
};
