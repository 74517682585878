<script setup>
import { ref, inject, onMounted, computed } from "vue";
import { useResizeObserver } from "@vueuse/core";

import { directive as vClickAway } from "vue3-click-away";

const mainMenu = inject("mainMenu", null);
console.log(mainMenu);
// main menu
const getDropMenuList = () => [...mainMenu].slice(itemsNumber.value);

const mainMenuEl = ref(null),
	itemsNumber = ref(mainMenu.length),
	dropMenuList = ref(getDropMenuList());

useResizeObserver(mainMenuEl, (entries) => {
	itemsNumber.value = mainMenu.length;
	const elWidth = entries[0].contentRect.width;
	let menuItemsWidth = 0;

	for (let index = 0; index < entries[0].target.children.length; index++) {
		const item = entries[0].target.children[index];
		menuItemsWidth += item.offsetWidth + 10;

		if (menuItemsWidth > elWidth) {
			itemsNumber.value = index;
			break;
		}
	}

	dropMenuList.value = getDropMenuList();
});

// drop menu
const dropMenuActive = ref(false);

const dropMenuToggle = () => {
		dropMenuActive.value = !dropMenuActive.value;
	},
	dropMenuHide = () => {
		dropMenuActive.value = false;
	};

const hasActiveItem = computed(() => {
	return mainMenu.some((item) => item.active);
});
</script>

<template>
	<div class="bot">
		<div class="bot__wrap">
			<nav class="menu">
				<ul ref="mainMenuEl" class="menu__list">
					<li
						v-for="(item, index) in mainMenu"
						:key="'main-menu' + index"
						:class="{ _hide: index + 1 > itemsNumber }"
						class="menu__item"
					>
						<a :href="item.slug" :class="{ _active: !hasActiveItem && index === 0 || item.active }" class="menu__link">
							<svg v-if="item.icon" width="40" height="40">
								<use :xlink:href="'#' + item.icon" />
							</svg>

							<span>{{ item.name }}</span>
						</a>
					</li>
				</ul>
			</nav>

			<div class="drop-menu-wrap">
				<button @click="dropMenuToggle" :class="{ _active: dropMenuActive }" class="menu__link">
					<svg width="40" height="40">
						<use xlink:href="#40-all" />
					</svg>
					<span>{{ $t('header_menu.button') }}</span>
				</button>

				<transition name="list">
					<div v-if="dropMenuActive" v-click-away="dropMenuHide" class="drop-menu">
						<nav class="drop-menu__nav">
							<ul class="drop-menu__list">
								<li
									v-for="(item, key) in dropMenuList"
									:key="'drop-menu' + key"
									class="drop-menu__item"
								>
									<a :href="item.slug" :class="{ _active: item.active }" class="drop-menu__link">
										<svg width="40" height="40">
											<use :xlink:href="'#' + item.icon" />
										</svg>
										<span>{{ item.name }}</span>
									</a>
								</li>
							</ul>
						</nav>
						<span class="drop-menu__message">{{ $t('header_menu.message') }}</span>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.bot {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0 $block-padding-hor;
	background: var(--color-dop-back);
}

.bot__wrap {
	width: 100%;
	max-width: $block-max-width;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 10px;
}
.menu {
	overflow: hidden;
	width: 100%;
}

.menu__list {
	display: flex;
	gap: 10px;
}

.menu__item {
	flex-shrink: 0;

	&._hide {
		visibility: hidden;
		opacity: 0;
	}
}

.menu__link {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0px 8px;
	height: 40px;
	color: var(--color-main-text);
	font-family: $accent-font;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	text-decoration: none;

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 4px;
		width: 100%;
		height: 100%;
		background: transparent;
		transform: skewX(-25deg);
		z-index: 1;

		transition: background 150ms ease-in-out;
	}

	> svg {
		position: relative;
		width: 40px;
		height: 40px;
		stroke: var(--color-main-text);
		stroke-width: 1.5;
		z-index: 2;
	}

	> span {
		position: relative;
		z-index: 2;
	}

	&:hover {
		color: var(--color-main-text);

		&::after {
			background: var(--color-menu-hover);
		}
	}

	&._active {
		color: hsl(0, 0%, 9%);

		> svg {
			stroke: hsl(0, 0%, 9%);
		}

		&::after {
			background: var(--color-dop-cta);
		}
	}
}

// transition
.list-enter-active,
.list-leave-active {
	transition: 250ms ease-in-out;
	transition-property: opacity, transform;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateY(-10px);
}

.drop-menu-wrap {
	position: relative;
}

.drop-menu {
	position: absolute;
	top: 100%;
	right: -14px;
	display: flex;
	flex-direction: column;
	padding: 4px;
	gap: 6px;
	min-width: 220px;
	background: var(--color-dop-back);
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
	border-radius: 10px;
	z-index: 11;
}

.drop-menu__message {
	display: block;
	padding: 4px 6px;
	font-size: 14px;
	color: var(--color-main-text);
	line-height: 130%;
}

.drop-menu__list {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.drop-menu__link {
	display: flex;
	align-items: center;
	padding-right: 6px;
	border-radius: 6px;
	font-family: $main-font;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	color: var(--color-main-text);
	text-decoration: none;
	background: transparent;

	transition: 150ms ease-in-out;
	transition-property: color, background;

	> svg {
		width: 30px;
		height: 30px;
		stroke: var(--color-main-text);
		stroke-width: 1.5;
		margin-right: 4px;
		transition: inherit;
		transition-property: stroke;
	}

	&:hover {
		background: var(--color-dop-cta);
		color: hsl(0, 0%, 9%);

		> svg {
			stroke: hsl(0, 0%, 9%);
		}
	}
}

@media screen and (max-width: 768px) {
	.bot {
		padding: 0 $block-padding-hor-m;
	}
}

@media screen and (max-width: 620px) {
	.menu__list {
		gap: 16px;
	}

	.menu__link {
		font-size: 14px;
		flex-direction: column;
		height: auto;
		padding-bottom: 2px;
		gap: 3px;

		> svg {
			width: 30px;
			height: 30px;
			margin-bottom: -2px;
		}

		&::after {
			top: auto;
			bottom: 0;
			height: 19px;
			left: 0;
		}

		&._active {
			> svg {
				stroke: var(--color-dop-cta);
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.menu__list {
		gap: 8px;
	}
	.menu__link {
		font-size: 12px;
	}
}
@media screen and (max-width: 410px) {
	.menu__link {
		font-size: 12px;
	}
}
@media screen and (max-width: 385px) {
	.menu__list {
		gap: 6px;
	}
	.menu__link {
		font-size: 12px;
	}
}
@media screen and (max-width: 365px) {
	.menu__list {
		gap: 4px;
	}
	.menu__link {
		font-size: 12px;
	}
}
@media screen and (max-width: 345px) {
	.menu__list {
		gap: 4px;
	}
	.menu__link {
		font-size: 12px;
	}
}
</style>
