import { defineStore } from "pinia";
import { ref } from "vue";

const ITEM_NAME = "_search_history";

export const useSearchStore = defineStore("SearchStore", () => {
	const searchValue = ref(""),
		result = ref([]);

	// history
	const getSearchHistory = () => {
			let list = window.localStorage.getItem(ITEM_NAME);

			if (list) return JSON.parse(list);
			return [];
		},
		addSearchHistory = (item) => {
			let list = getSearchHistory();

			if (!list.some((e) => e?.id === item?.id)) {
				if (list.length >= 10) list.pop();

				list.unshift(item);

				window.localStorage.setItem(ITEM_NAME, JSON.stringify(list));
			}
		};

	return {
		searchValue,
		result,
		getSearchHistory,
		addSearchHistory,
	};
});
