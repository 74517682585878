<script setup>
import { computed, inject } from "vue";

import { sportTypeName } from "@assets/app/consts.js";
import { eventType } from "../detailsGameConsts.js";

const sportType = inject("sportType", null);

const props = defineProps({
	event: String,
	name: String,
});

const typeIconSport = computed(() => {
		switch (sportType) {
			case sportTypeName.FOOTBALL:
				return "20-ball";

			case sportTypeName.HOCKEY:
				return "20-hockey-puck";

			// case sportTypeName.BOXING:
			// 	return "20-ball";

			// case sportTypeName.MMA_UFC:
			// 	return "20-ball";

			default:
				return "20-ball";
		}
	}),
	eventIconName = computed(() => {
		if (props.event) {
			switch (props.event) {
				case eventType.INJURY_TIME_SHOWN:
					return "20-stopwatch";

				case eventType.YELLOW_RED_CARD:
					return "20-red-yellow-card";

				case eventType.PENALTY_MISSED:
				case eventType.PENALTY_SHOOTOUT:
				case eventType.MISSED:
					return "20-warning";

				case eventType.CORNER_KICK:
					return "20-flag";

				case eventType.INJURY:
					return "20-cross";

				case eventType.YELLOW_CARD:
					return "20-yellow-card";

				case eventType.RED_CARD:
					return "20-red-card";

				case eventType.SCORE_CHANGE:
				case eventType.SCORED:
					return typeIconSport.value;

				case eventType.SUBSTITUTION:
					return "20-swap";

				case eventType.MATCH_STARTED:
				case eventType.MATCH_ENDED:
				case eventType.PERIOD_SCORE:
					return "20-whistle";

				default:
					return false;
			}
		} else if (props.name) {
			return props.name;
		} else {
			return false;
		}
	});
</script>

<template v-if="eventIconName">
	<svg class="info-icon" width="20" height="20">
		<use :xlink:href="'#' + eventIconName" />
	</svg>
</template>

<style scoped lang="scss">
.info-icon {
	flex-shrink: 0;
	fill: var(--color-main-text);
	stroke: var(--color-main-text);

	&._red {
		fill: var(--color-error);
		stroke: var(--color-error);
	}
}
</style>
