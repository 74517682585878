<script setup lang="ts">
import { getApi, getAgeFullYear } from "@app/helpers.ts";
import TabBtn from "@app/ui/tab-btn/TabBtn.vue";
import TableIconTitle from "@app/ui/table/icon-title/TableIconTitle.vue";
import TableIconName from "@app/ui/table/icon-name/TableIconName.vue";

import type { TeamSquadItem } from "../teamInterface";
import { useTeam } from "../TeamStore";
import TeamSquadSkeleton from "./TeamSquadSkeleton.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = withDefaults(
	defineProps<{
		api: string;
		showItems?: number;
	}>(),
	{
		showItems: 30,
	}
);

// store
const storeTeam = useTeam();

if (!storeTeam.squad.isReady) {
	getApi<{ squad: { [k: string]: TeamSquadItem } }>(props.api).then((response) => {
		if (response) {
			console.log(response);
			storeTeam.addSquad(Object.values(response.squad));
		}
	});
}
console.log(storeTeam);
</script>

<template>
	<div v-if="storeTeam.squad.isReady" class="group">
		<div class="tabs-wrap _secondary">
			<ul class="tabs">
				<li
					v-for="(_item, tabKey) in storeTeam.squad.tabs"
					:key="'squad_tabs' + tabKey"
					:style="{ order: tabKey === 'sum' ? '1' : '2' }"
					class="tab__item"
				>
					<TabBtn v-model="storeTeam.squad.nav" :tab-value="tabKey" :tab-name="'TeamDetailsSquad'">
						{{ tabKey === "sum" ? t('team.all') : tabKey }}
					</TabBtn>
				</li>
			</ul>
		</div>

		<div class="table-group">
			<div
				v-for="(role, keyRole) in storeTeam.squad.tabs[storeTeam.squad.nav]"
				:key="'tab_role' + keyRole"
				class="table-item"
			>
				<h3 class="title">
					{{ t(`player_head.ROLE_LIST.${[keyRole]}`) }}
				</h3>
				<div class="table-container">
					<table class="main-table">
						<tr>
							<th class="th-title _center">{{ t('team.index') }}</th>
							<th class="th-title">{{ t('team.player') }}</th>
							<th class="th-title _center">{{ t('team.old') }}</th>

							<th v-if="storeTeam.squad.tabs.sum.teams" class="th-icon">
								<TableIconTitle :title="t('STATISTIC_TITLE.MATCHES_PLAYED')" :icon-name="'play-football'" />
							</th>
							<th v-if="storeTeam.squad.tabs.sum.teams" class="th-icon">
								<TableIconTitle :title="t('STATISTIC_TITLE.GOALS_SCORED')" :icon-name="'ball'" />
							</th>
							<th v-if="storeTeam.squad.tabs.sum.teams" class="th-icon">
								<TableIconTitle :title="t('STATISTIC_TITLE.YELLOW_CARDS')" :icon-name="'yellow-card'" />
							</th>
							<th v-if="storeTeam.squad.tabs.sum.teams" class="th-icon">
								<TableIconTitle :title="t('STATISTIC_TITLE.RED_CARDS')" :icon-name="'red-card'" />
							</th>
						</tr>
						<tr v-for="(player, indexPlayer) in role" :key="'role_player' + indexPlayer">
							<td class="td-number">
								<span class="number">
									{{ player.jersey_number ? player.jersey_number : player.player_jersey_number }}
								</span>
							</td>
							<td>
								<a :href="player.route_to_player ?? undefined" class="player-link">
									<TableIconName :icon-flag="player.player_country" :name="player.player_name" />
								</a>
							</td>
							<td class="td-age">
								<span class="age">
									{{ getAgeFullYear(player.player_date_of_birth) }}
								</span>
							</td>
							<td v-if="player.statistics" class="td-stats">
								<span class="statistics">
									{{ player.statistics.matches_played }}
								</span>
							</td>
							<td v-if="player.statistics" class="td-stats">
								<span class="statistics">
									{{ player.statistics.goals_scored }}
								</span>
							</td>
							<td v-if="player.statistics" class="td-stats">
								<span class="statistics">
									{{ player.statistics.yellow_cards }}
								</span>
							</td>
							<td v-if="player.statistics" class="td-stats">
								<span class="statistics">
									{{ player.statistics.red_cards }}
								</span>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
	<TeamSquadSkeleton v-else />
</template>

<style scoped lang="scss">
.group {
	display: flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 10px;
	width: 100%;
	background: var(--color-dop-back);
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
}
.table-group {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.title {
	font-size: 16px;
	line-height: 130%;
	margin: 0 0 12px;
}

.table-container {
	overflow-x: auto;
	max-width: 100%;
}

.td-number,
.td-age,
.td-stats {
	width: 40px;
}

.th-title {
	&._center {
		text-align: center;
	}
}

.td-number {
	height: 38px;
}

.th-icon {
	fill: var(--color-main-text);
	stroke: var(--color-main-text);
}

.number,
.age,
.statistics {
	display: block;
	font-size: 14px;
	text-align: center;
}

.player-link {
	text-decoration: none;
}

@media screen and (max-width: 620px) {
}
</style>
