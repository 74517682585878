<script setup lang="ts">
import { computed, ref } from "vue";
import { useFocus } from "@vueuse/core";

import { INPUT_TYPE } from "./FormInputInterface";

const props = defineProps<{
		modelValue: string;
		autocomplete?: string;
		inputOption?: Record<
			string,
			{
				value: string | number;
				label: string;
				selected?: boolean;
			}
		>;
		inputRequired?: boolean;
		inputId?: string;
		inputType: INPUT_TYPE;
		inputName?: string;
		inputPlaceholder?: string;
		inputError?: string | null;
	}>(),
	emit = defineEmits(["update:modelValue"]);

console.log();

const value = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit("update:modelValue", value);
	},
});

// checkType
const checkType = computed(() => {
	switch (props.inputType) {
		case INPUT_TYPE.TEXT:
		case INPUT_TYPE.EMAIL:
		case INPUT_TYPE.PASSWORD:
		case INPUT_TYPE.FILE:
		case INPUT_TYPE.IMAGE:
		case INPUT_TYPE.COLOR:
		case INPUT_TYPE.NUMBER:
		case INPUT_TYPE.TEL:
		case INPUT_TYPE.SEARCH:
		case INPUT_TYPE.DATE:
			return true;

		default:
			return false;
	}
});

// checkPlaceholder
const input = ref(),
	inputIsFocus = useFocus(input);

const upPlaceholder = computed(() => {
	return value.value !== "" || inputIsFocus.focused.value;
});

// show password
const showPassword = ref(false);
const switchType = computed(() => {
	if (props.inputType === INPUT_TYPE.PASSWORD && showPassword.value) {
		return INPUT_TYPE.TEXT;
	} else {
		return props.inputType;
	}
});
</script>

<template>
	<label class="input">
		<span :class="{ _active: upPlaceholder }" class="placeholder">
			{{ inputPlaceholder }}
			<span v-if="inputRequired" class="required">*</span>
		</span>
		<input
			v-if="checkType"
			v-model="value"
			:type="switchType"
			:name="inputName"
			:id="inputId"
			:placeholder="inputPlaceholder"
			:required="inputRequired"
			:autocomplete="autocomplete"
			:class="{ _error: inputError }"
			class="input__input"
			ref="input"
		/>
		<textarea
			v-else-if="props.inputType === INPUT_TYPE.TEXTAREA"
			v-model="value"
			:name="inputName"
			:id="inputId"
			:placeholder="inputPlaceholder"
			:required="inputRequired"
			:class="{ _error: inputError }"
			class="input__input _textarea"
			ref="input"
		></textarea>
		<select
			v-else-if="props.inputType === INPUT_TYPE.SELECT"
			v-model="value"
			:name="inputName"
			:id="inputId"
			:required="inputRequired"
			:class="{ _error: inputError }"
			class="input__input _select"
			ref="input"
		>
			<option
				v-for="(item, key) in inputOption"
				:key="key + '_input_option'"
				:value="item?.value"
				:selected="item?.selected"
				:label="item?.label"
			>
				{{ item.label }}
			</option>
		</select>
		<button
			v-if="props.inputType === INPUT_TYPE.PASSWORD"
			@click="showPassword = !showPassword"
			class="input__show-password"
			type="button"
		>
			<svg width="20" height="20">
				<use :xlink:href="'#20-eye-' + (showPassword ? 'off' : 'bold')" />
			</svg>
		</button>
		<span v-if="inputError" class="error">{{ inputError }}</span>
	</label>
</template>

<style scoped lang="scss">
.input {
	display: block;
	position: relative;
}

.input__input {
	display: block;
	width: 100%;
	height: 42px;
	padding: 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: var(--color-main-text);
	background: var(--color-dop-back);
	border: 1px solid var(--color-grey);
	border-radius: 6px;

	&::placeholder {
		visibility: hidden;
	}

	&._textarea {
		height: 140px;
		min-height: 100px;
		max-height: 300px;
		resize: vertical;
	}

	&._select {
		background-image: var(--icon-arrow);
		background-repeat: no-repeat;
		background-position: calc(100% - 10px) center;
	}

	&._error {
		border-color: var(--color-error);
	}
}

.input__show-password {
	position: absolute;
	right: 8px;
	top: 10px;

	> svg {
		width: 24px;
		height: 24px;
		stroke: var(--color-main-text);
		stroke-width: 1px;
	}
}

.placeholder {
	position: absolute;
	display: block;
	left: 10px;
	top: 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	padding: 0 2px;
	border-radius: 4px;
	background: var(--color-dop-back);
	color: var(--color-main-text);
	pointer-events: none;
	transition: top 150ms ease-in-out;
	transition-property: top, font-size;

	&._active {
		top: -10px;
		font-size: 12px;
	}
}

.required {
	color: var(--color-error);
}

.error {
	font-size: 14px;
	color: var(--color-error);
}
</style>
