import { defineStore } from "pinia";
import { ref, computed } from "vue";
import type { Ref } from "vue";
import type { ConstObject } from "@app/interface";
import getBuKey from "lodash/get";

import { ResponseDetailsBombardier } from "./DetailsBombardierInterface";
import { formatNameCut, formatNameRemoveComma } from "@app/helpers";
import { SORT_PATH } from "./DetailsBombardierConsts";
import usei18n from '@app/i18n/i18n';

interface Options {
	country: Map<string, string>;
	role: Map<string, string>;
	competitor: Map<string, string>;
}
interface SelectedOptions {
	country: string;
	role: string;
	competitor: string;
}

const { t } = usei18n.global;

const ROLE_LIST: ConstObject = {
	midfielder: t('details_bombardier.roles.midfielder'),
	forward: t('details_bombardier.roles.forward'),
	defender: t('details_bombardier.roles.defender'),
	goalkeeper: t('details_bombardier.roles.goalkeeper')
},
All = "all";

// reset value
const resetOptionValue = (): Options => ({
	country: new Map(),
	role: new Map(),
	competitor: new Map(),
}),
resetSelectedOptionValue = (): SelectedOptions => ({
	country: All,
	role: All,
	competitor: All,
});

export const useDetailsBombardier = defineStore("DetailsBombardier", () => {
	const isReady: Ref<boolean> = ref(false),
		options: Ref<Options> = ref(resetOptionValue()),
		selectedOptions: Ref<SelectedOptions> = ref(resetSelectedOptionValue()),
		sortOption: Ref<string> = ref(SORT_PATH.STATISTICS_GOALS_SCORED),
		sortMax: Ref<boolean> = ref(false),
		sortType: Ref<StringConstructor | NumberConstructor> = ref(String),
		bombardier: Ref<any[]> = ref([]);

	// reset
	const reset = (): void => {
		isReady.value = false;
		options.value = resetOptionValue();
		selectedOptions.value = resetSelectedOptionValue();
		sortOption.value = SORT_PATH.STATISTICS_GOALS_SCORED;
		sortMax.value = false;
		sortType.value = String;
		bombardier.value = [];

		options.value.role.set(All, t('details_bombardier.roles.all'));
	};

	reset();

	const sortSelectMap = (list: Map<string, string>, name: string): Map<string, string> => {
		const sortList = [...list.entries()].sort((a, b) => +(a[1] > b[1]) - 1);
		return new Map([[All, name], ...sortList]);
	};

	const createBombardier = (result: ResponseDetailsBombardier): void => {
		bombardier.value = result.bombardier;

		for (const player of bombardier.value) {
			const name = player.playerName;
			player.playerName = formatNameRemoveComma(name);
			player.playerNameCut = formatNameCut(name);

			if (player.flag) {
				options.value.country.set(player.flag, player.playerCountry);
			}
			if (player.playerRole) {
				options.value.role.set(player.playerRole, ROLE_LIST[player.playerRole]);
			}
			if (player.competitorName) {
				options.value.competitor.set(player.competitorName, player.competitorName);
			}
		}

		options.value.country = sortSelectMap(options.value.country, t('details_bombardier.countries'));
		options.value.competitor = sortSelectMap(options.value.competitor, t('details_bombardier.competitors'));

		isReady.value = true;
	};

	const bombardierFilter = computed(() => {
			return bombardier.value.filter((i) => {
				const filters = [
					selectedOptions.value.competitor === All
						? true
						: i.competitorName === selectedOptions.value.competitor,
					selectedOptions.value.role === All ? true : i.playerRole === selectedOptions.value.role,
					selectedOptions.value.country === All ? true : i.flag === selectedOptions.value.country,
				];

				if (filters.every((f) => f)) return i;
			});
		}),
		bombardierResult = computed(() => {
			return bombardierFilter.value.sort((a, b) => {
				const valueA = getBuKey(a, sortOption.value),
					valueB = getBuKey(b, sortOption.value);

				if (sortType.value === String) {
					return sortMax.value
						? `${valueA}`.localeCompare(`${valueB}`)
						: `${valueB}`.localeCompare(`${valueA}`);
				} else if (sortType.value === Number) {
					return sortMax.value ? valueB - valueA : valueA - valueB;
				}

				return 0;
			});
		}),
		changeSort = (option: string, type: StringConstructor | NumberConstructor = String): void => {
			if (sortOption.value === option) {
				sortMax.value = !sortMax.value;
			} else {
				sortMax.value = true;
				sortOption.value = option;
				sortType.value = type;
			}
		};

	return {
		isReady,
		options,
		selectedOptions,
		createBombardier,
		bombardierResult,
		sortOption,
		sortMax,
		changeSort,
	};
});
