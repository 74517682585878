<script setup>
import { ref, computed, inject } from "vue";
import TabBtn from "@app/ui/tab-btn/TabBtn.vue";
import TableScoreProgress from "@app/ui/table/score/TableScoreProgress.vue";
import { useDetailsGame } from "../DetailsGameStore.js";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;
const sportType = inject("sportType", null);

const props = defineProps({
	api: String,
});

// store
const storeDetailsGame = useDetailsGame();
console.log(storeDetailsGame);
const periodType = {
	basketball: t('details_game.periodType.basketball'),
	tennis: t('details_game.periodType.tennis'),
};

const titleType = {
	basketball: t('details_game.titleType.basketball'),
	tennis: t('details_game.titleType.tennis'),
};

const formateScore = (score) => {
	const formattedScores = score.map((item) => `${item.home_score}:${item.away_score}`);
	return formattedScores.join(", ");
};

const navIsReady = ref(false);
const selectedPeriod = ref("");
const NAV_KEY = {};
const nav = {};

const updatePeriod = (periodKey) => {
	selectedPeriod.value = periodKey;
};

const calculatePeriodScore = () => {
	const NAV_KEY = {};
	let currentIndex = 1;

	for (const item of storeDetailsGame.progress) {
		if (item.type === "period_start" && item.period_name) {
			NAV_KEY[`ST_${currentIndex}`] = item.period_name;
			currentIndex++;
		}
	}

	currentIndex = 1;
	for (const key in NAV_KEY) {
		if (NAV_KEY.hasOwnProperty(key)) {
			nav[NAV_KEY[key]] = currentIndex + periodType[sportType];
			currentIndex++;
		}
	}

	if (!selectedPeriod.value) {
		selectedPeriod.value = NAV_KEY.ST_1;
	}

	navIsReady.value = true;

	const updatedPeriodScore = [];
	let startAdding = false;

	if (navIsReady.value) {
		let point = 0;

		for (const item of storeDetailsGame.progress) {
			if (startAdding) {
				if (item.type === "period_start") {
					break;
				}

				if (
					sportType === "basketball" &&
					item.type === "score_change" &&
					"home_score" in item &&
					"away_score" in item
				) {
					updatedPeriodScore.push({
						home_score: item.home_score,
						away_score: item.away_score,
					});
				} else if (sportType === "tennis") {
					let adding = true;
					if ((item.type === "point" && adding) || item.period) {
						if (!updatedPeriodScore["point_score"]) {
							updatedPeriodScore["point_score"] = {};
						}

						if (!updatedPeriodScore["point_score"][point]) {
							updatedPeriodScore["point_score"][point] = [];
						}
						if (item.type !== "period_score") {
							updatedPeriodScore.point_score[point].push({
								home_score: item.home_score,
								away_score: item.away_score,
							});
						} else {
							point++;
							adding = false;
						}
					}

					if (item.period && "home_score" in item && "away_score" in item) {
						updatedPeriodScore.push({
							ball: item.server,
							loss: item.result,
							home_score: item.home_score,
							away_score: item.away_score,
						});
					}
				}
			}
			if (item.type === "period_start" && item.period_name === selectedPeriod.value) {
				startAdding = true;
			}
		}
	}

	return updatedPeriodScore;
};

const periodScore = computed(() => calculatePeriodScore());
</script>

<template>
	<div v-if="storeDetailsGame.isReady" class="table-list-wrap">
		<div class="tabs-wrap _secondary">
			<ul class="tabs">
				<li v-if="navIsReady" v-for="(value, key) in nav" :key="key" class="tab__item">
					<TabBtn v-model="selectedPeriod" :tab-value="key" :tab-name="TAB_NAME" @click="updatePeriod(key)">
						{{ value }}
					</TabBtn>
				</li>
			</ul>
		</div>
		<div v-if="storeDetailsGame.progress.length > 0" class="wrap table-list-wrap">
			<div class="head">
				<h4 class="head__name">{{ t('details_game.titleType')[sportType] }}</h4>
			</div>
			<table v-for="(item, index) in periodScore" :key="'point' + index">
				<tr>
					<td>
						<TableScoreProgress
							:ball="item?.ball"
							:loss="item?.loss === 'receiver_won' ? true : false"
							:team-home="{ score: item?.home_score }"
							:team-away="{ score: item?.away_score }"
						/>
					</td>
				</tr>
				<tr>
					<td>
						<div v-if="sportType === 'tennis'" class="point_score">
							{{ formateScore(periodScore?.point_score?.[index]) }}
						</div>
					</td>
				</tr>
			</table>
			<div class="head">
				<h4 class="head__name">счет</h4>
				<div class="head__score">
					{{ periodScore?.[periodScore.length - 1]?.home_score }} -
					{{ periodScore?.[periodScore.length - 1]?.away_score }}
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.point_score {
	font-size: 12px;
	text-align: center;
}
.wrap {
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
	padding: 2px 2px 2px 6px;
	background: var(--color-dop-cta);
	border-radius: 6px;
}
.head__name {
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	color: hsl(220, 4%, 14%);
	margin: 0;
}
.head__score {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	color: var(--color-main-text);
	padding: 4px;
	background: var(--color-main-back);
	border-radius: 4px;
}
.tabs {
	margin-bottom: 12px;
}
</style>
