<script setup>
import { useDetailsGame } from "../DetailsGameStore.js";
import { useGame } from "@app/modules/game/GameStore";
import { inject, ref } from "vue";

import EventIcon from "../ui/EventIcon.vue";
import SuspensionIcon from "../ui/SuspensionIcon.vue";
import DetailsGameStatisticsPlayers from "./details-game-statistics-players/DetailsGameStatisticsPlayers.vue";
import DetailsVideo from "@app/modules/details/details-video/DetailsVideo.vue";
import TableScore from "@app/ui/table/score/TableScore.vue";
import TableGameTeams from "@app/ui/table/game-teams/TableGameTeams.vue";

const sportType = inject("sportType", null);
// store
const storeDetailsGame = useDetailsGame(),
	storeGame = useGame();

const enableTooltip = (commentaries) => {
	return commentaries?.length ? ["hover", "focus"] : [];
};
</script>

<template>
	<div v-if="sportType === 'tennis' || sportType === 'basketball' || sportType === 'table-tennis'">
		<div class="wrap table-list-wrap">
			<div class="head">
				<h4 class="head__name">Счет</h4>
			</div>
			<div class="team-wrap">
				<table>
					<tr class="tr-click">
						<td class="td-competitors">
							<TableGameTeams
								:team-home="{ name: storeGame?.game?.team_home?.[0] }"
								:team-away="{ name: storeGame?.game?.team_away?.[0] }"
							/>
						</td>
						<td class="td-score">
							<TableScore
								:team-home="{ score: storeGame?.game?.score?.team_home }"
								:team-away="{ score: storeGame?.game?.score?.team_away }"
								:period-scores="storeGame?.game?.period_scores"
							/>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div v-if="sportType !== 'tennis'">
		<div v-if="storeGame.game?.video?.videoLink" class="video-wrap">
			<DetailsVideo :video="storeGame.game.video" />
		</div>

		<div class="match">
			<div v-for="(wrap, key) in storeDetailsGame.compositions.period" :key="'wrap_' + key" class="wrap">
				<div class="head">
					<h4 class="head__name">{{ wrap.name }}</h4>
					<div class="head__score">{{ wrap.score.home }} - {{ wrap.score.away }}</div>
				</div>

				<ul class="list">
					<li
						v-for="(item, itemKey) in wrap.events"
						:key="'item_' + key + itemKey"
						:class="{ _away: item.competitor === 'away' }"
						class="list__item"
					>
						<span v-if="item?.match_time" class="list__time">
							{{ item.match_time }}
							<span v-if="item?.stoppage_time">+ {{ item.stoppage_time }}&nbsp;</span>'
						</span>

						<VTooltip :theme="'match'" :placement="'top'" :triggers="enableTooltip(item?.commentaries)">
							<div class="list__icon">
								<SuspensionIcon
									v-if="item.type === 'suspension'"
									:suspension-minutes="item.suspension_minutes"
								/>
								<EventIcon v-else-if="item.type === 'penalty_shootout'" :event="item.status" />
								<EventIcon v-else :event="item.type" :class="{ _red: item?.method === 'own_goal' }" />

								<div v-if="item.type === 'score_change'" class="list__score">
									{{ item?.home_score }} - {{ item?.away_score }}
								</div>
							</div>

							<template #popper>
								<div class="coment">
									<p v-for="(coment, comentKey) in item.commentaries" :key="'coment' + comentKey">
										{{ coment.text }}
									</p>
								</div>
							</template>
						</VTooltip>

						<div :class="{ _away: item.competitor === 'away' }" class="list__name">
							<a
								v-if="item.players?.scorer"
								:href="item.players.scorer.route"
								:title="item.players.scorer.name"
								class="list__name-primary"
							>
								{{ item.players.scorer.nameCut }}
							</a>
							<a
								v-if="item.players?.assist"
								:href="item.players.assist.route"
								:title="item.players.assist.name"
								class="list__name-secondary"
							>
								{{ item.players.assist.nameCut }}
							</a>
							<a
								v-if="item.players?.secondary_assist"
								:href="item.players.secondary_assist.route"
								:title="item.players.secondary_assist.name"
								class="list__name-secondary"
							>
								{{ item.players.secondary_assist.nameCut }}
							</a>
						</div>
					</li>
				</ul>
			</div>

			<div v-if="storeDetailsGame.compositions.mainReferee" class="wrap">
				<div class="head">
					<h4 class="head__name">{{ $t("details_game.match_info") }}</h4>
				</div>

				<ul class="list">
					<li class="list__item">
						<div class="list__icon">
							<EventIcon :name="'20-whistle'" />
						</div>
						<span class="list__info">{{ $t("details_game.referee") }}</span>
						<span class="list__info-name">{{ storeDetailsGame.compositions.mainReferee }}</span>
					</li>
					<li v-if="storeDetailsGame.compositions?.venue?.name" class="list__item">
						<div class="list__icon">
							<EventIcon :name="'20-stadium'" />
						</div>
						<span class="list__info">{{ $t("details_game.stadium") }}</span>
						<span class="list__info-name">{{ storeDetailsGame.compositions.venue.name }}</span>
					</li>
					<li v-if="storeDetailsGame.compositions?.venue?.capacity" class="list__item">
						<div class="list__icon">
							<EventIcon :name="'20-people'" />
						</div>
						<span class="list__info">{{ $t("details_game.attendance") }}</span>
						<span class="list__info-name">{{ storeDetailsGame.compositions.venue.capacity }}</span>
					</li>
				</ul>
			</div>

			<div v-if="storeDetailsGame.statisticsPlayers.top" class="wrap">
				<div class="head">
					<h4 class="head__name">{{ $t("details_game.top_players_statistics") }}</h4>
				</div>
				<DetailsGameStatisticsPlayers :statistics-players="storeDetailsGame.statisticsPlayers.top" />
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.video-wrap {
	margin-bottom: 12px;
}

// v-popper
.v-popper--theme-match {
	.v-popper__inner {
		background: var(--color-main-text);
		color: var(--color-dop-back);
		padding: 4px 6px;
		border-radius: 6px;
	}

	.v-popper__arrow-outer {
		border-color: var(--color-main-text);
	}

	.coment {
		max-width: 450px;

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 120%;
			margin: 6px 0;
		}

		> * {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
</style>

<style scoped lang="scss">
.match {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.wrap {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
	padding: 2px 2px 2px 6px;
	background: var(--color-dop-cta);
	border-radius: 6px;
}

.head__name {
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	color: hsl(220, 4%, 14%);
	margin: 0;
}

.head__score {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	color: var(--color-main-text);
	padding: 4px;
	background: var(--color-main-back);
	border-radius: 4px;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.list__item {
	display: flex;
	align-items: center;
	gap: 6px 12px;
	flex-wrap: wrap;
	padding: 0px 6px 4px;
	background: var(--color-border);
	background-size: 100% 1px;
	background-repeat: no-repeat;
	background-position: bottom left;

	&._away {
		flex-direction: row-reverse;
	}

	&:last-child {
		background: none;
	}
}

.list__time {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
}

.list__icon {
	display: flex;
	align-items: center;
	padding: 2px;
	gap: 6px;
	background: var(--color-main-back);
	border-radius: 4px;
}

.list__score {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
}

.list__name {
	display: flex;
	align-items: center;
	gap: 6px 12px;
	flex-wrap: wrap;

	&._away {
		flex-direction: row-reverse;
	}
}
.list__name-primary,
.list__name-secondary {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	text-decoration: none;
}

.list__name-primary {
	color: var(--color-main-text);
}
.list__name-secondary {
	color: var(--color-dop-text);
}

.list__info,
.list__info-name {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
}

.list__info-name {
	margin-left: auto;
}
.td-score {
	width: 30px;
}
.td-competitors {
	flex: 1;
	white-space: nowrap;
	overflow: hidden;

	.team-wrap {
		overflow: hidden;
		.team {
			padding-right: 0px;
			.name {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

.team-wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 6px;
	padding: 3px 4px;
}

.team-group {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.team {
	display: flex;
	align-items: center;
	gap: 6px;
	font-size: 12px;
	line-height: 100%;
}

@media screen and (max-width: 768px) {
	.team {
		padding-right: 30px;
	}
}

@media screen and (max-width: 570px) {
	.team {
		padding-right: 12px;

		& > ._yellow,
		& > ._red {
			display: none;
		}
	}
}

@media screen and (max-width: 480px) {
	.name {
		margin-right: auto;
	}
}

.main-table {
	tr {
		td.td-status {
			width: 20px;
			padding-left: 4px;
			// padding-right: 8px;
		}
	}
}

.line__icon {
	display: none;
}

.td-date {
	max-width: 70px;
}

.td-league {
	max-width: 110px;
}

.main-table {
	.th-stats,
	.td-stats {
		// TODO
		padding-right: 0;
		max-width: 86px;
	}
}

.td-stats__wrap,
.th-stats__wrap {
	display: flex;
	justify-content: space-around;

	svg {
		fill: var(--color-main-text);
		stroke: var(--color-main-text);
	}
}

.td-stats-item {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	width: 30px;

	span {
		font-size: 14px;
		display: flex;
		width: 20px;
		height: 20px;
		align-items: center;
		justify-content: center;
		border-radius: 4px;

		&._active {
			background: var(--color-dop-back);
		}
	}
}

@media screen and (max-width: 620px) {
	.table-wrap {
		.main-table {
			tr {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 5px 0px;
				gap: 5px 15px;

				td {
					display: flex;
					align-items: center;
					padding: 0;
				}
			}
			.tr-title {
				display: none;
			}
			.td-competitors {
				flex: 1;
				white-space: nowrap;
				overflow: hidden;

				.team-wrap {
					overflow: hidden;
					.team {
						padding-right: 0px;
						.name {
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
			.th-stats,
			.td-stats {
				max-width: none;
			}

			.td-stats {
				order: 1;
				width: 100%;
				height: auto;
				padding-left: 12px;
				gap: 15px;
			}

			.td-stats__wrap {
				gap: 15px;
			}

			.td-stats-item {
				width: auto;
				gap: 3px;
				span {
					font-size: 12px;
				}
			}
		}
		.line__icon {
			display: block;
		}
	}
	.main-table {
		.team,
		.icon-name span {
			font-size: 12px;
		}

		.td-stats,
		.tr-title {
			display: none;
		}

		.td-league {
			width: 160px;
		}
	}
}

@media screen and (max-width: 480px) {
	.main-table {
		.td-league {
			width: 70px;
		}
	}
}
.score-wrap {
	padding: 3px 4px;
	display: flex;
	gap: 6px;
}

.gol,
.main,
.period {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-template: 2px;
}

.status-spin,
.score {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 2px;
	min-height: 16px;
	min-width: 16px;
	border-radius: 4px;
	background: var(--color-main-back);
	font-size: 12px;
	line-height: 100%;
	color: var(--color-main-text);
	text-align: center;
}

.score {
	&._active {
		color: hsl(220, 4%, 14%);
		background: var(--color-dop-cta);
	}
}

.period {
	> .score {
		color: var(--color-dop-text);
	}
}

.status-spin {
	opacity: 0;
	visibility: hidden;
	color: hsl(220, 4%, 14%);
	background: var(--color-dop-cta);
	padding: 2px 4px;

	animation-direction: alternate-reverse;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;

	&._active {
		opacity: 1;
		visibility: visible;

		animation-name: gol-spin;
	}
}

@keyframes gol-spin {
	0% {
		opacity: 1;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}

@media screen and (max-width: 570px) {
	.period {
		display: none;
	}
}
</style>
