<script setup>
import { ref, computed } from "vue";

import { useDetailsGame } from "../../DetailsGameStore.js";

import TabBtn from "@assets/app/ui/tab-btn/TabBtn.vue";
import DetailsGameStatisticsPlayers from "./DetailsGameStatisticsPlayers.vue";

// store
const storeDetailsGame = useDetailsGame();

// nav
const navName = {
		ALL: "all",
		HOME: "home",
		AWAY: "away",
	},
	TAB_NAME = "statistics_players_name";

const nav = ref(navName.ALL);

// statisticsPlayersList
const statisticsPlayersList = computed(() => {
	try {
		switch (nav.value) {
		case navName.ALL:
			return [
				...storeDetailsGame.statisticsPlayers.home.players,
				...storeDetailsGame.statisticsPlayers.away.players,
			];

		case navName.HOME:
			return storeDetailsGame.statisticsPlayers.home.players;

		case navName.AWAY:
			return storeDetailsGame.statisticsPlayers.away.players;

		default:
			return [];
	}
	} catch (error) {
		console.log("Не задан: storeDetailsGame",error);
	}
});
</script>

<template>
	<div class="tabs-wrap _secondary">
		<ul class="tabs">
			<li class="tab__item">
				<TabBtn v-model="nav" :tab-value="navName.ALL" :tab-name="TAB_NAME"> Итого </TabBtn>
			</li>
			<li class="tab__item" v-if="storeDetailsGame.statisticsPlayers.home">
				<TabBtn v-model="nav" :tab-value="navName.HOME" :tab-name="TAB_NAME">
					{{ storeDetailsGame.statisticsPlayers.home.name }}
				</TabBtn>
			</li>
			<li class="tab__item" v-if="storeDetailsGame.statisticsPlayers.away">
				<TabBtn v-model="nav" :tab-value="navName.AWAY" :tab-name="TAB_NAME">
					{{ storeDetailsGame.statisticsPlayers.away.name }}
				</TabBtn>
			</li>
		</ul>
	</div>

	<DetailsGameStatisticsPlayers :statistics-players="statisticsPlayersList" />
</template>
