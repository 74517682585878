export enum INPUT_TYPE {
	TEXT = "text",
	EMAIL = "email",
	PASSWORD = "password",
	FILE = "file",
	IMAGE = "image",
	COLOR = "color",
	NUMBER = "number",
	TEL = "tel",
	SEARCH = "search",
	DATE = "date",
	TEXTAREA = "textarea",
	SELECT = "select",
}
