<script setup>
import { bookieLogoUrl } from "@app/helpers.js";
import Toggle from "@assets/app/ui/toggle/Toggle.vue";
import { useState } from "../HeaderStore";

import { ref, watch, inject } from "vue";
import { directive as vClickAway } from "vue3-click-away";

const presentData = inject("presentData", null);

const stateHeader = useState();

// present
const btnPresent = () => {
		stateHeader.presentActive = !stateHeader.presentActive;
		if (stateHeader.presentActive) stateHeader.burgerActive = false;stateHeader.langActive = false;
	},
	hidePresent = () => {
		stateHeader.presentActive = false;
	};

// theme
const localStorageTheme = window.localStorage.getItem("theme_dark"),
	theme = ref(window.matchMedia("(prefers-color-scheme: dark)").matches);

const switchTheme = () => {
	document.body.classList.toggle("_dark", theme.value);
	document.body.classList.toggle("_light", !theme.value);
};

if (localStorageTheme) {
	theme.value = localStorageTheme === "true";
}

</script>

<template>
	<div class="present" v-click-away="hidePresent">
		<button v-if="presentData.length > 0" @click.stop="btnPresent" class="present-btn">
			<svg width="25" height="25">
				<use :xlink:href="'#20-present'" />
			</svg>
		</button>

		<transition name="list">
			<div v-if="stateHeader.presentActive" class="present__drop">
				<ul class="present__list">
					<li class="present__item">
						<span class="present__title">
							<img :src="bookieLogoUrl(presentData[0].bookmaker)" height="60" width="260" />
							<div class="present__content">
								<span class="present__text">{{ presentData[0].text ?? "" }}</span>
								<span class="present__sum">&nbsp{{ presentData[0].sum ?? "" }}&#8381</span>
							</div>
						</span>
					</li>
					<li class="present__item">
						<button class="tab-btn" :style="{ 'background-color': presentData[0].color || '#bdff2e' }">
							<a class="link" title="Забрать" :href="presentData[0].link ?? ''" target="_blank">
								<span class="name">{{ presentData[0].textButton ?? "" }}</span>
							</a>
						</button>
					</li>
					<li class="present__item"><span class="present__req">{{ presentData[0].inn ?? "" }}</span></li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.tab-btn {
	width: 100%;
	margin: 10px 0;
}
.link {
	text-decoration: none;
	display: inline-block;
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: 6px;
}
.name {
	color: #222325;
}
.present__banner {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}
.present {
	position: relative;
}

.present__drop {
	position: absolute;
	top: 100%;
	right: -14px;
	// width: calc(100vw - ($block-padding-hor-m * 2));
	min-width: 355px;
	background: var(--color-dop-back);
	border-radius: 6px;
	z-index: 100;
	padding: 5px 0;
	box-shadow: 0 10px 30px var(--color-drop-shadow);
}

.present__list {
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: 1fr;
	padding: 0 5px;
	margin: 0 10px;
}

.present__toggle {
	margin-right: 6px;
}

.present__title {
	display: flex;
	align-items: center;
	margin: 10px 0;
}
.present__content {
	margin-left: 10px;
	width: 230px;
	font-weight: bold;
}

.present__sum {
	display: inline;
	color: #ff6427;
	font-family: Montserrat;
	font-size: 20px;
	font-weight: 700;
}
.present__text {
	display: inline;
	font-family: Montserrat;
	font-size: 20px;
	font-weight: 700;
}
.present__req {
	font-size: 12px;
}
.present__icon {
	stroke: var(--color-main-text);
	stroke-width: 1;
	flex-shrink: 0;
	margin-right: 10px;
}

// transition
.list-enter-active,
.list-leave-active {
	transition: 250ms ease-in-out;
	transition-property: opacity, transform;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateY(-10px);
}

@media screen and (max-width: 768px) {
	.present__drop {
		border: 1px solid var(--color-black);
		right: -75px;
	}
	.present__title {
		display: flex;
		flex-flow: column nowrap;
	}
	.present__content {
		margin-top: 10px;
	}
}

@keyframes backgroundPulse {
	0% {
		background: var(--color-dop-cta);
	}
	50% {
		background: #bdff2e;
	}
	100% {
		background: var(--color-dop-cta);
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.present-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 40px;
	height: 40px;
	padding: 8px;
	border-radius: 50%;
	animation: pulse 1s infinite, backgroundPulse 2s infinite;

	> svg {
		stroke: hsl(0, 0%, 100%);
		transition: stroke 150ms ease-in-out;
	}

	&:hover {
		color: var(--color-dop-cta);
		> svg {
			stroke: var(--color-dop-cta);
		}
	}
}
.present-pic {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 25px;
	height: 25px;
	padding: 2px;
	margin-right: 40px;
	background: #ffffffc8;
	border-radius: 30%;
}
</style>
