<script setup>
import { getApi } from "@app/helpers.js";

import ErrorMessage from "@app/ui/error-message/ErrorMessage.vue";

import { useDetailsGrid } from "./DetailsStore.js";

import DetailsGridCommand from "./components/DetailsGridCommand.vue";
import DetailsGridSkeleton from "./components/DetailsGridSkeleton.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = defineProps({
	api: String,
});

// store
const storeDetailsGrid = useDetailsGrid();

// get coef
if (!storeDetailsGrid.isReady) {
	getApi(props.api).then((result) => {
		storeDetailsGrid.createGrid(result);
		console.log(result);
	});
}

// created gridColumn
const stageName = {
	round_of_64: "1/32",
	round_of_32: "1/16",
	round_of_16: "1/8",
	quarterfinal: "1/4",
	semifinal: "1/2",
	qualification_round_1: "Квалификационный раунд 1",
	qualification_round_2: "Квалификационный раунд 2",
	qualification_round_3: "Квалификационный раунд 3",
	qualification_final: "Финал",
};
</script>

<template>
	<div v-if="storeDetailsGrid.isReady" class="table table-list-wrap">
		<div class="stage-wrap">
			<div
				v-for="(column, columnName, columnKey) in storeDetailsGrid.grid.gridColumn"
				:key="'stage-wrap' + columnKey"
				class="stage-wrap__item"
			>
			{{ t(`details_grid.stageName.${[columnName]}`) }}
			</div>
			<div
				v-if="
					storeDetailsGrid.grid.finalColumn.final?.[0].sport_events[0].competitors[0] &&
					storeDetailsGrid.grid.finalColumn.final?.[0].sport_events[0].competitors[1]
				"
				class="stage-wrap__item"
			>
			{{ t('details_grid.stageName.qualification_final') }}
			</div>
		</div>
		<div class="column-wrap">
			<div
				v-for="(column, columnName, columnKey) in storeDetailsGrid.grid.gridColumn"
				:key="columnName + columnKey"
				class="column"
			>
				<div v-for="(game, gameKey) in column" :key="'game_' + gameKey" class="game-wrap">
					<div v-if="columnKey !== 0" class="border">
						<div class="border__line"></div>
					</div>
					<component
						:is="game?.sport_events?.[0]?.route ? 'a' : 'div'"
						:href="game?.sport_events?.[0]?.route ?? false"
						class="game"
					>
						<DetailsGridCommand
							:competitors="game?.sport_events?.[0]?.competitors?.[0]"
							:competitors_2nd="game?.sport_events?.[0]?.competitor_2nd?.[1]"
						/>
						<DetailsGridCommand
							:competitors="game?.sport_events?.[0]?.competitors?.[1]"
							:competitors_2nd="game?.sport_events?.[0]?.competitor_2nd?.[0]"
						/>
					</component>
				</div>
			</div>

			<div
				class="column _final"
				v-if="
					storeDetailsGrid.grid.finalColumn.final?.[0].sport_events[0].competitors[0] &&
					storeDetailsGrid.grid.finalColumn.final?.[0].sport_events[0].competitors[1]
				"
			>
				<div class="game-wrap">
					<div class="border">
						<div class="border__line"></div>
					</div>
					<component
						:is="storeDetailsGrid.grid.finalColumn.final?.[0]?.sport_events?.[0]?.route ? 'a' : 'div'"
						:href="storeDetailsGrid.grid.finalColumn.final?.[0]?.sport_events?.[0]?.route ?? false"
						class="game"
					>
						<DetailsGridCommand
							:competitors="storeDetailsGrid.grid.finalColumn.final?.[0].sport_events[0].competitors[0]"
						/>
						<DetailsGridCommand
							:competitors="storeDetailsGrid.grid.finalColumn.final?.[0].sport_events[0].competitors[1]"
						/>
					</component>
					<div
						v-if="
							storeDetailsGrid.grid.finalColumn['3rd_place_playoff'] ||
							storeDetailsGrid.grid.finalColumn['3rd_place_final']
						"
						class="place_3rd"
					>
						<span class="place_3rd__name">{{ $t(`details_grid.last`) }}</span>
						<component
							:is="
								storeDetailsGrid.grid.finalColumn[
									storeDetailsGrid.grid.finalColumn['3rd_place_final'] !== undefined
										? '3rd_place_final'
										: '3rd_place_playoff'
								][0]?.sport_events?.[0]?.route
									? 'a'
									: 'div'
							"
							:href="
								storeDetailsGrid.grid.finalColumn[
									storeDetailsGrid.grid.finalColumn['3rd_place_final'] !== undefined
										? '3rd_place_final'
										: '3rd_place_playoff'
								][0]?.sport_events?.[0]?.route ?? false
							"
							class="game"
						>
							<DetailsGridCommand
								:competitors="
									storeDetailsGrid.grid.finalColumn[
										storeDetailsGrid.grid.finalColumn['3rd_place_final'] !== undefined
											? '3rd_place_final'
											: '3rd_place_playoff'
									][0].sport_events[0].competitors[0]
								"
							/>
							<DetailsGridCommand
								:competitors="
									storeDetailsGrid.grid.finalColumn[
										storeDetailsGrid.grid.finalColumn['3rd_place_final'] !== undefined
											? '3rd_place_final'
											: '3rd_place_playoff'
									][0].sport_events[0].competitors[1]
								"
							/>
						</component>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ErrorMessage v-else-if="storeDetailsGrid.isError" />

	<DetailsGridSkeleton v-else />
</template>

<style scoped lang="scss">
.table {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: auto;
	scroll-snap-type: x mandatory;
	padding: 12px 0;
}

.stage-wrap {
	display: flex;
	align-items: center;
	height: 20px;
	border-radius: 6px;
	padding: 0 12px;
}

.stage-wrap__item {
	width: 220px;
	flex-shrink: 0;
	background: var(--color-dop-cta);
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #222325;

	&:first-child {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		width: 200px;
	}

	&:last-child {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
}

.column-wrap {
	display: flex;
	align-items: stretch;
	width: 100%;
}

.column {
	display: grid;
	grid-auto-flow: row;
	grid-auto-rows: 1fr;
	grid-row-gap: 12px;

	&:first-child {
		padding: 12px 0 12px 12px;
		display: flex;
		flex-direction: column;

		.game-wrap {
			&:nth-child(2n + 2) {
				margin-bottom: 18px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&:last-child {
		padding-right: 12px;
	}
}

.game-wrap {
	position: relative;
	display: flex;
	align-items: center;
}

.place_3rd {
	position: absolute;
	right: 0;
	top: calc(50% + (58px / 2 + 12px));
}

.place_3rd__name {
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	color: var(--color-main-text);
	text-align: center;
	margin-bottom: 4px;
}

.border {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 20px;
	height: 50%;

	&::before,
	&::after {
		content: "";
		display: block;
		width: 10px;
		height: 50%;
		border: 1px solid var(--color-dop-text);
		border-left: none;
	}

	&::before {
		border-bottom: none;
		border-top-right-radius: 10px;
	}

	&::after {
		border-top: none;
		border-bottom-right-radius: 10px;
	}
}

.border__line {
	position: absolute;
	right: 0;
	top: 50%;
	width: 11px;
	height: 1px;
	background: var(--color-dop-text);
}

.game {
	display: grid;
	grid-template-rows: repeat(2, 20px);
	grid-row-gap: 8px;
	width: 200px;
	padding: 4px 6px 4px 4px;
	border-radius: 6px;
	background: var(--color-black);
	border: 1px solid var(--color-dop-text);
	text-decoration: none;

	&._active {
		border-color: var(--color-dop-cta);
	}

	&:hover {
		border-color: var(--color-main-cta);
	}
}
</style>
