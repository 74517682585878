const KEY_NAME = {
		SIGN_UP: {
			NAME: "registration[name]",
			EMAIL: "registration[email]",
			PHONE: "registration[phone]",
			PASSWORD: "registration[password]",
			AGREE_TERMS: "registration[agreeTerms]",
			TOKEN: "registration[_token]",
		},
		SIGN_IN: {
			EMAIL: "form[login]",
			PASSWORD: "form[password]",
			TOKEN: "form[_token]",
		},
		PASS_RESET: {
			EMAIL: "reset_password_request_form[email]",
		},
	},
	USER_API = {
		SIGNUP: "/registration/",
		SIGN_IN: "/auth/",
		RESET_PASSWORD: "/account/reset-password/",
	};

export { KEY_NAME, USER_API };
