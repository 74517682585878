import { defineStore } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import { SPORT_TYPE_NAME } from "@app/consts.ts";
import { getIsOpenItemLeague, currentLocale } from "@app/helpers.ts";
import cloneDeep from "lodash/cloneDeep";

import type { SportResponse } from "./sportInterface";
import { SPORT_TABS_NAME } from "./sportConst";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;
const LOCALE = currentLocale().locale
const SELECTED_DATE_RANGE: number = 7;

interface DateListItem {
	date: string;
	label: string;
	selected: boolean;
	repeatRequest: boolean;
}

interface ListsValueItem {
	[SPORT_TABS_NAME.TOTAL]: any[];
	[SPORT_TABS_NAME.LIVE]: any[];
	[SPORT_TABS_NAME.COEFFICIENT]: any[];
	[SPORT_TABS_NAME.COMPLETED]: any[];
	[SPORT_TABS_NAME.TIMETABLE]: any[];
	isReady: boolean;
	date: string;
}

// reset value
const resetDateListValue = (): DateListItem[] => {
		const list: DateListItem[] = [];

		for (let index = -SELECTED_DATE_RANGE, realIndex = 0; index <= SELECTED_DATE_RANGE; index++, realIndex++) {
			const currentDate = new Date(new Date().toISOString().slice(0, 10));
			let label: string,
				date: number | string = currentDate.setDate(currentDate.getDate() + index);
			date = new Date(date).toISOString().slice(0, 10);

			if (index === 0) {
				label =  t('tournament.today');
			} else {
				label = new Intl.DateTimeFormat(LOCALE, {
					weekday: "short",
					month: "numeric",
					day: "2-digit",
					year: "2-digit",
				}).format(new Date(date));
			}

			list.push({
				date: date,
				label: label,
				selected: index === 0,
				repeatRequest: index >= 0,
			});
		}

		return list;
	},
	resetListsValue = (dateList: DateListItem[]): ListsValueItem[] => {
		const list: ListsValueItem[] = [];

		for (let index = 0; index < SELECTED_DATE_RANGE * 2 + 1; index++) {
			list[index] = {
				[SPORT_TABS_NAME.TOTAL]: [],
				[SPORT_TABS_NAME.LIVE]: [],
				[SPORT_TABS_NAME.COEFFICIENT]: [],
				[SPORT_TABS_NAME.COMPLETED]: [],
				[SPORT_TABS_NAME.TIMETABLE]: [],
				isReady: false,
				date: dateList[index].date,
			};
		}

		return list;
	};

export const useSport = defineStore("Sport", () => {
	const sportType: Ref<string> = ref(SPORT_TYPE_NAME.FOOTBALL),
		nav: Ref<SPORT_TABS_NAME> = ref(SPORT_TABS_NAME.TOTAL),
		selectedDate: Ref<number> = ref(SELECTED_DATE_RANGE),
		dateList: Ref<DateListItem[]> = ref(resetDateListValue()),
		lists: Ref<ListsValueItem[]> = ref(resetListsValue(dateList.value));

	// reset
	// const reset = () => {
	// 	nav.value = SPORT_TABS_NAME.TOTAL;
	// 	selectedDate.value = SELECTED_DATE_RANGE;
	// 	dateList.value = resetDateListValue();
	// 	lists.value = resetListsValue(dateList.value);
	// };

	// reset();

	const createLists = (response: SportResponse[]) => {
		let generalList = response;
		// start time sort
		const isOpenList = getIsOpenItemLeague(sportType.value);

		for (const league of generalList) {
			const isOpen = isOpenList?.[league.id];
			if (isOpen !== undefined) league.is_open = isOpen;

			league.games.sort((a, b) => {
				if (a.status === t('game_head.status.over') && b.status === t('game_head.status.over')) {
					return +a.start_time - +b.start_time;
				} else if (b.status === t('game_head.status.over')) {
					return -1;
				} else {
					return +a.start_time - +b.start_time;
				}
			});
		}

		const selectedList = lists.value[selectedDate.value];

		// all
		selectedList[SPORT_TABS_NAME.TOTAL] = cloneDeep(generalList);

		// live
		selectedList[SPORT_TABS_NAME.LIVE] = cloneDeep(generalList).filter((league) => {
			league.games = league.games.filter((game) => {
				if (game.status && game.status !== t('game_head.status.over')) return game;
			});
			if (league.games.length) return league;
		});

		// coefficient
		selectedList[SPORT_TABS_NAME.COEFFICIENT] = selectedList[SPORT_TABS_NAME.TOTAL];

		// completed
		selectedList[SPORT_TABS_NAME.COMPLETED] = cloneDeep(generalList).filter((league) => {
			league.games = league.games.filter((game) => {
				if (game.status === t('game_head.status.over')) return game;
			});
			if (league.games.length) return league;
		});

		// timetable
		selectedList[SPORT_TABS_NAME.TIMETABLE] = cloneDeep(generalList).filter((league) => {
			league.games = league.games.filter((game) => {
				if (!game.status && new Date() <= new Date(+game.start_time * 1000)) return game;
			});
			if (league.games.length) return league;
		});

		// isReady
		selectedList.isReady = true;
	};

	return { sportType, nav, selectedDate, dateList, lists, createLists };
});
