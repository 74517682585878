<template>
	<svg width="100" height="100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="12" r="10" />
	</svg>
</template>

<style scoped lang="scss">
circle {
	stroke: var(--color-main-text);
	stroke-width: 1px;
	stroke-dasharray: 62 62;
	stroke-dashoffset: 58;
	stroke-linecap: round;
	animation: load-draw 1500ms linear 0ms infinite alternate-reverse, load-rotate 1400ms linear 0ms infinite;
	transform-origin: center;
}

@keyframes load-draw {
	0% {
		stroke-dashoffset: 61;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes load-rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}
</style>
