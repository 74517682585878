<script setup lang="ts">
import { ref, inject, computed } from "vue";

import { getApi } from "@app/helpers.ts";

import { useRanking } from "./RankingStore";

import TabBtn from "@app/ui/tab-btn/TabBtn.vue";
import RankingTable from "./components/RankingTable.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const api = inject<string>("api", "");

// store
const storeRanking = useRanking();

// show-more
const showItemsList = ref(16);

const selectedRank = ref(0);
const selectedCountry = ref("");
const searchValue = ref("");

// linkTu
const linkTu = (url: string | null): void => {
	if (url) window.location.href = url;
};
if (!storeRanking.isReady) {
	let route = api;
	if (route) {
		const navIndex = storeRanking.NAV_ITEM.findIndex((item) => item.route === route);
		selectedRank.value = navIndex;
	} else {
		route = storeRanking.NAV_ITEM[0].route;
	}
	getApi(route).then((result) => {
		storeRanking.createRanking(result);
		storeRanking.isReady = true;
	});
}

const filterRankingByName = () => {
	if (storeRanking.ranking) {
		const value = searchValue.value.trim().toLowerCase();
		if (!value) {
			return storeRanking.ranking;
		} else {
			return storeRanking.ranking.filter((item) => item.competitor.name.toLowerCase().includes(value));
		}
	}
};
const filterRankingByCountry = () => {
	if (storeRanking.ranking) {
		if (!selectedCountry.value) {
			return storeRanking.ranking;
		} else {
			return storeRanking.ranking.filter((item) => item.competitor.country === selectedCountry.value);
		}
	}
};
const filteredRanking = computed(() => {
	const filteredByCountry = filterRankingByCountry();
	const filteredByName = filterRankingByName();

	if (filteredByCountry && filteredByName) {
		const mergedResults = filteredByCountry.filter((item) => filteredByName.includes(item));
		return mergedResults.slice(0, showItemsList.value);
	} else {
		return [];
	}
});

const uniqueCountries = computed(() => {
	const countries = new Set<string>();
	if (storeRanking.ranking) {
		storeRanking.ranking.forEach((item) => {
			const country = item.competitor.country;
			if (country && country.trim() !== "") {
				countries.add(country);
			}
		});
	}
	return Array.from(countries);
});
const navItem = storeRanking.NAV_ITEM.find((navItem) => navItem.route === api);
const name = navItem ? navItem.name : "";
</script>

<template>
	<div>
		<span class="name"></span>
		<h1 class="smallH1">{{  t('ranking.ranking')  }}{{ api ? name :  t('ranking.empty_data') }}</h1>
		<div v-if="storeRanking.isReady" class="table-list-wrap">
			<div class="tabs-wrap _secondary">
				<ul class="tabs">
					<li v-for="(item, key) in storeRanking.NAV_ITEM" :key="key" class="tab__item">
						<TabBtn
							v-if="item.route"
							v-model="selectedRank"
							:tab-value="key"
							:tab-name="item.name"
							@click="linkTu(item.route)"
						>
							{{ item.name }}
						</TabBtn>
					</li>
				</ul>
			</div>
			<div class="select-wrap">
				<div>
					<input v-model.trim="searchValue" type="search" name="search" :placeholder="t('ranking.placeholder') " />
				</div>
				<select v-model="selectedCountry" class="select" name="selects_country">
					<option value="">{{  t('ranking.all_country')  }}</option>
					<option v-for="country in uniqueCountries" :key="country" :value="country">
						{{ country }}
					</option>
				</select>
			</div>
			<div v-if="storeRanking.ranking && storeRanking.ranking.length > 0" class="wrap table-list-wrap">
				<table class="main-table">
					<RankingTable :itemRanking="filteredRanking" />
				</table>
			</div>
			<div class="show-btn">
				<button
					v-if="
						filteredRanking.length != storeRanking.ranking?.length &&
						filteredRanking.length >= showItemsList
					"
					@click="showItemsList += 30"
					class="show-more"
				>
					Показать ещё
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.point_score {
	font-size: 12px;
	text-align: center;
}
.wrap {
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
	padding: 2px 2px 2px 6px;
	background: var(--color-dop-cta);
	border-radius: 6px;
}
.head__name {
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	color: hsl(220, 4%, 14%);
	margin: 0;
}
.head__score {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	color: var(--color-main-text);
	padding: 4px;
	background: var(--color-dop-back);
	border-radius: 4px;
}
.tabs {
	margin-bottom: 12px;
}
.show-more {
	align-self: center;
}
.show-btn {
	display: flex;
	justify-content: center;
	margin: 3px 0;
}
.select-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 15px;
	padding: 10px;
}
.ranking-filter {
	background: var(--color-dop-back);
	border-radius: 4px;
	box-sizing: border-box;
	justify-content: space-between;
	margin-left: 20px;
	margin-bottom: 8px;
	padding: 8px 12px;
}
.select {
	display: block;
	height: 42px;
	padding: 0 26px 0 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: var(--color-main-text);
	border: 1px solid var(--color-grey);
	border-radius: 6px;
	background: var(--color-dop-back);
	background-image: var(--icon-arrow);
	background-repeat: no-repeat;
	background-position: calc(100% - 4px) center;

	&::placeholder {
		visibility: hidden;
	}
}
@media screen and (max-width: 500px) {
	.select-wrap {
		gap: 5px;
	}
}
</style>
