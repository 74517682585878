<script setup lang="ts">
import { ref, computed, toRaw } from "vue";
import { getApi, linkTitle } from "@app/helpers.ts";
import TableDate from "@app/ui/table/date/TableDate.vue";
import TableTime from "@app/ui/table/time/TableTime.vue";
import TableIconName from "@app/ui/table/icon-name/TableIconName.vue";
import TableGameTeams from "@app/ui/table/game-teams/TableGameTeams.vue";
import TableScore from "@app/ui/table/score/TableScore.vue";
import TableGameStatus from "@app/ui/table/game-status/TableGameStatus.vue";
import RouterLinkScroll from "@app/ui/router-link-scroll/RouterLinkScroll.vue";

import type { TeamResultsItem } from "../teamInterface";
import { RESULTS_STATUS } from "../teamConsts";
import { useTeam } from "../TeamStore";
import TeamResultsSkeleton from "./TeamResultsSkeleton.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = withDefaults(
	defineProps<{
		api: string;
		title?: string;
		navTo?: string;
		statusGame?: RESULTS_STATUS.CLOSED | RESULTS_STATUS.NOT_STARTED;
		showItems?: number;
	}>(),
	{
		statusGame: RESULTS_STATUS.CLOSED,
		showItems: 20,
	}
);

// store
const storeTeam = useTeam();

if (!storeTeam.results.isReady && !storeTeam.results.requestIsGone && props.api) {
	storeTeam.results.requestIsGone = true;
	getApi<{ recentMatches: TeamResultsItem[] }>(props.api).then((response) => {
		if (response) {
			storeTeam.addResults(response.recentMatches);
		}
	});
}

// show-more
const showItemsList = ref(props.showItems);

const cutList = computed(() => {
	const list = storeTeam.results[props.statusGame];

	return toRaw(list).slice(0, showItemsList.value);
});
console.log(cutList);

// linkTu
const linkTu = (url: (string & Location) | null): void => {
	if (url) window.location = url;
};
</script>

<template>
	<div v-if="storeTeam.results.isReady" class="group">
		<h2 v-if="title" class="group__title">{{ title }}</h2>
		<div class="table-container">
			<table class="main-table">
				<tr>
					<th class="th-title _center">{{ t('team.date') }}</th>
					<th class="th-title">{{ t('team.ligue') }}</th>
					<th class="th-title">{{ t('team.team') }}</th>
					<th></th>
					<th v-if="statusGame === RESULTS_STATUS.CLOSED"></th>
				</tr>

				<tr
					v-for="(item, index) in cutList"
					:key="'transfers' + index"
					@click="linkTu(item.route_to_event)"
					@keyup.enter="linkTu(item.route_to_event)"
					:href="item.route_to_event"
					:title="linkTitle(item.competitor_home.name, item.competitor_away.name, item.league)"
					tabindex="0"
					role="link"
				>
					<td class="td-date">
						<div class="date">
							<TableDate :date="item.start_time" />
							<TableTime :date="item.start_time" />
						</div>
					</td>

					<td class="td-league">
						<TableIconName :icon-flag="item.league_country_code" :name="item.league" />
					</td>

					<td class="td-competitor">
						<TableGameTeams
							:team-home="{ name: item.competitor_home }"
							:team-away="{ name: item.competitor_away }"
						/>
					</td>

					<td class="td-score">
						<TableScore
							:period-scores="item.statistics.period_scores"
							:team-home="item.competitor_home"
							:team-away="item.competitor_away"
						/>
					</td>

					<td v-if="statusGame === 'closed'" class="td-status">
						<TableGameStatus :score="item.status?.score" />
					</td>
				</tr>
			</table>
		</div>
		<RouterLinkScroll
			v-if="navTo && storeTeam.results[props.statusGame].length > showItemsList"
			:nav-to="navTo"
			class="show-more group__btn"
		>
			Показать больше матчей
		</RouterLinkScroll>

		<button
			v-else-if="storeTeam.results[props.statusGame].length > cutList.length"
			@click="showItemsList += 30"
			class="show-more group__btn"
		>
			Показать ещё
		</button>
	</div>
	<TeamResultsSkeleton v-else />
</template>

<style scoped lang="scss">
.group {
	display: flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 10px;
	width: 100%;
	background: var(--color-dop-back);
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
}

.group__title {
	font-size: 20px;
	line-height: 120%;
	margin: 4px 0;
	padding-left: 8px;
}

.group__btn {
	margin-top: 6px;
	align-self: center;
}

.table-container {
	overflow-x: auto;
	max-width: 100%;
}

.th-title {
	&._center {
		text-align: center;
	}
}

.td-date {
	width: 100px;
}
.td-league {
	width: 110px;
}

.td-status {
	width: 40px;
}

.date {
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 3px;

	& :deep(.full-date),
	& :deep(.time) {
		font-size: 12px;
	}
}

@media screen and (max-width: 620px) {
}
</style>
