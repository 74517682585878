<script setup lang="ts">
import { computed, inject } from "vue";

import { useFavoriteStore } from "@assets/app/modules/favorites/FavoriteStore";
import { useUserMain } from "@app/modules/user/user-main/UserMainStore.js";
import { localStorageFavoriteKey, addRemoveUserFavorite } from "@app/helpers.ts";
import { FAVORITE_TYPE, SPORT_TYPE_NAME } from "@app/consts.ts";

const sportType = inject("sportType", SPORT_TYPE_NAME.FOOTBALL);

const props = defineProps<{
	type: FAVORITE_TYPE.LEAGUE | FAVORITE_TYPE.TEAM;
	favName: string;
	favId: string | number;
	favSlug: string;
	country?: string | null;
}>();

// store
const favoriteStore = useFavoriteStore(),
	storeUserMain = useUserMain();

const thereIsFav = (item: { id: string | number }) => item.id === props.favId,
	addRemove = () => {
		const favKey = favoriteStore[props.type].findIndex(thereIsFav);
		if (favKey >= 0) {
			favoriteStore[props.type].splice(favKey, 1);

			if (storeUserMain.userName) addRemoveUserFavorite(props.type, props.favId, "DELETE");
		} else {
			favoriteStore[props.type].push({
				country: props.country,
				id: props.favId,
				name: props.favName,
				slug: props.favSlug,
			});

			if (storeUserMain.userName) addRemoveUserFavorite(props.type, props.favId);
		}

		window.localStorage.setItem(
			localStorageFavoriteKey(sportType, props.type),
			JSON.stringify(favoriteStore[props.type])
		);
	};

const isFavorite = computed(() => {
	return favoriteStore[props.type].some(thereIsFav);
});
</script>

<template>
	<button v-if="favId" @click="addRemove()" :class="{ _active: isFavorite }" class="star">
		<svg width="20" height="20">
			<use xlink:href="#20-star" />
		</svg>
	</button>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.star {
	position: relative;
	width: 30px;
	height: 40px;

	> svg {
		fill: var(--color-grey);
		@include center();
		transition: fill 150ms ease-in-out;
	}

	&._active {
		> svg {
			fill: var(--color-dop-cta);
		}
	}
}
</style>
