<script setup>
import { ref, inject } from "vue";

import { addRemoveUserFavorite, localStorageFavoriteKey } from "@app/helpers.js";
import { favoriteType, sportTypeName } from "@app/consts.js";
import { useUserMain } from "@app/modules/user/user-main/UserMainStore.js";
import { usePopUp } from "@app/components/pop-up/PopUpStore.js";
import FormInput from "@app/ui/form/input/FormInput.vue";
import CheckRadio from "@app/ui/form/check-radio/CheckRadio.vue";

import { useUserAuthn } from "../UserAuthnStore.js";
import { KEY_NAME, USER_API } from "../userAuthnConsts.js";
import { signUpIn } from "../userAuthnHelpers.js";
import UserAuthnSignIn from "./UserAuthnSignIn.vue";
import UserAuthnMessage from "../ui/UserAuthnMessage.vue";
import UserAuthnSpinner from "../ui/UserAuthnSpinner.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const classNameAuthn = inject("classNameAuthn");

// store
const storeUserMain = useUserMain(),
	storeUserAuthn = useUserAuthn(),
	storePopUp = usePopUp();

// spinner
const spinnerIsActive = ref(false);

// add user favorite from local storage
const addUserFavoriteFromLocalStorage = () => {
	for (const key in sportTypeName) {
		if (Object.hasOwnProperty.call(sportTypeName, key)) {
			const sportType = sportTypeName[key];
			let league = window.localStorage.getItem(localStorageFavoriteKey(sportType, favoriteType.LEAGUE)),
				teams = window.localStorage.getItem(localStorageFavoriteKey(sportType, favoriteType.TEAM));

			league = league ? JSON.parse(league) : [];
			teams = teams ? JSON.parse(teams) : [];

			if (league?.length) {
				for (const iterator of league) {
					addRemoveUserFavorite(favoriteType.LEAGUE, iterator.id);
				}
			}
			if (teams?.length) {
				for (const iterator of teams) {
					addRemoveUserFavorite(favoriteType.TEAM, iterator.id);
				}
			}
		}
	}
};

// signup
const signupFormValue = ref({
		[KEY_NAME.SIGN_UP.NAME]: "",
		[KEY_NAME.SIGN_UP.PHONE]: "",
		[KEY_NAME.SIGN_UP.EMAIL]: "",
		[KEY_NAME.SIGN_UP.PASSWORD]: "",
		[KEY_NAME.SIGN_UP.AGREE_TERMS]: 1,
		// [keyName.SIGN_UP.TOKEN]: '',
	}),
	errorMessage = ref({
		name: "",
		phone: "",
		email: "",
		password: "",
	}),
	approveUserData = ref(false);

const signup = () => {
	spinnerIsActive.value = true;
	signUpIn(USER_API.SIGNUP, signupFormValue.value)
		.then((response) => {
			console.log("🚀 ~ file: UserAuthnSignUp.vue:47 ~ .then ~ response:", response);

			if (response.status === 200) {
				signUpIn(USER_API.SIGN_IN, {
					[KEY_NAME.SIGN_IN.EMAIL]: signupFormValue.value[KEY_NAME.SIGN_UP.EMAIL],
					[KEY_NAME.SIGN_IN.PASSWORD]: signupFormValue.value[KEY_NAME.SIGN_UP.PASSWORD],
				}).then((responseIn) => {
					storeUserMain.userName = signupFormValue.value[KEY_NAME.SIGN_UP.NAME];
					console.log("🚀 ~ file: UserSignUp.vue:39 ~ .then ~ response:", responseIn);

					spinnerIsActive.value = false;
					storeUserAuthn.components = UserAuthnMessage;
					storeUserAuthn.message.title = "Вы успешно зарегистрировались";
					storeUserAuthn.message.text = false;

					addUserFavoriteFromLocalStorage();

					setTimeout(() => {
						storePopUp.isOpen = false;
					}, 2000);
				});
			} else {
				spinnerIsActive.value = false;
				return response;
			}
		})
		.then((response) => {
			console.log("🚀 ~ file: UserAuthnSignUp.vue:72 ~ .then ~ response:", response);
			errorMessage.value.name = response?.name?.errors?.errors?.[0];
			errorMessage.value.phone = response?.phone?.errors?.errors?.[0];
			errorMessage.value.email = response?.email?.errors?.errors?.[0];
			errorMessage.value.password = response?.password?.errors?.errors?.[0];
		});
};
</script>

<template>
	<UserAuthnSpinner v-if="spinnerIsActive" />

	<h3 :class="classNameAuthn.title">{{ t('user.texts.newAc') }}</h3>

	<form @submit.prevent="signup" :class="classNameAuthn.form">
		<FormInput
			v-model="signupFormValue[KEY_NAME.SIGN_UP.NAME]"
			:inputType="'text'"
			:inputPlaceholder="t('user.placeholder.name')"
			:input-required="true"
			:autocomplete="'name'"
			:inputError="errorMessage.name"
		/>
		<FormInput
			v-model="signupFormValue[KEY_NAME.SIGN_UP.PHONE]"
			:inputType="'tel'"
			:inputPlaceholder="t('user.placeholder.phone')"
			:input-required="true"
			:autocomplete="'tel'"
			:inputError="errorMessage.phone"
		/>
		<FormInput
			v-model="signupFormValue[KEY_NAME.SIGN_UP.EMAIL]"
			:inputType="'email'"
			:inputPlaceholder="t('user.placeholder.email')"
			:input-required="true"
			:autocomplete="'username'"
			:inputError="errorMessage.email"
		/>
		<FormInput
			v-model="signupFormValue[KEY_NAME.SIGN_UP.PASSWORD]"
			:inputType="'password'"
			:inputPlaceholder="t('user.placeholder.password')"
			:input-required="true"
			:autocomplete="'new-password'"
			:inputError="errorMessage.password"
		/>

		<label :class="classNameAuthn.form_pd">
			<CheckRadio v-model="approveUserData" :inputType="'checkbox'" :input-required="true" />
			<span>{{ t('user.checkbox') }}</span>
		</label>

		<button :disabled="!approveUserData" :class="classNameAuthn.form_submit" class="btn" type="submit">
			{{ t('user.buttons.signAgree') }}
		</button>
	</form>

	<div :class="classNameAuthn.ore">
		<span>{{ t('user.texts.or') }}</span>
	</div>

	<p :class="classNameAuthn.nav_text">
		{{ t('user.texts.noAccount') }}
		<button @click="storeUserAuthn.components = UserAuthnSignIn" :class="classNameAuthn.nav_btn">{{ t('user.buttons.signIn') }}</button>
	</p>
</template>
