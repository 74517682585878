<script setup lang="ts">
import { computed } from "vue";
import { currentLocale } from "@app/helpers.ts";


const props = defineProps<{
	date?: string | number | Date | null;
}>();

const LOCALE = currentLocale().locale;

const formatTime = computed<string | null>(() => {
	if (props.date) {
		return new Intl.DateTimeFormat(LOCALE, {
			hour: "numeric",
			minute: "numeric",
		}).format(new Date(props.date));
	}
	return null;
});
</script>

<template>
	<span v-if="date" class="time">{{ formatTime }}</span>
</template>

<style scoped lang="scss">
.time {
	font-size: 14px;
	line-height: 120%;
}
</style>
