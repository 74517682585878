<script setup lang="ts">
import { ref } from "vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

defineProps<{
	author?: string | null;
	source?: string | null;
}>();

const isInfoShown = ref<boolean>(false);
</script>

<template>
	<div v-if="author" :class="{ _active: isInfoShown }" class="img-info">
		<VDropdown
			v-model:shown="isInfoShown"
			:theme="'match'"
			:distance="10"
			:placement="'right-end'"
			:triggers="['click']"
			auto-hide
		>
			<button class="info-btn">
				<svg width="20" height="20">
					<use xlink:href="#20-info" />
				</svg>
			</button>

			<template #popper>
				<div class="title">
					<span> {{ t("game_head.author") }} {{ author }} </span><br />
					<a v-if="source" :href="source" target="_blank" class="title-link">{{ t("game_head.a") }}</a>
				</div>
			</template>
		</VDropdown>
	</div>
</template>

<style scoped lang="scss">
.img-info {
	position: absolute;
	right: 3px;
	bottom: 3px;
	visibility: hidden;
	opacity: 0;

	transition: 150ms ease-in-out;
	transition-property: visibility, opacity;

	&._active {
		visibility: visible;
		opacity: 1;
	}
}

.info-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 30px;
	background: var(--color-main-text);

	> svg {
		fill: var(--color-main-back);
		stroke: var(--color-main-back);
		stroke-width: 1.5px;
	}
}

.title {
	font-size: 14px;
	line-height: 120%;
}

.title-link {
	color: var(--color-main-back);
}
</style>
