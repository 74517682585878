<script setup>
import { useDetailsGame } from "../DetailsGameStore.js";

import DetailsGameFormation from "./DetailsGameFormation.vue";
import EventPlayer from "../ui/EventPlayer.vue";

// store
const storeDetailsGame = useDetailsGame();
</script>

<template>
	<DetailsGameFormation />
	<div v-for="(list, listKey) in storeDetailsGame.lineups" :key="'listPlayer' + listKey" class="wrap">
		<h2 class="title">{{ list.name }}</h2>
		<div class="list-wrap">
			<ul class="list">
				<li
					v-for="(starterHome, keyStarterHome) in list.home"
					:key="'keyStarterHome' + keyStarterHome"
					class="list__item _home"
				>
					<span v-if="starterHome.jersey_number" class="item__number">{{ starterHome.jersey_number }}</span>
					<a :href="starterHome.route" :title="starterHome.name" class="item__name">
						{{ starterHome.nameCut }}
					</a>
					<EventPlayer :events="starterHome.events" />
				</li>
			</ul>
			<ul class="list">
				<li
					v-for="(starterAway, keyStarterAway) in list.away"
					:key="'keyStarterAway' + keyStarterAway"
					class="list__item _away"
				>
					<span v-if="starterAway.jersey_number" class="item__number">{{ starterAway.jersey_number }}</span>
					<a :href="starterAway.route" :title="starterAway.name" class="item__name">
						{{ starterAway.nameCut }}
					</a>
					<EventPlayer :events="starterAway.events" />
				</li>
			</ul>
		</div>
	</div>
</template>
<style scoped lang="scss">
.wrap {
	margin-bottom: 24px;
}

.title {
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	text-align: center;
	color: hsl(220, 4%, 14%);
	padding: 0 0 0 6px;
	background: var(--color-dop-cta);
	border-radius: 6px;
	margin: 0 0 10px;
}

.list-wrap {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 0;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 3px;
}
.list__item {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 6px;
	flex-wrap: wrap;
	min-height: 28px;
	padding: 0px 6px 4px;
	overflow: hidden;
	background: var(--color-border);
	background-size: 200% 1px;
	background-repeat: no-repeat;
	background-position: right bottom;

	&:last-child {
		background: none;
	}

	&._away {
		flex-direction: row-reverse;
		background-position: left bottom;
	}
}

:global(.list__item._away .icon-wrap) {
	flex-direction: row-reverse;
}

.item__number,
.item__name {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	text-decoration: none;
}

.item__number {
	display: block;
	min-width: 20px;
	text-align: center;
}
</style>
