<script setup lang="ts">
withDefaults(
	defineProps<{
		message?: string;
	}>(),
	{ message: "Не удалось загрузить данные 😧" }
);
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;
</script>

<template>
	<!-- <p>{{ message }}</p> -->
	<p>{{ t('ErrorMessage') }}</p>
</template>

<style scoped lang="scss">
p {
	font-size: 18px;
	text-align: center;
	color: var(--color-dop-text);
}
</style>
