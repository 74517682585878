<script setup lang="ts">
import { inject } from "vue";
import { FAVORITE_TYPE, SPORT_TYPE_NAME } from "@app/consts.ts";
import Star from "@app/ui/star/Star.vue";

const head = inject<{
		country: string | null;
		leagueName: string;
		leagueSlug: string;
		date: string | null;
		logo: string | null;
		id: number | string;
		title?: string;
	} | null>("head", null),
	sport = inject<string>("sport", SPORT_TYPE_NAME.FOOTBALL);
</script>

<template>
	<div v-if="head" class="head">
		<img v-if="head.logo" :src="head.logo" :alt="head.leagueName" class="img" width="100" height="100" />
		<svg v-else class="img-cap" width="100" height="100">
			<use :xlink:href="'#40-' + sport" />
		</svg>
		<div class="info">
			<h3 class="league-name">
				<span>{{ head.leagueName }}</span>
				<Star
					:type="FAVORITE_TYPE.LEAGUE"
					:country="head.country"
					:favId="head.id"
					:favName="head.leagueName"
					:favSlug="head.leagueSlug"
				></Star>
			</h3>
			<span class="date">{{ head.date }}</span>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.head {
	display: flex;
	align-items: center;
	gap: 12px;
	margin-bottom: 12px;
}

.img,
.img-cap {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	flex-shrink: 0;
}

.img {
	object-fit: cover;
}

.img-cap {
	stroke: hsl(0, 0%, 85%);
	stroke-width: 1;
	background: hsl(0, 0%, 100%);
}

.info {
	display: flex;
	flex-direction: column;
}

.league-name {
	font-family: $accent-font;
	font-size: 20px;
	line-height: 120%;
	margin: 0;
	display: flex;
	align-items: center;
}

.date {
	font-size: 16px;
	line-height: 120%;
	margin: 0;
}

@media screen and (max-width: 768px) {
	.head {
		display: flex;
		align-items: center;
		padding-bottom: 20px;
		gap: 20px;
	}

	.img,
	.img-cap {
		width: 70px;
		height: 70px;
		border-radius: 10px;
	}

	.league-name {
		font-size: 16px;
		margin: 0;
	}

	.date {
		font-size: 14px;
	}
}
</style>
