<script setup lang="ts">
import { ref, computed, toRaw } from "vue";
import { getApi } from "@app/helpers.js";
import TableDate from "@app/ui/table/date/TableDate.vue";
import TableIconName from "@app/ui/table/icon-name/TableIconName.vue";
import TableTransfersType from "@app/ui/table/transfer/TableTransfersType.vue";
import RouterLinkScroll from "@app/ui/router-link-scroll/RouterLinkScroll.vue";

import type { TeamTransfersItem } from "../teamInterface";
import { useTeam } from "../TeamStore";
import { TRANSFERS_KEY } from "../teamConsts";
import TeamResultsSkeleton from "./TeamResultsSkeleton.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = withDefaults(
	defineProps<{
		api: string;
		title?: string;
		navTo?: string;
		player?: any;
		showTransfersType?: TRANSFERS_KEY.TOTAL | TRANSFERS_KEY.FROM | TRANSFERS_KEY.TO;
		showItems?: number;
	}>(),
	{
		showTransfersType: TRANSFERS_KEY.TOTAL,
		showItems: 20,
	}
);

// store
const storeTeam = useTeam();

if (!storeTeam.transfers.isReady && !storeTeam.transfers.isLoading) {
	storeTeam.transfers.isLoading = true;
	getApi<{ transfers: TeamTransfersItem[] }>(props.api).then((response) => {
		if (response) {
			storeTeam.addTransfers(response.transfers);
		}
	});
}

// show-more
const showItemsList = ref(props.showItems);

const cutList = computed(() => {
	const list = storeTeam.transfers[props.showTransfersType];

	return toRaw(list).slice(0, showItemsList.value);
});
</script>

<template>
	<div v-if="storeTeam.transfers.isReady" class="group">
		<h2 v-if="title" class="group__title">{{ title }}</h2>
		<div class="table-container">
			<table class="main-table">
				<tr>
					<th class="th-title _center">{{ t('team.date') }}</th>
					<th class="th-title">{{ t('team.player') }}</th>
					<th class="th-title _center">{{ t('team.transferType') }}</th>
					<th class="th-title">{{ t('team.fromTo') }}</th>
				</tr>

				<tr v-for="(item, index) in cutList" :key="'transfers' + index">
					<td v-if="item?.player_name || player?.name" class="td-date">
						<TableDate :date="item.transferDate" />
					</td>

					<td v-if="item?.player_name || player?.name" class="td-player">
						<a
							v-if="item?.player_name"
							:href="item.route_to_player"
							class="player"
							:title="item.player_name"
						>
							<TableIconName :icon-flag="item.player_country_code" :name="item.player_name" />
						</a>
						<TableIconName v-else :icon-flag="player.countryCode" :name="player.name" />
					</td>

					<td v-if="item?.player_name || player?.name" class="td-type-transfers">
						<TableTransfersType
							v-if="item.roleType"
							:type-transfers="t(`TYPE_TRANSFERS.${item.roleType}`)"
							:is-arrow-direction-right="item.transfersIsFrom"
							:is-color-red="item.transfersIsFrom"
						/>
					</td>

					<td v-if="item?.player_name || player?.name" class="td-team">
						<a
							:href="item[item.transfersCompetitor].route"
							:title="item?.[item.transfersCompetitor].name"
							class="team"
						>
							<TableIconName
								:title="`${item[item.transfersCompetitor].name} (${
									item[item.transfersCompetitor].country
								})`"
								:icon-flag="item[item.transfersCompetitor].country_code"
								:name="item[item.transfersCompetitor].name"
							/>
						</a>
					</td>
				</tr>
			</table>
		</div>
		<RouterLinkScroll
			v-if="navTo && storeTeam.transfers[showTransfersType].length > showItemsList"
			:nav-to="navTo"
			class="show-more group__btn"
		>
		{{ t('player_career.show_more_match') }}
		</RouterLinkScroll>

		<button
			v-else-if="storeTeam.transfers[showTransfersType].length > cutList.length"
			@click="showItemsList += 30"
			class="show-more group__btn"
		>
		{{ t('player_career.show_more') }}
		</button>
	</div>
	<TeamResultsSkeleton v-else />
</template>

<style scoped lang="scss">
.group {
	display: flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 10px;
	width: 100%;
	background: var(--color-dop-back);
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
}

.group__title {
	font-size: 20px;
	line-height: 120%;
	margin: 4px 0;
	padding-left: 8px;
}

.group__btn {
	margin-top: 6px;
	align-self: center;
}

.table-container {
	overflow-x: auto;
	max-width: 100%;
}

.td-date {
	width: 90px;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	height: 36px;
	text-align: center;
}

.th-title {
	&._center {
		text-align: center;
	}
}

.td-team {
	max-width: 150px;
}

.player,
.team {
	text-decoration: none;
}

@media screen and (max-width: 620px) {
	.td-team {
		max-width: 100px;
	}
	.td-date {
		width: 70px;
		font-size: 12px;
	}

	td.td-type-transfers {
		max-width: 30px;
	}
}
</style>
