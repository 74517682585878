<script setup lang="ts">
import { computed, watch } from "vue";
import { useSport } from "../SportStore";
import { SPORT_TABS_NAME } from "../sportConst";

import TabBtn from "@assets/app/ui/tab-btn/TabBtn.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const TAB_NAME: string = "head-sport-tab",
	TABS_NAME: Record<string, string> = {
		[SPORT_TABS_NAME.TOTAL]: t('TABS_NAME.TOTAL'),
		[SPORT_TABS_NAME.LIVE]: t('TABS_NAME.LIVE'),
		[SPORT_TABS_NAME.COEFFICIENT]: t('TABS_NAME.COEFFICIENT'),
		[SPORT_TABS_NAME.COMPLETED]: t('TABS_NAME.COMPLETED'),
		[SPORT_TABS_NAME.TIMETABLE]: t('TABS_NAME.TIMETABLE'),
	};
let lists: Record<string, any> = {};

// store
const storeSport = useSport();
lists = storeSport.lists;

// date next prev
const disabledNext = computed(() => storeSport.selectedDate < storeSport.dateList.length - 1),
	disabledPrev = computed(() => storeSport.selectedDate > 0);

const dateShift = (route = true) => {
	if (route && disabledPrev.value) {
		storeSport.selectedDate -= 1;
	} else if (!route && disabledNext.value) {
		storeSport.selectedDate += 1;
	}
};

watch(
	() => storeSport.selectedDate,
	() => {lists = [storeSport.lists[storeSport.selectedDate]]}
);

const createKeyList = () => {
	const keysWithNonEmptyArrays: string[] = [];
	lists.forEach((item: Record<string, any>) => {
		Object.keys(item).forEach((key: string) => {
			const value = item[key];
			if (Array.isArray(value) && value.length > 0 && !keysWithNonEmptyArrays.includes(key)) {
				keysWithNonEmptyArrays.push(key);
			}
		});
	});
	return keysWithNonEmptyArrays.length === 0 ? ["total"] : keysWithNonEmptyArrays;
};
</script>

<template>
	<div class="head">
		<div class="tabs-wrap">
			<ul class="tabs">
				<li v-for="(item, index) in createKeyList()" :key="TAB_NAME + index">
					<TabBtn v-model="storeSport.nav" :tab-value="item" :tab-name="TAB_NAME" >
						{{ TABS_NAME[item] }}
					</TabBtn>
				</li>
			</ul>
		</div>

		<div class="date-select">
			<button @click="dateShift(true)" :disabled="!disabledPrev" class="date-select__btn _prev">
				<svg width="20" height="20">
					<use xlink:href="#20-arrow" />
				</svg>
			</button>

			<label class="date-select__select-wrap">
				<svg width="20" height="20">
					<use xlink:href="#20-calendar" />
				</svg>

				<select v-model="storeSport.selectedDate" class="date-select__select">
					<option
						v-for="(item, key) in storeSport.dateList"
						:key="item.date + '_optionHeadSport'"
						:value="key"
						:selected="item.selected"
						:label="item.label"
					>
						{{ item.label }}
					</option>
				</select>
			</label>

			<button @click="dateShift(false)" :disabled="!disabledNext" class="date-select__btn _next">
				<svg width="20" height="20">
					<use xlink:href="#20-arrow" />
				</svg>
			</button>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.head {
	display: flex;
	align-items: flex-start;
}

.date-select {
	display: flex;
	align-items: center;
	gap: 10px;
}
.date-select__btn {
	position: relative;
	width: 36px;
	height: 36px;
	flex-shrink: 0;
	background: var(--color-dop-back);
	border-radius: 6px;

	> svg {
		@include center();
		width: 24px;
		height: auto;
		stroke: var(--color-main-text);
		stroke-width: 1.5;
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	&:hover {
		> svg {
			stroke: var(--color-dop-cta);
		}
	}

	&:disabled {
		> svg {
			stroke: var(--color-dop-text);
		}
	}

	&._prev {
		> svg {
			transform: translate(-50%, -50%) rotate(90deg);
		}
	}
	&._next {
		> svg {
			transform: translate(-50%, -50%) rotate(-90deg);
		}
	}
}

.date-select__select-wrap {
	display: flex;
	align-items: center;
	background: var(--color-dop-back);
	border-radius: 6px;
	height: 36px;

	> svg {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		stroke: var(--color-main-text);
		stroke-width: 1.5;
		stroke-linecap: round;
		stroke-linejoin: round;
		margin-left: 8px;
	}
}

.date-select__select {
	appearance: none;
	border: none;
	color: var(--color-main-text);
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	padding: 0 8px;
	background: var(--color-dop-back);
	cursor: pointer;

	background: transparent;
	height: 100%;

	option {
		color: var(--color-main-text);
		background: var(--color-dop-back);
	}
}

@media screen and (max-width: 1100px) {
	.head {
		flex-direction: column;
	}

	.date-select {
		margin-bottom: 12px;
		width: 100%;
	}

	.date-select__select-wrap {
		width: 100%;
		justify-content: center;
	}
}
</style>