<script setup lang="ts">
import { computed, toRaw, ref } from "vue";
import { getApi } from "@app/helpers.ts";
import TableIconName from "@app/ui/table/icon-name/TableIconName.vue";
import RouterLinkScroll from "@app/ui/router-link-scroll/RouterLinkScroll.vue";

import type { ResponsePlayerCareer } from "../playerInterface";
import { CAREER_KEYS } from "../playerConsts";
import { usePlayer } from "../PlayerStore";
import PlayerSkeleton from "./PlayerSkeleton.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = withDefaults(
	defineProps<{
		api: string;
		title?: string;
		navTo?: string;
		sport?: string;
		careerType?: CAREER_KEYS.ALL | CAREER_KEYS.NATIONAL;
		showItems?: number;
	}>(),
	{
		careerType: CAREER_KEYS.ALL,
		showItems: 20,
	}
);

// store
const storePlayer = usePlayer();

if (!storePlayer.career.isReady && props.api) {
	getApi<ResponsePlayerCareer>(props.api).then((response) => {
		if (response?.career) {
			storePlayer.addCareer(response.career, CAREER_KEYS.ALL);
			storePlayer.addCareer(response.career, CAREER_KEYS.NATIONAL);
			storePlayer.career.isReady = true;
		}
	});
}

// statistic
const statisticList = (statistics: { [key: string]: any | undefined } = {}) => {
	return {
		matches_played: {
			value: statistics?.matches_played,
			title: t('STATISTIC_TITLE.MATCHES_PLAYED'),
			icon: "play-football",
		},
		goals_scored: {
			value: statistics?.goals_scored,
			title: t('STATISTIC_TITLE.GOALS_SCORED'),
			icon: "ball",
		},
		assists: {
			value: statistics?.assists,
			title: t('STATISTIC_TITLE.ASSISTS'),
			icon: "assist",
		},
		yellow_cards: {
			value: statistics?.yellow_cards,
			title: t('STATISTIC_TITLE.YELLOW_CARDS'),
			icon: "yellow-card",
		},
		red_cards: {
			value: statistics?.red_cards,
			title: t('STATISTIC_TITLE.RED_CARDS'),
			icon: "red-card",
		},
	};
};

// show-more
const showItemsList = ref<number>(props.showItems);

const cutList = computed(() => {
	const list = storePlayer.career[props.careerType].list;

	return toRaw(list).slice(0, showItemsList.value);
});
</script>

<template>
	<div v-if="storePlayer.career.isReady" class="group">
		<h2 v-if="title" class="group__title">{{ title }}</h2>
		<div class="table-container">
			<table class="main-table">
				<tr>
					<th class="th-title td-date">{{ $t('player_career.date') }}</th>
					<th class="th-title td-team">{{ $t('player_career.team') }}</th>
					<th class="th-title td-tournament">{{ $t('player_career.tournament') }}</th>
					<th class="th-stats">
						<div class="th-stats__wrap">
							<svg
								:key="iconName.title"
								:title="iconName.title"
								v-for="iconName in statisticList()"
								width="20"
								height="20"
							>
								<use :xlink:href="`#20-${iconName.icon}`" />
							</svg>
						</div>
					</th>
				</tr>

				<tr v-for="(item, index) in cutList" :key="'career' + index">
					<td class="td-date">
						{{ item.year.length > 4 ? item.year.slice(0, 5) + item.year.slice(-2) : item.year }}
					</td>
					<td class="td-team">
						<a :href="item.route_to_competitor" :title="item.competitor_name" class="team">
							<TableIconName :name="item.competitor_name" />
						</a>
					</td>
					<td class="td-tournament">
						<TableIconName
							:icon-flag="item.country_code"
							:name="item.season_name"
							:title="item.season_name"
						/>
					</td>
					<td class="td-stats">
						<div class="td-stats__wrap">
							<div
								:key="'statisticIndex' + statisticIndex"
								class="td-stats-item"
								v-for="(statisticItem, statisticIndex) in statisticList(item.statistics)"
								:title="statisticList()[statisticIndex].title"
							>
								<svg width="16" height="16" :title="statisticItem.title" class="stats__icon">
									<use :xlink:href="`#20-${statisticItem.icon}`" />
								</svg>
								<span :class="{ _active: statisticItem.value >= 1 }">{{
									statisticItem.value ?? "-"
								}}</span>
							</div>
						</div>
					</td>
				</tr>

				<tr class="tf-row">
					<td class="tf-total" colspan="3">
						<span>Всего</span>
					</td>
					<td class="tf-stats">
						<div class="td-stats__wrap">
							<div
								:key="'statisticIndex' + statisticIndex"
								class="td-stats-item"
								v-for="(statisticItem, statisticIndex) in storePlayer.career[careerType].total"
								:title="statisticList()[statisticIndex].title"
							>
								<svg width="16" height="16" class="stats__icon">
									<use :xlink:href="`#20-${statisticList()[statisticIndex].icon}`" />
								</svg>
								<span :class="{ _active: statisticItem >= 1 }">{{ statisticItem ?? "-" }}</span>
							</div>
						</div>
					</td>
				</tr>
			</table>
		</div>

		<RouterLinkScroll
			v-if="navTo && storePlayer.career[careerType].list.length > showItemsList"
			:nav-to="navTo"
			class="show-more group__btn"
		>
		{{ $t('player_career.show_more_match') }}
		</RouterLinkScroll>

		<button
			v-else-if="storePlayer.career[careerType].list.length > cutList.length"
			@click="showItemsList += 30"
			class="show-more group__btn"
		>
		{{ $t('player_career.show_more') }}
		</button>
	</div>
	<PlayerSkeleton v-else />
</template>

<style scoped lang="scss">
.group {
	display: flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 10px;
	width: 100%;
	background: var(--color-dop-back);
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
}

.group__title {
	font-size: 20px;
	line-height: 120%;
	margin: 4px 0;
	padding-left: 8px;
}

.group__btn {
	margin-top: 6px;
	align-self: center;
}
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

a {
	text-decoration: none;
}
td {
	font-size: 12px;
}
.stats__icon {
	display: none;
}
.td-date {
	height: 38px;
	width: 90px;
}
.td-tournament {
	max-width: 160px;
}
.td-stats__wrap,
.th-stats__wrap {
	display: flex;
	justify-content: space-around;

	svg {
		fill: var(--color-main-text);
		stroke: var(--color-main-text);
	}
}
.td-stats-item {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	width: 30px;

	span {
		font-size: 14px;
		display: flex;
		width: 20px;
		height: 20px;
		align-items: center;
		justify-content: center;
		border-radius: 4px;

		&._active {
			background: var(--color-dop-back);
		}
	}
}
.tf-row {
	color: var(--color-dop-text);
}
.tf-total {
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 14px;
	flex: 1;
}

@media screen and (max-width: 620px) {
	.stats__icon {
		display: block;
	}
	.table-wrap {
		.main-table {
			tr {
				display: flex;
				flex-wrap: wrap;
				padding: 5px 0px;
				gap: 5px 15px;

				td {
					display: flex;
					align-items: center;
					padding: 2px 0;
					max-width: none;
				}
			}
			.td-team {
				width: 100px;
			}
			.td-tournament {
				flex: 1;
				white-space: nowrap;
				overflow: hidden;
				margin-right: 12px;
			}
			.td-stats,
			.tf-stats {
				height: auto;
				padding-left: 12px;
			}
		}
	}
	.th-stats {
		display: none;
	}
	.td-date {
		width: 55px;
		font-size: 12px;
	}
	.td-stats {
		order: 1;
		width: 100%;
	}
	.td-stats__wrap {
		gap: 15px;
	}
	.td-stats-item {
		width: auto;
		gap: 3px;
		span {
			font-size: 12px;
		}
	}
	.tf-row {
		flex-direction: column;
	}
	.tf-total {
		font-size: 12px;
	}

	.tf-total-all {
		display: flex;
		justify-content: space-between;
		color: var(--color-main-text);

		> ul {
			display: flex;
			margin-left: auto;
			align-items: center;
			gap: 15px;

			> li {
				display: flex;
				align-items: center;
				gap: 3px;
			}
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}
}
@media screen and (max-width: 480px) {
	.main-table {
		.td-team {
			max-width: none;
			width: 60px;
		}
	}
}
</style>
