<script setup>
import { ref, inject } from "vue";

import FormInput from "@app/ui/form/input/FormInput.vue";

import { useUserAuthn } from "../UserAuthnStore.js";
import { KEY_NAME, USER_API } from "../userAuthnConsts.js";
import { signUpIn } from "../userAuthnHelpers.js";
import UserAuthnSignUp from "./UserAuthnSignUp.vue";
import UserAuthnMessage from "../ui/UserAuthnMessage.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;
const classNameAuthn = inject("classNameAuthn");

// store
const storeUserAuthn = useUserAuthn();

// signup
const signInFormValue = ref({
	[KEY_NAME.PASS_RESET.EMAIL]: "",
});

const signup = () => {
	signUpIn(USER_API.RESET_PASSWORD, signInFormValue.value).then((response) => {
		storeUserAuthn.components = UserAuthnMessage;
		storeUserAuthn.message.title = t('user.messages.resetPasswordSent');
		storeUserAuthn.message.text = t('user.messages.checkSpam');

		console.log("🚀 ~ file: HeaderUserLogIn.vue:32 ~ .then ~ response:", response);
	});
};
</script>

<template>
	<h3 :class="classNameAuthn.title">{{ t('user.headings.resetPassword') }}</h3>

	<p :class="classNameAuthn.nav_text" class="reset-text">{{ t('user.texts.resetInstructions') }}</p>

	<form @submit.prevent="signup" :class="classNameAuthn.form">
		<FormInput
			v-model="signInFormValue[KEY_NAME.PASS_RESET.EMAIL]"
			:inputType="'email'"
			:inputPlaceholder="'Email'"
			:input-required="true"
			:autocomplete="'email'"
			:inputError="''"
		/>

		<button :class="classNameAuthn.form_submit" class="btn" type="submit">{{ t('user.buttons.resetPassword') }}</button>
	</form>

	<div :class="classNameAuthn.ore">
		<span>{{ t('user.texts.or') }}</span>
	</div>

	<p :class="classNameAuthn.nav_text">
		{{ t('user.texts.noAccount') }}
		<button @click="storeUserAuthn.components = UserAuthnSignUp" :class="classNameAuthn.nav_btn">
			{{ t('user.buttons.signUp') }}
		</button>
	</p>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.reset-text {
	margin-bottom: 10px;
}
</style>
