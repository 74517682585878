<script setup>
import { computed } from "vue";

import { bookieLogoUrl } from "@assets/app/helpers.js";

import TableCoefficient from "./TableCoefficient.vue";

const props = defineProps({
	coefficient: Object,
	bookie: String,
});

const enableTooltip = computed(() => {
	if (props.bookie && (props.coefficient.old || props.coefficient.new)) {
		return ["hover", "focus"];
	} else {
		return [];
	}
});

const coefNumber = (number) => {
	return number || number === 0 ? number.toFixed(2) : "-";
};
</script>

<template>
	<VTooltip :theme="'coef-tooltip'" :placement="'bottom'" :triggers="enableTooltip">
		<TableCoefficient :coefficient="coefficient" />
		<template #popper>
			<div class="popper-wrap">
				<span class="line-top">
					<img height="20" :src="bookieLogoUrl(bookie)" :alt="bookie" />
					<span v-if="coefficient.old">{{ coefNumber(coefficient.old) }}</span>
					<span v-if="coefficient.old && coefficient.new">»</span>
					<span v-if="coefficient.new">{{ coefNumber(coefficient.new) }}</span>
				</span>
			</div>
		</template>
	</VTooltip>
</template>

<style scoped lang="scss">
// v-popper
.popper-wrap {
	display: flex;
	flex-direction: column;
	gap: 6px;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
}

.line-top {
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 600;

	> img {
		height: 16px;
		width: auto;
		border-radius: 3px;
	}
}
</style>

<style lang="scss">
// v-popper
.v-popper--theme-coef-tooltip {
	.v-popper__inner {
		background: var(--color-main-text);
		color: var(--color-dop-back);
		padding: 10px;
		border-radius: 6px;
	}

	.v-popper__arrow-outer {
		border-color: var(--color-main-text);
	}
}
</style>
