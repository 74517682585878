<script setup>
const colum = [8, 4, 2, 1];
</script>

<template>
	<div class="wrap">
		<div class="colum-wrap">
			<div class="skeleton-line head"></div>
			<div v-for="(columItem, columIndex) in colum" :key="'colum' + columIndex" class="colum">
				<div v-for="(item, index) in columItem" :key="index" class="skeleton-line"></div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.wrap {
	overflow: hidden;
	padding: 0 12px;
}

.colum-wrap {
	display: grid;
	grid-template-columns: repeat(4, auto);
	grid-gap: 12px 20px;
}

.head {
	grid-column: 1 / -1;
	width: 100%;
	height: 20px;
}

.colum {
	display: grid;
	grid-auto-flow: row;
	align-items: center;
	grid-auto-rows: 1fr;
	grid-row-gap: 30px;

	&:nth-child(2) {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		gap: 12px;

		> .skeleton-line {
			&:nth-child(2n + 2) {
				margin-bottom: 18px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	> .skeleton-line {
		width: 200px;
		height: 58px;
	}
}
</style>
