<script setup lang="ts">
defineProps<{
	navTo: string;
}>();

// switch-nav-to
const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: "smooth" });
};
</script>

<template>
	<router-link :to="`/${navTo}/`" @click="scrollToTop">
		<slot></slot>
	</router-link>
</template>
