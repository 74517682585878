import { createApp } from "vue";
import { defineStore } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";
import { formatNameCut } from "@app/helpers.ts";
import { GAME_STATUS_LIST } from "@app/consts.ts";
import * as Sentry from "@sentry/vue";

import type { NavList, TeamResultsItem, TeamSquadItem, TeamTransfersItem } from "./teamInterface";
import { ROUTE_NAME_TEAM, RESULTS_STATUS, TRANSFERS_KEY, TRANSFERS_IS_FROM } from "./teamConsts";

interface Transfers {
	isReady: boolean;
	isLoading: boolean;
	[TRANSFERS_KEY.TOTAL]: TeamTransfersItem[];
	[TRANSFERS_KEY.FROM]: TeamTransfersItem[];
	[TRANSFERS_KEY.TO]: TeamTransfersItem[];
	nav: TRANSFERS_KEY.TOTAL | TRANSFERS_KEY.FROM | TRANSFERS_KEY.TO;
}

interface Squad {
	isReady: boolean;
	tabs: {
		[k: string]: {
			[k: string]: TeamSquadItem[];
		};
	};
	nav: string;
}

interface Results {
	isReady: boolean;
	requestIsGone: boolean;
	[RESULTS_STATUS.CLOSED]: TeamResultsItem[];
	[RESULTS_STATUS.NOT_STARTED]: TeamResultsItem[];
}

export const useTeam = defineStore("Team", () => {
	const app = createApp({
	});

	// router
	const route = useRoute(),
	router = useRouter();

	Sentry.init({
	  app,
	  dsn: "https://4b175102efb6d93e74c0f877ea4691d0@o534631.ingest.us.sentry.io/4508209102848000",
	  integrations: [
		new Sentry.BrowserTracing({
		  routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Integrations.Breadcrumbs({ console: false }),
		new Sentry.Replay(),
	  ],
	});

	app.use(router);
	app.mount("#app");

	const navList = ref<NavList>({
			[ROUTE_NAME_TEAM.REVIEW]: {
				name: "Обзор",
			},
		}),
		id = ref<string>(""),
		transfers = ref<Transfers>({
			isReady: false,
			isLoading: false,
			[TRANSFERS_KEY.TOTAL]: [],
			[TRANSFERS_KEY.FROM]: [],
			[TRANSFERS_KEY.TO]: [],
			nav: TRANSFERS_KEY.TOTAL,
		}),
		results = ref<Results>({
			isReady: false,
			requestIsGone: false,
			[RESULTS_STATUS.CLOSED]: [],
			[RESULTS_STATUS.NOT_STARTED]: [],
		}),
		squad = ref<Squad>({
			isReady: false,
			tabs: {},
			nav: "sum",
		});

	const addResults = (response: TeamResultsItem[]): void => {
		response = response.filter((i) => {
			return i.statistics.status === RESULTS_STATUS.CLOSED || i.statistics.status === RESULTS_STATUS.NOT_STARTED;
		});

		for (const game of response) {
			// status
			const mainTeamIsHome = game.competitor_home.id === id.value;
			game.status = {
				score: null,
			};

			if (!(game.statistics?.home_score === undefined || game.statistics?.away_score === undefined)) {
				switch (true) {
					case game.statistics.home_score > game.statistics.away_score:
						game.status.score = mainTeamIsHome ? GAME_STATUS_LIST.VICTORY : GAME_STATUS_LIST.DEFEAT;
						break;

					case game.statistics.home_score < game.statistics.away_score:
						game.status.score = mainTeamIsHome ? GAME_STATUS_LIST.DEFEAT : GAME_STATUS_LIST.VICTORY;
						break;

					default:
						game.status.score = GAME_STATUS_LIST.DRAW;
						break;
				}
			}

			if (game.statistics.status === RESULTS_STATUS.CLOSED || new Date(game.start_time) <= new Date()) {
				results.value[RESULTS_STATUS.CLOSED].push(game);
			} else {
				results.value[RESULTS_STATUS.NOT_STARTED].push(game);
			}
		}

		// nav
		if (!results.value[RESULTS_STATUS.CLOSED].length) {
			if (String(route.matched?.[0]?.name) === ROUTE_NAME_TEAM.RESULTS) {
				router.push({ name: ROUTE_NAME_TEAM.REVIEW });
			}
			delete navList.value[ROUTE_NAME_TEAM.RESULTS];
		}
		if (!results.value[RESULTS_STATUS.NOT_STARTED].length) {
			if (String(route.matched?.[0]?.name) === ROUTE_NAME_TEAM.CALENDAR) {
				router.push({ name: ROUTE_NAME_TEAM.REVIEW });
			}
			delete navList.value[ROUTE_NAME_TEAM.CALENDAR];
		}

		results.value.isReady = true;
	};

	const addTransfers = (response: TeamTransfersItem[]) => {
		for (const item of response) {
			// formatName
			if (item?.player_name) {
				item.player_name = formatNameCut(item.player_name);
			}

			// transfers
			item.transfersIsFrom = item.fromCompetitor.id === id.value;
			item.transfersCompetitor = item.transfersIsFrom ? TRANSFERS_IS_FROM.TO : TRANSFERS_IS_FROM.FROM;

			transfers.value.total.push(item);
			if (item.transfersIsFrom) {
				transfers.value.to.push(item);
			} else {
				transfers.value.from.push(item);
			}
		}

		transfers.value.isReady = true;
	};

	const addSquad = (response: TeamSquadItem[]) => {
		squad.value.tabs.sum = {};
		squad.value.tabs.sum.team = [];
		// squad.value.tabs.sum.trainer = [];
		for (const player of response) {
			player.player_name = formatNameCut(player.player_name);
			if (!player.seasons) {
				squad.value.tabs.sum.team.push({ ...player });
				// squad.value.tabs.sum.trainer = { ...player };
			} else {
				for (const season of player.seasons) {
					if (!squad.value.tabs?.[season.season_name]) {
						squad.value.tabs[season.season_name] = {};
						squad.value.tabs.sum = {};
					}

					if (player.player_role) {
						if (!squad.value.tabs[season.season_name]?.[player.player_role]) {
							squad.value.tabs[season.season_name][player.player_role] = [];
							squad.value.tabs.sum[player.player_role] = [];
						}
						squad.value.tabs[season.season_name][player.player_role].push({
							...player,
							statistics: { ...season.statistics },
						});
					}

					if (!player?.statistics) {
						player.statistics = {
							matches_played: 0,
							goals_scored: 0,
							yellow_cards: 0,
							red_cards: 0,
						};
					}

					for (const key in player.statistics) {
						if (Object.prototype.hasOwnProperty.call(player.statistics, key)) {
							player.statistics[key] += +season.statistics[key]!;
						}
					}
				}
			}

			if (player.player_role) {
				squad.value.tabs.sum[player.player_role].push({ ...player });
			}
		}

		squad.value.isReady = true;
		console.log(squad.value);
	};

	return {
		navList,
		id,
		transfers,
		addTransfers,
		results,
		addResults,
		squad,
		addSquad,
	};
});
