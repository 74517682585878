import { defineStore } from "pinia";
import { ref } from "vue";
import usei18n from "@app/i18n/i18n";

import { tabsName, periodType, eventType, statisticTypes } from "./detailsGameConsts.js";
import { sportTypeName } from "@assets/app/consts.js";

export const useDetailsGame = defineStore("DetailsGame", () => {
	const { t } = usei18n.global;

	const sportType = ref(),
		isReady = ref(false),
		nav = ref(),
		compositions = ref(),
		statistics = ref(),
		lineups = ref(),
		formation = ref(),
		text = ref(),
		statisticsPlayers = ref(),
		progress = ref();

	// reset
	const reset = () => {
		compositions.value = { period: {}, venue: false, mainReferee: false };
		statistics.value = [];
		lineups.value = [];
		formation.value = {};
		text.value = [];
		statisticsPlayers.value = {};
		progress.value = [];
	};

	reset();

	// Compositions
	const playerName = (players, isPrimary = true, type) => {
			if (players) {
				switch (type) {
					case eventType.SCORE_CHANGE:
						return players.find((item) => item.type === (isPrimary ? "scorer" : "assist"));

					case eventType.SUBSTITUTION:
						return players.find((item) => item.type === (isPrimary ? "substituted_in" : "substituted_out"));

					case eventType.YELLOW_CARD:
					case eventType.YELLOW_RED_CARD:
					case eventType.PENALTY_SHOOTOUT:
					case eventType.PENALTY_MISSED:
						return isPrimary ? players[0] : null;

					default:
						return null;
				}
			}
			return null;
		},
		compositionsHockey = (result, periods) => {
			let periodName = "";

			for (const item of result.match_data.timeline) {
				if (item.type === "period_start") {
					let name = "";
					switch (item.period_name) {
						case "1st_period":
							name = t("details_game.periodNames.1st_period");
							break;
						case "2nd_period":
							name = t("details_game.periodNames.2nd_period");
							break;
						case "3rd_period":
							name = t("details_game.periodNames.3rd_period");
							break;
						case "overtime":
							name = t("details_game.periodNames.overtime");
							break;
					}

					periodName = item.period_name;
					periods[periodName] = {
						events: [],
						name: name,
					};
				} else if ([eventType.SCORE_CHANGE, eventType.SUSPENSION].includes(item.type)) {
					periods[periodName].events.push(item);
				}
			}

			for (const key in periods) {
				if (Object.hasOwnProperty.call(periods, key)) {
					for (const event of periods[key].events) {
						if (event?.players) {
							const players = {
								scorer: false,
								assist: false,
								secondary_assist: false,
							};
							for (const player of event.players) {
								switch (player.type) {
									case "scorer":
										players.scorer = player;
										break;

									case "assist":
										players.assist = player;
										break;

									case "secondary_assist":
										players.secondary_assist = player;
										break;
								}
							}
							event.players = players;
						} else {
							event.players = {
								scorer: event.player,
							};
						}
					}
				}
			}
		},
		compositionsFootball = (result, periods) => {
			const PERIOD_TYPE = [periodType.REGULAR_PERIOD, periodType.OVERTIME, periodType.PENALTIES],
				TYPE = [
					eventType.SCORE_CHANGE,
					eventType.PENALTY_MISSED,
					eventType.SUBSTITUTION,
					eventType.YELLOW_CARD,
					eventType.YELLOW_RED_CARD,
					eventType.PENALTY_SHOOTOUT,
				];

			const allList = result.match_data.timeline.filter((item) => {
				return PERIOD_TYPE.includes(item.period_type) && TYPE.includes(item.type);
			});

			for (const event of allList) {
				event.players = {
					scorer: playerName(event.players, true, event.type),
					assist: playerName(event.players, false, event.type),
				};
			}

			const period1 = allList.filter(
					(item) => item.period_type === periodType.REGULAR_PERIOD && item.period === 1
				),
				period2 = allList.filter((item) => item.period_type === periodType.REGULAR_PERIOD && item.period === 2),
				overtime = allList.filter((item) => item.period_type === periodType.OVERTIME),
				penalties = allList.filter(
					(item) => item.period_type === periodType.PENALTIES && item.type === eventType.PENALTY_SHOOTOUT
				);

			if (period1.length) {
				periods.period1 = {
					name: t("details_game.periodNames.period1"),
					events: period1,
				};
			}
			if (period2.length) {
				periods.period2 = {
					name: t("details_game.periodNames.period2"),
					events: period2,
				};
			}
			if (overtime.length) {
				periods.overtime = {
					name: t("details_game.periodNames.extratime"),
					events: overtime,
				};
			}
			if (penalties.length) {
				periods.penalties = {
					name: t("details_game.periodNames.penalties"),
					events: penalties,
				};
			}
		},
		createCompositions = (result) => {
			const periodsScore = (periods, competitor) => {
				return periods.events.reduce((sum, item) => {
					if (
						item.type === eventType.SCORE_CHANGE ||
						(item.type === eventType.PENALTY_SHOOTOUT && item.status === "scored")
					) {
						return sum + +(item.competitor === competitor);
					}

					return sum;
				}, 0);
			};

			const periods = {};

			if (sportType.value === sportTypeName.HOCKEY) {
				compositionsHockey(result, periods);
			} else if (sportType.value === sportTypeName.FOOTBALL) {
				compositionsFootball(result, periods);
			}

			for (const key in periods) {
				if (Object.hasOwnProperty.call(periods, key)) {
					periods[key].score = {
						away: periodsScore(periods[key], "away"),
						home: periodsScore(periods[key], "home"),
					};
				}
			}

			// info compositions
			compositions.value.period = periods;
			compositions.value.venue = result.match_data?.venue;
			compositions.value.mainReferee = result.match_data?.referees.find(
				(item) => item.type === "main_referee"
			)?.name;
		};
	const createProgress = (result) => {
		try {
			progress.value = result;
			isReady.value = true;
		} catch (error) {
			isReady.value = false;
		}
	};

	// Statistics
	const NAME_STATISTICS = {
		[sportTypeName.FOOTBALL]: [
			{
				key: statisticTypes.BALL_POSSESSION,
				name: t("details_game.statisticNames.football.BALL_POSSESSION"),
				type: "percent",
			},
			{
				key: statisticTypes.CORNER_KICKS,
				name: t("details_game.statisticNames.football.CORNER_KICKS"),
				type: "number",
			},
			{
				key: statisticTypes.FOULS,
				name: t("details_game.statisticNames.football.FOULS"),
				type: "number",
			},
			{
				key: statisticTypes.FREE_KICKS,
				name: t("details_game.statisticNames.football.FREE_KICKS"),
				type: "number",
			},
			{
				key: statisticTypes.INJURIES,
				name: t("details_game.statisticNames.football.INJURIES"),
				type: "number",
			},
			{
				key: statisticTypes.OFFSIDES,
				name: t("details_game.statisticNames.football.OFFSIDES"),
				type: "number",
			},
			{
				key: statisticTypes.SHOTS_BLOCKED,
				name: t("details_game.statisticNames.football.SHOTS_BLOCKED"),
				type: "number",
			},
			{
				key: statisticTypes.SHOTS_OFF_TARGET,
				name: t("details_game.statisticNames.football.SHOTS_OFF_TARGET"),
				type: "number",
			},
			{
				key: statisticTypes.SHOTS_ON_TARGET,
				name: t("details_game.statisticNames.football.SHOTS_ON_TARGET"),
				type: "number",
			},
			{
				key: statisticTypes.SHOTS_SAVED,
				name: t("details_game.statisticNames.football.SHOTS_SAVED"),
				type: "number",
			},
			{
				key: statisticTypes.SHOTS_TOTAL,
				name: t("details_game.statisticNames.football.SHOTS_TOTAL"),
				type: "number",
			},
			{
				key: statisticTypes.SUBSTITUTIONS,
				name: t("details_game.statisticNames.football.SUBSTITUTIONS"),
				type: "number",
			},
			{
				key: statisticTypes.THROW_INS,
				name: t("details_game.statisticNames.football.THROW_INS"),
				type: "number",
			},
			{
				key: statisticTypes.RED_CARDS,
				name: t("details_game.statisticNames.football.RED_CARDS"),
				type: "number",
			},
			{
				key: statisticTypes.YELLOW_CARDS,
				name: t("details_game.statisticNames.football.YELLOW_CARDS"),
				type: "number",
			},
		],
		[sportTypeName.HOCKEY]: [
			{
				key: statisticTypes.GOALS_CONCEDED,
				name: t("details_game.statisticNames.hockey.GOALS_CONCEDED"),
				type: "number",
			},
			{
				key: statisticTypes.GOALS_IN_POWER_PLAY,
				name: t("details_game.statisticNames.hockey.GOALS_IN_POWER_PLAY"),
				type: "number",
			},
			{
				key: statisticTypes.GOALS_WHILE_SHORT_HANDED,
				name: t("details_game.statisticNames.hockey.GOALS_WHILE_SHORT_HANDED"),
				type: "number",
			},
			{
				key: statisticTypes.PENALTIES,
				name: t("details_game.statisticNames.hockey.PENALTIES"),
				type: "number",
			},
			{
				key: statisticTypes.PENALTY_MINUTES,
				name: t("details_game.statisticNames.hockey.PENALTY_MINUTES"),
				type: "number",
			},
			{
				key: statisticTypes.POWER_PLAYS,
				name: t("details_game.statisticNames.hockey.POWER_PLAYS"),
				type: "number",
			},
			{
				key: statisticTypes.PUCK_POSSESSION,
				name: t("details_game.statisticNames.hockey.PUCK_POSSESSION"),
				type: "number",
			},
			{
				key: statisticTypes.SAVES,
				name: t("details_game.statisticNames.hockey.SAVES"),
				type: "number",
			},
			{
				key: statisticTypes.SHOTS_ON_GOAL,
				name: t("details_game.statisticNames.hockey.SHOTS_ON_GOAL"),
				type: "number",
			},
			{
				key: statisticTypes.SHUTOUTS,
				name: t("details_game.statisticNames.hockey.SHUTOUTS"),
				type: "number",
			},
		],
		[sportTypeName.TENNIS]: [
			{
				key: statisticTypes.ACES,
				name: t("details_game.statisticNames.tennis.ACES"),
				type: "number",
			},
			{
				key: statisticTypes.BREAKPOINTS_WON,
				name: t("details_game.statisticNames.tennis.BREAKPOINTS_WON"),
				type: "number",
			},
			{
				key: statisticTypes.DOUBLE_FAULTS,
				name: t("details_game.statisticNames.tennis.DOUBLE_FAULTS"),
				type: "number",
			},
			{
				key: statisticTypes.FIRST_SERVE_POINTS_WON,
				name: t("details_game.statisticNames.tennis.FIRST_SERVE_POINTS_WON"),
				type: "number",
			},
			{
				key: statisticTypes.FIRST_SERVE_SUCCESSFUL,
				name: t("details_game.statisticNames.tennis.FIRST_SERVE_SUCCESSFUL"),
				type: "number",
			},
			{
				key: statisticTypes.GAMES_WON,
				name: t("details_game.statisticNames.tennis.GAMES_WON"),
				type: "number",
			},
			{
				key: statisticTypes.MAX_GAMES_IN_A_ROW,
				name: t("details_game.statisticNames.tennis.MAX_GAMES_IN_A_ROW"),
				type: "number",
			},
			{
				key: statisticTypes.MAX_POINTS_IN_A_ROW,
				name: t("details_game.statisticNames.tennis.MAX_POINTS_IN_A_ROW"),
				type: "number",
			},
			{
				key: statisticTypes.POINTS_WON,
				name: t("details_game.statisticNames.tennis.POINTS_WON"),
				type: "number",
			},
			{
				key: statisticTypes.SECOND_SERVE_POINTS_WON,
				name: t("details_game.statisticNames.tennis.SECOND_SERVE_POINTS_WON"),
				type: "number",
			},
			{
				key: statisticTypes.SECOND_SERVE_SUCCESSFUL,
				name: t("details_game.statisticNames.tennis.SECOND_SERVE_SUCCESSFUL"),
				type: "number",
			},
			{
				key: statisticTypes.SERVICE_GAMES_WON,
				name: t("details_game.statisticNames.tennis.SERVICE_GAMES_WON"),
				type: "number",
			},
			{
				key: statisticTypes.SERVICE_POINTS_LOST,
				name: t("details_game.statisticNames.tennis.SERVICE_POINTS_LOST"),
				type: "number",
			},
			{
				key: statisticTypes.SERVICE_POINTS_WON,
				name: t("details_game.statisticNames.tennis.SERVICE_POINTS_WON"),
				type: "number",
			},
			{
				key: statisticTypes.TIEBREAKS_WON,
				name: t("details_game.statisticNames.tennis.TIEBREAKS_WON"),
				type: "number",
			},
			{
				key: statisticTypes.TOTAL_BREAKPOINTS,
				name: t("details_game.statisticNames.tennis.TOTAL_BREAKPOINTS"),
				type: "number",
			},
		],
		[sportTypeName.BASKETBALL]: [
			{
				key: statisticTypes.LEADER_TURNOVERS,
				name: t("details_game.statisticNames.basketball.LEADER_TURNOVERS"),
				type: "number",
			},
			{
				key: statisticTypes.OFFENSIVE_REBOUNDS_PLAYER,
				name: t("details_game.statisticNames.basketball.OFFENSIVE_REBOUNDS_PLAYER"),
				type: "number",
			},
			{
				key: statisticTypes.REBOUNDS,
				name: t("details_game.statisticNames.basketball.REBOUNDS"),
				type: "number",
			},
			{
				key: statisticTypes.SHOTS_BLOCKED,
				name: t("details_game.statisticNames.basketball.SHOTS_BLOCKED"),
				type: "number",
			},
			{
				key: statisticTypes.STEALS,
				name: t("details_game.statisticNames.basketball.STEALS"),
				type: "number",
			},
			{
				key: statisticTypes.TEAM_LEADS,
				name: t("details_game.statisticNames.basketball.TEAM_LEADS"),
				type: "number",
			},
			{
				key: statisticTypes.TEAM_REBOUNDS,
				name: t("details_game.statisticNames.basketball.TEAM_REBOUNDS"),
				type: "number",
			},
			{
				key: statisticTypes.TEAM_TURNOVERS,
				name: t("details_game.statisticNames.basketball.TEAM_TURNOVERS"),
				type: "number",
			},
			{
				key: statisticTypes.THREE_POINT_ATTEMPTS_SUCCESSFUL,
				name: t("details_game.statisticNames.basketball.THREE_POINT_ATTEMPTS_SUCCESSFUL"),
				type: "number",
			},
			{
				key: statisticTypes.THREE_POINT_ATTEMPTS_TOTAL,
				name: t("details_game.statisticNames.basketball.THREE_POINT_ATTEMPTS_TOTAL"),
				type: "number",
			},
			{
				key: statisticTypes.TIME_SPENT_IN_LEAD,
				name: t("details_game.statisticNames.basketball.TIME_SPENT_IN_LEAD"),
				type: "number",
			},
			{
				key: statisticTypes.TIMEOUTS,
				name: t("details_game.statisticNames.basketball.TIMEOUTS"),
				type: "number",
			},
			{
				key: statisticTypes.TURNOVERS,
				name: t("details_game.statisticNames.basketball.TURNOVERS"),
				type: "number",
			},
			{
				key: statisticTypes.TWO_POINT_ATTEMPTS_SUCCESSFUL,
				name: t("details_game.statisticNames.basketball.TWO_POINT_ATTEMPTS_SUCCESSFUL"),
				type: "number",
			},
			{
				key: statisticTypes.TWO_POINT_ATTEMPTS_TOTAL,
				name: t("details_game.statisticNames.basketball.TWO_POINT_ATTEMPTS_TOTAL"),
				type: "number",
			},
		],
	};

	const createStatistics = (result) => {
		try {
			const list = [],
				home = result.match_data.statistics.totals.competitors.find((i) => i.qualifier === "home").statistics,
				away = result.match_data.statistics.totals.competitors.find((i) => i.qualifier === "away").statistics;
			console.log(home);
			for (const item of NAME_STATISTICS[sportType.value]) {
				if (
					home?.[item.key] !== undefined &&
					away?.[item.key] !== undefined &&
					(home[item.key] !== 0 || away[item.key] !== 0)
				) {
					const allPercent = home[item.key] + away[item.key];

					list.push({
						name: item.name,
						value: {
							home: home[item.key] + (item.type === "percent" ? "%" : ""),
							away: away[item.key] + (item.type === "percent" ? "%" : ""),
						},
						progress: {
							home: (home[item.key] / allPercent) * 100,
							away: (away[item.key] / allPercent) * 100,
						},
					});
				}
			}
			statistics.value = list;
		} catch (error) {
			isReady.value = false;
		}
	};
	// lineups
	const sortPlayer = (list, time, qualifier) => {
			for (const item of time) {
				if (item?.starter) {
					list.starter[qualifier].push(item);
				} else {
					list.substitution[qualifier].push(item);
				}
			}
		},
		createLineups = (result) => {
			try {
				const home = result.match_data.lineup.competitors.find((i) => i.qualifier === "home"),
					away = result.match_data.lineup.competitors.find((i) => i.qualifier === "away"),
					list = {
						starter: {
							name: t("details_game.sections.starter"),
							home: [],
							away: [],
						},
						substitution: {
							name: t("details_game.sections.substitution"),
							home: [],
							away: [],
						},
						manager: {
							name: t("details_game.sections.manager"),
							home: home?.manager ? [home.manager] : [],
							away: away?.manager ? [away.manager] : [],
						},
					};

				sortPlayer(list, home.players, "home");
				sortPlayer(list, away.players, "away");

				for (const key in list) {
					if (Object.hasOwnProperty.call(list, key)) {
						const item = list[key];
						if (!item.home.length && !item.away.length) {
							delete list[key];
						}
					}
				}

				lineups.value = list;
			} catch (error) {
				isReady.value = false;
			}
		};

	// formation
	const createFormation = (result) => {
		try {
			const position = {
				home: {
					players: [],
				},
				away: {
					players: [],
				},
			};

			for (const team of result.match_data.lineup.competitors) {
				if (team?.formation?.type) {
					const players = team.players.filter((i) => i?.starter).sort((a, b) => b.order - a.order);

					position[team.qualifier].players.push([players.pop()]);

					for (const countInColumn of team.formation.type.split("-")) {
						const column = [];
						for (let i = 0; i < countInColumn; i++) {
							column.push(players.pop());
						}
						position[team.qualifier].players.push(column);
					}

					position[team.qualifier].formation = team.formation.type;
					position[team.qualifier].jersey = team.jersey;
				} else {
					formation.value = false;
					return false;
				}
			}

			formation.value = position;
		} catch (error) {
			isReady.value = false;
		}
	};

	// text
	const createText = (result) => {
		try {
			text.value = result.match_data.timeline
				.filter((event) => {
					return (
						![
							"shot_saved",
							"possible_goal",
							"video_assistant_referee_over",
							"video_assistant_referee",
							"period_start",
							"break_start",
						].includes(event.type) && !(event.type === "penalty_shootout" && event.status === "scored")
					);
				})
				.reverse()
				.map((event) => {
					const isNotComment = !event.commentaries || event.commentaries.length === 0;
					if (event.type === "match_ended" && isNotComment) {
						event.commentaries = [{ text: t("details_game.commentaries.matchEnded") }];
					} else if (event.type === "injury_time_shown" && isNotComment && event["injury_time_announced"]) {
						event.commentaries = [
							{ text: t("details_game.commentaries.injuryTimeAnnounced", { time: event["injury_time_announced"]}, "details_game.commentaries.minute" ) },
							{ text: `Назначено дополнительное время ${event["injury_time_announced"]} минут` },
						];
						event.stoppage_time = "";
					} else if (event.type === "match_started" && isNotComment) {
						event.commentaries = [{ text: t("details_game.commentaries.matchStarted") }];
					} else if (event.type === "penalty_awarded" && isNotComment) {
						event.commentaries = [{ text: t("details_game.commentaries.penaltyAwarded") }];
					}
					return event;
				})
				.filter((event) => !!event.commentaries);
		} catch (error) {
			isReady.value = false;
		}
	};

	// statisticsPlayers
	const createStatisticsPlayers = (result) => {
		try {
			let top = [];

			for (const competitor of result.match_data.statistics.totals.competitors) {
				competitor.players.map((item) => {
					item.competitorName = competitor.name;
					item.competitorAbbreviation = competitor.abbreviation;
				});

				statisticsPlayers.value[competitor.qualifier] = {
					players: competitor.players,
					abbreviation: competitor.abbreviation,
					name: competitor.name,
				};

				top = [...top, ...competitor.players];
			}

			// top 3
			let sortType;
			if (sportType.value === sportTypeName.FOOTBALL) {
				sortType = statisticTypes.GOALS_SCORED;
			} else if (sportType.value === sportTypeName.HOCKEY) {
				sortType = statisticTypes.POINTS;
			}

			top.sort((a, b) => b.statistics[sortType] - a.statistics[sortType]);
			statisticsPlayers.value.top = top.slice(0, 3);
		} catch (error) {
			isReady.value = false;
		}
	};
	return {
		reset,
		sportType,
		isReady,
		nav,
		compositions,
		createCompositions,
		statistics,
		createStatistics,
		lineups,
		createLineups,
		formation,
		createFormation,
		text,
		createText,
		statisticsPlayers,
		createStatisticsPlayers,
		progress,
		createProgress,
	};
});
