<script setup lang="ts">
import { useRoute } from "vue-router";
import { computed, watch } from "vue";

import type { PlayerInfo, NavListItem } from "./playerInterface";
import { ROUTE_NAME_PLAYER } from "./playerConsts";
import PlayerHead from "./components/PlayerHead.vue";
import PlayerReview from "./components/PlayerReview.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = defineProps<{
	value: {
		playerInfo: PlayerInfo;
		sport: string;
	};
}>();

const canonicalLink = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;

// router
const route = useRoute();

watch(
	() => route.fullPath,
	() => {
		if (canonicalLink) {
			canonicalLink.href = window.location.href;
		}
	}
);

const routeProps = computed<NavListItem>(() => {
	let pr: NavListItem = {
		api: undefined,
		name: "",
	};
	if (route.matched.length) {
		pr = {
			api: navList[String(route.matched?.[0].name)]?.api,
			name: navList[String(route.matched?.[0].name)].name,
		};
	}
	return pr;
});

// navList
const navList: Record<string, NavListItem> = {
	[ROUTE_NAME_PLAYER.REVIEW]: {
		name: t("details_game.tabs.compositions"),
	},
	...props.value.playerInfo.tabs,
};

const isActive = (key: string) => {
	if (route.path === "/" && key === "review") {
		return "_active";
	}
	return "";
};
</script>

<template>
	<div class="player-details">
		<PlayerHead :player-info="value.playerInfo" />

		<div>
			<div class="tabs-wrap">
				<ul class="tabs">
					<li v-for="(item, key) in navList" :key="'player_tab_' + key" class="tab__item">
						<router-link :to="`/${key}/`" class="tab-btn" :class="isActive(String(key))">{{
							item.name
						}}</router-link>
					</li>
				</ul>
			</div>
		</div>
		<PlayerReview
			v-if="route.path === '/'"
			:api="routeProps.api"
			:title="routeProps.name"
			:nav-list="navList"
			:player="{
				name: value.playerInfo.name,
				countryCode: value.playerInfo.country_code,
			}"
			:sportType="props.value.sport"
		/>
		<router-view
			v-else
			:api="routeProps.api"
			:title="routeProps.name"
			:nav-list="navList"
			:player="{
				name: value.playerInfo.name,
				countryCode: value.playerInfo.country_code,
			}"
		></router-view>
	</div>
</template>
