<script setup lang="ts">
import { FAVORITE_TYPE } from "@app/consts.ts";
import Star from "@app/ui/star/Star.vue";

import { useFavoriteStore } from "../FavoriteStore";

// store
const store = useFavoriteStore();
</script>

<template>
	<ul class="list">
		<li v-for="item in store.teams" :key="'league' + item.id" class="item">
			<a :href="item.slug" :title="item.name" class="link">
				<!-- <img class="icon-flag" :src="flagIconUrl(item.country)" alt="Флаг " width="27" height="20" /> -->
				<span class="name">{{ item.name }}</span>
			</a>
			<Star
				:type="FAVORITE_TYPE.TEAM"
				:favName="item.name"
				:favId="item.id"
				:favSlug="item.slug"
				class="star"
			></Star>
		</li>
	</ul>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.item {
	width: 100%;
	display: flex;
	align-items: center;
	border-radius: 15px;
}
.link {
	display: flex;
	align-items: center;
	gap: 6px;
	text-decoration: none;
	width: calc(100% - 20px);
	font-size: 12px;
	line-height: 110%;
	color: var(--color-main-text);
	transition: color 200ms ease-in-out;

	&:hover,
	&:focus {
		color: var(--color-dop-cta);
	}
}

.name {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.star {
	flex-shrink: 0;
	opacity: 0;
	height: 20px;
	width: 20px;
	transition: opacity 200ms ease-in-out;
	margin-left: auto;
}
.item {
	&:hover {
		.star {
			opacity: 1;
		}
	}
}

@media screen and (max-width: 768px) {
	.star {
		opacity: 1;
	}
}
</style>
