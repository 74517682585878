<script setup lang="ts">
import { computed, watch, inject } from "vue";
import { FETCH_PATH, SPORT_TYPE_NAME } from "@app/consts.ts";
import { useFavoriteStore } from "@app/modules/favorites/FavoriteStore";

import type { SportResponse } from "./sportInterface";
import { useSport } from "./SportStore";

import SportHead from "./components/SportHead.vue";
import SportBody from "./components/SportBody.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;
console.log(t('game_head.status.over'));

// store
const storeSport = useSport();
const favoriteStore = useFavoriteStore();

// inject
const api = inject<string>("api", "/"),
	sportType = inject<string>("sportType", SPORT_TYPE_NAME.FOOTBALL),
	seoTitle = inject<string | null>("seoTitle", null);

storeSport.sportType = sportType;

// selectedDateItem
const selectedDateItem = computed(() => storeSport.dateList[storeSport.selectedDate]);

// fetch
const getAllList = (): void => {
	// fetch(FETCH_PATH() + "/sport/tennis/", {
	fetch(FETCH_PATH() + api, {
		method: "POST",
		body: `{"date":"${selectedDateItem.value.date}"}`,
	})
		.then((response) => response.json())
		.then((response: SportResponse[]) => {
			// favoriteLeague sort
			const favoriteLeague: (string | number)[] = [];
			if (favoriteStore.league) {
				for (const item of favoriteStore.league) {
					favoriteLeague.push(item.id);
				}
			}

			response.sort((a) => {
				return a.games.every((game) => game.status == t('game_head.status.over')) ? 0 : -1;
			});

			response.sort((a, b) => {
				return +favoriteLeague.includes(b.id) - +favoriteLeague.includes(a.id);
			});

			storeSport.createLists(response);
		})
		.catch((e) => {
			console.error("POST Sport " + e);
		});
};

getAllList();

watch(
	() => storeSport.selectedDate,
	() => {
		if (!storeSport.lists[storeSport.selectedDate].isReady) {
			getAllList();
		}
	}
);

setInterval(() => {
	if (selectedDateItem.value.repeatRequest && !document.hidden) {
		getAllList();
	}
}, 25000);

document.addEventListener("visibilitychange", () => {
	if (selectedDateItem.value.repeatRequest && !document.hidden) {
		getAllList();
	}
});
</script>

<template>
	<h1 v-if="seoTitle" class="smallH1">{{ seoTitle }}</h1>
	<SportHead :api="api || '/'"/>
	<SportBody />
</template>
