<script setup lang="ts">
import { onMounted, inject, ref } from "vue";

const props = defineProps<{
	bookieLogoUrl: string;
	bannerName: string;
}>();

const adfoxCode = inject<any | null>("adfoxCode", null);

let showAdfox = ref(false);

onMounted(() => {
	if (adfoxCode) {
		if (adfoxCode[props.bannerName]) {
			// @ts-ignore
			const adfoxCodeItem: any = adfoxCode[props.bannerName];
			// @ts-ignore
			window.yaContextCb.push(() => {
				const adfoxCodeItem: any = adfoxCode[props.bannerName];
				// @ts-ignore
				Ya.adfoxCode.create({
					...adfoxCodeItem,
					...{
						onRender: () => {
							showAdfox.value = true;
						},
					},
				});
			});
		}
	}
});
</script>

<template>
	<div class="banner">
		<div class="banner-ya" v-if="adfoxCode[bannerName].containerId" :id="adfoxCode[bannerName].containerId"></div>
		<img class="banner-loc" :src="props.bookieLogoUrl" height="16" width="70" />
	</div>
</template>

<style scoped lang="scss">
.banner {
  position: relative;
  height: 30px;
}
.banner-ya {
  z-index: 1;
	position: absolute;
  width: 100%;
}
.banner-loc {
	position: absolute;
}
</style>
