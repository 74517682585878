<script setup>
import { inject } from "vue";

import Player from "@app/modules/player/Player.vue";

const api = inject("api", ""),
	sportType = inject("sportType", "football"),
	playerInfo = inject("playerInfo", false);
</script>

<template>
	<Player
		:value="{
			eventUrl: api,
			sport: sportType,
			playerInfo: playerInfo,
		}"
	/>
</template>

<style lang="scss">
#player-details {
	width: 100%;
}
</style>
