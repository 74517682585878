<script setup>
import { computed, ref, inject } from "vue";
import usei18n from "@app/i18n/i18n";

import TableGameStatus from "@app/ui/table/game-status/TableGameStatus.vue";
import { sportTypeName } from "@assets/app/consts.js";
import { useDetailsStanding } from "../DetailsStandingStore.js";

const { t } = usei18n.global;

const props = defineProps({
	currentSportType: String,
});

// store
const storeDetailsStanding = useDetailsStanding();

const sortField = ref("");
const sortFieldNav = ref("asc");

const sortStandingsComputed = computed(() => {
	const result = [...storeDetailsStanding.standing.teams[0].list];
	console.log(result);
	if (sortField && result && result.length) {
		return result.sort((teamA, teamB) => {
			if (sortField.value === "name") {
				const checkBool =
					sortFieldNav.value === "asc"
						? teamA.competitor.name > teamB.competitor.name
						: teamA.competitor.name < teamB.competitor.name;
				return checkBool === true ? 1 : -1;
			} else {
				return sortFieldNav.value === "asc"
					? teamA[sortField.value] - teamB[sortField.value]
					: teamB[sortField.value] - teamA[sortField.value];
			}
		});
	}
	return result;
});

console.log(sortStandingsComputed.value);

const setSort = (fieldName) => {
	if (fieldName === "") {
		return;
	}
	if (sortField.value === fieldName) {
		sortFieldNav.value = sortFieldNav.value === "asc" ? "desc" : "asc";
	} else {
		sortFieldNav.value = "asc";
	}
	sortField.value = fieldName;
};

const tableThNames = {
	[sportTypeName.FOOTBALL]: [
		{ id: "index", name: t("details_standing.FOOTBALL.name_index"), sort: true, title: t("details_standing.FOOTBALL.title_index") },
		{ id: "name", name: t("details_standing.FOOTBALL.name_name"), sort: true, title: t("details_standing.FOOTBALL.title_name") },
		{ id: "played", name: t("details_standing.FOOTBALL.name_played"), sort: true, title: t("details_standing.FOOTBALL.title_played") },
		{ id: "win", name: t("details_standing.FOOTBALL.name_win"), sort: true, title: t("details_standing.FOOTBALL.title_win") },
		{ id: "draw", name: t("details_standing.FOOTBALL.name_draw"), sort: true, title: t("details_standing.FOOTBALL.title_draw") },
		{ id: "loss", name: t("details_standing.FOOTBALL.name_loss"), sort: true, title: t("details_standing.FOOTBALL.title_loss") },
		{ id: "goals_against", name: t("details_standing.FOOTBALL.name_goals_against"), sort: true, title: t("details_standing.FOOTBALL.title_goals_against") },
		{ id: "points", name: t("details_standing.FOOTBALL.name_points"), sort: true, title: t("details_standing.FOOTBALL.title_points") },
		{ id: "form", name: t("details_standing.FOOTBALL.name_form"), sort: false, title: t("details_standing.FOOTBALL.title_form") }
	],
	[sportTypeName.HOCKEY]: [
		{ id: "index", name: t("details_standing.HOCKEY.name_index"), sort: true, title: t("details_standing.HOCKEY.title_index") },
		{ id: "name", name: t("details_standing.HOCKEY.name_name"), sort: true, title: t("details_standing.HOCKEY.title_name") },
		{ id: "played", name: t("details_standing.HOCKEY.name_played"), sort: true, title: t("details_standing.HOCKEY.title_played") },
		{ id: "win", name: t("details_standing.HOCKEY.name_win"), sort: true, title: t("details_standing.HOCKEY.title_win") },
		{ id: "draw", name: t("details_standing.HOCKEY.name_draw"), sort: true, title: t("details_standing.HOCKEY.title_draw") },
		{ id: "loss", name: t("details_standing.HOCKEY.name_loss"), sort: true, title: t("details_standing.HOCKEY.title_loss") },
		{ id: "goals_against", name: t("details_standing.HOCKEY.name_goals_against"), sort: true, title: t("details_standing.HOCKEY.title_goals_against") },
		{ id: "points", name: t("details_standing.HOCKEY.name_points"), sort: true, title: t("details_standing.HOCKEY.title_points") },
		{ id: "form", name: t("details_standing.HOCKEY.name_form"), sort: false, title: t("details_standing.HOCKEY.title_form") }
	],
	[sportTypeName.BASKETBALL]: [
		{ id: "index", name: t("details_standing.BASKETBALL.name_index"), sort: true, title: t("details_standing.BASKETBALL.title_index") },
		{ id: "name", name: t("details_standing.BASKETBALL.name_name"), sort: true, title: t("details_standing.BASKETBALL.title_name") },
		{ id: "played", name: t("details_standing.BASKETBALL.name_played"), sort: true, title: t("details_standing.BASKETBALL.title_played") },
		{ id: "win", name: t("details_standing.BASKETBALL.name_win"), sort: true, title: t("details_standing.BASKETBALL.title_win") },
		{ id: "draw", name: t("details_standing.BASKETBALL.name_draw"), sort: true, title: t("details_standing.BASKETBALL.title_draw") },
		{ id: "loss", name: t("details_standing.BASKETBALL.name_loss"), sort: true, title: t("details_standing.BASKETBALL.title_loss") },
		{ id: "points_against", name: t("details_standing.BASKETBALL.name_points_against"), sort: true, title: t("details_standing.BASKETBALL.title_points_against") },
		{ id: "win_percentage", name: t("details_standing.BASKETBALL.name_win_percentage"), sort: true, title: t("details_standing.BASKETBALL.title_win_percentage") },
		{ id: "form", name: t("details_standing.BASKETBALL.name_form"), sort: false, title: t("details_standing.BASKETBALL.title_form") }
	],
};

// linkTu
const linkTu = (url) => {
	if (url) window.location = url;
};
</script>

<template>
	<div class="standing-table__wrapper">
		<table class="standing main-table">
			<tbody>
				<tr class="standing-table__title">
					<th
						@click="setSort(headerTitle.sort ? headerTitle.id : '')"
						v-for="headerTitle in tableThNames[currentSportType]"
						:key="headerTitle.id"
						:title="headerTitle.title"
						:class="{
							cursorPointer: headerTitle.sort,
							active: headerTitle.sort && sortField === headerTitle.id,
						}"
					>
						{{ headerTitle.name }}
						<span
							v-if="headerTitle.sort && sortField === headerTitle.id"
							class="arrow"
							:class="{
								arrowRotate: sortFieldNav === 'desc',
							}"
							>▲</span
						>
					</th>
				</tr>
				<tr
					v-for="team in sortStandingsComputed"
					:key="team.competitor.id"
					class="standing-row"
					@click="linkTu(team.competitor.route ?? undefined)"
					@keyup.enter="linkTu(team.competitor.route ?? undefined)"
					:href="team.competitor.route ?? undefined"
					role="link"
				>
					<td v-for="headerTitle in tableThNames[currentSportType]" :key="headerTitle.id">
						<template v-if="headerTitle.id === 'name'">
							{{ team.competitor.name }}
						</template>
						<template v-else-if="headerTitle.id === 'points_against' || headerTitle.id === 'goals_against'">
							{{ team.goals_for ?? team.points_for }}:{{ team.goals_against ?? team.points_against }}
						</template>
						<template v-else-if="headerTitle.id === 'form'">
							<div class="standing-row__match-status">
								<TableGameStatus
									v-for="match in team.matchesNew"
									:key="`${team.competitor.id}_${match.id}`"
									:score="match?.status?.class"
									v-tooltip="match.title ? { content: match.title, html: true } : false"
								/>
							</div>
						</template>
						<template v-else>
							{{ team[headerTitle.id] }}
						</template>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<style scoped lang="scss">
th {
	color: var(--color-dop-text);
	font-weight: 400;
	position: relative;
	&.active {
		color: #fff;
	}
}
.standing-table__title {
	&:hover {
		background: transparent;
	}
}
.standing-table__wrapper {
	overflow: auto;
}
.standing {
	width: 100%;
	font-size: 12px;
	border-collapse: collapse;
}
.standing-title {
	background: var(--color-dop-cta);
	border-radius: 5px;
	color: hsl(220, 4%, 14%);
	th:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	th:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	th {
		padding: 5px;
	}
}
.standing td {
	text-align: center;
	padding: 5px;
	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6),
	&:nth-child(7),
	&:nth-child(8),
	&:nth-child(1) {
		width: 50px;
	}

	&:nth-child(2) {
		width: 350px;
		text-align: left;
	}
}

.standing-row__match-status {
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.standing-row {
	span {
		border-radius: 5px;
		width: 25px;
		height: 25px;
		color: white;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.h2h-status__winner {
		background-color: #00a83f;
	}
	.h2h-status__lose {
		background-color: #dc0000;
	}
	.h2h-status__draw {
		background-color: #f3a000;
	}
}
.h2h-status {
	position: relative;
	overflow: hidden;
	&.dop_draw::after {
		border-bottom: 12px solid transparent;
		border-right: 12px solid #f3a000;
		border-top: 12px solid transparent;
		content: "";
		height: 0;
		position: absolute;
		right: 0;
		top: 13px;
		width: 0;
	}
}
.arrow {
	font-size: 0px;
	width: 2px;
	height: 4px;
	position: absolute;
	color: transparent;
	border: 4px solid transparent;
	border-top-color: #fff;
	margin-left: 5px;
	top: 15px;
}
.arrowRotate {
	transform: rotate(180deg);
	display: inline-block;
	top: 10px;
}
.cursorPointer {
	cursor: pointer;
	&:hover {
		color: #fff;
	}
}
.name-wrap {
	a {
		margin-left: 10px;
		text-decoration: none;
		line-height: 100%;
		white-space: nowrap;
	}
}

.name {
	display: flex;
	justify-content: flex-start;
	font-size: 14px;
}
</style>
