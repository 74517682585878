<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
		modelValue: string | number | boolean;
		tabValue: string | number | boolean;
		tabName: string;
	}>(),
	emit = defineEmits(["update:modelValue"]);

const value = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit("update:modelValue", value);
	},
});
</script>

<template>
	<label class="btn-wrap">
		<input v-model="value" :value="props.tabValue" :name="props.tabName" type="radio" class="input" />
		<span class="tab-btn" :class="{ _active: value === props.tabValue }">
			<slot></slot>
		</span>
	</label>
</template>

<style scoped lang="scss">
.btn-wrap {
	position: relative;
	display: inline-block;
	cursor: pointer;
	border: none;
}

.input {
	appearance: none;
	display: block;
	position: absolute;
	flex-shrink: 0;
	top: 0;
	left: 0;
	opacity: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	margin: 0;
	z-index: 1;
	border-radius: 0;
	border: none;
}

.tab-btn {
	white-space: nowrap;
}
</style>
