const ROUTE_NAME_GAME = {
	COEFFICIENT: "coefficient",
	MATCH: "match",
	_H2H: "h2h",
	BOMBARDIER: "bombardier",
	GRID: "grid",
	STANDING: "standing",
	VIDEO: "video",
	BROADCAST: "broadcast",
};

// limitation tabs of sport
const LIMITATION = {
	"tennis": [
		"standing"
	],
	"table-tennis": [
		"standing"
	],
	"mma-ufc": [
		"standing"
	],
	"boxing": [
		"standing"
	]
}

export { ROUTE_NAME_GAME, LIMITATION };
