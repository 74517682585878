// https://stage.myscore.club
const FETCH_PATH = () => (import.meta.env.MODE === "dev" ? "/api" : "/api"),
	HEADERS_LIST = () => {
		if (import.meta.env.MODE === "dev") {
			const headers = new Headers();
			headers.set("Authorization", "Basic " + btoa("myscore:tWtKw3gLO4"));
			return headers;
		} else {
			return {};
		}
	},
	ADMIN_IMG_STATIC_PATH = () => {
		return import.meta.env.MODE === "prod" ? STATIC_PATH : "";
	};

const STATIC_PATH = "https://staticmy.devrepo2.ru",
	// LOCALE = "ru-RU",
	IS_OPEN_ITEM_LEAGUE = "_is_open_item_league",
	TARGET_BLANK = "_blank",
	USER_NAME = "_user_name",
	GET_INTERVAL_DURATION = 25000;
const LANGUAGE = {
	"?locale=ru": { codeLang: "ru", flagIcon: "ru", name: "Русский", locale: "ru-RU" },
	"?locale=es": { codeLang: "es", flagIcon: "es", name: "Español", locale: "es-ES" },
	"?locale=en": { codeLang: "en", flagIcon: "gb-eng", name: "English", locale: "en-EN" },
};

const gameStatusList = {
		VICTORY: "victory",
		DEFEAT: "defeat",
		DRAW: "draw",
	},
	sportTypeName = {
		FOOTBALL: "football",
		HOCKEY: "hockey",
		BOXING: "boxing",
		MMA_UFC: "mma-ufc",
		TENNIS: "tennis",
		BASKETBALL: "basketball",
	},
	favoriteType = {
		LEAGUE: "league",
		TEAM: "teams",
	},
	periodType = {
		REGULAR_PERIOD: "regular_period",
		OVERTIME: "overtime",
		PENALTIES: "penalties",
	};

export {
	FETCH_PATH,
	HEADERS_LIST,
	ADMIN_IMG_STATIC_PATH,
	STATIC_PATH,
	// LOCALE,
	IS_OPEN_ITEM_LEAGUE,
	TARGET_BLANK,
	USER_NAME,
	GET_INTERVAL_DURATION,
	gameStatusList,
	sportTypeName,
	favoriteType,
	periodType,
	LANGUAGE,
};
