import { defineStore } from "pinia";
import { shallowRef, ref } from "vue";

import UserAuthnSignIn from "./components/UserAuthnSignIn.vue";

export const useUserAuthn = defineStore("UserAuthn", () => {
	const components = shallowRef(UserAuthnSignIn),
		message = ref({
			title: false,
			text: false,
		});

	return { components, message };
});
