const ROUTE_NAME_TEAM = {
		REVIEW: "review",
		CALENDAR: "calendar",
		RESULTS: "results",
		SQUAD: "squad",
		STANDING: "standing",
		TRANSFERS: "transfers",
		GRID: "grid",
	},
	TITLE_LIST = {
		RESULTS: "Последние результаты",
		CALENDAR: "Расписание",
		TRANSFERS: "Переходы",
	};

enum RESULTS_STATUS {
	CLOSED = "closed",
	NOT_STARTED = "not_started",
}

enum TRANSFERS_KEY {
	TOTAL = "total",
	FROM = "from",
	TO = "to",
}

enum TRANSFERS_IS_FROM {
	FROM = "toCompetitor",
	TO = "fromCompetitor",
}

export { ROUTE_NAME_TEAM, TITLE_LIST, RESULTS_STATUS, TRANSFERS_KEY, TRANSFERS_IS_FROM };
