<script setup>
import { getApi } from "@app/helpers.js";
import TabBtn from "@app/ui/tab-btn/TabBtn.vue";
import { inject } from "vue";
import usei18n from "@app/i18n/i18n";

import { useDetailsStanding } from "./DetailsStandingStore.js";
import { TAB_KEYS } from "./detailsStandingConsts.js";
import DetailsStandingTable from "./components/DetailsStandingTable.vue";
import DetailsStandingUnder from "./components/DetailsStandingUnder.vue";
import DetailsStandingHTFT from "./components/DetailsStandingHTFT.vue";
import DetailsStandingSkeleton from "./components/DetailsStandingSkeleton.vue";
import { sportTypeName } from "@assets/app/consts.js";

const { t } = usei18n.global;
const sportType = inject("sportType", null);

const props = defineProps({
	api: String,
});
console.log(props.api);

// store
const storeDetailsStanding = useDetailsStanding();

if (!storeDetailsStanding.isReady) {
	getApi(props.api).then((response) => {
		storeDetailsStanding.addStandingTable(response.standing);
		storeDetailsStanding.addStandingOverUnder(response.standing);
		storeDetailsStanding.addStandingHTFT(response.standing);
		storeDetailsStanding.isReady = true;
		console.log(response);
	});
}

const currentSportType = () => {
	if (sportType) {
		return sportType;
	} else {
		const parts = props.api.split("/");
		for (let i = 0; i < parts.length; i++) {
			if (parts[i] === "sport") {
				return parts[i + 1];
			}
		}
	}
};

// tabs
const tabValue = {
	[sportTypeName.FOOTBALL]: {
		[TAB_KEYS.TABLE]: {
			name: t("details_standing.TABLE"),
			component: DetailsStandingTable,
		},
		[TAB_KEYS.UNDER]: {
			name: t("details_standing.UNDER"),
			component: DetailsStandingUnder,
		},
		[TAB_KEYS.HTFT]: {
			name: t("details_standing.HTFT"),
			component: DetailsStandingHTFT,
		},
	},

	[sportTypeName.BASKETBALL]: {
		[TAB_KEYS.TABLE]: {
			name: t("details_standing.TABLE"),
			component: DetailsStandingTable,
		},
	},
};
</script>

<template>
	<section v-if="storeDetailsStanding.isReady" class="table-list-wrap">
		<div class="tabs-wrap _secondary">
			<ul class="tabs">
				<li v-for="(item, key) in tabValue[currentSportType()]" :key="'details_standing_' + key" class="tab__item">
					<TabBtn v-model="storeDetailsStanding.nav" :tab-value="key" :tab-name="'game-details_nav1'">
						{{ item.name }}
					</TabBtn>
				</li>
			</ul>
		</div>
		<component :is="tabValue[currentSportType()][storeDetailsStanding.nav].component" :currentSportType="currentSportType()" />
	</section>
	<DetailsStandingSkeleton v-else />
</template>

<style scoped lang="scss"></style>
