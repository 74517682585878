<script setup>
import { useDetailsGame } from "../DetailsGameStore.js";
import { eventType } from "../detailsGameConsts.js";

import EventIcon from "../ui/EventIcon.vue";

// store
const storeDetailsGame = useDetailsGame();

const isIconType = (type) => {
	return [
		eventType.INJURY_TIME_SHOWN,
		eventType.CORNER_KICK,
		eventType.INJURY,
		eventType.YELLOW_CARD,
		eventType.RED_CARD,
		eventType.YELLOW_RED_CARD,
		eventType.SCORE_CHANGE,
		eventType.SUBSTITUTION,
		eventType.MATCH_ENDED,
		eventType.PERIOD_SCORE,
		eventType.MATCH_STARTED,
		eventType.SCORE_CHANGE,
		eventType.PENALTY_MISSED,
		eventType.PENALTY_SHOOTOUT,
	].includes(type);
};
</script>

<template>
	<div class="comment">
		<div
			v-for="(event, eventKey) in storeDetailsGame.text"
			:key="'eventKey_' + eventKey"
			:class="{ [event.type]: true }"
			class="comment-line"
		>
			<div class="comment-line__title">
				<span v-if="event.match_time" :class="{ _active: 'score_change' === event.type }">
					{{ event.match_time }}
					<span v-if="event.stoppage_time">+ {{ event.stoppage_time }}</span
					>'
				</span>
				<div
					class="comment-line__info_svg"
					v-if="isIconType(event.type)"
					:class="{ _active: 'score_change' === event.type }"
				>
					<EventIcon :event="event.type" />
					<span v-if="event.type === 'score_change' && event.period_type !== 'penalties'"
						>{{ event["home_score"] }} - {{ event["away_score"] }}</span
					>
				</div>
			</div>
			<div class="comment-line__text">
				<p v-for="(comment, commentKey) in event.commentaries" :key="'commentKey_' + commentKey">
					{{ comment.text }}
				</p>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.comment {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 0 6px 6px;
}
.comment-line__title {
	display: flex;
	align-items: center;
	gap: 6px;

	> span {
		font-weight: 400;
		font-size: 14px;
		line-height: 100%;
		color: var(--color-dop-text);

		&._active {
			color: var(--color-main-text);
		}
	}
}
.comment-line {
	display: flex;
	flex-direction: column;
	gap: 6px;
	padding-bottom: 13px;
	background: var(--color-border);
	background-size: 100% 1px;
	background-repeat: no-repeat;
	background-position: bottom left;

	&:last-child {
		padding-bottom: 0;
		background: none;
	}
}

.comment-line__text {
	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 130%;
		margin: 4px 0;
	}

	* {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.comment-line.score_change {
	font-weight: bolder;
}
.comment-line__info_svg {
	display: flex;
	align-items: center;
	padding: 2px 4px;
	gap: 6px;
	background: var(--color-main-back);
	border-radius: 4px;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	color: var(--color-main-text);

	> span {
		margin-right: 3px;
	}

	&._active {
		background: var(--color-dop-cta);
		color: hsl(220, 4%, 14%);

		svg {
			fill: hsl(220, 4%, 14%);
			stroke: hsl(220, 4%, 14%);
		}
	}
}

@media screen and (max-width: 960px) {
	.comment {
		padding: 0 10px;
	}
}
</style>
