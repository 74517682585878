<script setup>
import { computed } from "vue";

const props = defineProps({
	coefficient: Object,
});

const coefUpDown = computed(() => {
	switch (true) {
		case !props.coefficient.new || !props.coefficient.old || props.coefficient.new === props.coefficient.old:
			return false;

		case +props.coefficient.new > +props.coefficient.old:
			return "_up";

		case +props.coefficient.new < +props.coefficient.old:
			return "_down";
	}
});

const coefNumber = computed(() => {
	const number = props.coefficient?.new ? +props.coefficient.new : +props.coefficient.old;
	return number !== 0 && number ? number.toFixed(2) : "-";
});
</script>

<template>
	<div class="coef" title="">
		<svg :class="coefUpDown" class="icon-arrow" width="20" height="20">
			<use xlink:href="#20-arrow" />
		</svg>
		{{ coefNumber }}
	</div>
</template>

<style scoped lang="scss">
.coef {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding: 0 4px;
	font-size: 12px;
	line-height: 100%;
	cursor: default;
}

.icon-arrow {
	stroke-width: 2;
	width: 16px;
	height: 16px;
	stroke-linecap: round;
	stroke-linejoin: round;

	&._up {
		stroke: var(--color-dop-cta);
		transform: rotate(180deg);
	}

	&._down {
		stroke: var(--color-error);
	}
}

@media screen and (max-width: 1020px) {
	.coef {
		font-size: 12px;
		padding: 2px 4px;
	}
}

@media screen and (max-width: 570px) {
	.coef {
		justify-content: flex-start;
		padding: 2px 2px;
	}
}
</style>
