<script setup>
import { linkTitle } from "@app/helpers.js";
import { flagIconUrl } from "@app/helpers.ts";

const props = defineProps({
	itemRanking: Object,
});

// linkTu
const linkTu = (url) => {
	window.location = url;
};
</script>

<template>
	<tr>
		<td class="item_td">{{ $t('ranking.position') }}</td>
		<td class="item_td">{{ $t('ranking.name') }}</td>
		<td class="item_td">{{ $t('ranking.country') }}</td>
		<td class="item_td">{{ $t('ranking.points') }}</td>
		<td class="item_td">{{ $t('ranking.tournaments') }}</td>
	</tr>
	<tr
		v-for="item in itemRanking"
		@click="linkTu(item?.competitor?.route)"
		@keyup.enter="linkTu(item.competitor.route)"
		:href="item.competitor.route"
		:title="linkTitle(item?.competitor)"
		tabindex="0"
		role="link"
		class="tr-click"
	>
		<td class="td__rank">
			<div>{{ item?.rank }}</div>
		</td>
		<td class="td__rank">
			<div>
				{{ item?.competitor?.name }}
				<div
					class="movement"
					:class="{ up: item?.movement > 0, down: item?.movement < 0 }"
				>
					{{ item?.movement > 0 ? "+" : "" }}{{item?.movement !== "0" ? item?.movement : ""}}
				</div>
			</div>
		</td>
		<td class="td__rank">
			<div class="country">
				<img
					v-if="item.competitor?.country_code && item.competitor?.country_code !== 'nan'"
					:src="flagIconUrl(item.competitor?.country_code)"
					class="icon-flag"
					alt="Флаг"
					width="27"
					height="20"
				/>
				<span class="country-name">{{ item?.competitor?.country }}</span>
			</div>
		</td>
		<td class="td__rank">
			<div>
				<div>{{ item?.points }}</div>
			</div>
		</td>
		<td class="td__tour">
			<div>
				<div>{{ item?.competitions_played }}</div>
			</div>
		</td>
	</tr>
</template>

<style scoped lang="scss">
.up {
	font-size: 12px;
	color: var(--color-dop-cta);
}
.down {
	font-size: 12px;
	color: var(--color-error);
}
.movement {
	display: inline-block;
	padding-left: 5px;
}
.icon-flag {
	margin-right: 12px;
	border-radius: 5px;
}
.item_td {
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 100%;
	text-align: left;
	color: var(--color-dop-text);
}
.td__rank {
	white-space: nowrap;
	text-align: left;
	padding: 0 6px;
	font-size: 14px;
	line-height: 100%;
}
.td__tour {
	text-align: center;
	padding: 6px 6px;
	font-size: 14px;
	line-height: 100%;
}
.country {
	display: flex;
	align-items: center;
}
@import "@assets/scss/utils.scss";
tr.tr-click {
	position: relative;
	cursor: pointer;
	&:hover {
		background: var(--color-dop-back);
	}

	&:first-child {
		> td {
			&:first-child {
				border-top-left-radius: 6px;
			}

			&:last-child {
				border-top-right-radius: 6px;
			}
		}
	}

	&:last-child {
		> td {
			&:first-child {
				border-bottom-left-radius: 6px;
			}

			&:last-child {
				border-bottom-right-radius: 6px;
			}
		}
	}

	td {
		// padding-bottom: 1px;

		&:first-child {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				width: 720px;
				height: 1px;
			}
		}
	}

	&:last-child {
		td {
			&::after {
				content: none;
			}
		}
	}

	&._active {
		> td {
			background: hsla(var(--accent-hsl), 0.25);
		}
	}
}

@media screen and (max-width: 1120px) {
	tr.tr-click {
		td {
			&:first-child {
				&::after {
					width: calc(100vw - 360px - (#{$block-padding-hor} * 2));
				}
			}
		}
	}
}

@media screen and (max-width: 910px) {
	tr.tr-click {
		td {
			&:first-child {
				&::after {
					width: calc(100vw - 188px - (#{$block-padding-hor} * 2));
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	tr.tr-click {
		td {
			&:first-child {
				&::after {
					width: calc(100vw - 8px - (#{$block-padding-hor-m} * 2));
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.item_td {
		font-weight: 500;
		font-size: 12px;
		line-height: 100%;
	}
	.td__rank {
		font-size: 12px;
		line-height: 100%;
	}
	.td__tour {
		font-size: 12px;
		line-height: 100%;
	}
}
</style>
