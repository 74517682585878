const ROUTE_NAME_PLAYER = {
	REVIEW: "review",
	CAREER: "career",
	RECENT_MATCHES: "recentMatches",
	TRANSFERS: "transfers",
};

enum CAREER_KEYS {
	ALL = "all",
	NATIONAL = "national",
}

export { ROUTE_NAME_PLAYER, CAREER_KEYS };
