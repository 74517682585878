<script setup>
import { ref, onMounted } from "vue";
import { directive as vClickAway } from "vue3-click-away";
import { LANGUAGE } from "@app/consts.ts";
import { flagIconUrl, setCookie, currentLocale } from "@app/helpers.ts";
import Toggle from "@assets/app/ui/toggle/Toggle.vue";
import { useState } from "../HeaderStore";
import usei18n from "@app/i18n/i18n";

// store
const stateHeader = useState();

// Locale
const t = usei18n.global;

const toggleLanguageDropdown = () => {
	stateHeader.langActive = !stateHeader.langActive;
	if (stateHeader.langActive) {
		stateHeader.presentActive = false;
		stateHeader.burgerActive = false;
	}
};

const hideLanguageDropdown = () => {
	stateHeader.langActive = false;
};

const changeLanguage = (locale) => {
	stateHeader.langActive = false;
	setCookie("locale", locale, { "max-age": 31536000 });
	t.locale = currentLocale().codeLang;
	stateHeader.currentLang = t.locale;
	location.reload();
};

onMounted(() => {
	t.locale = currentLocale().codeLang;
	stateHeader.currentLang = t.locale;
});
</script>

<template>
	<div class="lang" v-click-away="hideLanguageDropdown">
		<button @click.stop="toggleLanguageDropdown" :class="{ _active: stateHeader.langActive }" class="lang-btn">
			<span class="lang-btn__name">{{ stateHeader.currentLang }}</span>
		</button>

		<transition name="list">
			<div v-if="stateHeader.langActive" class="lang__drop">
				<ul class="lang__list">
					<li v-for="(item, key) in LANGUAGE" :key="'lang' + item.name">
						<div
							@click="stateHeader.currentLang !== item.codeLang && changeLanguage(key)"
							:class="['lang__item', { _disabled: stateHeader.currentLang === item.codeLang }]"
						>
							<span class="lang__name">{{ item.name }}</span>
							<img
								v-if="item.flagIcon"
								:src="flagIconUrl(item.flagIcon)"
								class="icon-flag"
								width="27"
								height="20"
							/>
						</div>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.lang {
	position: relative;
}

.lang__drop {
	position: absolute;
	top: 100%;
	right: -5px;
	width: calc(100vw - ($block-padding-hor-m * 2));
	max-width: 130px;
	background: var(--color-dop-back);
	border-radius: 6px;
	z-index: 100;
	padding: 15px 0;
	box-shadow: 0 10px 30px var(--color-drop-shadow);
}

.lang__list {
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: 1fr;
	padding: 0 5px;
}

.lang__item {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	margin: 5px;
	color: var(--color-dop-text);

	&:hover,
	&:focus {
		color: var(--color-dop-cta);
	}

	&._disabled {
		cursor: default;
		color: var(--color-main-text);

		&:hover,
		&:focus {
			color: var(--color-main-text);
		}
	}
}

.lang__name {
	margin-right: 10px;
}

.lang-btn {
	width: 22px;
	height: 18px;
	padding: 11px 9px;
	display: block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	border-radius: 6px;
	margin: 0;
	overflow: visible;
	box-sizing: content-box;
	color: hsl(0, 0%, 100%);

	&:hover {
		opacity: 1;
		color: var(--color-dop-cta);
	}

	&._active {
		color: hsl(0, 0%, 9%);
		background-color: var(--color-dop-cta);
	}
}

.lang-btn._active:hover {
	opacity: 1;
}

.lang-btn__name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform:uppercase
}

.list-enter-active,
.list-leave-active {
	transition: 250ms ease-in-out;
	transition-property: opacity, transform;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateY(-10px);
}

@media screen and (max-width: 768px) {
	.lang__drop {
		border: 1px solid var(--color-black);
	}

	.country {
		display: block;
		min-width: 300px;
		border-top: 1px solid var(--color-black);
		padding: 10px 5px;
	}

	.country__title {
		font-size: 16px;
		font-weight: 400;
		color: var(--color-dop-cta);
		margin: 0 0 10px 8px;
	}
}
</style>
