<script setup lang="ts">
import { toRaw, ref, computed } from "vue";
import type { GameItem } from "@app/modules/sport/sportInterface";
import SportItemGames from "@app/modules/sport/components/sport-item/SportItemGames.vue";
import RouterLinkScroll from "@app/ui/router-link-scroll/RouterLinkScroll.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = withDefaults(
	defineProps<{
		title?: string;
		games: GameItem[];
		showItems: number;
		navTo?: string;
	}>(),
	{
		showItems: 30,
	}
);

// show-more
const showItemsList = ref(props.showItems);

const cutList = computed(() => {
	const list = props.games;

	return toRaw(list).slice(0, showItemsList.value);
});
console.log(cutList);
</script>

<template>
	<div class="group">
		<h2 v-if="title" class="group__title">{{ title }}</h2>
		<table class="table">
			<SportItemGames
				v-for="itemGame in cutList"
				:key="'ItemGame_' + itemGame.id"
				:item-game="itemGame"
				:show-date="true"
			/>
		</table>

		<RouterLinkScroll v-if="navTo && games.length > showItemsList" :nav-to="navTo" class="show-more group__btn">
			{{ t('player_career.show_more_match') }}
		</RouterLinkScroll>

		<button v-else-if="games.length > cutList.length" @click="showItemsList += 30" class="show-more group__btn">
			{{ t('player_career.show_more') }}
		</button>
	</div>
</template>

<style scoped lang="scss">
.group {
	display: flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 10px;
	width: 100%;
	background: var(--color-dop-back);
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
}

.group__title {
	font-size: 20px;
	line-height: 120%;
	margin: 4px 0;
	padding-left: 8px;
}

.group__btn {
	margin-top: 6px;
	align-self: center;
}

.table {
	width: 100%;
	background: var(--color-black);
	border-radius: 6px;
	border: none;
	border-collapse: collapse;

	td {
		margin: 0;
		padding: 0;
	}
}

.list {
	display: flex;
	flex-direction: column;
}
</style>
