import { defineStore } from "pinia";
import { ref } from "vue";

import { periodType, gameStatusList } from "@assets/app/consts.js";

export const useDetailsH2H = defineStore("DetailsH2H", () => {
	const nav = ref(),
		h2h = ref(),
		isReady = ref();

	// reset
	const reset = () => {
		isReady.value = false;
		nav.value = "all";
		h2h.value = [];
	};

	reset();

	const createH2h = (result) => {
		const keys = Object.keys(result);
		if (result && keys.length > 0) {
			for (const key in result) {
				if (Object.hasOwnProperty.call(result, key)) {
					const filteredResult = result[key].filter((e) => {
						const hasValidScore = !!e?.score?.period_scores;
						const hasValidIds = e?.away_id && e?.home_id;
						const hasMatchingCompetitor = keys.some((k) => {
							return e?.competitors.some((competitor) => competitor.id === k);
						});
			
						return hasValidScore && hasValidIds && hasMatchingCompetitor;
					});
					result[key] = filteredResult.sort((a, b) => b.date - a.date);
				}
			}

			for (const key in result) {
				if (Object.hasOwnProperty.call(result, key) && result[key].length) {
					const eventList = result[key];
					const foundCompetitor = (key, eventList) => {
						for (const item of eventList) {
							const info = item.competitors.find((el) => el.id === key);
							if (info) {
								return {
									key: key,
									info: info,
									list: eventList,
								};
							}
						}
					};
					const competitor = foundCompetitor(key, eventList);
					for (const event of eventList) {
						const teamHome = event.competitors.find((e) => e.id === event.home_id);
						const teamAway = event.competitors.find((e) => e.id === event.away_id);

						event.teamHome = teamHome || event.competitors.find((e) => e.id !== event.away_id);
						event.teamAway = teamAway || event.competitors.find((e) => e.id !== event.home_id);

						// gameStatus
						const result = {
							score: {
								home: 0,
								away: 0,
							},
						};

						for (const period of event.score.period_scores) {
							if (
								(period.type === periodType.OVERTIME || period.type === periodType.PENALTIES) &&
								!result?.regular
							) {
								result.regular = {
									home: result.score.home,
									away: result.score.away,
								};
							}

							switch (period.type) {
								case periodType.REGULAR_PERIOD:
									result.score.home += +period.home_score;
									result.score.away += +period.away_score;
									break;

								case periodType.OVERTIME:
									result.score.home += +period.home_score;
									result.score.away += +period.away_score;
									break;

								case periodType.PENALTIES:
									result.score.home += +period.home_score > period.away_score;
									result.score.away += +period.away_score > period.home_score;
									break;

								default:
									break;
							}
						}

						// status
						if (event.teamHome) {
							const mainTeamIsHome = event.teamHome.id === key;
							event.status = {
								score: "",
							};

							switch (true) {
								case result.score.home > result.score.away:
									event.status.score = mainTeamIsHome
										? gameStatusList.VICTORY
										: gameStatusList.DEFEAT;
									break;

								case result.score.home < result.score.away:
									event.status.score = mainTeamIsHome
										? gameStatusList.DEFEAT
										: gameStatusList.VICTORY;
									break;

								default:
									event.status.score = gameStatusList.DRAW;
									break;
							}

							if (result?.regular) event.status.regular = gameStatusList.DRAW;
						}
					}
					h2h.value.push(competitor);
				}
			}
			const jointGames = {
				key: "jointGames",
				info: {
					name: "Очные встречи",
				},
				list: result[keys[0]].filter((eventEl) => {
					const gamesList = eventEl.competitors.filter((gameEl) => {
						return gameEl.id === keys[0] || gameEl.id === keys[1];
					});

					return gamesList.length === 2;
				}),
			};

			if (jointGames.list.length) {
				jointGames.list.sort((a, b) => b.date - a.date);
				h2h.value.push(jointGames);
			}
			isReady.value = true;
		}
	};

	return {
		isReady,
		nav,
		h2h,
		createH2h,
	};
});
