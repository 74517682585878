import { defineStore } from "pinia";
import { ref } from "vue";

export const useRanking = defineStore("Ranking", () => {
	const isReady = ref<boolean>(false),
	ranking = ref<any[] | null>(null);
	const reset = () => {
		ranking.value = [];
	};

	reset();

	const createRanking = (result: any): void => {
		try {
			isReady.value = true;
			ranking.value = result;
		} catch (error) {
			isReady.value = false;
		}
	};

	const NAV_ITEM = [
		{ route: "", name: "ATP", slug: "atp" },
		{ route: "", name: "WTA", slug: "wta" },
		{ route: "", name: "APT Гонка", slug: "atp-race" },
		{ route: "", name: "WTA Гонка", slug: "wta-race" },
		{ route: "", name: "ATP-Пары", slug: "atp-doubles" },
		{ route: "", name: "WTA-Пары", slug: "wta-doubles" },
		{ route: "", name: "Гонка APT-Пары", slug: "atp-doubles-race" },
		{ route: "", name: "Гонка WTA-Пары",slug: "wta-doubles-race" },
	];

	return {
		isReady,
		createRanking,
		ranking,
		NAV_ITEM,
	};
});
