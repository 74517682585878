import { createI18n } from 'vue-i18n';
import messages from './locales/locale';
import { LANGUAGE } from "@app/consts.ts";
import { getCookie } from "@app/helpers.js";

const locale = getCookie('locale') || '?locale=ru';

const i18n = createI18n({
  locale: LANGUAGE[locale].codeLang,
  fallbackLocale: LANGUAGE[locale].codeLang,
  messages,
});

export default i18n;