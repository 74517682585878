import { defineStore } from "pinia";
import type { Ref } from "vue";
import { inject, ref } from "vue";

import { FAVORITE_TYPE, SPORT_TYPE_NAME } from "@app/consts.ts";
import { localStorageFavoriteKey } from "@app/helpers.ts";

interface FavoriteItem {
	country?: string | null;
	id: string | number;
	name: string;
	slug: string;
}
interface StartLeague {
	tournaments: FavoriteItem[];
}
interface StartTeams {
	teams: FavoriteItem[];
}

export const useFavoriteStore = defineStore("favorite", () => {
	const sportTypePage = inject<string>("sportType", SPORT_TYPE_NAME.FOOTBALL),
		startLeague = inject<StartLeague | null>("startLeague", null),
		startTeams = inject<StartTeams | null>("startTeams", null),
		localStorageLeague = window.localStorage.getItem(localStorageFavoriteKey(sportTypePage, FAVORITE_TYPE.LEAGUE)),
		localStorageTeams = window.localStorage.getItem(localStorageFavoriteKey(sportTypePage, FAVORITE_TYPE.TEAM));

	const setLocalStorageData = (
		storageData: string | null,
		startData?: FavoriteItem[] | null
	): FavoriteItem[] | [] => {
		if (storageData) {
			return JSON.parse(storageData);
		} else if (startData) {
			return startData;
		}
		return [];
	};

	const league: Ref<FavoriteItem[]> = ref(setLocalStorageData(localStorageLeague, startLeague?.tournaments)),
		teams: Ref<FavoriteItem[]> = ref(setLocalStorageData(localStorageTeams, startTeams?.teams));

	const getUserFavorite = async (sportType: string) => {
			try {
				const response = await fetch(`/account/favorites/${sportType}`, {
					method: "GET",
				});
				const favorites = await response.json();

				if (favorites?.favorites?.tournaments) {
					if (sportType === sportTypePage) league.value = favorites.favorites.tournaments;
					window.localStorage.setItem(
						localStorageFavoriteKey(sportType, FAVORITE_TYPE.LEAGUE),
						JSON.stringify(favorites.favorites.tournaments)
					);
				}

				if (favorites?.favorites?.teams) {
					if (sportType === sportTypePage) teams.value = favorites.favorites.teams;
					window.localStorage.setItem(
						localStorageFavoriteKey(sportType, FAVORITE_TYPE.TEAM),
						JSON.stringify(favorites.favorites.teams)
					);
				}
			} catch (error) {
				console.error("getUserFavorite", error);
			}
		},
		getAllUserFavorite = () => {
			for (const key in SPORT_TYPE_NAME) {
				if (Object.hasOwnProperty.call(SPORT_TYPE_NAME, key)) {
					getUserFavorite(SPORT_TYPE_NAME[key]);
				}
			}
		};

	return { league, teams, getAllUserFavorite };
});
