<script setup>
import { computed, inject } from "vue";

const sportType = inject("sportType", null);

const props = defineProps({
	teamHome: Object,
	teamAway: Object,
	score: Object,
	ball: Object,
	loss: Boolean,
	showWine: {
		default: true,
		type: Boolean,
	},
});

const formatScore = (score) => (score || score === 0 || score === "0" ? score : "-");
const formatProgressScore = (score) => (score > 0 ? `+${score}` : "");
const scores = computed(() => {
	const allScores = {
		ball: props.ball,
		loss: props.loss,
		main: {
			home: props.teamHome?.score,
			away: props.teamAway?.score,
		},
	};
	return allScores;
});
</script>

<template>
	<div class="score-wrap">
		<div class="main" v-if="formatScore(scores.main.home) != '-' || formatScore(scores.main.awey) != '-'">
			<span class="score-progress">
				<div v-if="scores.loss && scores.ball === 'home'" class="score-left">проигранная подача</div>
			</span>
			<span class="score-progress">
				<a v-if="scores.ball === 'home'" class="logo-img">
					<svg width="180" height="180">
						<use :xlink:href="'#40-' + sportType" />
					</svg>
				</a>
			</span>
			<span
				v-if="sportType !== 'tennis'"
				:title="teamHome?.name"
				:class="{ _isfirst: scores.main.home > scores.main.away }"
				class="score-progress"
			>
				{{ formatProgressScore(scores.main.home - scores.main.away) }}
			</span>
			<span
				:title="teamHome?.name"
				:class="{ _active: showWine && scores.main.home > scores.main.away }"
				class="score"
			>
				{{ formatScore(scores.main.home) }}
			</span>
			<span
				:title="teamAway?.name"
				:class="{ _active: showWine && scores.main.away > scores.main.home }"
				class="score"
			>
				{{ formatScore(scores.main.away) }}
			</span>
			<span
				v-if="sportType !== 'tennis'"
				:title="teamAway?.name"
				:class="{ _isfirst: scores.main.away > scores.main.home }"
				class="score-progress"
			>
				{{ formatProgressScore(scores.main.away - scores.main.home) }}
			</span>
			<span class="score-progress">
				<a v-if="scores.ball === 'away'" class="logo-img">
					<svg width="20" height="20">
						<use :xlink:href="'#40-' + sportType" />
					</svg>
				</a>
			</span>
			<span class="score-progress">
				<div v-if="scores.loss && scores.ball === 'away'" class="score-right">проигранная подача</div>
			</span>
		</div>
	</div>
</template>

<style scoped lang="scss">
.score-left {
	margin-left: -150px;
	white-space: nowrap;
	border-radius: 4px;
	background: var(--color-main-back);
	color: var(--color-dop-cta);
	padding: 0 5px;
}
.score-right {
	margin-right: -150px;
	white-space: nowrap;
	border-radius: 4px;
	background: var(--color-main-back);
	color: var(--color-dop-cta);
	padding: 0 5px;
}
.logo-img {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 6px;
	background: var(--color-dop-back);

	> svg {
		stroke: var(--color-main-text);
		stroke-width: 1.5;
	}
}
.main {
	display: flex;
}
.score-wrap {
	padding: 3px 4px;
	display: flex;
	justify-content: space-evenly;
	gap: 6px;
}

.score {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 2px;
	min-height: 16px;
	border-radius: 4px;
	background: var(--color-main-back);
	font-size: 12px;
	line-height: 100%;
	color: var(--color-main-text);
	text-align: center;
	&._active {
		color: hsl(220, 4%, 14%);
		background: var(--color-dop-cta);
	}
}

._isfirst {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 2px;
	min-height: 16px;
	border-radius: 4px;
	border: 1px solid var(--color-main-back);
	font-size: 12px;
	line-height: 100%;
	color: var(--color-dop-cta);
}

.score,
.score-progress {
	width: 23px;
	margin: 0 3px 0 3px;
}
@media screen and (max-width: 620px) {
	.score-left {
		margin-left: -83px;
		font-size: 12px;
	}
	.score-right {
		margin-right: -83px;
		font-size: 12px;
	}
}
</style>
