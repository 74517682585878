<script setup>
import { useDetailsH2H } from "@app/modules/details/details-h2h/DetailsH2HStore.js";
import usei18n from "@app/i18n/i18n";

import { getApi } from "@app/helpers.js";

import TabBtn from "@app/ui/tab-btn/TabBtn.vue";
import DetailsTableWrap from "@app/ui/details-table-wrap/DetailsTableWrap.vue";
import DetailsH2HTable from "./components/DetailsH2HTable.vue";
import DetailsH2HTab from "./components/DetailsH2HTab.vue";
import DetailsH2HSkeleton from "./components/DetailsH2HSkeleton.vue";

const props = defineProps({
	api: String,
});

const { t } = usei18n.global;

// store
const storeDetailsH2H = useDetailsH2H();

const titleName = (key) => (key !== "jointGames" ? t("details_h2h.last_game") : "");

if (!storeDetailsH2H.isReady) {
	getApi(props.api).then((result) => {
		storeDetailsH2H.createH2h(result?.h2h);
		console.log(storeDetailsH2H.h2h);
	});
}
</script>

<template>
	<div v-if="storeDetailsH2H.isReady" class="table-list-wrap">
		<div class="tabs-wrap _secondary">
			<ul class="tabs">
				<li class="tab__item">
					<TabBtn v-model="storeDetailsH2H.nav" :tab-value="'all'" :tab-name="'nav_GameDetailsH2H'">
						{{ $t("details_game.tabs.compositions") }}
					</TabBtn>
				</li>
				<li v-for="(item, key) in storeDetailsH2H.h2h" :key="'nav_GameDetailsH2H' + key" class="tab__item">
					<TabBtn v-model="storeDetailsH2H.nav" :tab-value="item.key" :tab-name="'nav_GameDetailsH2H'">
						{{ item.info.name }}
					</TabBtn>
				</li>
			</ul>
		</div>

		<div v-if="storeDetailsH2H.nav === 'all'" class="h2h-table">
			<DetailsTableWrap
				v-for="(item, index) in storeDetailsH2H.h2h"
				:key="'h2h_' + index"
				:title="titleName(item.key) + item.info.name"
			>
				<DetailsH2HTable :competitor="item" :show-items="10" />
				<button v-if="!(item.list.length <= 10)" @click="storeDetailsH2H.nav = item.key" class="show-more">
					{{ $t("details_h2h.show_more") }}
				</button>
			</DetailsTableWrap>
		</div>

		<DetailsH2HTab v-else />
	</div>

	<DetailsH2HSkeleton v-else />
</template>

<style scoped lang="scss">
.h2h-table {
	display: flex;
	flex-direction: column;
	gap: 18px;
}
</style>
