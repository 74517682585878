import { ref } from "vue";
import { defineStore } from "pinia";

import { favoriteType, sportTypeName } from "@app/consts.js";
import { localStorageFavoriteKey } from "@app/helpers.js";

export const useUserMain = defineStore("UserMain", () => {
	const userName = ref(null),
		favorite = ref({}),
		favoriteNav = ref(null);

	// favorite
	const cheatedFavorite = () => {
		for (const key in sportTypeName) {
			if (Object.hasOwnProperty.call(sportTypeName, key)) {
				const sportType = sportTypeName[key];
				let league = window.localStorage.getItem(localStorageFavoriteKey(sportType, favoriteType.LEAGUE)),
					team = window.localStorage.getItem(localStorageFavoriteKey(sportType, favoriteType.TEAM));

				league = league ? JSON.parse(league) : [];
				team = team ? JSON.parse(team) : [];

				if (!favorite.value?.[sportType] && (league.length || team.length)) {
					favorite.value[sportType] = {};

					if (!favoriteNav.value) favoriteNav.value = sportType;
				}

				if (league.length) favorite.value[sportType][favoriteType.LEAGUE] = league;

				if (team.length) favorite.value[sportType][favoriteType.TEAM] = team;
			}
		}
	};

	return { userName, favorite, cheatedFavorite, favoriteNav };
});
