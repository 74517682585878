import { defineStore } from "pinia";
import { ref } from "vue";

export const useState = defineStore("State", () => {
	const currentLang = ref("ru"),
		presentActive = ref(false),
		langActive = ref(false),
		burgerActive = ref(false);

	return {
		currentLang,
		presentActive,
		langActive,
		burgerActive,
	};
});
