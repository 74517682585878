<script setup lang="ts">
import { computed } from "vue";
import { GAME_STATUS_LIST } from "@app/consts.ts";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = defineProps<{
	regular?: string;
	score?: string | null;
}>();

const GAME_STATUS_LETTER = {
	VICTORY: "В",
	DEFEAT: "П",
	DRAW: "Н",
};

const status = computed(() => {
	const templateStatus: {
		letter: string;
		title: string;
		class?: string;
	} = {
		letter: "?",
		title: "Неизвестно",
	};

	if (props.regular) {
		switch (props.score) {
			case GAME_STATUS_LIST.VICTORY:
				templateStatus.letter = GAME_STATUS_LETTER.VICTORY;
				templateStatus.title = t('GAME_STATUS_TITLE.DRAW') + "/" + t('GAME_STATUS_TITLE.VICTORY');
				templateStatus.class = "_over-victory";
				break;

			case GAME_STATUS_LIST.DEFEAT:
				templateStatus.letter = GAME_STATUS_LETTER.DEFEAT;
				templateStatus.title = t('GAME_STATUS_TITLE.DRAW') + "/" + t('GAME_STATUS_TITLE.DEFEAT');
				templateStatus.class = "_over-defeat";
				break;

			case GAME_STATUS_LIST.DRAW:
				templateStatus.letter = GAME_STATUS_LETTER.DRAW;
				templateStatus.title = t('GAME_STATUS_TITLE.DRAW');
				templateStatus.class = "_draw";
				break;

			default:
				break;
		}
	} else {
		switch (props.score) {
			case GAME_STATUS_LIST.VICTORY:
				templateStatus.letter = GAME_STATUS_LETTER.VICTORY;
				templateStatus.title = t('GAME_STATUS_TITLE.VICTORY');
				templateStatus.class = "_victory";
				break;

			case GAME_STATUS_LIST.DEFEAT:
				templateStatus.letter = GAME_STATUS_LETTER.DEFEAT;
				templateStatus.title = t('GAME_STATUS_TITLE.DEFEAT');
				templateStatus.class = "_defeat";
				break;

			case GAME_STATUS_LIST.DRAW:
				templateStatus.letter = GAME_STATUS_LETTER.DRAW;
				templateStatus.title = t('GAME_STATUS_TITLE.DRAW');
				templateStatus.class = "_draw";
				break;

			default:
				break;
		}
	}
	return templateStatus;
});
</script>

<template>
	<div :class="status.class" :title="status.title" class="status">
		{{ status.letter }}
	</div>
</template>

<style scoped lang="scss">
.status {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
	width: 20px;
	height: 20px;
	background: transparent;
	border-radius: 4px;
	font-size: 14px;
	line-height: 100%;
	text-align: center;
	color: var(--color-main-text);
	background: var(--color-grey);
	cursor: default;

	&._victory {
		color: hsl(0, 0%, 13%);
		background: var(--color-dop-cta);
	}

	&._defeat {
		color: hsl(0, 0%, 0%);
		background: var(--color-error);
	}

	&._draw {
		color: hsl(0, 0%, 0%);
		background: var(--color-allert);
	}

	&._over-victory {
		color: hsl(0, 0%, 13%);
		background: linear-gradient(
			135deg,
			var(--color-dop-cta) 0%,
			var(--color-dop-cta) 80%,
			var(--color-allert) 80%,
			var(--color-allert) 100%
		);
	}

	&._over-defeat {
		color: hsl(0, 0%, 0%);
		background: linear-gradient(
			135deg,
			var(--color-error) 0%,
			var(--color-error) 80%,
			var(--color-allert) 80%,
			var(--color-allert) 100%
		);
	}
}
</style>
