<script setup>
import { inject } from "vue";

import Team from "@app/modules/team/Team.vue";

const api = inject("api", ""),
	sportType = inject("sportType", "football"),
	teamInfo = inject("teamInfo", false);
</script>

<template>
	<Team
		:value="{
			eventUrl: api,
			sport: sportType,
			teamInfo: teamInfo,
		}"
	/>
</template>

<style lang="scss">
#team-details {
	width: 100%;
}
</style>
