<script setup lang="ts">
import { TITLE_LIST, ROUTE_NAME_TEAM, RESULTS_STATUS } from "../teamConsts";
import { useTeam } from "../TeamStore";
import TeamResults from "./TeamResults.vue";
import TeamTransfers from "./TeamTransfers.vue";
import TeamReviewSkeleton from "./TeamReviewSkeleton.vue";

// store
const storeTeam = useTeam();
</script>

<template>
	<div v-show="storeTeam.results.isReady" class="team-review">
		<TeamResults
			v-if="storeTeam.navList?.[ROUTE_NAME_TEAM.RESULTS]?.api"
			:title="TITLE_LIST.RESULTS"
			:nav-to="ROUTE_NAME_TEAM.RESULTS"
			:api="storeTeam.navList[ROUTE_NAME_TEAM.RESULTS]?.api!"
			:show-items="5"
			:status-game="RESULTS_STATUS.CLOSED"
		/>

		<TeamResults
			v-if="storeTeam.navList[ROUTE_NAME_TEAM.CALENDAR]?.api"
			:title="TITLE_LIST.CALENDAR"
			:nav-to="ROUTE_NAME_TEAM.CALENDAR"
			:api="storeTeam.navList[ROUTE_NAME_TEAM.CALENDAR].api!"
			:show-items="5"
			:status-game="RESULTS_STATUS.NOT_STARTED"
		/>

		<TeamTransfers
			v-if="storeTeam.navList?.[ROUTE_NAME_TEAM.TRANSFERS]?.api"
			:title="TITLE_LIST.TRANSFERS"
			:nav-to="ROUTE_NAME_TEAM.TRANSFERS"
			:api="storeTeam.navList[ROUTE_NAME_TEAM.TRANSFERS].api!"
			:show-items="5"
		/>
	</div>

	<TeamReviewSkeleton v-if="!storeTeam.results.isReady" />
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.team-review {
	display: flex;
	flex-direction: column;
	gap: 12px;
}
</style>
