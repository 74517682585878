<script setup>
import { computed, ref, inject } from "vue";
import { getIsOpenItemLeague } from "@app/helpers.js";
import { IS_OPEN_ITEM_LEAGUE } from "@app/consts.js";

import CountryLeague from "@app/ui/country-league/CountryLeague.vue";
import SportItemGames from "./SportItemGames.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = defineProps({
	item: Object,
	isCoef: Boolean,
	showDate: {
		type: Boolean,
		default: false,
	},
});

// open
const sportType = inject("sportType", null);

const isOpen = ref(props.item.is_open),
	openText = computed(() => (isOpen.value ? t('sport.close') : t('sport.open')));

const openListGame = () => {
	isOpen.value = !isOpen.value;

	let openStatus = getIsOpenItemLeague(sportType);
	openStatus[props.item.id] = isOpen.value;
	window.localStorage.setItem(sportType + IS_OPEN_ITEM_LEAGUE, JSON.stringify(openStatus));
};
</script>

<template>
	<li class="item">
		<div :class="{ _active: isOpen }" class="head">
			<CountryLeague
				:country-code="item.country"
				:country-name="item.countryName"
				:league-name="item.leagueName"
				:league-id="item.id"
				:country-slug="item.countrySlug"
				:league-slug="item.leagueSlug"
			/>
			<button @click="openListGame()" :class="{ _active: isOpen }" class="open-wrap">
				<span class="open-wrap__text">{{ openText }}</span>
				<span class="open-wrap__number">{{ item.games?.length }}</span>
				<div class="open-wrap__icon">
					<svg width="20" height="20">
						<use xlink:href="#20-arrow" />
					</svg>
				</div>
			</button>
		</div>

		<table v-if="isOpen" class="table">
			<tr v-if="isCoef">
				<th class="coefficient_time"></th>
				<th class="coefficient_team"></th>
				<th class="coefficient_score"></th>
				<th class="coefficient">1</th>
				<th class="coefficient">X</th>
				<th class="coefficient">2</th>
			</tr>

			<SportItemGames
				v-for="itemGame in item.games"
				:key="'ItemGame_' + itemGame.id"
				:item-game="itemGame"
				:is-coef="isCoef"
				:show-date="showDate"
			/>
		</table>
	</li>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.item {
	position: relative;
	width: 100%;
}

.head {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 40px;
	padding-bottom: 1px;
	background: var(--color-border);
	background-size: 100% 1px;
	background-repeat: no-repeat;
	background-position: bottom left;
}

.item:last-child > .head,
.head._active {
	background: none;
}

.open-wrap {
	margin: 0 4px 0 auto;
	display: flex;
	align-items: center;
	gap: 8px;
}

.open-wrap__text {
	font-size: 14px;
	line-height: 100%;
	text-align: right;
	color: var(--color-dop-text);
}

.open-wrap__number {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 4px;
	min-height: 20px;
	min-width: 20px;
	border-radius: 6px;
	background: var(--color-main-back);
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: var(--color-main-text);
	text-align: center;
}

.open-wrap__icon {
	position: relative;
	width: 20px;
	height: 40px;

	> svg {
		@include center();
		width: 24px;
		height: auto;
		stroke: var(--color-dop-cta);
		stroke-width: 1.5;
		stroke-linecap: round;
		stroke-linejoin: round;
	}
}

.open-wrap {
	&._active {
		.open-wrap__icon {
			> svg {
				transform: translate(-50%, -50%) rotate(180deg);
			}
		}
	}
}

.table {
	width: 100%;
	background: var(--color-black);
	border-radius: 6px;
	border: none;
	border-collapse: collapse;
	margin-bottom: 8px;

	td {
		margin: 0;
		padding: 0;
	}
}

.item:last-child {
	> .table {
		margin-bottom: 0;
	}
}

.td__star {
	width: 60px;
}

.table__star {
	display: block;
	width: 40px;
	height: 45px;
	margin: 0 5px;
	position: relative;

	> svg {
		fill: var(--color-grey);
		@include center();
		transition: fill 150ms ease-in-out;
	}

	&._active {
		> svg {
			fill: var(--color-dop-cta);
		}
	}
}

.coefficient {
	padding: 4px 6px 0px;
	font-size: 12px;
	line-height: 100%;
	text-align: center;
	padding-left: 20px;
	color: var(--color-main-text);
}

@media screen and (max-width: 1020px) {
	.head {
		padding: 0 10px;
	}

	.open-wrap__text {
		display: none;
	}
}

@media screen and (max-width: 570px) {
	.head {
		padding-left: 2px;
	}
	.coefficient {
		display: none;
	}

	.td__team {
		width: 100%;
	}

	.table {
		.td__team {
			padding-left: 10px;
		}
	}
}
</style>
