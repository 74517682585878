<script setup>
import { bookieLogoUrl } from "@app/helpers.js";
import { TARGET_BLANK } from "@app/consts.js";

import TableCoefficient from "@app/ui/table/coefficient/TableCoefficient.vue";

import { COEF_TYPE } from "../detailsCoefConsts.js";
import { useDetailsCoef } from "../DetailsCoefStore.js";

import DetailsCoefBanner from "../ui/DetailsCoefBanner.vue";

// store
const storeDetailsCoef = useDetailsCoef();

// linkTu
const linkTu = (url) => {
	window.open(url, TARGET_BLANK);
};
</script>

<template>
	<div class="table-wrap">
		<table
			v-for="(bookie, tableKey) in storeDetailsCoef.coef[COEF_TYPE.DOUBLE_OUTCOME]"
			:key="'details_coef_double_outcome_' + tableKey"
			class="main-table coef-table"
		>
			<tr class="table-head">
				<th class="cof-table-th" colspan="2">{{ tableKey }}</th>
			</tr>
		<!-- ADD IN <tr> -->
		<!-- 
			@click="linkTu(item.url)"
			@keyup.enter="linkTu(item.url)"
			:href="item.url" 
		-->
			<tr
				v-for="(item, bookieKey) in bookie"
				:key="'bookieKey' + bookieKey"
				:class="{ _active: item?.crown }"
				tabindex="0"
				role="link"
			>
				<td class="cof-table__logo">
					<div class="crown">
						<svg width="20" height="20">
							<use xlink:href="#20-crown" />
						</svg>
					</div>
					<DetailsCoefBanner v-if="item.bookie === 'liga'" :bannerName="item.bookie" :bookie-logo-url="bookieLogoUrl(item.bookie)" />
                    <DetailsCoefBanner v-else-if="item.bookie === 'boombet'" :bannerName="item.bookie"  :bookie-logo-url="bookieLogoUrl(item.bookie)" />
				    <img v-else :src="bookieLogoUrl(item.bookie)" height="16" width="70" />
				</td>

				<td class="coef-td">
					<div class="coef-wrap">
						<TableCoefficient :coefficient="item.value" class="coef-number" />
					</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<style scoped lang="scss">
.table-wrap {
	display: flex;
	flex-direction: column;
	gap: 12px;

	th {
		font-size: 14px;
		padding-left: 0;
	}
}
.coef-td {
	width: 100px;
}

.cof-table-th {
	text-align: center;
	color: var(--color-main-text);
}

.coef-wrap {
	display: flex;
	align-items: center;
	justify-content: center;

	> .coef {
		margin: 0;
	}
}

.coef-number {
	min-width: 50px;
}

@media screen and (max-width: 768px) {
	.coef-td {
		width: 80px;
	}
}
</style>
