<script setup>
import { computed, ref, toRaw } from "vue";
import { FETCH_PATH, HEADERS_LIST } from "@app/consts.ts";
import { currentLocale } from "@app/helpers.ts";
import { useSearchStore } from "./SearchStore.js";
import SearchItem from "./components/SearchItem.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;
const locale = currentLocale();
// store
const storeSearchStore = useSearchStore();

const getApi = async (api) => {
	try {
		const response = await fetch(FETCH_PATH() + api + `&locale=${locale.codeLang}`, {
			method: "GET",
			headers: HEADERS_LIST(),
		});
		return response.json()
	} catch (error) {
		console.error("GET error", error);
	}
};
// search
const search = () => {
	if (storeSearchStore.searchValue.length >= 3) {
		getApi("/search?q=" + storeSearchStore.searchValue + "/").then((response) => {
			console.log("🚀 ~ file: Search.vue:15 ~ getApi ~ response:", response);
			storeSearchStore.result = response;
		});
	} else {
		storeSearchStore.result = [];
	}
};

// messages
const messages = computed(() => {
	if (storeSearchStore.searchValue.length >= 3) {
		if (storeSearchStore.result.length <= 0) {
			return t('search.info1');
		}
		return "";
	} else {
		return t('search.info2');
	}
});

// show-more
const showItemsList = ref(14);

const cutList = computed(() => {
	const list = storeSearchStore.result;
	return toRaw(list).slice(0, showItemsList.value);
});

// focus
const vFocus = {
	mounted: (el) => el.focus(),
};
</script>

<template>
	<div class="search">
		<h3 class="search-title">{{ t('search.search_title') }}</h3>

		<input
			v-focus
			v-model.trim="storeSearchStore.searchValue"
			@input="search"
			class="search-input"
			type="search"
			name="search"
			:placeholder="t('search.placeholder')"
		/>

		<div v-if="storeSearchStore.result.length" class="search-result">
			<SearchItem v-for="(item, index) in cutList" :key="'search_' + index" :item="item" />

			<button
				v-if="storeSearchStore.result.length > cutList.length"
				@click="showItemsList += 30"
				class="show-more"
			>
			{{ t('search.show_more') }}
			</button>
		</div>

		<p v-else class="search-message">{{ messages }}</p>

		<div
			v-if="!storeSearchStore.result.length && storeSearchStore.getSearchHistory().length"
			class="search-history"
		>
			<h4 class="search-history-title">{{ t('search.history_title') }}</h4>

			<SearchItem
				v-for="(item, index) in storeSearchStore.getSearchHistory()"
				:key="'search_history_' + index"
				:item="item"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.search {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
}
.search-title {
	margin: 0;
	font-size: 24px;
	line-height: 100%;
	text-align: center;
	margin-bottom: 14px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
	padding-bottom: 10px;
}

.search-message {
	font-size: 16px;
	margin: 10px 0 26px;
	text-align: center;
}

.search-input {
	margin-bottom: 20px;
}

.search-history {
	padding-top: 20px;
	border-top: 1px solid hsla(0, 0%, 100%, 0.1);
}

.search-history-title {
	margin: 0 0 8px;
}

.search-result {
	display: flex;
	flex-direction: column;
}

.show-more {
	align-self: center;
}

@media screen and (max-width: 768px) {
	.search {
		padding: 0;
	}
}
</style>
