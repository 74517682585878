import { createRouter, createWebHistory } from "vue-router";
import DetailsGame from "@app/modules/details/details-game/DetailsGame.vue";
import DetailsH2H from "@app/modules/details/details-h2h/DetailsH2H.vue";
import DetailsCoef from "@app/modules/details/details-coef/DetailsCoef.vue";
import DetailsBombardier from "@app/modules/details/details-bombardier/DetailsBombardier.vue";
import DetailsGrid from "@app/modules/details/details-grid/DetailsGrid.vue";
import DetailsBroadcast from "@app/modules/details/deteils-broadcast/DetailsBroadcast.vue";
import DetailsVideo from "@app/modules/details/details-video/DetailsVideo.vue";
import DetailsStanding from "@app/modules/details/details-standing/DetailsStanding.vue";

import { ROUTE_NAME_GAME } from "./gameConsts";

const createGameRouter = (basePath: string) => {
	return createRouter({
		history: createWebHistory(basePath),
		routes: [
			// { path: "/", redirect: `/${ROUTE_NAME_GAME.MATCH}/` },
			{ path: `/${ROUTE_NAME_GAME.MATCH}/`, name: ROUTE_NAME_GAME.MATCH, component: DetailsGame },
			{ path: `/${ROUTE_NAME_GAME._H2H}/`, name: ROUTE_NAME_GAME._H2H, component: DetailsH2H },
			{ path: `/${ROUTE_NAME_GAME.COEFFICIENT}/`, name: ROUTE_NAME_GAME.COEFFICIENT, component: DetailsCoef },
			{ path: `/${ROUTE_NAME_GAME.BOMBARDIER}/`, name: ROUTE_NAME_GAME.BOMBARDIER, component: DetailsBombardier },
			{ path: `/${ROUTE_NAME_GAME.GRID}/`, name: ROUTE_NAME_GAME.GRID, component: DetailsGrid },
			{ path: `/${ROUTE_NAME_GAME.VIDEO}/`, name: ROUTE_NAME_GAME.VIDEO, component: DetailsVideo },
			{ path: `/${ROUTE_NAME_GAME.STANDING}/`, name: ROUTE_NAME_GAME.STANDING, component: DetailsStanding },
			{ path: `/${ROUTE_NAME_GAME.BROADCAST}/`, name: ROUTE_NAME_GAME.BROADCAST, component: DetailsBroadcast },
		],
	});
};

export default createGameRouter;
