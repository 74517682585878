import {
	IS_OPEN_ITEM_LEAGUE,
	STATIC_PATH,
	FETCH_PATH,
	HEADERS_LIST,
	sportTypeName,
	favoriteType,
	LANGUAGE,
} from "@app/consts.js";

const flagIconUrl = (country) => `${STATIC_PATH}/flags/${country}.svg`,
	picBroudcastUrl = (sportType) => `${STATIC_PATH}/broudcast/${sportType}.png`,
	bookieLogoUrl = (name) => `/bookie/${name}.svg`,
	cofMod = (cof) => {
		for (const key in cof) {
			if (Object.hasOwnProperty.call(cof, key)) {
				const bookie = cof[key];

				for (const key in bookie) {
					if (Object.hasOwnProperty.call(bookie, key)) {
						const number = +bookie[key];

						bookie[key] = {
							old: number,
							new: null,
						};
					}
				}
			}
		}

		return cof;
	},
	getIsOpenItemLeague = (sportType) => {
		let openStatus = window.localStorage.getItem(sportType + IS_OPEN_ITEM_LEAGUE);
		if (openStatus === null) {
			openStatus = {};
		} else {
			openStatus = JSON.parse(openStatus);
		}

		return openStatus;
	},
	formatNameCut = (name) => {
		const splitName = name.split(/,\s*/g);
		if (splitName.length <= 1) {
			return splitName[0];
		}
		return `${splitName[0]} ${splitName[1].match(/^[a-яёA-ЯЁ]/g)[0]}.`;
	},
	formatNameRemoveComma = (name) => {
		const splitName = name.split(/,\s*/g);
		if (splitName.length <= 1) {
			return splitName[0];
		}
		return `${splitName[0]} ${splitName[1]}`;
	},
	getAgeFullYear = (dateOfBirth, dateToCalculate = new Date()) => {
		const dob = new Date(dateOfBirth).getTime();
		const dateToCompare = new Date(dateToCalculate).getTime();
		return Math.floor((dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000));
	},
	linkTitle = (homeName, awayName, leagueName = false) => {
		let title = `${homeName} против ${awayName}`;
		if (leagueName) title += ` (${leagueName})`;

		return title;
	},
	setCookie = (name, value, options = {}) => {
		options = {
			path: "/",
			...options,
		};

		if (options.expires instanceof Date) {
			options.expires = options.expires.toUTCString();
		}

		let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

		for (let optionKey in options) {
			updatedCookie += "; " + optionKey;
			let optionValue = options[optionKey];
			if (optionValue !== true) {
				updatedCookie += "=" + optionValue;
			}
		}

		document.cookie = updatedCookie;
	},
	getCookie = (name) => {
		let matches = document.cookie.match(
			new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
		);
		return matches ? decodeURIComponent(matches[1]) : undefined;
	},
	localStorageFavoriteKey = (sportType, favoriteType) => `${sportType}_favorite_${favoriteType}`,
	removeFavoriteLocalStorage = () => {
		for (const key in sportTypeName) {
			if (Object.hasOwnProperty.call(sportTypeName, key)) {
				window.localStorage.removeItem(localStorageFavoriteKey(sportTypeName[key], favoriteType.LEAGUE));
				window.localStorage.removeItem(localStorageFavoriteKey(sportTypeName[key], favoriteType.TEAM));
			}
		}
	};

// async
const getApi = async (api) => {
		const locale = getCookie("locale") || "?locale=ru";
		try {
			const response = await fetch(FETCH_PATH() + api + locale, {
				method: "GET",
				headers: HEADERS_LIST(),
			});

			return response.json();
		} catch (error) {
			console.error("GET error", error);
		}
	},
	addRemoveUserFavorite = async (type, id, method = "POST") => {
		try {
			// FIXME: type
			type = favoriteType.LEAGUE === type ? "tournament" : "team";

			await fetch(`/account/favorites/${type}/${id}`, {
				method: method,
			});
		} catch (error) {
			console.error("POST error", error);
		}
	};
	const currentLocale = () => {
		const locale = getCookie('locale') || '?locale=ru';
		return LANGUAGE[locale];
	}

export {
	flagIconUrl,
	picBroudcastUrl,
	bookieLogoUrl,
	cofMod,
	getIsOpenItemLeague,
	formatNameCut,
	formatNameRemoveComma,
	getAgeFullYear,
	linkTitle,
	setCookie,
	getCookie,
	getApi,
	localStorageFavoriteKey,
	removeFavoriteLocalStorage,
	addRemoveUserFavorite,
	currentLocale,
};
