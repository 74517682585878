<script setup lang="ts">
import { computed } from "vue";
import { currentLocale } from "@app/helpers.js";

const props = defineProps<{
	date?: string | number | Date | null;
}>();

const LOCALE = currentLocale().locale;

const formatDate = computed<{
	full: string;
	normal: string;
} | null>(() => {
	if (props.date) {
		return {
			full: new Intl.DateTimeFormat(LOCALE, {
				month: "numeric",
				day: "numeric",
				year: "numeric",
			}).format(new Date(props.date)),
			normal: new Intl.DateTimeFormat(LOCALE, {
				month: "numeric",
				day: "numeric",
				year: "2-digit",
			}).format(new Date(props.date)),
		};
	}

	return null;
});
</script>

<template>
	<template v-if="date">
		<span class="full-date">{{ formatDate?.full }}</span>
		<span class="date">{{ formatDate?.normal }}</span>
	</template>
</template>

<style scoped lang="scss">
.date {
	display: none;
}

.full-date,
.date {
	font-size: 12px;
	line-height: 120%;
}

@media screen and (max-width: 620px) {
	.full-date {
		display: none;
	}

	.date {
		display: inline;
		font-size: 12px;
	}
}
</style>
