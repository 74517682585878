<script setup>
import { usePopUp } from "./PopUpStore.js";

// store
const popUpStore = usePopUp();

// close
const close = () => {
	popUpStore.isOpen = false;
};
</script>

<template>
	<div class="wrap">
		<div v-if="popUpStore.isOpen" class="pop-up">
			<button @click="close" class="close">
				<svg width="20" height="20">
					<use xlink:href="#20-close" />
				</svg>
			</button>
			<component :is="popUpStore.component" :value="popUpStore.value"></component>
		</div>
	</div>
	<div v-if="popUpStore.isOpen" @click="close" class="close-wrap"></div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.wrap {
	position: fixed;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1001;
	max-height: calc(100% - 30px);
	overflow-x: hidden;
	overflow-y: auto;
	padding-right: 10px;
}

.pop-up {
	position: relative;
	background: var(--color-main-back);
	border-radius: 16px;
	width: 100vw;
	max-width: 780px;
	padding: 24px 12px 12px;
	margin: 0 auto;
}

.close {
	position: absolute;
	top: 6px;
	right: 6px;
	width: 30px;
	height: 30px;
	z-index: 10;

	> svg {
		@include center();
		width: 30px;
		height: 30px;
		stroke-width: 1.5;
		stroke: var(--color-error);
	}
}

.close-wrap {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: var(--color-dop-back);
	opacity: 0.7;
	z-index: 1000;
}

@media screen and (max-width: 790px) {
	.wrap {
		padding: 0;
	}
}
</style>
