<script setup>
import { bookieLogoUrl } from "@app/helpers.js";
import { TARGET_BLANK } from "@app/consts.js";

import TableCoefficient from "@app/ui/table/coefficient/TableCoefficient.vue";

import { COEF_TYPE } from "../detailsCoefConsts.js";
import { useDetailsCoef } from "../DetailsCoefStore.js";

import DetailsCoefBanner from "../ui/DetailsCoefBanner.vue";

// store
const storeDetailsCoef = useDetailsCoef();

// linkTu
const linkTu = (url) => {
	window.open(url, TARGET_BLANK);
};
</script>

<template>
	<table class="main-table coef-table">
		<tr>
			<th class="coef-th tal">{{ $t('details_coef.bookmaker') }}</th>
			<th class="coef-th">1</th>
			<th class="coef-th">X</th>
			<th class="coef-th">2</th>
		</tr>
		<!-- ADD IN <tr> -->
		<!-- 
			@click="linkTu(item.url)"
			@keyup.enter="linkTu(item.url)"
			:href="item.url" 
		-->
		<tr
			v-for="(item, key) in storeDetailsCoef.coef[COEF_TYPE._1X2]"
			:key="'details_coef1x2_' + key"
			:class="{ _active: item?.crown }"
			tabindex="0"
			role="link"
		>
			<td class="cof-table__logo">
				<div class="crown">
					<svg width="20" height="20">
						<use xlink:href="#20-crown" />
					</svg>
				</div>
				<DetailsCoefBanner v-if="item.bookie === 'liga'" :bannerName="item.bookie" :bookie-logo-url="bookieLogoUrl(item.bookie)" />
                <DetailsCoefBanner v-else-if="item.bookie === 'boombet'" :bannerName="item.bookie"  :bookie-logo-url="bookieLogoUrl(item.bookie)" />
				<img v-else :src="bookieLogoUrl(item.bookie)" height="16" width="70" />
			</td>
			<td class="coef-td">
				<div class="coef-wrap">
					<TableCoefficient :coefficient="item[1]" class="coef-number" />
				</div>
			</td>
			<td class="coef-td">
				<div class="coef-wrap">
					<TableCoefficient :coefficient="item.x" class="coef-number" />
				</div>
			</td>
			<td class="coef-td">
				<div class="coef-wrap">
					<TableCoefficient :coefficient="item[2]" class="coef-number" />
				</div>
			</td>
		</tr>
	</table>
</template>

<style scoped lang="scss">
.coef-wrap {
	display: flex;
	align-items: center;
	justify-content: center;

	> span {
		font-weight: 400;
		font-size: 14px;
		line-height: 100%;
	}

	> .coef {
		margin: 0;
	}
}

.coef-number {
	min-width: 50px;
}

@media screen and (max-width: 768px) {
	.coef-td {
		width: 80px;
	}
	.coef-wrap {
		> span {
			font-size: 14px;
		}
	}
}
</style>
