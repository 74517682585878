import { createRouter, createWebHistory } from "vue-router";
import TeamTransfers from "@app/modules/team/components/TeamTransfers.vue";

import PlayerReview from "./components/PlayerReview.vue";
import PlayerLastGame from "./components/PlayerLastGame.vue";
import PlayerCareer from "./components/PlayerCareer.vue";
import { ROUTE_NAME_PLAYER } from "./playerConsts";

const createPlayerRouter = (basePath: string) => {
	return createRouter({
		history: createWebHistory(basePath),
		routes: [
			// { path: "/", redirect: `/${ROUTE_NAME_PLAYER.REVIEW}/` },
			{ path: `/${ROUTE_NAME_PLAYER.REVIEW}/`, name: ROUTE_NAME_PLAYER.REVIEW, component: PlayerReview },
			{
				path: `/${ROUTE_NAME_PLAYER.RECENT_MATCHES}/`,
				name: ROUTE_NAME_PLAYER.RECENT_MATCHES,
				component: PlayerLastGame,
			},
			{
				path: `/${ROUTE_NAME_PLAYER.CAREER}/`,
				name: ROUTE_NAME_PLAYER.CAREER,
				component: PlayerCareer,
			},
			{
				path: `/${ROUTE_NAME_PLAYER.TRANSFERS}/`,
				name: ROUTE_NAME_PLAYER.TRANSFERS,
				component: TeamTransfers,
			},
		],
	});
};

export default createPlayerRouter;
