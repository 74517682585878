<script setup lang="ts">
import { computed, toRaw, ref } from "vue";
import { GAME_STATUS_LIST } from "@app/consts.ts";
import { getApi, linkTitle } from "@app/helpers.ts";
import TableDate from "@app/ui/table/date/TableDate.vue";
import TableIconName from "@app/ui/table/icon-name/TableIconName.vue";
import TableGameTeams from "@app/ui/table/game-teams/TableGameTeams.vue";
import TableScore from "@app/ui/table/score/TableScore.vue";
import TableGameStatus from "@app/ui/table/game-status/TableGameStatus.vue";
import RouterLinkScroll from "@app/ui/router-link-scroll/RouterLinkScroll.vue";

import type { ResponsePlayerLastGame } from "../playerInterface";
import { usePlayer } from "../PlayerStore";
import PlayerSkeleton from "./PlayerSkeleton.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = withDefaults(
	defineProps<{
		api: string;
		title?: string;
		navTo?: string;
		showItems: number;
	}>(),
	{
		showItems: 20,
	}
);

// store
const storePlayer = usePlayer();

if (!storePlayer.lastGame.isReady && props.api) {
	getApi<ResponsePlayerLastGame>(props.api).then((response) => {
		if (response?.recentMatches) {
			storePlayer.addLastGame(response.recentMatches);
		}
	});
}

// statistic
const statisticList = (statistics: {
	[key: string]: number;
}): {
	[key: string]: {
		value: number;
		title: string;
		icon: string;
	};
} => {
	return {
		goals_scored: {
			value: statistics.goals_scored,
			title: t('STATISTIC_TITLE.GOALS_SCORED'),
			icon: "ball",
		},
		assists: {
			value: statistics.assists,
			title: t('STATISTIC_TITLE.ASSISTS'),
			icon: "assist",
		},
		yellow_cards: {
			value: statistics.yellow_cards,
			title: t('STATISTIC_TITLE.YELLOW_CARDS'),
			icon: "yellow-card",
		},
		red_cards: {
			value: statistics.red_cards,
			title: t('STATISTIC_TITLE.RED_CARDS'),
			icon: "red-card",
		},
	};
};

// status
const status = (item: {
	score_home: number;
	score_away: number;
	home: {
		id: string | number;
	};
	players_competitor?: string | number;
}): string => {
	switch (true) {
		case item.score_home === item.score_away:
			return GAME_STATUS_LIST.DRAW;

		case item?.score_home > item?.score_away:
			return item.home.id === item.players_competitor ? GAME_STATUS_LIST.VICTORY : GAME_STATUS_LIST.DEFEAT;

		case item.score_home < item.score_away:
			return item.home.id === item.players_competitor ? GAME_STATUS_LIST.DEFEAT : GAME_STATUS_LIST.VICTORY;

		default:
			return "";
	}
};

// linkTu
const linkTu = (url: string & Location): void => {
	window.location = url;
};

// show-more
const showItemsList = ref<number>(props.showItems);

const cutList = computed<any[]>(() => {
	const list = storePlayer.lastGame.total;

	return toRaw(list).slice(0, showItemsList.value);
});
</script>

<template>
	<div v-if="storePlayer.lastGame.isReady" class="group">
		<h2 v-if="title" class="group__title">{{ title }}</h2>
		<div class="table-container">
			<table class="main-table">
				<tr class="tr-title">
					<th></th>
					<th></th>
					<th></th>
					<th></th>

					<th class="th-stats">
						<div class="th-stats__wrap">
							<svg width="20" height="20" :title="t('STATISTIC_TITLE.GOALS_SCORED')">
								<use xlink:href="#20-ball" />
							</svg>
							<svg width="20" height="20" :title="t('STATISTIC_TITLE.ASSISTS')">
								<use xlink:href="#20-assist" />
							</svg>
							<svg width="20" height="20" :title="t('STATISTIC_TITLE.YELLOW_CARDS')">
								<use xlink:href="#20-yellow-card" />
							</svg>
							<svg width="20" height="20" :title="t('STATISTIC_TITLE.RED_CARDS')">
								<use xlink:href="#20-red-card" />
							</svg>
						</div>
					</th>

					<th></th>
				</tr>

				<tr
					v-for="(item, index) in cutList"
					:key="'lastGame' + index"
					@click="linkTu(item.route)"
					@keyup.enter="linkTu(item.route)"
					:href="item.route"
					:title="linkTitle(item.home.name, item.away.name, item.league)"
					tabindex="0"
					role="link"
				>
					<td class="td-date">
						<TableDate :date="item.start_time" />
					</td>

					<td class="td-league">
						<TableIconName :icon-flag="item.country_code" :name="item.league" :title="item.league" />
					</td>

					<td class="td-competitors">
						<TableGameTeams :team-home="item.home" :team-away="item.away" :event-url="item.route" />
					</td>

					<td class="td-score">
						<TableScore :team-home="{ score: item.score_home }" :team-away="{ score: item.score_away }" />
					</td>

					<td class="td-stats">
						<div class="td-stats__wrap">
							<div
								class="td-stats-item"
								v-for="(statisticItem, statisticIndex) in statisticList(item.statistics)"
								:key="'statisticIndex' + statisticIndex"
							>
								<svg width="16" height="16" :title="statisticItem.title" class="line__icon">
									<use :xlink:href="`#20-${statisticItem.icon}`" />
								</svg>
								<span :class="{ _active: statisticItem.value >= 1 }">{{
									statisticItem.value ?? "-"
								}}</span>
							</div>
						</div>
					</td>

					<td class="td-status">
						<TableGameStatus :score="status(item)" />
					</td>
				</tr>
			</table>
		</div>
		<RouterLinkScroll
			v-if="navTo && storePlayer.lastGame.total.length > showItemsList"
			:nav-to="navTo"
			class="show-more group__btn"
		>
			{{ $t('player_career.show_more_match') }}
		</RouterLinkScroll>

		<button
			v-else-if="storePlayer.lastGame.total.length > cutList.length"
			@click="showItemsList += 30"
			class="show-more group__btn"
		>
		{{ $t('player_career.show_more') }}
		</button>
	</div>
	<PlayerSkeleton v-else />
</template>

<style scoped lang="scss">
.group {
	display: flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 10px;
	width: 100%;
	background: var(--color-dop-back);
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
}

.group__title {
	font-size: 20px;
	line-height: 120%;
	margin: 4px 0;
	padding-left: 8px;
}

.group__btn {
	margin-top: 6px;
	align-self: center;
}
.table-container {
	overflow-x: auto;
	max-width: 100%;
}
.main-table {
	tr {
		td.td-status {
			width: 20px;
			padding-left: 4px;
			// padding-right: 8px;
		}
	}
}

.line__icon {
	display: none;
}

.td-date {
	max-width: 70px;
}

.td-league {
	max-width: 110px;
}

.main-table {
	.th-stats,
	.td-stats {
		// TODO
		padding-right: 0;
		max-width: 86px;
	}
}

.td-stats__wrap,
.th-stats__wrap {
	display: flex;
	justify-content: space-around;

	svg {
		fill: var(--color-main-text);
		stroke: var(--color-main-text);
	}
}

.td-stats-item {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	width: 30px;

	span {
		font-size: 14px;
		display: flex;
		width: 20px;
		height: 20px;
		align-items: center;
		justify-content: center;
		border-radius: 4px;

		&._active {
			background: var(--color-dop-back);
		}
	}
}

@media screen and (max-width: 620px) {
	.table-wrap {
		.main-table {
			tr {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 5px 0px;
				gap: 5px 15px;

				td {
					display: flex;
					align-items: center;
					padding: 0;
				}
			}
			.tr-title {
				display: none;
			}
			.td-competitors {
				flex: 1;
				white-space: nowrap;
				overflow: hidden;

				.team-wrap {
					overflow: hidden;
					.team {
						padding-right: 0px;
						.name {
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
			.th-stats,
			.td-stats {
				max-width: none;
			}

			.td-stats {
				order: 1;
				width: 100%;
				height: auto;
				padding-left: 12px;
				gap: 15px;
			}

			.td-stats__wrap {
				gap: 15px;
			}

			.td-stats-item {
				width: auto;
				gap: 3px;
				span {
					font-size: 12px;
				}
			}
		}
		.line__icon {
			display: block;
		}
	}
	.main-table {
		.team,
		.icon-name span {
			font-size: 12px;
		}

		.td-stats,
		.tr-title {
			display: none;
		}

		.td-league {
			width: 160px;
		}
	}
}

@media screen and (max-width: 480px) {
	.main-table {
		.td-league {
			width: 70px;
		}
	}
}
</style>
