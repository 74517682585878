<script setup lang="ts">
import { inject, ref, onMounted, computed } from "vue";
import { FETCH_PATH } from "@app/consts.ts";
import type { SportResponse } from "@app/modules/sport/sportInterface";
import { currentLocale } from "@app/helpers.ts";
import usei18n from "@app/i18n/i18n";


const LOCALE = currentLocale().locale

const { t } = usei18n.global;

const props = defineProps({
	sportType: String,
	id: Number,
	teams: Array,
	title: String,
});

const formatedTime = (date: number) => {
	const dateObject = new Date(date);
		return new Intl.DateTimeFormat(LOCALE, {
			hour: "2-digit",
			minute: "2-digit",
			hour12: false,
		}).format(dateObject);
};
const formatedMonth = (date: string) => {
	const dateObject = new Date(date);
	const dayOfMonth = dateObject.getDate();
	const monthName = dateObject.toLocaleDateString(LOCALE, { month: "short" });
	return `${dayOfMonth} ${monthName}`;
};

const gamesSportType = inject("sportType", null);

const gameList = ref([] as any[]);
const gameTeams = new Set<string>();

const fetchGames = async (date: string): Promise<SportResponse[]> => {
	const response = await fetch(`${FETCH_PATH()}/sport/${gamesSportType ?? props.sportType}/`, {
		method: "POST",
		body: JSON.stringify({ date }),
	});

	if (!response.ok) {
		throw new Error(`Failed to fetch data. Status: ${response.status}`);
	}

	return response.json();
};

const processGames = (gamesData: SportResponse[]): void => {
	for (const data of gamesData) {
		if (gameList.value.length >= 6) {
			break;
		}

		const games = data.games;
		if (games && games.length > 0) {
			for (const game of games) {
				if (gameList.value.length >= 6) {
					break;
				}
                const teamHomeId = game.teams[0];
                const teamAwayId = game.teams[1];

                if (teamHomeId !== props.teams?.[0] && teamAwayId !== props.teams?.[1] && !gameTeams.has(teamHomeId) && !gameTeams.has(teamAwayId)) {
                    game.leagueSlug = data.leagueSlug;
                    gameList.value.push(game);
                    gameTeams.add(teamHomeId);
                    gameTeams.add(teamAwayId);
                }
			}
		}
	}
};

const getList = async (data: string): Promise<void> => {
	try {
		const responseData = await fetchGames(data);

		responseData.sort((a) => (a.games.every((game) => game.status === t('game_head.status.over')) ? 0 : -1));

		if (responseData.length > 6) {
			processGames(responseData.slice(0, 6));
		} else {
			processGames(responseData);
		}

		if (nextDay.value < maxDays && gameList.value.length < 6) {
			nextDay.value++;
			getList(currentDate.value.toISOString().split("T")[0]);
		}
	} catch (e) {
		console.error("POST Sport " + e);
	}
};

const nextDay = ref(0);
const maxDays = 7;

const currentDate = computed(() => {
	const date = new Date();
	const d = new Date(date);
	d.setDate(d.getDate() + nextDay.value);
	return d;
});

onMounted(() => {
	getList(currentDate.value.toISOString().split("T")[0]);
});

// linkTu
const linkTu = (url: string | null): void => {
	if (url) window.location.href = url;
};
</script>

<template>
	<div v-if="gameList.length > 0" class="events-container">
		<div class="events-wrap">
			<div v-if="title" class="events-head">
				<h4 class="events-head__name">{{ title }}</h4>
			</div>
			<ul class="events-list">
				<li v-for="(item, key) in gameList" :key="'statistic_' + key" class="item">
					<a :href="item.leagueSlug" class="head">
						<h3 class="name" :title="item.tournamentName">{{ item.tournamentName }}</h3>
					</a>
					<a :href="item.prematchId === id ? undefined : item.event_url" class="info">
						<div class="body">
							<span class="team-name">{{ item.team_home[0].name }}</span>
							<span class="time">{{ formatedTime(item.liveDatetime) }}</span>
						</div>
						<div class="body">
							<span class="team-name">{{ item.team_away[0].name }}</span>
							<span class="date">{{ formatedMonth(item.liveDatetime) }}</span>
						</div>
					</a>
					<div v-if="!gamesSportType" class="events-btn">
						<router-link
							@click="linkTu(item.prematchId === id ? undefined : item.event_url)"
							:to="`/${key}/`"
							class="tab-btn _active"
						>
							{{ t("game_head.events.more_stata") }}
							<svg width="25" height="25" class="pulse-arrow">
								<use xlink:href="#20-arrow" />
							</svg>
						</router-link>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<style scoped lang="scss">
.events-container {
	background: var(--color-dop-back);
	padding: 4px;
	border-radius: 10px;
}

.events-wrap {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.events-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
}

.events-head__name {
	width: 100%;
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	color: hsl(220, 4%, 14%);
	padding: 2px 2px 2px 6px;
	background: var(--color-dop-cta);
	border-radius: 6px;
	margin: 0 0 20px 0;
}

.events-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
}

.events-btn {
	margin: 0 5px;
}

.item {
	padding: 2px;
	border: 1px solid var(--color-grey);
	border-radius: 6px;
	background: var(--color-black);
}

.head {
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid var(--color-grey);
	text-decoration: none;
}

.body {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
}

.name {
	height: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
	font-size: 12px;
	margin: 7px 0;
}
.info {
	text-decoration: none;
}
.time {
	margin-top: 5px;
	font-size: 17px;
}
.date {
	color: var(--color-dop-text);
	font-size: 12px;
}
.team-name {
	max-width: 165px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@media screen and (max-width: 1120px) {
	.name {
		max-width: 255px;
	}
	.team-name {
		max-width: 210px;
	}
	.events-list {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media screen and (max-width: 770px) {
	.name {
		max-width: 255px;
	}
	.events-list {
		grid-template-columns: repeat(1, 1fr);
	}
}
.tab-btn {
	margin: 5px 0;
	display: flex;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
}

.tab-btn svg {
	margin-left: 5px;
	stroke: var(--color-dop-text);
	transform: rotate(270deg);
}

.tab-btn.router-link-active svg {
	stroke: hsl(220, 4%, 14%);
}

.tab-btn:hover .pulse-arrow {
	animation: pulse 1s infinite alternate;
}

@keyframes pulse {
	0% {
		transform: translateX(0) rotate(270deg);
	}
	100% {
		transform: translateX(10px) rotate(270deg);
	}
}
</style>
