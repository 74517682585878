<script setup>
import { ref, inject, watch } from "vue";

import { statisticTypes } from "../../detailsGameConsts.js";
import { sportTypeName } from "@assets/app/consts.js";
import usei18n from '@app/i18n/i18n';

import SortBtn from "@assets/app/ui/table/sort-btn/SortBtn.vue";

const { t } = usei18n.global;

const sportType = inject("sportType", null);

const props = defineProps({
	statisticsPlayers: Array,
});

const tableThNames = {
	[sportTypeName.FOOTBALL]: {
		[statisticTypes.GOALS_SCORED]: {
			name: t('statistics.football.goals_scored.title'),
			catName: t('statistics.football.goals_scored.short')
		},
		[statisticTypes.ASSISTS]: {
			name: t('statistics.football.assists.title'),
			catName: t('statistics.football.assists.short')
		},
		[statisticTypes.OWN_GOALS]: {
			name: t('statistics.football.own_goals.title'),
			catName: t('statistics.football.own_goals.short')
		},
		[statisticTypes.OFFSIDES]: {
			name: t('statistics.football.offsides.title'),
			catName: t('statistics.football.offsides.short')
		},
		[statisticTypes.RED_CARDS]: {
			name: t('statistics.football.red_cards.title'),
			catName: t('statistics.football.red_cards.short')
		},
		[statisticTypes.YELLOW_CARDS]: {
			name: t('statistics.football.yellow_cards.title'),
			catName: t('statistics.football.yellow_cards.short')
		},
		[statisticTypes.YELLOW_RED_CARDS]: {
			name: t('statistics.football.yellow_red_cards.title'),
			catName: t('statistics.football.yellow_red_cards.short')
		},
		[statisticTypes.CORNER_KICKS]: {
			name: t('statistics.football.corner_kicks.title'),
			catName: t('statistics.football.corner_kicks.short')
		},
		[statisticTypes.SHOTS_BLOCKED]: {
			name: t('statistics.football.shots_blocked.title'),
			catName: t('statistics.football.shots_blocked.short')
		},
		[statisticTypes.SHOTS_OFF_TARGET]: {
			name: t('statistics.football.shots_off_target.title'),
			catName: t('statistics.football.shots_off_target.short')
		},
		[statisticTypes.SHOTS_ON_TARGET]: {
			name: t('statistics.football.shots_on_target.title'),
			catName: t('statistics.football.shots_on_target.short')
		}
	},
	[sportTypeName.HOCKEY]: {
		[statisticTypes.GOALS_SCORED]: {
			name: t('statistics.hockey.goals_scored.title'),
			catName: t('statistics.hockey.goals_scored.short')
		},
		[statisticTypes.ASSISTS]: {
			name: t('statistics.hockey.assists.title'),
			catName: t('statistics.hockey.assists.short')
		},
		[statisticTypes.POINTS]: {
			name: t('statistics.hockey.points.title'),
			catName: t('statistics.hockey.points.short')
		},
		[statisticTypes.SHOTS_ON_GOAL]: {
			name: t('statistics.hockey.shots_on_goal.title'),
			catName: t('statistics.hockey.shots_on_goal.short')
		},
		[statisticTypes.PLUS_MINUS]: {
			name: t('statistics.hockey.plus_minus.title'),
			catName: t('statistics.hockey.plus_minus.short')
		},
		[statisticTypes.PENALTY_MINUTES]: {
			name: t('statistics.hockey.penalty_minutes.title'),
			catName: t('statistics.hockey.penalty_minutes.short')
		},
		[statisticTypes.SAVES]: {
			name: t('statistics.hockey.saves.title'),
			catName: t('statistics.hockey.saves.short')
		}
	},
	[sportTypeName.BASKETBALL]: {
		[statisticTypes.ASSISTS]: {
			name: t('statistics.basketball.assists.title'),
			catName: t('statistics.basketball.assists.short')
		},
		[statisticTypes.BLOCKS]: {
			name: t('statistics.basketball.blocks.title'),
			catName: t('statistics.basketball.blocks.short')
		},
		[statisticTypes.DEFENSIVE_REBOUNDS]: {
			name: t('statistics.basketball.defensive_rebounds.title'),
			catName: t('statistics.basketball.defensive_rebounds.short')
		},
		[statisticTypes.FIELD_GOALS_ATTEMPTED]: {
			name: t('statistics.basketball.field_goals_attempted.title'),
			catName: t('statistics.basketball.field_goals_attempted.short')
		},
		[statisticTypes.FIELD_GOALS_MADE]: {
			name: t('statistics.basketball.field_goals_made.title'),
			catName: t('statistics.basketball.field_goals_made.short')
		},
		[statisticTypes.FREE_THROWS_ATTEMPTED]: {
			name: t('statistics.basketball.free_throws_attempted.title'),
			catName: t('statistics.basketball.free_throws_attempted.short')
		},
		[statisticTypes.FREE_THROWS_MADE]: {
			name: t('statistics.basketball.free_throws_made.title'),
			catName: t('statistics.basketball.free_throws_made.short')
		},
		[statisticTypes.MINUTES]: {
			name: t('statistics.basketball.minutes.title'),
			catName: t('statistics.basketball.minutes.short')
		},
		[statisticTypes.OFFENSIVE_REBOUNDS]: {
			name: t('statistics.basketball.offensive_rebounds.title'),
			catName: t('statistics.basketball.offensive_rebounds.short')
		},
		[statisticTypes.PERSONAL_FOULS]: {
			name: t('statistics.basketball.personal_fouls.title'),
			catName: t('statistics.basketball.personal_fouls.short')
		},
		[statisticTypes.POINTS]: {
			name: t('statistics.basketball.points.title'),
			catName: t('statistics.basketball.points.short')
		},
		[statisticTypes.STEALS]: {
			name: t('statistics.basketball.steals.title'),
			catName: t('statistics.basketball.steals.short')
		},
		[statisticTypes.TECHNICAL_FOULS]: {
			name: t('statistics.basketball.technical_fouls.title'),
			catName: t('statistics.basketball.technical_fouls.short')
		},
		[statisticTypes.THREE_POINTERS_ATTEMPTED]: {
			name: t('statistics.basketball.three_pointers_attempted.title'),
			catName: t('statistics.basketball.three_pointers_attempted.short')
		},
		[statisticTypes.THREE_POINTERS_MADE]: {
			name: t('statistics.basketball.three_pointers_made.title'),
			catName: t('statistics.basketball.three_pointers_made.short')
		},
		[statisticTypes.TOTAL_REBOUNDS]: {
			name: t('statistics.basketball.total_rebounds.title'),
			catName: t('statistics.basketball.total_rebounds.short')
		},
		[statisticTypes.TURNOVERS]: {
			name: t('statistics.basketball.turnovers.title'),
			catName: t('statistics.basketball.turnovers.short')
		}
	}
};

const sortTable = ref(props.statisticsPlayers),
	max = ref(true),
	sortKey = ref("");

const tableSorter = (sortOption, switchMax = true) => {
	if (switchMax) {
		max.value = sortKey.value === sortOption ? !max.value : true;
	}
	sortKey.value = sortOption;

	sortTable.value = props.statisticsPlayers.sort((a, b) => {
		const getValue = (player) => {
			if (sortOption === "minutes") {
				const [minutes, seconds] = player.statistics[sortOption].split(":").map(Number);
				return minutes + seconds / 60;
			} else {
				return +player.statistics[sortOption];
			}
		};

		if (max.value) {
			return getValue(b) - getValue(a);
		} else {
			return getValue(a) - getValue(b);
		}
	});
};

watch(
	() => props.statisticsPlayers,
	() => {
		tableSorter(sortKey.value, false);
	}
);
</script>

<template>
	<div class="main-table-scroll-wrap table-container">
		<table class="main-table">
			<tr>
				<th>{{ t('statistics.table.player') }}</th>
				<th>{{ t('statistics.table.team') }}</th>
				<th
					v-for="(statistic, key) in tableThNames[sportType]"
					:key="'DetailsGameStatisticsPlayers-statistic' + key"
				>
					<SortBtn
						@click="tableSorter(key)"
						:is-max="max"
						:is-active="key === sortKey"
						:title="statistic.name"
					>
						{{ statistic.catName }}
					</SortBtn>
				</th>
			</tr>
			<tr v-for="(player, index) in sortTable" :key="'DetailsGameStatisticsPlayers' + index">
				<td class="td-name">
					<a :href="player.route" :title="player.name">{{ player.nameCut ?? player.name }}</a>
				</td>

				<td>{{ player.competitorAbbreviation }}</td>
				<td
					v-for="(statistic, key) in tableThNames[sportType]"
					:key="'DetailsGameStatisticsPlayers-statistic' + key"
					class="td-statistic"
				>
					{{ player.statistics[key] ?? "-" }}
				</td>
			</tr>
		</table>
	</div>
</template>
<style scoped lang="scss">
.table-container {
	overflow-x: auto;
	max-width: 100%;
}
.td-name {
	a {
		white-space: nowrap;
	}
}
.td-statistic {
	text-align: center;
	width: 40px;
}
</style>
