import { createRouter, createWebHistory } from "vue-router";
import DetailsBombardier from "@app/modules/details/details-bombardier/DetailsBombardier.vue";
import DetailsGrid from "@app/modules/details/details-grid/DetailsGrid.vue";
import DetailsStanding from "@app/modules/details/details-standing/DetailsStanding.vue";

import TournamentReview from "./components/TournamentReview.vue";
import TournamentGames from "./components/TournamentGames.vue";
import { ROUTE_NAME_TOURNAMENT } from "./tournamentConsts";

const createTournamentRouter = (basePath: string) => {
	return createRouter({
		history: createWebHistory(basePath),
		routes: [
			// { path: "/", redirect: `/${ROUTE_NAME_TOURNAMENT.REVIEW}/` },
			{
				path: `/${ROUTE_NAME_TOURNAMENT.REVIEW}/`,
				name: ROUTE_NAME_TOURNAMENT.REVIEW,
				component: TournamentReview,
			},
			{
				path: `/${ROUTE_NAME_TOURNAMENT.RESULTS}/`,
				name: ROUTE_NAME_TOURNAMENT.RESULTS,
				component: TournamentGames,
			},
			{
				path: `/${ROUTE_NAME_TOURNAMENT.TIMETABLE}/`,
				name: ROUTE_NAME_TOURNAMENT.TIMETABLE,
				component: TournamentGames,
			},
			{
				path: `/${ROUTE_NAME_TOURNAMENT.GRID}/`,
				name: ROUTE_NAME_TOURNAMENT.GRID,
				component: DetailsGrid,
			},
			{
				path: `/${ROUTE_NAME_TOURNAMENT.BOMBARDIER}/`,
				name: ROUTE_NAME_TOURNAMENT.BOMBARDIER,
				component: DetailsBombardier,
			},
			{
				path: `/${ROUTE_NAME_TOURNAMENT.STANDING}/`,
				name: ROUTE_NAME_TOURNAMENT.STANDING,
				component: DetailsStanding,
			},
		],
	});
};

export default createTournamentRouter;
