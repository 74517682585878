<script setup lang="ts">
import type { ComputedRef } from "vue";
import { computed } from "vue";
import { useGame } from "../../GameStore";
import { currentLocale } from "@app/helpers.ts";

import type { GameTeam, GameScore } from "../../GameInterface";
import GameHeadLogo from "./ui/GameHeadLogo.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const LOCALE = currentLocale().locale;
console.log(LOCALE);

type StoreGameType = {
	period_scores?: {
		home_score: number;
		away_score: number;
	}[];
};
const storeGame = useGame().game as StoreGameType;

const props = defineProps<{
	sport: string;
	status?: string | number;
	startTime?: string | number | null;
	clock?: {
		played: string | null;
		stoppage_time_played: string | null;
	};
	score?: GameScore;
	overtimeScore?: GameScore;
	penaltyScore?: GameScore;
	teamHome?: GameTeam[];
	teamAway?: GameTeam[];
}>();

// score
const currentScore = computed(() => {
	if (storeGame && storeGame.period_scores && storeGame.period_scores.length >= 2 && props.sport === "football") {
		return {
			home: +storeGame.period_scores[0].home_score + storeGame.period_scores[1].home_score,
			away: +storeGame.period_scores[0].away_score + storeGame.period_scores[1].away_score,
		};
	} else if (storeGame && storeGame.period_scores && storeGame.period_scores.length === 1) {
		return {
			home: +storeGame.period_scores[0].home_score,
			away: +storeGame.period_scores[0].away_score,
		};
	} else if (
		storeGame &&
		storeGame.period_scores &&
		storeGame.period_scores.length > 1 &&
		props.sport !== "hockey" &&
		props.sport !== "basketball"
	) {
		let home_score = 0;
		let away_score = 0;
		for (let i = 0; i < storeGame.period_scores.length; i++) {
			const score = storeGame.period_scores[i];
			if (score.home_score > score.away_score) {
				home_score++;
			} else if (score.home_score < score.away_score) {
				away_score++;
			}
		}
		return {
			home: home_score,
			away: away_score,
		};
	} else if (props.score?.team_home && props.score?.team_away) {
		return {
			home: +props.score.team_home,
			away: +props.score.team_away,
		};
	} else if (props?.score?.team_home === 0 && props?.score?.team_away) {
		return {
			home: 0,
			away: +props.score.team_away,
		};
	} else if (props?.score?.team_home && props?.score?.team_away === 0) {
		return {
			home: +props.score.team_home,
			away: 0,
		};
	} else if (props?.score?.team_home === 0 && props?.score?.team_away === 0) {
		return {
			home: 0,
			away: 0,
		};
	}
	return null;
});
const scoreRender = (score?: number | string | null): string | number => {
		return score || score === 0 || score === "0" ? score : "-";
	},
	penaltyScore: ComputedRef<{ home: number; away: number } | null> = computed(() => {
		if (currentScore) {
			const score = {
				home: currentScore.value ? +currentScore.value.home : 0,
				away: currentScore.value ? +currentScore.value.away : 0,
			};
			if (props.overtimeScore?.team_home && props.overtimeScore?.team_away) {
				score.home += props.overtimeScore.team_home;
				score.away += props.overtimeScore.team_away;

				if (props.penaltyScore?.team_home && props.penaltyScore?.team_away) {
					score.home += +(props.penaltyScore.team_home > props.penaltyScore.team_away);
					score.away += +(props.penaltyScore.team_away > props.penaltyScore.team_home);
					return score;
				}
				return score;
			}
		}

		return null;
	});

// const playoffs = computed(() => {
// 	if (endScore.value.away && endScore.value.home) {
// 		return endScore.value.away > endScore.value.home;
// 	}

// 	return score.value.away > score.value.home;
// });

// statues
const timeFormatRound = (time: string): number => {
		return Math.round(+time.split(/:/g).join("."));
	},
	timeFormatCeil = (time: string): number => {
		return Math.ceil(+time.split(/:/g).join("."));
	};

const statuesActive: ComputedRef<boolean> = computed(() => props.status !== "Завершен"),
	statusTime: ComputedRef<string | number | undefined> = computed(() => {
		if (props.clock?.played && props.status !== "Перерыв") {
			if (props.clock?.stoppage_time_played) {
				return timeFormatRound(props.clock.played) + " + " + timeFormatCeil(props.clock.stoppage_time_played);
			}
			return timeFormatRound(props.clock.played);
		} else {
			if (!Number(props.status)) {
				return t(`game_head.status.${[props.status]}`);
			}
			return props.status;
		}
	});

// date time
const gameDate: ComputedRef<string | null> = computed(() => {
		if (props.startTime) {
			const date = new Date(+props.startTime * 1000);
			return new Intl.DateTimeFormat(LOCALE, {
				month: "numeric",
				day: "numeric",
				year: "numeric",
			}).format(date);
		}
		return null;
	}),
	gameTime: ComputedRef<string | null> = computed(() => {
		if (props.startTime) {
			const date = new Date(+props.startTime * 1000);
			return new Intl.DateTimeFormat(LOCALE, {
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			}).format(date);
		}
		return null;
	});
</script>

<template>
	<div class="head">
		<div class="head__logo-wrap">
			<GameHeadLogo
				v-for="(item, index) in teamHome"
				:key="index + 'logoHome'"
				:sport="sport"
				:team="item"
				:playoffs="false"
				:isTwin="teamHome && teamHome.length > 1 ? true : false"
			/>
		</div>

		<div class="head__center">
			<div class="head__date">
				<span v-if="gameDate">{{ gameDate }}</span>
				<span v-if="gameTime">{{ gameTime }}</span>
			</div>

			<div v-if="scoreRender" class="head__score-wrap">
				<span v-if="penaltyScore" class="head__score">{{ penaltyScore.home }} : {{ penaltyScore.away }}</span>
				<span v-else class="head__score"
					>( {{ currentScore ? scoreRender(currentScore.home) : "-" }} :
					{{ currentScore ? scoreRender(currentScore.away) : "-" }} )</span
				>

				<div v-if="penaltyScore" class="head__penalty">
					<span class="head__penalty__score">
						( {{ currentScore ? scoreRender(currentScore.home) : "-" }} :
						{{ currentScore ? scoreRender(currentScore.away) : "-" }} )
					</span>
					<span>После серии пенальти</span>
				</div>
			</div>

			<span v-if="status" :class="{ _active: statuesActive }" class="head__status">
				{{ statusTime }}
				<span class="status-spin" v-if="statuesActive && props.sport !== 'tennis'">’</span>
			</span>
		</div>
		<div class="head__logo-wrap">
			<GameHeadLogo
				v-for="(item, index) in teamAway"
				:key="index + 'logoAway'"
				:sport="sport"
				:team="item"
				:playoffs="false"
				:isTwin="teamAway && teamAway.length > 1 ? true : false"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.head {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-column-gap: 30px;
	align-items: center;
	margin-bottom: 48px;
}

.head__logo-wrap {
	display: flex;
	flex-flow: row;
	gap: 12px;
}

.head__center {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 18px;
	padding-bottom: 40px;
}

.head__date {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	text-align: center;
	color: var(--color-main-text);
}

.head__score-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.head__score {
	font-family: $accent-font;
	font-weight: 500;
	font-size: 32px;
	line-height: 80%;
	text-align: center;
	white-space: nowrap;
	color: var(--color-main-text);
}

.head__penalty {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	text-align: center;
}

.head__penalty__score {
	font-family: $accent-font;
	font-weight: 500;
	font-size: 14px;
}

.head__status {
	font-family: $accent-font;
	font-weight: 500;
	font-size: 16px;
	line-height: 100%;
	text-align: center;
	color: var(--color-main-text);

	&._active {
		display: flex;
		align-items: center;
		padding: 2px 6px;
		background: var(--color-dop-cta);
		border-radius: 4px;
		color: hsl(220, 4%, 14%);
	}
}

@media screen and (max-width: 960px) {
	.head {
		grid-column-gap: 1px;
		width: 100%;
		margin-bottom: 30px;
	}
	.head__center {
		gap: 12px;
		padding-bottom: 0;
	}
	.head__date {
		font-size: 14px;
	}
	.head__score {
		font-size: 32px;
	}
	.head__status {
		font-size: 16px;
	}
}

@media screen and (max-width: 570px) {
	.head {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 20px;
	}
	.head__center {
		grid-column: 1 / -1;
		grid-row-start: 1;
		margin-bottom: 0;
	}
}
</style>
