<script setup>
import { ref, inject } from "vue";

import { removeFavoriteLocalStorage } from "@app/helpers.js";
import { useUserMain } from "@app/modules/user/user-main/UserMainStore.js";
import { usePopUp } from "@app/components/pop-up/PopUpStore.js";
import { useFavoriteStore } from "@app/modules/favorites/FavoriteStore";
import FormInput from "@app/ui/form/input/FormInput.vue";

import { useUserAuthn } from "../UserAuthnStore.js";
import { KEY_NAME, USER_API } from "../userAuthnConsts.js";
import { signUpIn } from "../userAuthnHelpers.js";
import UserAuthnSignUp from "./UserAuthnSignUp.vue";
import UserAuthnPassReset from "./UserAuthnPassReset.vue";
import UserAuthnMessage from "../ui/UserAuthnMessage.vue";
import UserAuthnSpinner from "../ui/UserAuthnSpinner.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const classNameAuthn = inject("classNameAuthn");

// store
const storeUserMain = useUserMain(),
	storeUserAuthn = useUserAuthn(),
	storePopUp = usePopUp(),
	favoriteStore = useFavoriteStore();

// spinner
const spinnerIsActive = ref(false);

// errorMessage
const errorMessage = ref(false);

// signup
const signInFormValue = ref({
	[KEY_NAME.SIGN_IN.EMAIL]: "",
	[KEY_NAME.SIGN_IN.PASSWORD]: "",
});

const signup = () => {
	errorMessage.value = false;
	// spinnerIsActive.value = true;
	signUpIn(USER_API.SIGN_IN, signInFormValue.value)
		.then((response) => {
			console.log("🚀 ~ file: HeaderUserLogIn.vue:32 ~ .then ~ response:", response);
			spinnerIsActive.value = false;

			removeFavoriteLocalStorage();
			favoriteStore.getAllUserFavorite();

			if (response.status === 200) {
				storeUserMain.userName = response.userName;
				storeUserAuthn.components = UserAuthnMessage;
				storeUserAuthn.message.title = false;
				storeUserAuthn.message.text = false;

				setTimeout(() => {
					storePopUp.isOpen = false;
				}, 2000);
			} else {
				return response;
			}
		})
		.then((response) => {
			errorMessage.value = t('user.messages.error');
		});
};
</script>

<template>
	<UserAuthnSpinner v-if="spinnerIsActive" />

	<h3 :class="classNameAuthn.title">{{ t('user.headings.signIn') }}</h3>

	<p v-if="errorMessage" class="message">{{ errorMessage }}</p>

	<form @submit.prevent="signup" :class="classNameAuthn.form">
		<FormInput
			v-model="signInFormValue[KEY_NAME.SIGN_IN.EMAIL]"
			:inputType="'email'"
			:inputPlaceholder="t('user.placeholder.email')"
			:input-required="true"
			:autocomplete="'username'"
			:inputError="''"
		/>
		<FormInput
			v-model="signInFormValue[KEY_NAME.SIGN_IN.PASSWORD]"
			:inputType="'password'"
			:inputPlaceholder="t('user.placeholder.password')"
			:input-required="true"
			:autocomplete="'current-password'"
			:inputError="''"
		/>

		<button :class="classNameAuthn.form_submit" class="btn" type="submit">{{ t('user.buttons.signIn') }}</button>
	</form>

	<p :class="classNameAuthn.nav_text">
		<button @click="storeUserAuthn.components = UserAuthnPassReset" :class="classNameAuthn.nav_btn">
			{{ t('user.texts.forgotPassword') }}
		</button>
	</p>

	<div :class="classNameAuthn.ore">
		<span>{{ t('user.texts.or') }}</span>
	</div>

	<p :class="classNameAuthn.nav_text">
		{{ t('user.texts.noAccount') }}
		<button @click="storeUserAuthn.components = UserAuthnSignUp" :class="classNameAuthn.nav_btn">
			{{ t('user.buttons.signUp') }}
		</button>
	</p>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.message {
	color: var(--color-error);
	margin: -10px 0 0 0;
}
</style>
