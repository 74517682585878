const ROUTE_NAME_TOURNAMENT = {
	REVIEW: "review",
	RESULTS: "results",
	TIMETABLE: "timetable",
	BOMBARDIER: "bombardier",
	GRID: "grid",
	STANDING: "standing",
};

export { ROUTE_NAME_TOURNAMENT };
