<script setup>
import SkeletonNav from "@app/ui/skeleton/SkeletonNav.vue";
import SkeletonWrap from "@app/ui/skeleton/SkeletonWrap.vue";
import SkeletonList from "@assets/app/ui/skeleton/SkeletonList.vue";

const lists = [6, 8, 4, 8, 3];
</script>

<template>
	<SkeletonWrap>
		<SkeletonNav class="nav" />

		<div v-for="(item, index) in lists" :key="'DetailsGameSkeleton_' + index" class="list-wrap">
			<div class="skeleton-line _head"></div>
			<SkeletonList :line="item" />
		</div>
	</SkeletonWrap>
</template>

<style scoped lang="scss">
.nav {
	margin-bottom: 12px;
}

.list-wrap {
	margin-bottom: 27px;
}

.skeleton-line._head {
	width: 100%;
	height: 26px;
	margin-bottom: 6px;
}

.wrap {
	width: 100%;
}
</style>
