<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { computed, watch } from "vue";

import type { TeamInfo } from "./teamInterface";
import { useTeam } from "./TeamStore";
import { ROUTE_NAME_TEAM, RESULTS_STATUS, TITLE_LIST } from "./teamConsts";
import TeamHead from "./components/TeamHead.vue";
import TeamReview from "./components/TeamReview.vue";

const TAB_BTN = "team_tab";
const canonicalLink = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;

const props = defineProps<{
	value: {
		eventUrl: string;
		sport: string;
		teamInfo: TeamInfo;
	};
}>();

console.log(props.value);

// store
const storeTeam = useTeam();
storeTeam.id = props.value.teamInfo.id;

// nav
storeTeam.navList = { ...storeTeam.navList, ...props.value.teamInfo.tabs };
for (const key in storeTeam.navList) {
	if (Object.hasOwnProperty.call(storeTeam.navList, key)) {
		const element = storeTeam.navList[key];

		switch (key) {
			case ROUTE_NAME_TEAM.RESULTS:
				element.statusGame = RESULTS_STATUS.CLOSED;
				element.title = TITLE_LIST.RESULTS;
				break;

			case ROUTE_NAME_TEAM.CALENDAR:
				element.statusGame = RESULTS_STATUS.NOT_STARTED;
				element.title = TITLE_LIST.CALENDAR;
				break;

			case ROUTE_NAME_TEAM.TRANSFERS:
				element.title = TITLE_LIST.TRANSFERS;
				break;
		}
	}
}

// router
const route = useRoute(),
	router = useRouter();

if (route.path === "/") {
	router.addRoute({ path: "/", redirect: `/${ROUTE_NAME_TEAM.REVIEW}/` });
}

watch(
	() => route.fullPath,
	() => {
		if (canonicalLink) {
			canonicalLink.href = window.location.href;
		}
	}
);

const activeNavItem = computed(() => storeTeam.navList[String(route.matched?.[0]?.name)]);

const isActive = (key: string) => {
	if (route.path === "/" && storeTeam.navList[ROUTE_NAME_TEAM.REVIEW] && key === "review") {
		return "_active";
	}
	return "";
};
</script>

<template>
	<div class="team-details">
		<TeamHead :team-info="value.teamInfo" :sport="value.sport" />

		<div class="tabs-wrap">
			<ul class="tabs">
				<li v-for="(item, key) in storeTeam.navList" :key="TAB_BTN + key" class="tab__item">
					<router-link :to="`/${key}/`" class="tab-btn" :class="isActive(String(key))">{{ item.name }}</router-link>
				</li>
			</ul>
		</div>
		<TeamReview v-if="route.path === '/' && storeTeam.navList[ROUTE_NAME_TEAM.REVIEW]" />

		<router-view
			v-else
			:title="activeNavItem?.title"
			:api="activeNavItem?.api"
			:status-game="activeNavItem?.statusGame"
		/>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.tabs-wrap {
	margin-bottom: 12px;
}
</style>
