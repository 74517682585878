<script setup lang="ts">
import { computed, ref, onUpdated } from "vue";

const props = defineProps<{
		modelValue: string | boolean;
		inputType: "checkbox" | "radio";
		inputValue?: string | boolean;
		inputRequired?: boolean;
		inputId?: string;
		inputIdName?: string;
	}>(),
	emit = defineEmits(["update:modelValue"]);

const value = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit("update:modelValue", value);
	},
});

const isActive = ref<boolean | undefined>(false),
	inputEl = ref<HTMLInputElement>();

onUpdated(() => {
	isActive.value = inputEl.value?.checked;
});
</script>

<template>
	<div
		:class="{
			_active: isActive,
			_radio: props.inputType === 'radio',
		}"
		class="check-radio"
	>
		<input
			v-model="value"
			:type="props.inputType"
			:id="props.inputId"
			:name="props.inputIdName"
			:value="props.inputValue"
			:required="props.inputRequired"
			ref="inputEl"
		/>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.check-radio {
	position: relative;
	display: block;
	width: 18px;
	height: 18px;
	border-radius: 3px;
	cursor: pointer;
	flex-shrink: 0;

	transition: left 200ms ease-in-out;
	transition-property: background;

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		background: var(--color-deep-grey);
		border-radius: inherit;
		flex-shrink: 0;
		transition: inherit;
		z-index: 2;
		pointer-events: none;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		background-image: var(--icon-check);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 20px;
		opacity: 0;
		transition: inherit;
		transition-property: opacity;
		z-index: 3;
		pointer-events: none;
	}

	&._radio {
		border-radius: 18px;

		&::after {
			@include center();
			width: 8px;
			height: 8px;
			background: hsl(0, 0%, 20%);
		}
	}

	&._active {
		&::before {
			background: var(--color-main-cta);
		}

		&::after {
			opacity: 1;
		}
	}
}

input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	margin: 0;
	z-index: 1;
}
</style>
