const signUpIn = async (api, body) => {
	try {
		const data = new FormData();

		for (const key in body) {
			if (Object.hasOwnProperty.call(body, key)) {
				data.append(key, body[key]);
			}
		}

		const response = await fetch(api, {
			method: "POST",
			body: data,
		});

		return response.json();
	} catch (error) {
		console.error("POST error", error);
	}
};

export { signUpIn };
