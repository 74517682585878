<script setup lang="ts">
import { ref, inject } from "vue";
import { flagIconUrl } from "@app/helpers.ts";
import { FETCH_PATH, HEADERS_LIST, FAVORITE_TYPE } from "@app/consts.ts";
import Star from "@app/ui/star/Star.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

interface FavoriteCountryItem {
	country: string;
	countryId: number;
	countryName: string;
	countrySlug: string;
	api: string;
	list?: any[];
	isOpen?: boolean;
}

// country
const favoriteCountry = ref(inject<FavoriteCountryItem[]>("favoriteCountry", [])),
	topCountry = ["Россия", "Англия", "Германия", "Испания"];

if (favoriteCountry.value && Array.isArray(favoriteCountry.value)) {
	favoriteCountry.value.sort((a, b) => {
		if (topCountry.includes(b.countryName) || topCountry.includes(a.countryName)) {
			return +topCountry.includes(b.countryName) - +topCountry.includes(a.countryName);
		} else {
			return +(a.countryName > b.countryName) - 1;
		}
	});
}

const favoriteCountryValue = favoriteCountry.value,
	isActiveShowMore = ref(favoriteCountryValue && favoriteCountryValue.length > 10),
	favoriteCountryList = ref(favoriteCountryValue ? favoriteCountryValue.slice(0, 10) : []);

const showMore = () => {
	favoriteCountryList.value = favoriteCountry.value;
	isActiveShowMore.value = false;
};

// open
const open = (api: string, key: number): void => {
	if (!favoriteCountry.value[key].list) {
		fetch(FETCH_PATH() + api, {
			method: "GET",
			headers: HEADERS_LIST(),
		})
			.then((response) => response.json())
			.then((response) => {
				favoriteCountry.value[key].list = response;
				favoriteCountry.value[key].isOpen = true;
			})
			.catch(() => {
				console.error("GET favoriteCountry error");
			});
	} else {
		favoriteCountry.value[key].isOpen = !favoriteCountry.value[key].isOpen;
	}
};
</script>

<template>
	<ul class="country__list">
		<li v-for="(item, key) in favoriteCountryList" :key="'country' + item.countryId" class="country__item">
			<div :class="{ _active: favoriteCountryList[key].isOpen }" class="country__head">
				<a :href="item.countrySlug" :title="item.countryName" class="country__link">
					<img v-if="item.country && item.country !== 'nan'" class="icon-flag" :src="flagIconUrl(item.country)" alt="Флаг " width="27" height="20" />

					<span class="country__name">{{ item.countryName }}</span>
				</a>

				<button @click="open(item.api, key)" class="country__open">
					<svg
						:class="{ _active: favoriteCountryList[key].isOpen }"
						class="country__arrow"
						width="20"
						height="20"
					>
						<use xlink:href="#20-arrow" />
					</svg>
				</button>
			</div>
			<ul v-if="favoriteCountryList[key].isOpen" class="league__list">
				<li
					v-for="itemLeague in favoriteCountryList[key].list"
					:key="'league-' + itemLeague.id"
					class="league__item"
				>
					<a :href="itemLeague.leagueSlug" :title="itemLeague.leagueName" class="league__link">{{
						itemLeague.leagueName
					}}</a>
					<Star
						:type="FAVORITE_TYPE.LEAGUE"
						:country="item.country"
						:favName="itemLeague.leagueName"
						:favId="itemLeague.id"
						:favSlug="itemLeague.leagueSlug"
						class="star"
					></Star>
				</li>
			</ul>
		</li>
		<li v-if="isActiveShowMore">
			<button @click="showMore" class="country__btn">
				<svg width="30" height="30">
					<use xlink:href="#40-all" />
				</svg>
				<span>{{ t("favorites.country") }}</span>
			</button>
		</li>
	</ul>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.country__list {
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.country__item {
	width: 100%;
}
.country__head {
	width: 100%;
	display: flex;
	align-items: center;
	min-height: 24px;
	padding: 2px;
	color: var(--color-main-text);
	border-radius: 6px;
	&._active {
		background: var(--color-dop-back);
	}
}
.country__link {
	display: flex;
	align-items: center;
	text-decoration: none;
	gap: 6px;
	width: calc(100% - 25px);
}

.country__name {
	font-size: 14px;
	line-height: 110%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.country__open {
	margin-left: auto;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.country__arrow {
	width: 25px;
	height: 25px;
	stroke-width: 1.5px;
	stroke: var(--color-dop-cta);
	&._active {
		transform: rotate(180deg);
	}
}

// list
.league__list {
	padding-left: 6px;
	margin-top: 4px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.league__item {
	display: flex;
	align-items: center;
}
.league__link {
	text-decoration: none;
	font-size: 12px;
	line-height: 110%;
	width: calc(100% - 20px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.star {
	opacity: 0;
	height: 20px;
	transition: opacity 200ms ease-in-out;
	margin-left: auto;
}
.league__item {
	&:hover {
		.star {
			opacity: 1;
		}
	}
}

.country__btn {
	display: flex;
	align-items: center;
	gap: 6px;
	width: 100%;
	font-size: 14px;
	line-height: 120%;
	color: var(--color-main-text);
	transition: color 200ms ease-in-out;

	&:hover,
	&:focus {
		color: var(--color-dop-cta);
	}

	svg {
		stroke: var(--color-dop-cta);
		stroke-width: 1.8;
	}
}

@media screen and (max-width: 768px) {
	.star {
		opacity: 1;
	}
}
</style>
