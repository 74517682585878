import { defineStore } from "pinia";
import { ref } from "vue";
import usei18n from "@app/i18n/i18n";

import { gameStatusList } from "@app/consts";

import { TAB_KEYS } from "./detailsStandingConsts.js";

const { t } = usei18n.global;

export const useDetailsStanding = defineStore("DetailsStanding", () => {
	const standing = ref(false),
		coef = ref(false),
		isReady = ref(false),
		nav = ref(false);

	const reset = () => {
		isReady.value = false;
		coef.value = 0.5;
		nav.value = TAB_KEYS.TABLE;
		standing.value = {
			teams: [
				{ key: "", list: [] },
				{ key: "", coef: null, container: {} },
				{ key: "", list: [] },
			],
		};
	};

	reset();

	const addStandingTable = (total) => {
		standing.value.teams[0].key = "table";
		standing.value.teams[0].list = total.standings.map((team, index) => {
			const newTeam = {
				index: index + 1,
				played: team.played,
				win: team.win,
				draw: team.draw,
				loss: team.loss,
				goals_for: team.goals_for,
				points_for: team.points_for,
				goals_against: team.goals_against,
				points_against: team.points_against,
				points: team.points,
				win_percentage: team.win_percentage,
				win_ratio: team.win_ratio,
				competitor: {
					name: team.competitor.name,
					id: team.competitor.id,
					route: team.competitor.route
				},
				matchesNew: [],
			};
			for (const game of team.matches) {
				const score = game?.sportEventStatus;
				const newGame = {
					matchTime: new Date(game?.sportEventStatus?.match_situation?.updated_at),
				};
				if (score && score.period_scores !== undefined) {
					let isPenaltiWin = false;
					const penaltiesRowIndex = score.period_scores.findIndex((row) => row.type === "penalties");
					if (penaltiesRowIndex !== -1) {
						const penaltiesRow = score.period_scores[penaltiesRowIndex];
						isPenaltiWin = penaltiesRow.away_score !== penaltiesRow.home_score;
					}
					if (game.statistics?.totals?.competitors) {
						const nameCommand = game.statistics.totals.competitors.reduce((accum, item) => {
							return `${accum}${accum ? "-" : ""}${item.name}`;
						}, "");
						newGame.title = `<span style="font-size: 12px">${game.sportEventStatus.home_score}:${game.sportEventStatus.away_score} (${nameCommand})</span>`;
					}

					if (score.winner_id === team.competitor.id) {
						newGame.status = {
							value:  t("details_standing.status.w.value"),
							class: gameStatusList.VICTORY,
							scoreChange: isPenaltiWin,
							title: t("details_standing.status.w.title"),
						};
						if (isPenaltiWin) {
							newGame.status.dopClass = "dop_draw";
							newGame.status.title += "/"+t("details_standing.status.n.title");
						}
					} else if (score.match_tie) {
						newGame.status = {
							value: t("details_standing.status.n.value"),
							class: gameStatusList.DRAW,
							scoreChange: isPenaltiWin,
							title: t("details_standing.status.n.title"),
						};
					} else {
						newGame.status = {
							value: t("details_standing.status.p.value"),
							class: gameStatusList.DEFEAT,
							scoreChange: isPenaltiWin,
							title: t("details_standing.status.p.title"),
						};
						if (isPenaltiWin) {
							newGame.status.dopClass = "dop_draw";
							newGame.status.title += "/"+t("details_standing.status.n.title");
						}
					}
					if (!newGame.status.dopClass) {
						newGame.status.dopClass = "";
					}
				}
				newTeam.matchesNew.push(newGame);
			}

			newTeam.matchesNew = newTeam.matchesNew
				.filter((match) => {
					return !!match.status;
				})
				.sort((matchA, matchB) => {
					return matchA.matchTime > matchB.matchTime ? -1 : 1;
				})
				.splice(0, 5);

			return newTeam;
		});
	};

	const addStandingOverUnder = (total) => {
		standing.value.teams[1].key = "overUnder";
		const coefList = [0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5];

		coefList.map((coef) => {
			standing.value.teams[1].container[coef] = total.standings.map((team, index) => {
				const newTeam = {
					index: ++index,
					competitor: {
						id: team.competitor.id,
						name: team.competitor.name,
					},
					played: team.played,
					goals_for: team.goals_for,
					goals_against: team.goals_against,
					overUnderInfo: {
						over: 0,
						under: 0,
						check: false,
						allGoalMatch: 0,
						matchCount: 0,
						percentGoalOnMatch: 0,
					},
					matchesNew: [],
					matchTime: null,
				};

				const fileredMatches = team.matches.filter((match) => match.status !== "not_started");

				fileredMatches.map((match) => {
					const newMatch = {
						overUnderCheck: false,
						title: "",
					};
					newTeam.overUnderInfo.matchCount++;
					newTeam.matchTime = new Date(team?.sportEventStatus?.match_situation?.updated_at);
					const score = Math.round(coef);
					const matchScore =
						(match.sportEventStatus.away_score ?? 0) + (match.sportEventStatus.home_score ?? 0);

					if (matchScore >= score) {
						newTeam.overUnderInfo.over++;
						newMatch.overUnderCheck = true;
					} else if (matchScore < score) {
						newTeam.overUnderInfo.under++;
					}
					if (match?.statistics?.totals?.competitors) {
						const nameCommand = match.statistics.totals.competitors.reduce((accum, item) => {
							return `${accum}${accum ? "-" : ""}${item.name}`;
						}, "");
						const hScore = match?.sportEventStatus?.home_score;
						const aScore = match?.sportEventStatus?.away_score;
						const titleScore =
							(hScore || hScore === 0) && (aScore || aScore === 0) ? `${hScore}:${aScore}` : "";
						newMatch.title = `<span style="font-size: 12px">${titleScore} (${nameCommand})</span>`;
					}
					newTeam.overUnderInfo.allGoalMatch += +matchScore;
					newTeam.matchesNew.push(newMatch);
				});
				newTeam.overUnderInfo.percentGoalOnMatch =
					newTeam.overUnderInfo.allGoalMatch / newTeam.overUnderInfo.matchCount;

				if (!Number.isInteger(newTeam.overUnderInfo.percentGoalOnMatch)) {
					newTeam.overUnderInfo.percentGoalOnMatch = newTeam.overUnderInfo.percentGoalOnMatch.toFixed(1);
				}
				newTeam.matchesNew = newTeam.matchesNew
					.sort((gameA, gameB) => {
						return gameA.matchTime < gameB.matchTime ? 1 : -1;
					})
					.splice(0, 5);

				return newTeam;
			});
		});
	};

	const addStandingHTFT = (total) => {
		standing.value.teams[2].key = "HTFT";
		standing.value.teams[2].list = total.standings.map((team, index) => {
			const newTeam = {
				index: ++index,
				competitor: {
					id: team.competitor.id,
				},
				htFt: {
					ww: 0,
					wd: 0,
					wl: 0,
					dw: 0,
					dd: 0,
					dl: 0,
					lw: 0,
					ld: 0,
					ll: 0,
				},
				matchesNew: [],
			};
			newTeam.matchesNew = team.matches.filter((match) => match.status !== "not_started");
			newTeam.matchesNew = newTeam.matchesNew.map((game) => {
				const competitors = {};
				let reviewId = "";
				let opponentId = "";
				const sportEventStatus = game?.sportEventStatus;

				if (game?.statistics?.totals?.competitors) {
					for (const gameCommand of game?.statistics?.totals?.competitors) {
						competitors[`${gameCommand.qualifier}_score`] = {
							id: gameCommand.id,
							qualifier: gameCommand.qualifier,
							review: gameCommand.id === newTeam.competitor.id,
							name: gameCommand.name,
						};
						if (gameCommand.id === newTeam.competitor.id) {
							reviewId = `${gameCommand.qualifier}_score`;
						}
						competitors[`${gameCommand.qualifier}_score`].win =
							sportEventStatus && sportEventStatus.winner_id === gameCommand.id;
					}

					const nameCommand = game.statistics.totals.competitors.reduce((accum, item) => {
						return `${accum}${accum ? "-" : ""}${item.name}`;
					}, "");
					game.title = `<span style="font-size: 12px">${game.sportEventStatus.home_score}:${game.sportEventStatus.away_score} (${nameCommand})</span>`;
				}
				opponentId = reviewId === "home_score" ? "away_score" : "home_score";

				if (
					Object.values(competitors).length > 0 &&
					sportEventStatus &&
					sportEventStatus?.period_scores?.length > 0
				) {
					let status = "";
					let reviewScore = 0;
					let opponentScore = 0;
					for (const period of sportEventStatus.period_scores) {
						reviewScore += +period[reviewId];
						opponentScore += +period[opponentId];

						if (reviewScore > opponentScore) {
							status += "w";
						} else if (reviewScore === opponentScore) {
							status += "d";
						} else {
							status += "l";
						}
					}
					const statusKey = sportEventStatus.period_scores.length > 1 ? status : `${status}${status}`;
					newTeam.htFt[statusKey]++;
				}
				return game;
			});
			return newTeam;
		});
	};

	return {
		addStandingTable,
		addStandingOverUnder,
		addStandingHTFT,
		standing,
		reset,
		coef,
		nav,
	};
});
