<script setup lang="ts">
import { ref, inject } from "vue";

interface CurrentTournamentsItem {
  country: string;
  id: number;
  name: string;
  slug: string;
  list?: any[];
}

const currentTournaments = ref(inject<CurrentTournamentsItem[]>("currentTournaments", []));

const isActiveShowMore = ref(currentTournaments.value.length > 10);
const currentTournamentsList = ref(currentTournaments?.value.slice(0, 10));

const showMore = () => {
  currentTournamentsList.value = currentTournaments.value;
  isActiveShowMore.value = false;
};
</script>

<template>
	<ul class="list">
		<li v-for="item in currentTournamentsList" :key="'tournament' + item.id" class="item">
			<a :href="item.slug" :title="item.name" class="link">
				<span class="name">{{ item.name }}</span>
			</a>
		</li>
		<li v-if="isActiveShowMore">
			<button @click="showMore" class="tournament-btn">
				<svg width="30" height="30">
					<use xlink:href="#40-all" />
				</svg>
				<span>{{ $t('current_tournaments.all_tournaments') }}</span>
			</button>
		</li>
	</ul>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.list {
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.item {
	width: 100%;
	display: flex;
	align-items: center;
	min-height: 24px;
	padding: 2px;
	color: var(--color-main-text);
	border-radius: 6px;
	&._active {
		background: var(--color-dop-back);
	}
}
.link {
	display: flex;
	align-items: center;
	text-decoration: none;
	gap: 6px;
	width: calc(100% - 25px);
}
.name {
	font-size: 14px;
	line-height: 110%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tournament-btn {
	display: flex;
	align-items: center;
	gap: 6px;
	width: 100%;
	font-size: 14px;
	line-height: 120%;
	color: var(--color-main-text);
	transition: color 200ms ease-in-out;

	&:hover,
	&:focus {
		color: var(--color-dop-cta);
	}

	svg {
		stroke: var(--color-dop-cta);
		stroke-width: 1.8;
	}
}
</style>