import { defineStore } from "pinia";
import { ref } from "vue";

import { COEF_TYPE } from "./detailsCoefConsts.js";

export const useDetailsCoef = defineStore("DetailsCoef", () => {
	const isReady = ref(false),
		nav = ref(COEF_TYPE._1X2),
		coef = ref(),
		maxCoef = ref(),
		maxOdds = ref();

	// reset
	const reset = () => {
		coef.value = {};
		maxCoef.value = {};
		maxOdds.value = {};
	};

	reset();

	const createCoef = (result) => {
		const orders = result.orders,
			coefficients = result.coefficients;

		// sort order
		const addOrders = (item) => {
				if (orders?.[item.bookie]) {
					item.crown = orders[item.bookie].crown;
					item.order = orders[item.bookie].order;
				}
				return item;
			},
			sortOrders = (a, b) => a.order - b.order;

		for (const coefItemKey in coefficients) {
			if (Object.hasOwnProperty.call(coefficients, coefItemKey)) {
				const coefItem = coefficients[coefItemKey];

				switch (coefItemKey) {
					case COEF_TYPE._1X2:
					case COEF_TYPE.EVEN_ODD:
						coefItem.map(addOrders);
						coefItem.sort(sortOrders);
						break;

					case COEF_TYPE.BOTH_TEAMS_WILL_SCORE:
						coefficients[coefItemKey] = Object.values(coefItem);
						coefficients[coefItemKey].map(addOrders);
						coefficients[coefItemKey].sort(sortOrders);
						break;

					case COEF_TYPE.DOUBLE_OUTCOME:
					case COEF_TYPE.HTFT:
					case COEF_TYPE.EXACT_SCORE:
					case COEF_TYPE.HANDICAP:
					case COEF_TYPE.LESS_AND_GROSS:
						for (const coefGroupKey in coefItem) {
							if (Object.hasOwnProperty.call(coefItem, coefGroupKey)) {
								coefItem[coefGroupKey] = Object.values(coefItem[coefGroupKey]);
								coefItem[coefGroupKey].map(addOrders);
								coefItem[coefGroupKey].sort(sortOrders);
							}
						}
						break;
				}
			}
		}

		console.log(result.coefficients);

		coef.value = result.coefficients;

		// handicap
		if (coef.value?.handicap) {
			for (const elKey in coef.value.handicap) {
				if (Object.hasOwnProperty.call(coef.value.handicap, elKey)) {
					const element = coef.value.handicap[elKey];

					for (const bkKey in element) {
						if (Object.hasOwnProperty.call(element, bkKey)) {
							const bookie = element[bkKey];

							for (const key in bookie) {
								if (Object.hasOwnProperty.call(bookie, key) && key !== "bookie" && key !== "url") {
									const coef = bookie[key];

									if (/(\/ 1|\/ [a-zA-Zа-яА-Я]1)/gi.test(key)) {
										bookie["1"] = coef;
									} else if (/(\/ 2|\/ [a-zA-Zа-яА-Я]2)/gi.test(key)) {
										bookie["2"] = coef;
									}
								}
							}
						}
					}
				}
			}
		}
		if (coef.value?.[COEF_TYPE._1X2]) {
			const coef1x2 = coef.value?.[COEF_TYPE._1X2];
		  
			const getCoef = (category, data) => {
			  if (!data || !data.length) {
				return [];
			  }
		  
			  const maxNewCoefficient = data.reduce((max, item) => {
				const newCoefficient = item[category]?.new || 0;
				return newCoefficient > max ? newCoefficient : max;
			  }, 0);
		  
			  const obj = data.filter((item) => item[category]?.new === maxNewCoefficient);
			  return obj;
			};
		  
			const coef1 = getCoef("1", coef1x2);
			const coefX = getCoef("x", coef1x2);
			const coef2 = getCoef("2", coef1x2);

			const coefs = [coef1[0], coefX[0], coef2[0]];
		  
			maxCoef.value = coefs;
		  }
		  

		// evenOdd
		if (coef.value?.evenOdd) {
			coef.value.evenOdd = coef.value.evenOdd.filter((item) => {
				return item?.url && (item?.even || item?.odd);
			});
		}

		// nav
		if (!coef.value?.[COEF_TYPE._1X2]) {
			nav.value = Object.keys(coef.value)[0];
		}

		maxOdds.value = result.maxodds;
		isReady.value = true;
	};

	return {
		isReady,
		nav,
		coef,
		maxCoef,
		maxOdds,
		createCoef,
	};
});
