<script setup>
import { computed, inject, onUnmounted } from "vue";

import { getApi } from "@app/helpers.js";
import { sportTypeName, GET_INTERVAL_DURATION } from "@app/consts.js";
import { useGame } from "@app/modules/game/GameStore";
import TabBtn from "@app/ui/tab-btn/TabBtn.vue";

import { useDetailsGame } from "./DetailsGameStore.js";
import { tabsName } from "./detailsGameConsts.js";
import { formatPlayerName, addPlayersEvent } from "./detailsGameHelpers.js";
import DetailsGameCompositions from "./components/DetailsGameCompositions.vue";
import DetailsGameStatistics from "./components/DetailsGameStatistics.vue";
import DetailsGameLineups from "./components/DetailsGameLineups.vue";
import DetailsGameText from "./components/DetailsGameText.vue";
import DetailsGameSkeleton from "./components/DetailsGameSkeleton.vue";
import DetailsGameStatisticsPlayersTab from "./components/details-game-statistics-players/DetailsGameStatisticsPlayersTab.vue";
import DetailsGameProgress from "./components/DetailsGameProgress.vue";

const sportType = inject("sportType", null);

const props = defineProps({
	api: String,
});
const api = inject("api", "");
// store
const storeDetailsGame = useDetailsGame(),
	storeGame = useGame();

storeDetailsGame.sportType = sportType;

// const
const TAB_NAME = "details-game-nav";

// getApi
const getDetailsGame = () => {
	if (props.api) {
		getApi(props.api).then((result) => {
			const competitors = result.match_data.lineup?.competitors;
			const timeline = result.match_data?.timeline;
			const statisticsCompetitors = result.match_data.statistics?.totals?.competitors;
			if (Array.isArray(competitors)) {
				formatPlayerName(competitors, timeline, statisticsCompetitors);
			}
			addPlayersEvent(result.match_data.lineup.competitors, result.match_data.timeline);
			// storeDetailsGame.reset();
			storeDetailsGame.createCompositions(result);
			storeDetailsGame.createStatistics(result);
			storeDetailsGame.createLineups(result);
			storeDetailsGame.createFormation(result);
			storeDetailsGame.createText(result);
			storeDetailsGame.createStatisticsPlayers(result);
			storeDetailsGame.createProgress(result?.match_data?.timeline);
			storeDetailsGame.isReady = true;
			if (!showCompositions.nav) {
				if (showCompositions.value) {
					storeDetailsGame.nav = tabsName.COMPOSITIONS;
				} else if (showStatistics.value) {
					storeDetailsGame.nav = tabsName.STATISTIC;
				} else if (showLineups.value) {
					storeDetailsGame.nav = tabsName.LINEUPS;
				} else if (showText.value) {
					storeDetailsGame.nav = tabsName.TEXT;
				} else if (showStatisticsPlayers.value) {
					storeDetailsGame.nav = tabsName.STATISTICS_PLAYERS;
				} else if (showProgress.value) {
					storeDetailsGame.nav = tabsName.GAME_PROGRESS;
				}
			}
			console.log(result);
		});
	}
};
if (!storeDetailsGame.isReady) {
	getDetailsGame();
}

// fetch Interval
const getDetailsGameInterval = setInterval(() => {
	if (storeGame.game?.status !== t('game_head.status.over') && !document.hidden) {
		getDetailsGame();
	} else if (storeGame.game?.status === t('game_head.status.over')) {
		clearInterval(getDetailsGameInterval);
	}
}, GET_INTERVAL_DURATION);

document.addEventListener("visibilitychange", () => {
	if (storeGame.game?.status !== t('game_head.status.over') && !document.hidden) {
		getDetailsGame();
	}
});

onUnmounted(() => {
	clearInterval(getDetailsGameInterval);
});
// tabs
const showCompositions = computed(() => {
		return (
			storeDetailsGame.compositions.venue ||
			Object.keys(storeDetailsGame.compositions.period).length ||
			storeDetailsGame.compositions.mainReferee ||
			sportType === "basketball"
		);
	}),
	showStatistics = computed(() => {
		return storeDetailsGame.statistics.length;
	}),
	showLineups = computed(() => {
		return Object.keys(storeDetailsGame.lineups).length;
	}),
	showText = computed(() => {
		return Object.keys(storeDetailsGame.text).length && storeDetailsGame.sportType === "football";
	}),
	showStatisticsPlayers = computed(() => {
		return Object.keys(storeDetailsGame.statisticsPlayers).length;
	}),
	showProgress = computed(() => {
		return (
			Object.keys(storeDetailsGame.progress).length &&
			storeDetailsGame.sportType !== "hockey" &&
			storeDetailsGame.sportType !== "football"
		);
	});
</script>

<template>
	<div class="wrap">
		<div class="tabs-wrap _secondary">
			<ul class="tabs">
				<li v-if="showCompositions" class="tab__item">
					<TabBtn v-model="storeDetailsGame.nav" :tab-value="tabsName.COMPOSITIONS" :tab-name="TAB_NAME">
						{{ $t("details_game.tabs.compositions") }}
					</TabBtn>
				</li>
				<li v-if="showStatistics" class="tab__item">
					<TabBtn v-model="storeDetailsGame.nav" :tab-value="tabsName.STATISTIC" :tab-name="TAB_NAME">
						{{ $t("details_game.tabs.statistics") }}
					</TabBtn>
				</li>
				<li v-if="showLineups" class="tab__item">
					<TabBtn v-model="storeDetailsGame.nav" :tab-value="tabsName.LINEUPS" :tab-name="TAB_NAME">
						{{ $t("details_game.tabs.lineups") }}
					</TabBtn>
				</li>
				<li v-if="showText" class="tab__item">
					<TabBtn v-model="storeDetailsGame.nav" :tab-value="tabsName.TEXT" :tab-name="TAB_NAME">
						{{ $t("details_game.tabs.text") }}
					</TabBtn>
				</li>
				<li v-if="showStatisticsPlayers" class="tab__item">
					<TabBtn
						v-model="storeDetailsGame.nav"
						:tab-value="tabsName.STATISTICS_PLAYERS"
						:tab-name="TAB_NAME"
					>
						{{ $t("details_game.tabs.statistics_players") }}
					</TabBtn>
				</li>
				<li v-if="showProgress" class="tab__item">
					<TabBtn v-model="storeDetailsGame.nav" :tab-value="tabsName.GAME_PROGRESS" :tab-name="TAB_NAME">
						{{ $t("details_game.tabs.progress") }}
					</TabBtn>
				</li>
			</ul>
		</div>

		<DetailsGameSkeleton v-if="!storeDetailsGame.isReady" />
		<DetailsGameCompositions v-else-if="storeDetailsGame.nav === tabsName.COMPOSITIONS" />
		<DetailsGameStatistics v-else-if="storeDetailsGame.nav === tabsName.STATISTIC" />
		<DetailsGameLineups v-else-if="storeDetailsGame.nav === tabsName.LINEUPS" />
		<DetailsGameText v-else-if="storeDetailsGame.nav === tabsName.TEXT" />
		<DetailsGameStatisticsPlayersTab v-else-if="storeDetailsGame.nav === tabsName.STATISTICS_PLAYERS" />
		<DetailsGameProgress v-else-if="storeDetailsGame.nav === tabsName.GAME_PROGRESS" />
	</div>
</template>

<style scoped lang="scss">
.wrap {
	background: var(--color-dop-back);
	padding: 4px;
	border-radius: 10px;
}
.head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
	padding: 2px 2px 2px 6px;
	background: var(--color-dop-cta);
	border-radius: 6px;
}

.head__name {
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	color: hsl(220, 4%, 14%);
	margin: 0;
}

.tabs-wrap {
	margin-bottom: 12px;
}
</style>
