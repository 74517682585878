<template>
	<div class="skeleton-wrap">
		<slot></slot>
	</div>
</template>

<style scoped lang="scss">
.skeleton-wrap {
	display: flex;
	flex-direction: column;
	padding: 4px;
	gap: 6px;
	background: var(--color-dop-back);
	border-radius: 10px;
}
</style>
