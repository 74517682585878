import { defineStore } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import { GameResponse } from "./GameInterface";

export const useGame = defineStore("Game", () => {
	const isReady: Ref<boolean> = ref(false),
		game: Ref<GameResponse | null> = ref(null);

	return {
		isReady,
		game,
	};
});
