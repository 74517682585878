<script setup>
import HeaderSearch from "./components/HeaderSearch.vue";
import HeaderLang from "./components/HeaderLang.vue";
import HeaderBurger from "./components/HeaderBurger.vue";
import HeaderMenu from "./components/HeaderMenu.vue";
import HeaderUser from "./components/HeaderUser.vue";
import HeaderPresent from "./components/HeaderPresent.vue";
import { useState } from "./HeaderStore";

const stateHeader = useState();
console.log(stateHeader);
</script>

<template>
	<!-- top -->
	<div class="top">
		<div class="top__wrap">
			<div class="logo__wrap">
				<a class="logo" href="/">
					<svg width="216" height="35">
						<use xlink:href="#logo" />
					</svg>
				</a>
				<nav class="logo-menu">
					<ul class="logo-menu__list">
						<li class="logo-menu__item">
							<a class="logo-menu__link" href="/news/list">
								<span>{{ $t("header.title") }}</span>
							</a>
						</li>
					</ul>
				</nav>
			</div>
			<div class="top-menu">
				<HeaderPresent />
				<HeaderLang />
				<HeaderSearch />
				<HeaderBurger />
			</div>
		</div>
	</div>

	<!-- bot -->
	<HeaderMenu />
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.top {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 6px $block-padding-hor;
	background: hsl(0, 0%, 9%);
}

.top__wrap {
	width: 100%;
	max-width: $block-max-width;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.logo__wrap {
	display: flex;
	align-items: center;
	gap: 12px;
}

.logo {
	fill: var(--color-dop-cta);
}

.logo-menu__list {
	display: flex;
	align-items: center;
	gap: 10px;
}

.logo-menu__link {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0px 8px;
	height: 35px;
	color: hsl(0, 0%, 100%);
	font-family: $accent-font;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	text-decoration: none;

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		transform: skewX(-25deg);
		z-index: 1;

		transition: background 150ms ease-in-out;
	}

	> span {
		position: relative;
		z-index: 2;
	}

	&:hover {
		color: var(--color-dop-cta);
	}

	&._active {
		&::after {
			background: var(--color-dop-cta);
		}
	}
}

.top-menu {
	min-width: 100px;
	display: flex;
	align-items: center;
	gap: 10px;
}

@media screen and (max-width: 768px) {
	.logo__wrap {
		gap: 0;
	}
	.top-menu {
		gap: 0;
	}
	.top {
		margin-top: 100px;
		padding: 6px $block-padding-hor-m;
	}
}

@media screen and (max-width: 620px) {
	.top {
		margin-top: 75px;
	}
	.logo {
		> svg {
			width: auto;
			height: 24px;
		}
	}
}
@media screen and (max-width: 520px) {
	.top {
		margin-top: 65px;
	}
}

@media screen and (max-width: 420px) {
	.top {
		margin-top: 50px;
	}
}

@media screen and (max-width: 360px) {
	.top {
		margin-top: 40px;
	}
}
</style>
