import { defineStore } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";

import { ROUTE_NAME_PLAYER, CAREER_KEYS } from "./playerConsts";

// interface
interface CareerTemplate {
	list: any[];
	total: {
		matches_played: number;
		goals_scored: number;
		assists: number;
		yellow_cards: number;
		red_cards: number;
	};
}
interface GameValue {
	isReady: boolean;
	total: any[];
}
interface CareerValue {
	isReady: boolean;
	[CAREER_KEYS.ALL]: CareerTemplate;
	[CAREER_KEYS.NATIONAL]: CareerTemplate;
}

// reset value
const careerTemplate = (): CareerTemplate => ({
		list: [],
		total: {
			matches_played: 0,
			goals_scored: 0,
			assists: 0,
			yellow_cards: 0,
			red_cards: 0,
		},
	}),
	gameResetValue = (): GameValue => ({
		isReady: false,
		total: [],
	}),
	careerResetValue = (): CareerValue => ({
		isReady: false,
		[CAREER_KEYS.ALL]: careerTemplate(),
		[CAREER_KEYS.NATIONAL]: careerTemplate(),
	});

export const usePlayer = defineStore("Player", () => {
	const nav: Ref<string> = ref(ROUTE_NAME_PLAYER.REVIEW),
		lastGame: Ref<GameValue> = ref(gameResetValue()),
		transfers: Ref<GameValue> = ref(gameResetValue()),
		career: Ref<CareerValue> = ref(careerResetValue());

	const reset = () => {
		lastGame.value = gameResetValue();
		transfers.value = gameResetValue();
		career.value = careerResetValue();
	};

	const addLastGame = (total: any[]): void => {
			for (const item of total) {
				item[item.competitor1.qualifier] = item.competitor1;
				item[item.competitor2.qualifier] = item.competitor2;
				delete item.competitor1;
				delete item.competitor2;
			}

			lastGame.value.total = total;
			lastGame.value.isReady = true;
		},
		addCareer = (response: any[], type: CAREER_KEYS.ALL | CAREER_KEYS.NATIONAL): void => {
			for (const item of response) {
				career.value[type].total.matches_played += +item.statistics.matches_played;
				career.value[type].total.goals_scored += +item.statistics.goals_scored;
				career.value[type].total.assists += +item.statistics.assists;
				career.value[type].total.yellow_cards += +item.statistics.yellow_cards;
				career.value[type].total.red_cards += +item.statistics.red_cards;
			}

			career.value[type].list = response;
		};

	return { reset, nav, lastGame, addLastGame, career, addCareer, transfers };
});
