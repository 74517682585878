<script setup lang="ts">
import { bookieLogoUrl } from "@app/helpers.js";
import { getApi } from "@app/helpers.js";
import { useDetailsCoef } from "@app/modules/details/details-coef/DetailsCoefStore.js";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = defineProps({
	api: String,
});

// store
const storeDetailsCoef = useDetailsCoef();

interface CoefLargeItem {
	cat: string;
	name: string;
	title: string;
}

const COEF_MAX: { [key: number]: CoefLargeItem } = {
	0: {
		cat: t("game_head.COEF_MAX.0.cat"),
		name: t("game_head.COEF_MAX.0.name"),
		title: t("game_head.COEF_MAX.0.title"),
	},
	1: {
		cat: t("game_head.COEF_MAX.1.cat"),
		name: t("game_head.COEF_MAX.1.name"),
		title: t("game_head.COEF_MAX.1.title"),
	},
	2: {
		cat: t("game_head.COEF_MAX.2.cat"),
		name: t("game_head.COEF_MAX.2.name"),
		title: t("game_head.COEF_MAX.2.title"),
	},
};

// get coef
const getDetailsCoef = () => {
	getApi(props?.api!).then((result) => {
		storeDetailsCoef.createCoef(result);
	});
};

if (!storeDetailsCoef.isReady) {
	getDetailsCoef();
}
console.log(storeDetailsCoef.maxCoef);
</script>

<template>
	<ul class="coef-large-list">
		<li v-for="(item, key) in storeDetailsCoef.maxCoef" :key="'coeflarge_' + key">
			<!-- ADD IN <a> -->
			<!-- <a v-if="item" class="link" :title="COEF_MAX[key].title" :href="storeDetailsCoef?.maxOdds?.[item.bookie] ?? item.url"> -->
			<a v-if="item && storeDetailsCoef.maxCoef.length === 3" class="link" :title="COEF_MAX[key].title">
				<div class="items">
					<div class="item">
						<div class="name">{{ COEF_MAX[key].name }}</div>
						<div class="coef">{{ item[COEF_MAX[key].cat].new }}</div>
						<div class="bookie">
							<img class="bookie-logo" :src="bookieLogoUrl(item.bookie)" />
						</div>
					</div>
				</div>
			</a>
		</li>
	</ul>
</template>

<style scoped lang="scss">
.link {
	text-decoration: none;
}
.coef-large-list {
	// width: 100%;
	margin: 0 0 10px 0;
}
.coef-large-list,
.items,
.item {
	display: flex;
	justify-content: space-between;
}
.item {
	margin: 0 3px 15px 3px;
	width: 90%;
	height: 27px;
}
.name {
	width: 80px;
	padding: 3px 0 0 7px;
	border-radius: 10px 0 0 10px;
	color: #000000;
	background-color: #ffffff;
}
.coef {
	padding: 3px 0 0 0;
	margin-left: -10px;
	width: 60px;
	text-align: center;
	border-radius: 10px 0 0 10px;
	color: #000000;
	background-color: #d5d5d5;
}
.bookie {
	margin-left: -5px;
}
.bookie-logo {
	height: 100%;
	border-radius: 3px 10px 10px 3px;
}
@media screen and (max-width: 1050px) {
	.item {
		display: grid;
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		gap: 0px;

		margin: 0 5px 15px 3px;
		height: 100%;
		width: 160px;
	}

	.name {
		grid-row-start: 1;
		grid-column-start: 1;
		grid-row-end: 2;
		grid-column-end: 3;

		text-align: center;
		height: 130%;
		width: 100%;
		border-radius: 10px 10px 0 0;
	}
	.coef {
		grid-row-start: 2;
		grid-column-start: 1;
		grid-row-end: 3;
		grid-column-end: 2;

		font-size: 13px;
		margin-left: 0px;
		width: 65%;
	}
	.bookie {
		margin-left: -30px;
		grid-row-start: 2;
		grid-column-start: 2;
		grid-row-end: 3;
		grid-column-end: 3;
	}
}
@media screen and (max-width: 500px) {
	.coef-large-list {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.items,
	.item {
		display: flex;
		justify-content: space-between;
	}
	.item {
		width: 280px;
		margin: 0 0 25px -50px;
		height: 20px;
	}
	.name {
		width: 60%;
		padding: 3px 0 0 7px;
		border-radius: 10px 0 0 10px;
		margin-top: 1px;
		color: #000000;
		background-color: #ffffff;
	}
	.coef {
		font-size: 16px;
		padding: 3px 0 0 0;
		margin: 1px 15px 0 -10px;
		height: 130%;
	}
	.bookie-logo {
		height: 138%;
	}
}
</style>
