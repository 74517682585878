<script setup>
import { inject, computed } from "vue";

import { usePopUp } from "@app/components/pop-up/PopUpStore.js";

import Search from "@app/modules/search/Search.vue";

// store
const storePopUp = usePopUp();

const switchSearchPopUp = () => {
	storePopUp.isOpen = true;
	storePopUp.component = Search;
};
</script>

<template>
	<button @click="switchSearchPopUp" class="search-btn">
		<svg width="25" height="25">
			<use :xlink:href="'#24-search'" />
		</svg>
	</button>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.search-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 40px;
	height: 40px;
	padding: 8px;

	> svg {
		stroke: hsl(0, 0%, 100%);
		transition: stroke 150ms ease-in-out;
	}

	&:hover {
		color: var(--color-dop-cta);

		> svg {
			stroke: var(--color-dop-cta);
		}
	}
}
</style>
