<script setup lang="ts">
import { ROUTE_NAME_PLAYER } from "../playerConsts";
import TeamTransfers from "@app/modules/team/components/TeamTransfers.vue";

import type { NavListItem } from "../playerInterface";
import PlayerLastGame from "./PlayerLastGame.vue";
import PlayerCareer from "./PlayerCareer.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

defineProps<{
	navList: Record<string, NavListItem>;
	sportType: string;
	player?: any;
}>();
</script>

<template>
	<div class="player-details__tables">
		<PlayerLastGame
			v-if="navList?.recentMatches?.api"
			:api="navList.recentMatches.api"
			:title="t('player_review.last_game')"
			:nav-to="ROUTE_NAME_PLAYER.RECENT_MATCHES"
			:show-items="8"
		/>

		<PlayerCareer
			v-if="navList?.career?.api"
			:api="navList.career.api"
			:title="t('player_review.career')"
			:nav-to="ROUTE_NAME_PLAYER.CAREER"
			:show-items="8"
			:sport="sportType"
		/>

		<TeamTransfers
			v-if="navList?.transfers?.api"
			:api="navList.transfers.api"
			:title="t('player_review.transfers')"
			:player="player"
			:show-items="8"
		/>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.player-details__tables {
	display: flex;
	flex-direction: column;
	gap: 18px;
}
</style>
