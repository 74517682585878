<script setup>
import { computed } from "vue";
import { flagIconUrl } from "@app/helpers.js";

import { useSearchStore } from "../SearchStore.js";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

// store
const storeSearchStore = useSearchStore();

const props = defineProps({
	item: Object,
});

const ITEM_TYPES = {
	TEAM: "team",
	PLAYER: "player",
};

// typeName
const typeName = computed(() => {
	switch (props.item.type) {
		case ITEM_TYPES.PLAYER:
			return t('statistics.table.player');

		case ITEM_TYPES.TEAM:
			return t('statistics.table.team');

		default:
			return false;
	}
});

// avatarIcon
const avatarIcon = computed(() => {
	switch (props.item.type) {
		case ITEM_TYPES.PLAYER:
			return "40-avatar";

		case ITEM_TYPES.TEAM:
			return "40-team";

		default:
			return false;
	}
});
</script>

<template>
	<a @click="storeSearchStore.addSearchHistory(item)" :href="item.route" class="item">
		<div class="avatar">
			<img v-if="item.logo" :src="item.logo" :alt="item.name" class="icon-flag" width="32" height="32" />
			<svg v-else width="32" height="32">
				<use :xlink:href="'#' + avatarIcon" />
			</svg>
		</div>

		<div class="text-wrap">
			<div class="name-wrap">
				<img
					:src="flagIconUrl(item.country_code)"
					:alt="'Флаг ' + item.country"
					class="icon-flag"
					width="27"
					height="20"
				/>
				<span class="name">{{ item.name }}</span>
			</div>

			<span class="type">{{ typeName }}</span>
		</div>
	</a>
</template>

<style lang="scss" scoped>
.item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 16px;
	padding: 8px 8px;
	position: relative;
	text-decoration: none;
	border-radius: 8px;

	&:hover {
		background: var(--color-dop-back);
	}

	&:after {
		content: "";
		width: 100%;
		height: 1px;
		position: absolute;
		background: var(--color-border);
		bottom: 0;
		left: 0;
	}
	&:last-of-type {
		&:after {
			display: none;
		}
	}
}
.avatar {
	width: 40px;
	height: 40px;
	background: hsl(0, 0%, 100%);
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 4px;

	> svg {
		width: 100%;
		height: 100%;
		stroke: hsl(0, 0%, 70%);
		stroke-width: 2px;
	}
}

.text-wrap {
	display: flex;
	flex-direction: column;
}

.name-wrap {
	display: flex;
	align-items: center;
	gap: 8px;
}
.name {
	font-size: 14px;
	line-height: 1.2;
	margin: 0;
	display: inline-block;
}

.type {
	margin-top: 6px;
	font-size: 12px;
	line-height: 100%;
	color: var(--color-dop-text);
}
</style>
