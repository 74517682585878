<script setup>
import { flagIconUrl } from "@app/helpers.js";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = defineProps({
	competitors: Object,
	competitors_2nd: Object,
});
</script>

<template>
	<div
		v-if="competitors?.country_code"
		:title="`${t('details_grid.details_bombardier.clickToLearnMore')} ${competitors.country_code} ${competitors.name} ${t(
			'details_grid.details_bombardier.score'
		)}: ${competitors.score}`"
		class="command"
	>
		<img
			class="flag icon-flag"
			:src="flagIconUrl(competitors?.flag)"
			:alt="`Флаг ${competitors?.country}`"
			width="27"
			height="20"
		/>
		<span class="name">{{ competitors?.name }}</span>
		<div class="scores" v-if="competitors_2nd">
			<span
				v-if="competitors?.score || competitors?.score === 0"
				:class="{ _active: competitors?.isWine }"
				class="score"
				>{{ competitors.score }}</span
			>
			<span v-else class="score">-</span>
			<span
				v-if="competitors_2nd?.score || competitors_2nd?.score === 0"
				:class="{ _active: competitors_2nd?.isWine }"
				class="score"
				>{{ competitors_2nd.score }}</span
			>
			<span v-else class="score">-</span>
		</div>
		<div class="scores" v-else>
			<span
				v-if="competitors?.score || competitors?.score === 0"
				:class="{ _active: competitors?.isWine }"
				class="score"
				>{{ competitors.score }}</span
			>
			<span v-else class="score">-</span>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.command {
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
	grid-column-gap: 8px;
	width: 100%;

	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	color: var(--color-main-text);
}

.flag {
	border-radius: 4px;
	width: auto;
	height: 20px;
	object-fit: cover;
}

.name {
	display: block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-shrink: 3;
}

.scores {
	display: flex;
	gap: 5px;
}

.score {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 2px;
	min-height: 16px;
	min-width: 16px;
	border-radius: 4px;
	background: var(--color-main-back);
	font-size: 12px;
	line-height: 100%;
	color: var(--color-main-text);
	text-align: center;

	&._active {
		color: hsl(220deg, 4%, 14%);
		background: var(--color-dop-cta);
	}
}
</style>
