<script setup>
import { inject } from "vue";

const favoriteName = inject("favoriteName", null),
	component = inject("component", null);
</script>

<template>
	<div class="wrap">
		<span v-if="favoriteName" class="title">{{ favoriteName }}</span>
		<component class="component_list" :is="component"></component>
	</div>
</template>

<style scoped lang="scss">
.wrap {
	width: 100%;
	margin-bottom: 24px;
}
.title {
	font-size: 18px;
	line-height: 120%;
	color: var(--color-main-text);
}
.component_list {
	margin-top: 8px;
}
</style>
