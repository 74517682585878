import { defineStore } from "pinia";
import { ref } from "vue";
import cloneDeep from "lodash/cloneDeep";
import type { GameItem } from "@app/modules/sport/sportInterface";

import type { TagsItem, TournamentGetApi } from "./TournamentInterface";

export const useTournament = defineStore("Tournament", () => {
	const isReady = ref<boolean>(false),
		total = ref<TournamentGetApi | null>(null),
		today = ref<GameItem[] | null>(null),
		results = ref<GameItem[] | null>(null),
		timetable = ref<GameItem[] | null>(null),
		tags = ref<Record<string, TagsItem>>({});

	const createTournament = (tournament: TournamentGetApi) => {
		// start time sort
		tournament.games.sort((a, b) => b.start_time - a.start_time);

		// today
		const getCurrentDate = () => new Date(new Date().toISOString().slice(0, 10)),
			currentDate = getCurrentDate(),
			nextDayDate = new Date(getCurrentDate().setDate(getCurrentDate().getDate() + 1));

		let createToday = cloneDeep(tournament.games);
		createToday = createToday.filter((game) => {
			const startTime = new Date(game.start_time * 1000);
			if (startTime >= currentDate && startTime < nextDayDate) {
				return game;
			}
		});

		if (createToday.length) {
			createToday.sort((a, b) => a.start_time - b.start_time);
			today.value = createToday;
		}

		// results
		let createResults = cloneDeep(tournament.games);
		createResults = createResults.filter((game) => {
			if (game.status) return game;
		});

		if (createResults.length) {
			results.value = createResults;
		}

		// timetable
		let createTimetable = cloneDeep(tournament.games);
		createTimetable = createTimetable.filter((game) => {
			if (!game.status && new Date() <= new Date(game.start_time * 1000)) return game;
		});

		if (createTimetable.length) {
			createTimetable.sort((a, b) => a.start_time - b.start_time);
			timetable.value = createTimetable;
		}

		// total
		total.value = tournament;
	};

	return {
		isReady,
		createTournament,
		total,
		today,
		results,
		timetable,
		tags,
	};
});
