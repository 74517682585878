<script setup>
import { computed, inject } from "vue";
import { sportTypeName, periodType } from "@assets/app/consts.js";
import { useRouter } from "vue-router";

// router
const router = useRouter();

const extractSportType = (path) => {
	const startIndex = path.indexOf("/sport/") + "/sport/".length;
	const endIndex = path.indexOf("/", startIndex);
	return path.slice(startIndex, endIndex);
};

const sportType = inject("sportType") || extractSportType(router.options.history.base);

const periodName = {
	[sportTypeName.FOOTBALL]: {
		REGULAR_PERIOD: "-й Тайм",
		OVERTIME: "Дополнительное время",
		PENALTIES: "Серия пенальти",
	},
	[sportTypeName.HOCKEY]: {
		REGULAR_PERIOD: "-й Период",
		OVERTIME: "Овертайм",
	},
	[sportTypeName.TENNIS]: {
		SET: "-й Сет",
	},
};

const props = defineProps({
	teamHome: Object,
	teamAway: Object,
	periodScores: Array,
	score: Object,
	isGol: Object,
	showWine: {
		default: true,
		type: Boolean,
	},
});

const formatScore = (score) => (score || score === 0 || score === "0" ? score : "-");
const scoreTennis = (match) => {
	const matchScore = {
		home: 0,
		away: 0,
	};

	for (const set of match) {
		const homeScore = set.home;
		const awayScore = set.away;

		if (homeScore > awayScore) {
			matchScore.home += 1;
		} else if (awayScore > homeScore) {
			matchScore.away += 1;
		}
	}

	return matchScore;
};

const scores = computed(() => {
	const allScores = {
		main: {
			home: props.teamHome?.score,
			away: props.teamAway?.score,
		},
		period: [],
	};

	if (props.periodScores) {
		allScores.main = {
			home: 0,
			away: 0,
		};

		for (let index = 0; index < props.periodScores.length; index++) {
			const period = props.periodScores[index];

			allScores.period.push({
				home: period.home_score,
				away: period.away_score,
			});

			switch (period.type) {
				case periodType.REGULAR_PERIOD:
				case periodType.OVERTIME:
					allScores.main.home += +period.home_score;
					allScores.main.away += +period.away_score;

					if (period.type === periodType.REGULAR_PERIOD) {
						allScores.period[index].title = period.number + periodName?.[sportType]?.REGULAR_PERIOD;
					} else {
						allScores.period[index].title = periodName?.[sportType]?.OVERTIME;
					}
					break;

				case periodType.PENALTIES:
					allScores.main.home += +period.home_score > period.away_score;
					allScores.main.away += +period.away_score > period.home_score;
					allScores.period[index].title = periodName?.[sportType]?.PENALTIES;
					break;

				case periodType.SET:
					allScores.period[index].title = periodName?.[sportType]?.SET;
					break;

				default:
					break;
			}
		}
		if (sportType === "table-tennis" || sportType === "tennis" || props.periodScores[0].type === "set") {
			allScores.main = scoreTennis(allScores.period);
		}

		allScores.period.reverse();
	} else if (props.score) {
		allScores.main = {
			home: props.score.team_home,
			away: props.score.team_away,
		};
	}

	return allScores;
});
</script>

<template>
	<div class="score-wrap">
		<div class="gol">
			<span :class="{ _active: isGol?.home }" class="status-spin">Гол</span>
			<span :class="{ _active: isGol?.away }" class="status-spin">Гол</span>
		</div>

		<div class="main">
			<span
				:title="teamHome?.name"
				:class="{ _active: showWine && scores.main.home > scores.main.away }"
				class="score"
			>
				{{ formatScore(scores.main.home) }}
			</span>

			<span
				:title="teamAway?.name"
				:class="{ _active: showWine && scores.main.away > scores.main.home }"
				class="score"
			>
				{{ formatScore(scores.main.away) }}
			</span>
		</div>

		<div v-for="(period, index) in scores.period" :key="'TableScore_period' + index" class="period">
			<span :title="teamHome.name + ' - ' + period.title" class="score">
				{{ formatScore(period.home) }}
			</span>

			<span :title="teamAway.name + ' - ' + period.title" class="score">
				{{ formatScore(period.away) }}
			</span>
		</div>
	</div>
</template>

<style scoped lang="scss">
.score-wrap {
	padding: 3px 4px;
	display: flex;
	gap: 6px;
}

.gol,
.main,
.period {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-template: 2px;
}

.status-spin,
.score {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 2px;
	min-height: 16px;
	min-width: 16px;
	border-radius: 4px;
	background: var(--color-main-back);
	font-size: 12px;
	line-height: 100%;
	color: var(--color-main-text);
	text-align: center;
}

.score {
	&._active {
		color: hsl(220, 4%, 14%);
		background: var(--color-dop-cta);
	}
}

.period {
	> .score {
		color: var(--color-dop-text);
	}
}

.status-spin {
	opacity: 0;
	visibility: hidden;
	color: hsl(220, 4%, 14%);
	background: var(--color-dop-cta);
	padding: 2px 4px;

	animation-direction: alternate-reverse;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;

	&._active {
		opacity: 1;
		visibility: visible;

		animation-name: gol-spin;
	}
}

@keyframes gol-spin {
	0% {
		opacity: 1;
	}
	70% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}

@media screen and (max-width: 570px) {
	.period {
		display: none;
	}
}
</style>
