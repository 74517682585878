<script setup>
import SkeletonNav from "@app/ui/skeleton/SkeletonNav.vue";
import SkeletonTable from "@app/ui/skeleton/SkeletonTable.vue";

const wrap = 3;
</script>

<template>
	<div class="wrap">
		<SkeletonNav class="nav" />
		<div v-for="(item, key) in wrap" :key="'Tournament' + key" class="item">
			<div class="skeleton-line _head"></div>

			<SkeletonTable :line="10" class="table" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.nav {
	margin-bottom: 12px;
}

.wrap {
	padding: 4px;
	border-radius: 10px;
	width: 100%;
	background: var(--color-dop-back);
	margin-bottom: 12px;
}

.item {
	display: flex;
	flex-direction: column;
}
._head {
	width: 40%;
	height: 26px;
	margin-bottom: 12px;
}
.table {
	margin-bottom: 16px;
}
</style>
