<script setup lang="ts">
import { inject } from "vue";
import { picBroadcastUrl } from "@app/helpers.ts";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const sportType = inject("sportType", null) || "";

const redirectPath = "/goto/broadcast/";
</script>

<template>
	<div class="wrap">
		<div class="left-section">
			<a class="link" :href="redirectPath" target="_blank">
				<div class="play-btn">
					<svg width="20" height="20">
						<use xlink:href="#20-play" />
					</svg>
				</div>
				<img :src="picBroadcastUrl(sportType) ?? undefined" class="img" width="359" height="289" />
			</a>
		</div>
		<div class="right-section">
			<h2>{{ t("details_broadcast.head") }}</h2>
			<p class="text-content">{{ t("details_broadcast.p1") }}</p>
			<p class="text-content">{{ t("details_broadcast.p2") }}</p>
			<ul class="list">
				<li><span>1</span>{{ t("details_broadcast.li1") }}</li>
				<li><span>2</span>{{ t("details_broadcast.li2") }}</li>
				<li><span>3</span>{{ t("details_broadcast.li3") }}</li>
			</ul>
			<button class="tab-btn">
				<a class="link" :href="redirectPath" target="_blank" onclick="ym(91864289,'reachGoal','click_reg_video'); return true;">
					<span class="name">{{ t("details_broadcast.btn_name") }}</span>
				</a>
			</button>
		</div>
	</div>
</template>

<style scoped lang="scss">
.wrap {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: var(--color-dop-back);
	padding: 4px;
	border-radius: 10px;
	margin: 10px 0;
}
.list {
	margin: 20px 0;
}
.text-content {
	font-size: 13px;
	margin: 0;
}
.link {
	text-decoration: none;
	display: inline-block;
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: 6px;
}

.play-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 40px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--color-dop-cta);

	> svg {
		width: 25px;
		height: auto;
		fill: hsl(220, 4%, 14%);
	}
}

.img {
	width: 100%;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	aspect-ratio: 16/9;
	object-fit: cover;
}
.left-section {
	width: 100%;
}
.right-section {
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 20px;
}
.tab-btn {
	margin: 20px 0;
	background: #bdff2e;
}
.name {
	color: #222325;
}
li {
	font-size: 14px;
}
li > span {
	display: inline-block;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: var(--color-black);
	text-align: center;
	line-height: 24px;
	margin: 0 10px 5px 0;
}
@media screen and (max-width: 690px) {
	li {
		font-size: 12px;
	}
	.text-content {
		font-size: 12px;
	}
	h2 {
		font-size: 15px;
	}
}
@media screen and (max-width: 400px) {
	li {
		font-size: 12px;
	}
	.text-content {
		font-size: 12px;
	}
	h2 {
		font-size: 16px;
	}
	li > span {
		display: inline-block;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background-color: var(--color-black);
		text-align: center;
		line-height: 14px;
		margin: 0 5px 2px 0;
	}
}
</style>
