<script setup>
import { bookieLogoUrl } from "@app/helpers.js";
import { TARGET_BLANK } from "@app/consts.js";

import TableCoefficient from "@app/ui/table/coefficient/TableCoefficient.vue";

import { COEF_TYPE } from "../detailsCoefConsts.js";
import { useDetailsCoef } from "../DetailsCoefStore.js";

import DetailsCoefBanner from "../ui/DetailsCoefBanner.vue";

// store
const storeDetailsCoef = useDetailsCoef();

// linkTu
const linkTu = (url) => {
	window.open(url, TARGET_BLANK);
};

console.log(storeDetailsCoef.coef[COEF_TYPE.EVEN_ODD]);
</script>

<template>
	<div class="table-wrap">
		<table class="main-table coef-table">
			<tr>
				<th></th>
				<th>{{ $t('details_coef.odd') }}</th>
				<th>{{ $t('details_coef.even') }}</th>
			</tr>
		<!-- ADD IN <tr> -->
		<!-- 
			@click="linkTu(item.url)"
			@keyup.enter="linkTu(item.url)"
			:href="item.url" 
		-->
			<tr
				v-for="(item, bookieKey) in storeDetailsCoef.coef[COEF_TYPE.EVEN_ODD]"
				:key="'details_coef_even_odd_' + bookieKey"
				:class="{ _active: item?.crown }"
				tabindex="0"
				role="link"
			>
				<td class="cof-table__logo">
					<div class="crown">
						<svg width="20" height="20">
							<use xlink:href="#20-crown" />
						</svg>
					</div>
					<DetailsCoefBanner v-if="item.bookie === 'liga'" :bannerName="item.bookie" :bookie-logo-url="bookieLogoUrl(item.bookie)" />
                    <DetailsCoefBanner v-else-if="item.bookie === 'boombet'" :bannerName="item.bookie"  :bookie-logo-url="bookieLogoUrl(item.bookie)" />
				    <img v-else :src="bookieLogoUrl(item.bookie)" height="16" width="70" />
				</td>

				<td class="coef-td">
					<div class="coef-wrap">
						<TableCoefficient :coefficient="item.even" class="coef-number" />
					</div>
				</td>
				<td class="coef-td">
					<div class="coef-wrap">
						<TableCoefficient :coefficient="item.odd" class="coef-number" />
					</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<style scoped lang="scss">
.table-wrap {
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.coef-td {
	width: 100px;
}

.coef-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.coef-number {
	min-width: 50px;
}

@media screen and (max-width: 768px) {
	.coef-td {
		width: 80px;
	}
}
</style>
