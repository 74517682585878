<script setup>
import { useDetailsGame } from "../DetailsGameStore.js";

import EventPlayer from "../ui/EventPlayer.vue";

// store
const storeDetailsGame = useDetailsGame();
</script>

<template>
	<h2 v-if="storeDetailsGame.formation" class="area-title">
		<span>{{ storeDetailsGame.formation.home.formation }}</span>
		<span>{{ storeDetailsGame.formation.away.formation }}</span>
	</h2>
	<div v-if="storeDetailsGame.formation" class="area">
		<svg width="592" height="364" class="area-image">
			<use xlink:href="#footballArea" />
		</svg>
		<div class="area-players__wrapper">
			<div
				class="area-players__command"
				v-for="(commandPosition, commandPositionIndex) in [
					storeDetailsGame.formation.home,
					storeDetailsGame.formation.away,
				]"
				:key="'commandPosition' + commandPositionIndex"
			>
				<div
					v-for="(positionColumn, positionColumnIndex) in commandPosition.players"
					:key="`${commandPositionIndex}_positionColumnIndex_${positionColumnIndex}`"
					:class="{ '_to-much': positionColumn.length >= 5 }"
					class="area-players__column"
				>
					<div
						class="area-players__player"
						v-for="(player, playerIndex) in positionColumn"
						:key="`${commandPositionIndex}_positionColumnIndex_${positionColumnIndex}_${playerIndex}`"
						:title="`${player.jersey_number} ${player.name}`"
					>
						<div class="area-players__clothes">
							<svg
								width="40"
								height="40"
								class="area-players__clothes_image"
								:style="{
									'--icon-color-base': '#' + (commandPosition?.jersey?.base ?? 'fff'),
									'--icon-color-sleeve': '#' + (commandPosition?.jersey?.sleeve ?? 'fff'),
								}"
							>
								<use xlink:href="#40-uniform-football" />
							</svg>
						</div>
						<div class="area-players__player_event">
							<event-player :events="player.events" :substitution="false" />
						</div>
						<span class="area-players__player_name">{{ player.nameCut }}</span>
						<span
							:style="{ color: '#' + (commandPosition?.jersey?.number ?? '000') }"
							class="area-players__player_number"
						>
							{{ player.jersey_number }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.area {
	position: relative;
	margin-bottom: 24px;
}
.area-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	color: hsl(220, 4%, 14%);
	padding: 0 6px;
	background: var(--color-dop-cta);
	border-radius: 6px;
	margin: 0 0 12px;
}
.area-image {
	width: 100%;
	height: auto;
	stroke: var(--color-dop-text);
	stroke-width: 2;
}
.area-players__clothes_image {
	width: 100%;
	height: auto;
}
.area-players__wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
}
.area-players__command {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.area-players__wrapper .area-players__command:nth-child(2) {
	flex-direction: row-reverse;
}
.area-players__wrapper .area-players__command:nth-child(1) .area-players__column {
	flex-direction: column-reverse;
}
.area-players__column {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	height: 100%;
	gap: 54px;

	&._to-much {
		gap: 34px;
	}
}
.area-players__player {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}
.area-players__player_number {
	position: absolute;
	top: 40%;
	left: 50%;
	color: #fff;
	transform: translate(-50%, -50%);
}
.area-players__player_name {
	background: var(--color-main-text);
	padding: 0px 4px;
	border-radius: 4px;
	line-height: 120%;
	font-size: 12px;
	color: var(--color-main-back);

	max-width: 65px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.area-players__clothes {
	width: 40px;
	height: auto;
	fill: #fff;
	stroke: var(--color-main-text);
	stroke-width: 1px;
}

.area-players__player_event {
	position: absolute;
	bottom: 44px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	background: var(--color-main-back);
}
:global(.area-players__player_event .icon-wrap) {
	padding: 0;
	background: transparent;
}

@media screen and (max-width: 1020px) {
	.area {
		height: 500px;
	}
	.area-players__wrapper {
		grid-template-columns: 1fr;
	}

	.area-image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(90deg);
		width: 500px;
	}

	.area-players__command {
		flex-direction: column;
	}

	.area-players__wrapper .area-players__command:nth-child(2) {
		flex-direction: column-reverse;
	}

	.area-players__column {
		flex-direction: row;
		gap: 26px;

		&._to-much {
			gap: 4px;
		}
	}
	.area-players__wrapper .area-players__command:nth-child(1) .area-players__column {
		flex-direction: row-reverse;
	}

	.area-players__clothes {
		width: 26px;
	}

	.area-players__player_event {
		display: none;
	}
}
</style>
