<script setup>
import { inject } from "vue";

import { useUserAuthn } from "../UserAuthnStore.js";

const classNameAuthn = inject("classNameAuthn");

// store
const storeUserAuthn = useUserAuthn();
</script>

<template>
	<div class="message-wrap">
		<div class="message-icon">
			<svg width="20" height="20">
				<use xlink:href="#20-check" />
			</svg>
		</div>

		<h3 v-if="storeUserAuthn.message.title" :class="classNameAuthn.title">
			{{ storeUserAuthn.message.title }}
		</h3>

		<p v-if="storeUserAuthn.message.text" :class="classNameAuthn.nav_text" class="reset-text">
			{{ storeUserAuthn.message.text }}
		</p>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.message-wrap {
	min-height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.message-icon {
	width: 80px;
	height: 80px;
	border-radius: 80px;
	background: var(--color-dop-cta);
	margin-bottom: 40px;

	svg {
		width: 100%;
		height: 100%;
		stroke: hsl(220, 4%, 14%);
	}
}
</style>
