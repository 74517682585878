<script setup lang="ts">
import type { ConstObject } from "@app/interface";
import { computed, inject, ref } from "vue";
import { ADMIN_IMG_STATIC_PATH } from "@app/consts.ts";
import { flagIconUrl, getAgeFullYear, formatNameRemoveComma, currentLocale } from "@app/helpers.ts";
import ImgInfo from "@app/ui/img-info/ImgInfo.vue";

import type { PlayerInfo } from "../playerInterface";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;
const LOCALE = currentLocale().locale;

interface FavoriteCountryItem {
  country: string;
  countryName: string;
  countrySlug: string;
}

const props = defineProps<{
	playerInfo: PlayerInfo;
}>();

// country
const favoriteCountry = ref(inject<FavoriteCountryItem[]>("favoriteCountry", []));
const filteredCountry = favoriteCountry.value.filter(obj => obj.country === props.playerInfo.team_country_code);

const ROLE_LIST: ConstObject = {
	goalkeeper: t('player_head.ROLE_LIST.goalkeeper'),
	defender: t('player_head.ROLE_LIST.defender'),
	forward: t('player_head.ROLE_LIST.forward'),
	midfielder: t('player_head.ROLE_LIST.midfielder'),
};

const PLAY_FOOT: ConstObject = {
	left: t('player_head.PLAY_FOOT.left'),
	right: t('player_head.PLAY_FOOT.right'),
};

// role
const role = computed(() => {
	if (props.playerInfo.type) {
		return ROLE_LIST[props.playerInfo.type];
	}
});

// date
const dateBirth = computed(() => {
	if (props.playerInfo.date_of_birth) {
		const date = new Date(props.playerInfo.date_of_birth);
		return new Intl.DateTimeFormat(LOCALE, {
			month: "numeric",
			day: "numeric",
			year: "numeric",
		}).format(date);
	}
});

const teamRoute = () => {
	if (props.playerInfo?.team_id) {
		let competitor = props.playerInfo.team_id.split(":");
		return `/team/${competitor[2]}`;
	}
	return "";
};
</script>

<template>
	<div class="head">
		<div class="info">
			<div class="avatar">
				<img v-if="playerInfo.logo" :src="ADMIN_IMG_STATIC_PATH() + playerInfo.logo" width="100" height="100" />
				<svg v-else width="100" height="100">
					<use xlink:href="#40-avatar" />
				</svg>
				<ImgInfo :author="playerInfo?.photo_author" :source="playerInfo?.photo_source" />
			</div>

			<div class="name-wrap">
				<h1 v-if="playerInfo.name" class="name">
					{{ formatNameRemoveComma(playerInfo.name) }}
					<img
						v-if="playerInfo.country_code"
						:src="flagIconUrl(playerInfo.country_code)"
						class="icon-flag"
						alt="Флаг"
						width="27"
						height="20"
					/>
				</h1>

				<ul class="list">
					<li v-if="playerInfo.type" class="item">
						<span>{{ role }}&nbsp;</span>
						<span>({{ playerInfo.team_name }})</span>
					</li>
					<li v-if="playerInfo.date_of_birth" class="item">
						<span>{{ $t('player_head.playerInfo.old') }}&nbsp;</span>
						<span>{{ getAgeFullYear(playerInfo.date_of_birth) }}&nbsp;</span>
						<span>({{ dateBirth }})</span>
					</li>
					<li v-if="playerInfo.height" class="item">
						<span>{{ $t('player_head.playerInfo.height') }}&nbsp;</span>
						<span>{{ playerInfo.height }} см.</span>
					</li>
					<li v-if="playerInfo.preferred_foot" class="item">
						<span>{{ $t('player_head.playerInfo.preferredFoot') }}&nbsp;</span>
						<span>{{ PLAY_FOOT[playerInfo.preferred_foot] }}</span>
					</li>
					<li v-if="playerInfo.team_name" class="item">
						<span>{{ $t('player_head.playerInfo.club') }}&nbsp;</span>
						<a :href="teamRoute()" :title="playerInfo.team_name" class="team">{{ playerInfo.team_name }}</a>
					</li>
					<li v-if="playerInfo.height" class="item">
						<span>{{ $t('player_head.playerInfo.country') }}&nbsp;</span>
							<a :href="filteredCountry[0].countrySlug" :title="filteredCountry[0].countryName" class="team">
								<span>{{ filteredCountry[0].countryName }}</span>
							</a>
					</li>
				</ul>
			</div>
		</div>
		<div v-if="playerInfo.team_logo" class="team-logo">
			<img :src="ADMIN_IMG_STATIC_PATH() + playerInfo.team_logo" width="100" height="100" />
			<ImgInfo :author="playerInfo?.team_photo_author" :source="playerInfo?.team_photo_source" />
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.head {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 18px;
	margin-bottom: 32px;
}

.info {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 18px;
}

.avatar,
.team-logo {
	position: relative;
	background: hsl(0, 0%, 100%);
	border-radius: 6px;
	width: 100px;
	height: 100px;

	> svg {
		width: 100%;
		height: 100%;
		stroke: hsl(0, 0%, 85%);
		stroke-width: 1px;
	}

	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: inherit;
	}

	&:hover {
		> :deep(.img-info) {
			visibility: visible;
			opacity: 1;
		}
	}
}

.name-wrap {
	flex: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.name {
	display: flex;
	align-items: center;
	gap: 10px;
	font-family: $accent-font;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	margin: 0;
}

.list {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.item {
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
}

@media screen and (max-width: 570px) {
	.info {
		gap: 10px;
	}
	.avatar,
	.team-logo {
		width: 60px;
		height: 60px;
	}

	.name-wrap {
		gap: 8px;
	}

	.name {
		font-size: 16px;
	}

	.list {
		margin-top: 0;
	}

	.item {
		font-size: 12px;
	}
}
</style>
