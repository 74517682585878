<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@unhead/vue";
import { ref, computed, watch } from "vue";
import type { GameTabsItem } from "@app/modules/game/GameInterface";
import type { GameItem } from "@app/modules/sport/sportInterface";
import { getApi } from "@app/helpers.ts";

import type { TournamentGetApi, TagsItem } from "./TournamentInterface";
import { useTournament } from "./TournamentStore";
import { ROUTE_NAME_TOURNAMENT } from "./tournamentConsts";
import TournamentHead from "./components/TournamentHead.vue";
import TournamentSkeleton from "./components/TournamentSkeleton.vue";
import TournamentReview from "./components/TournamentReview.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

const props = defineProps<{
	value?: {
		event_url: string;
		sport: string;
	};
}>();

const canonicalLink = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;

// router
const route = useRoute(),
	router = useRouter();

if (route.path === "/") {
	router.addRoute({ path: "/", redirect: `/${ROUTE_NAME_TOURNAMENT.REVIEW}/` });
}

watch(
	() => route.fullPath,
	() => {
		if (canonicalLink) {
			if (route.name === "review") {
				canonicalLink.href = `${window.location.origin + router.options.history.base}/`
			} else {
				canonicalLink.href = window.location.href;
			}
		}
	}
);
console.log(route.matched)
const routeName = computed<string>(() => String(route.matched?.[0].name));



// store
const storeTournament = useTournament();

if (props?.value) {
	getApi<{ 0: TournamentGetApi; tabs: Record<string, TagsItem> }>(props.value.event_url).then((response) => {
		if (response?.[0]) {
			storeTournament.createTournament(response[0]);
			// nav
			createNavList();
			if (response[0]?.tabs) {
				navList.value = { ...navList.value, ...response[0].tabs };
			}

			// isReady
			storeTournament.isReady = true;

			// tags
			storeTournament.tags = response.tabs;
			useHead({
				title: (): any => {
					if (route.name) return storeTournament?.tags?.[String(route.name)]?.title;
				},
				meta: [
					{
						name: "description",
						content: (): any => {
							if (route.name) return storeTournament?.tags?.[String(route.name)]?.description;
						},
					},
					{
						property: "og:title",
						content: (): any => {
							if (route.name) return storeTournament?.tags?.[String(route.name)]?.title;
						},
					},
					{
						property: "og:description",
						content: (): any => {
							if (route.name) return storeTournament?.tags?.[String(route.name)]?.description;
						},
					},
				],
			});
		}
	});
}

// nav
interface NavListItem extends GameTabsItem {
	games?: GameItem[];
}
const navList = ref<Record<string, NavListItem>>({});

const createNavList = () => {
	navList.value = {
		[ROUTE_NAME_TOURNAMENT.REVIEW]: {
			name: t("details_game.tabs.compositions"),
		},
	};

	if (storeTournament.results) {
		navList.value[ROUTE_NAME_TOURNAMENT.RESULTS] = {
			games: storeTournament.results,
			name: t("details_game.tabs.rezults"),
		};
	}

	if (storeTournament.timetable) {
		navList.value[ROUTE_NAME_TOURNAMENT.TIMETABLE] = {
			games: storeTournament.timetable,
			name: t("details_game.tabs.calendar"),
		};
	}
};

const isActive = (key: string) => {
	if (route.path === "/" && navList.value[ROUTE_NAME_TOURNAMENT.REVIEW] && key === "review") {
		return "_active";
	}
	return "";
};

// smallH1
const title = computed(() => {
	if (route.name) return storeTournament?.tags?.[String(route.name)]?.h1;
});
</script>

<template>
	<h1 v-if="storeTournament.isReady" class="smallH1">
		{{ title || storeTournament?.tags?.review?.h1 }}
	</h1>
	<TournamentHead />
	<div v-if="storeTournament.isReady">
		<div class="tabs-wrap">
			<ul class="tabs">
				<li v-for="(item, key) in navList" :key="'tournament_nav_' + key">
					<router-link :to="`/${key}/`" class="tab-btn" :class="isActive(String(key))">{{
						item.name
					}}</router-link>
				</li>
			</ul>
		</div>
		<TournamentReview v-if="route.path === '/'" />

		<router-view
			v-else
			:games="navList[routeName]?.games"
			:api="navList[routeName]?.api"
			:title="navList[routeName]?.name"
		/>
	</div>

	<TournamentSkeleton v-else />
</template>

<style scoped lang="scss">
.grid-wrap {
	padding-top: 12px;
	width: 100%;
	border-radius: 10px;
	background: var(--color-dop-back);
}
</style>
