const tabsName = {
		COMPOSITIONS: "compositions",
		STATISTIC: "statistic",
		LINEUPS: "lineups",
		TEXT: "text",
		STATISTICS_PLAYERS: "statistics_players",
		GAME_PROGRESS: "timeline",
	},
	eventType = {
		CORNER_KICK: "corner_kick",
		INJURY_TIME_SHOWN: "injury_time_shown",
		INJURY: "injury",
		MATCH_ENDED: "match_ended",
		MATCH_STARTED: "match_started",
		MISSED: "missed",
		PENALTY_MISSED: "penalty_missed",
		PENALTY_SHOOTOUT: "penalty_shootout",
		PERIOD_SCORE: "period_score",
		PERIOD_START: "period_start",
		RED_CARD: "red_card",
		SCORE_CHANGE: "score_change",
		SCORED: "scored",
		SUBSTITUTION: "substitution",
		SUSPENSION: "suspension",
		YELLOW_CARD: "yellow_card",
		YELLOW_RED_CARD: "yellow_red_card",
	},
	periodType = {
		OVERTIME: "overtime",
		PENALTIES: "penalties",
		REGULAR_PERIOD: "regular_period",
	},
	statisticTypes = {
		// tennis
		ACES: "aces",
		BACKHAND_ERRORS: "backhand_errors",
		BACKHAND_UNFORCED_ERRORS: "backhand_unforced_errors",
		BACKHAND_WINNERS: "backhand_winners",
		BREAKPOINTS_WON: "breakpoints_won",
		DOUBLE_FAULTS: "double_faults",
		DROP_SHOT_UNFORCED_ERRORS: "drop_shot_unforced_errors",
		DROP_SHOT_WINNERS: "drop_shot_winners",
		FIRST_SERVE_POINTS_WON: "first_serve_points_won",
		FIRST_SERVE_SUCCESSFUL: "first_serve_successful",
		FOREHAND_ERRORS: "forehand_errors",
		FOREHAND_UNFORCED_ERRORS: "forehand_unforced_errors",
		FOREHAND_WINNERS: "forehand_winners",
		GAMES_WON: "games_won",
		GROUNDSTROKE_ERRORS: "groundstroke_errors",
		GROUNDSTROKE_UNFORCED_ERRORS: "groundstroke_unforced_errors",
		GROUNDSTROKE_WINNERS: "groundstroke_winners",
		LOB_UNFORCED_ERRORS: "lob_unforced_errors",
		LOB_WINNERS: "lob_winners",
		MAX_GAMES_IN_A_ROW: "max_games_in_a_row",
		MAX_POINTS_IN_A_ROW: "max_points_in_a_row",
		OVERHEAD_STROKE_ERRORS: "overhead_stroke_errors",
		OVERHEAD_STROKE_UNFORCED_ERRORS: "overhead_stroke_unforced_errors",
		OVERHEAD_STROKE_WINNERS: "overhead_stroke_winners",
		POINTS_WON: "points_won",
		POINTS_WON_FROM_LAST_10: "points_won_from_last_10",
		RETURN_ERRORS: "return_errors",
		RETURN_WINNERS: "return_winners",
		SECOND_SERVE_POINTS_WON: "second_serve_points_won",
		SECOND_SERVE_SUCCESSFUL: "second_serve_successful",
		SERVICE_GAMES_WON: "service_games_won",
		SERVICE_POINTS_LOST: "service_points_lost",
		SERVICE_POINTS_WON: "service_points_won",
		TIEBREAKS_WON: "tiebreaks_won",
		TOTAL_BREAKPOINTS: "total_breakpoints",
		VOLLEY_UNFORCED_ERRORS: "volley_unforced_errors",
		VOLLEY_WINNERS: "volley_winners",
		// football
		ASSISTS: "assists",
		BALL_POSSESSION: "ball_possession",
		CORNER_KICKS: "corner_kicks",
		FIRST_ASSISTS: "first_assists",
		FOULS: "fouls",
		FREE_KICKS: "free_kicks",
		GOALS_CONCEDED: "goals_conceded",
		GOALS_IN_POWER_PLAY: "goals_in_power_play",
		GOALS_SCORED: "goals_scored",
		GOALS_WHILE_SHORT_HANDED: "goals_while_short_handed",
		INJURIES: "injuries",
		OFFSIDES: "offsides",
		OWN_GOALS: "own_goals",
		PENALTIES: "penalties",
		PENALTY_MINUTES: "penalty_minutes",
		PLUS_MINUS: "plus_minus",
		POINTS: "points",
		POWER_PLAYS: "power_plays",
		PUCK_POSSESSION: "puck_possession",
		RED_CARDS: "red_cards",
		SAVES: "saves",
		SECOND_ASSISTS: "second_assists",
		SHOTS_BLOCKED: "shots_blocked",
		SHOTS_OFF_TARGET: "shots_off_target",
		SHOTS_ON_GOAL: "shots_on_goal",
		SHOTS_ON_TARGET: "shots_on_target",
		SHOTS_SAVED: "shots_saved",
		SHOTS_TOTAL: "shots_total",
		SHUTOUTS: "shutouts",
		SUBSTITUTED_IN: "substituted_in",
		SUBSTITUTED_OUT: "substituted_out",
		SUBSTITUTIONS: "substitutions",
		THROW_INS: "throw_ins",
		YELLOW_CARDS: "yellow_cards",
		YELLOW_RED_CARDS: "yellow_red_cards",
		// basketball
		BLOCKS: "blocks",
		DEFENSIVE_REBOUNDS: "defensive_rebounds",
		MINUTES: "minutes",
		FIELD_GOALS_ATTEMPTED: "field_goals_attempted",
		FIELD_GOALS_MADE: "field_goals_made",
		FREE_THROWS_ATTEMPTED: "free_throws_attempted",
		FREE_THROWS_MADE: "free_throws_made",
		PERSONAL_FOULS: "personal_fouls",
		POINTS: "points",
		TECHNICAL_FOULS: "technical_fouls",
		THREE_POINTERS_ATTEMPTED: "three_pointers_attempted",
		THREE_POINTERS_MADE: "three_pointers_made",
		TOTAL_REBOUNDS: "total_rebounds",
		TURNOVERS: "turnovers",
		BIGGEST_LEAD: "biggest_lead",
		FREE_THROW_ATTEMPTS_SUCCESSFUL: "free_throw_attempts_successful",
		FREE_THROW_ATTEMPTS_TOTAL: "free_throw_attempts_total",
		OFFENSIVE_REBOUNDS: "offensive_rebounds",
		REBOUNDS: "rebounds",
		SHOTS_BLOCKED: "shots_blocked",
		STEALS: "steals",
		TEAM_LEADS: "team_leads",
		TEAM_REBOUNDS: "team_rebounds",
		TEAM_TURNOVERS: "team_turnovers",
		THREE_POINT_ATTEMPTS_SUCCESSFUL: "three_point_attempts_successful",
		THREE_POINT_ATTEMPTS_TOTAL: "three_point_attempts_total",
		TIME_SPENT_IN_LEAD: "time_spent_in_lead",
		TIMEOUTS: "timeouts",
		TWO_POINT_ATTEMPTS_SUCCESSFUL: "two_point_attempts_successful",
		TWO_POINT_ATTEMPTS_TOTAL: "two_point_attempts_total",
	};

export { tabsName, eventType, periodType, statisticTypes };
