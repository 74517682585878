<script setup lang="ts">
import type { Ref } from "vue";
import { computed, toRaw, ref } from "vue";
import { flagIconUrl, getApi } from "@app/helpers";
import SortBtn from "@app/ui/table/sort-btn/SortBtn.vue";
import ErrorMessage from "@app/ui/error-message/ErrorMessage.vue";

import type { ResponseDetailsBombardier } from "./DetailsBombardierInterface";
import { useDetailsBombardier } from "./DetailsBombardierStore";
import { SORT_PATH } from "./DetailsBombardierConsts";
import DetailsBombardierSkeleton from "./components/DetailsBombardierSkeleton.vue";

const props = defineProps({
	api: {
		type: String,
		required: true,
	},
});

// store
const storeDetailsBombardier = useDetailsBombardier();

// get details bombardier
if (!storeDetailsBombardier.isReady) {
	getApi<ResponseDetailsBombardier>(props.api).then((result) => {
		if (result) {
			storeDetailsBombardier.createBombardier(result);
		}
	});
}

// show-more
const showItemsList: Ref<number> = ref(30);

const cutList = computed(() => {
	const list = storeDetailsBombardier.bombardierResult;
	return toRaw(list).slice(0, showItemsList.value);
});
</script>

<template>
	<DetailsBombardierSkeleton v-if="!storeDetailsBombardier.isReady" />

	<div v-else class="bombardier">
		<div class="select-wrap">
			<select v-model="storeDetailsBombardier.selectedOptions.country" class="select" name="selects_country">
				<option
					v-for="[key, value] in storeDetailsBombardier.options.country"
					:key="'selects_country_' + key"
					:value="key"
				>
					{{ value }}
				</option>
			</select>

			<select
				v-model="storeDetailsBombardier.selectedOptions.competitor"
				class="select"
				name="selects_competitor"
			>
				<option
					v-for="[key, value] in storeDetailsBombardier.options.competitor"
					:key="'selects_competitor_' + key"
					:value="key"
				>
					{{ value }}
				</option>
			</select>

			<select v-model="storeDetailsBombardier.selectedOptions.role" class="select" name="selects_role">
				<option
					v-for="[key, value] in storeDetailsBombardier.options.role"
					:key="'selects_role_' + key"
					:value="key"
				>
					{{ value }}
				</option>
			</select>
		</div>

		<table class="table main-table">
			<tr>
				<th class="th-name">
					<SortBtn
						@click="storeDetailsBombardier.changeSort(SORT_PATH.PLAYER_NAME_CUT)"
						:is-max="storeDetailsBombardier.sortMax"
						:is-active="SORT_PATH.PLAYER_NAME_CUT === storeDetailsBombardier.sortOption"
						:title="$t('details_bombardier.player.title')"
					>
					{{ $t('details_bombardier.player.btn_name') }}
					</SortBtn>
				</th>
				<th class="th-competitor">
					<SortBtn
						@click="storeDetailsBombardier.changeSort(SORT_PATH.COMPETITOR_NAME)"
						:is-max="storeDetailsBombardier.sortMax"
						:is-active="SORT_PATH.COMPETITOR_NAME === storeDetailsBombardier.sortOption"
						:title="$t('details_bombardier.team.title')"
					>
					{{ $t('details_bombardier.team.btn_name') }}
					</SortBtn>
				</th>
				<th>
					<SortBtn
						@click="storeDetailsBombardier.changeSort(SORT_PATH.STATISTICS_GOALS_SCORED)"
						:is-max="storeDetailsBombardier.sortMax"
						:is-active="SORT_PATH.STATISTICS_GOALS_SCORED === storeDetailsBombardier.sortOption"
						:title="$t('details_bombardier.gol.title')"
					>
					{{ $t('details_bombardier.gol.btn_name') }}
					</SortBtn>
				</th>
				<th>
					<SortBtn
						@click="storeDetailsBombardier.changeSort(SORT_PATH.STATISTICS_ASSISTS)"
						:is-max="storeDetailsBombardier.sortMax"
						:is-active="SORT_PATH.STATISTICS_ASSISTS === storeDetailsBombardier.sortOption"
						:title="$t('details_bombardier.pass.title')"
					>
					{{ $t('details_bombardier.pass.btn_name') }}
					</SortBtn>
				</th>
			</tr>
			<tr v-for="(item, key) in cutList" :key="'bombardier_' + key">
				<td class="td-name">
					<a :href="item.route_to_player" :title="`${item.playerName} (${item.playerCountry})`" class="name">
						<img
							v-if="item.flag"
							class="icon-flag"
							:src="flagIconUrl(item.flag)"
							:alt="$t('details_bombardier.flag_alt')+item.country"
							width="27"
							height="20"
						/>
						{{ item.playerNameCut }}
					</a>
				</td>
				<td class="td-competitor">
					<a :href="item.route_to_competitor" class="competitor">{{ item.competitorName }} </a>
				</td>
				<td class="td-scored">
					<div class="scored">{{ item.statistics.goals_scored }}</div>
				</td>
				<td class="td-scored">
					<div class="scored">{{ item.statistics.assists }}</div>
				</td>
			</tr>
		</table>

		<button
			v-if="storeDetailsBombardier.bombardierResult.length > cutList.length"
			@click="showItemsList += 30"
			class="show-more"
		>
			{{ $t('details_bombardier.show_more') }}
		</button>

		<ErrorMessage v-if="!storeDetailsBombardier.bombardierResult.length" :message="$t('details_bombardier.no_results')" />
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
.bombardier {
	display: flex;
	flex-direction: column;
	background: var(--color-dop-back);
	padding: 4px;
	border-radius: 10px;
}
.select-wrap {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 12px;
	margin-bottom: 12px;
}

.select {
	display: block;
	height: 36px;
	padding: 0 26px 0 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: var(--color-main-text);
	border: 1px solid var(--color-grey);
	border-radius: 6px;
	background: var(--color-dop-back);
	background-image: var(--icon-arrow);
	background-repeat: no-repeat;
	background-position: calc(100% - 4px) center;

	&::placeholder {
		visibility: hidden;
	}
}

.th-name,
.th-competitor {
	color: red;
	& :deep(.sort-btn) {
		justify-content: flex-start;
	}
}
.table {
	td {
		padding: 6px 6px;
		font-size: 14px;
		line-height: 110%;
	}

	th {
		font-size: 13px;
		line-height: 150%;
		text-align: center;
		padding: 4px 6px;
		font-weight: 400;
	}
}

.competitor {
	text-decoration: none;
}

.show-more {
	align-self: center;
	margin-top: 12px;
}

.td-scored {
	width: 60px;
}

.name {
	display: flex;
	align-items: center;
	gap: 12px;
	text-decoration: none;
}

.td-scored {
	text-align: center;
}
</style>
