<script setup>
import { computed, toRaw } from "vue";

import { linkTitle } from "@app/helpers";

import TableDate from "@app/ui/table/date/TableDate.vue";
import TableIconName from "@app/ui/table/icon-name/TableIconName.vue";
import TableGameTeams from "@app/ui/table/game-teams/TableGameTeams.vue";
import TableScore from "@app/ui/table/score/TableScore.vue";
import TableGameStatus from "@app/ui/table/game-status/TableGameStatus.vue";

const props = defineProps({
	competitor: Object,
	showItems: {
		default: 20,
		type: Number,
	},
});

// gameList
const gameList = computed(() => {
	return toRaw(props.competitor.list).slice(0, props.showItems);
});

// linkTu
const linkTu = (url) => {
	window.location = url;
};
</script>

<template>
	<div class="table-container">
		<table class="main-table">
			<tr
				v-for="(item, index) in gameList"
				:key="'career' + index"
				@click="linkTu(item.route_to_event)"
				@keyup.enter="linkTu(item.route_to_event)"
				:href="item.route_to_event"
				:title="linkTitle(item.teamHome.name, item.teamAway.name, item.season)"
				tabindex="0"
				role="link"
			>
				<td class="td-date">
					<TableDate :date="item.date * 1000" />
				</td>
				<td class="td-season">
					<TableIconName :title="`${item.season} (${item.category})`" :name="item.season" />
				</td>
				<td class="td-game-team">
					<TableGameTeams
						:team-home="{ name: item.teamHome }"
						:team-away="{ name: item.teamAway }"
						:event-url="item.route_to_event"
					/>
				</td>
				<td class="td-score">
					<TableScore
						:team-home="item.teamHome"
						:team-away="item.teamAway"
						:show-wine="false"
						:period-scores="item.score.period_scores"
					/>
				</td>
				<td class="td-status">
					<TableGameStatus :regular="item.status?.regular" :score="item.status?.score" />
				</td>
			</tr>
		</table>
	</div>
</template>

<style scoped lang="scss">
.table-container {
	overflow-x: auto;
	max-width: 100%;
}
.td-game-team {
	white-space: nowrap;
}

.td-date {
	width: 110px;
	text-align: right;
}
.td-game-team {
	width: 220px;
}
.td-score {
	width: 30px;
}
.td-status {
	width: 45px;
}
</style>
