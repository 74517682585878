<script setup lang="ts">
import { useTournament } from "../TournamentStore";
import { ROUTE_NAME_TOURNAMENT } from "@app/modules/tournament/tournamentConsts";

import TournamentGames from "./TournamentGames.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

// store
const storeTournament = useTournament();
</script>

<template>
	<TournamentGames v-if="storeTournament.today" :title="t('tournament.today')" :games="storeTournament.today" :show-items="10" />

	<TournamentGames
		v-if="storeTournament.results"
		:title="t('tournament.results')"
		:games="storeTournament.results"
		:show-items="10"
		:nav-to="ROUTE_NAME_TOURNAMENT.RESULTS"
	/>

	<TournamentGames
		v-if="storeTournament.timetable"
		:title="t('tournament.timetable')"
		:games="storeTournament.timetable"
		:show-items="10"
		:nav-to="ROUTE_NAME_TOURNAMENT.TIMETABLE"
	/>
</template>
