<script setup>
import { ref, computed } from "vue";
import usei18n from "@app/i18n/i18n";
import { useDetailsH2H } from "@app/modules/details/details-h2h/DetailsH2HStore.js";

import DetailsH2HTable from "./DetailsH2HTable.vue";
import DetailsTableWrap from "@app/ui/details-table-wrap/DetailsTableWrap.vue";
const titleName = (key) => (key !== "jointGames" ? t("details_h2h.last_game") : "");
// store

const { t } = usei18n.global;
const storeDetailsH2H = useDetailsH2H();

const competitor = computed(() => {
	return storeDetailsH2H.h2h.find((el) => el.key === storeDetailsH2H.nav);
});

// show items
const showItems = ref(16);
</script>

<template>
	<DetailsTableWrap :title="titleName(competitor.key) + competitor.info.name">
		<DetailsH2HTable :competitor="competitor" :show-items="showItems" />
		<button v-if="!(competitor.list.length <= showItems)" @click="showItems = Infinity" class="show-more">
			Все игры
		</button>
	</DetailsTableWrap>
</template>

<style scoped lang="scss">
.tab {
	margin-bottom: 12px;
}
.h2h-table {
	display: flex;
	flex-direction: column;
	gap: 18px;
}
</style>
