<script setup>
import { ref, inject } from "vue";
import FormInput from "@app/ui/form/input/FormInput.vue";
import CheckRadio from "@app/ui/form/check-radio/CheckRadio.vue";

import FormMessage from "./ui/FormMessage.vue";
import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;
const formOptions = inject("formOptions", null);

const formValue = ref({
		"advertising[email]": "",
		"advertising[name]": "",
		"advertising[company]": "",
		"advertising[text]": "",
	}),
	userData = ref(false);

// submit
const ERROR_MESSAGE = t("form_advertising.error"),
	OK_MESSAGE = t("form_advertising.ok");

const fmIsActive = ref(false),
	fmMessage = ref(false);

const submit = () => {
	const data = new FormData(),
		list = formValue.value;

	data.append(formOptions._token.full_name, formOptions._token.value);
	for (const key in list) {
		if (Object.hasOwnProperty.call(list, key)) {
			data.append(key, list[key]);
		}
	}

	fmIsActive.value = true;

	fetch("/advertising/", {
		method: "POST",
		body: data,
	})
		.then((response) => {
			if (response.ok) {
				userData.value = false;
				for (const key in formValue.value) {
					if (Object.hasOwnProperty.call(formValue.value, key)) {
						formValue.value[key] = "";
					}
				}
			} else {
				fmMessage.value = ERROR_MESSAGE;
				throw new Error("Error occurred!");
			}

			return response.json();
		})
		.then((response) => {
			fmMessage.value = OK_MESSAGE;
		})
		.catch(() => {
			console.error("submit error");
			fmMessage.value = ERROR_MESSAGE;
		});
};
</script>

<template>
	<form class="form" @submit.prevent="submit">
		<FormMessage v-model:is-active="fmIsActive" :message="fmMessage" />

		<FormInput
			v-model="formValue['advertising[text]']"
			:inputType="'textarea'"
			:inputPlaceholder="formOptions.text.attr?.placeholder"
			:input-required="formOptions.text.required"
			:inputError="''"
		/>

		<FormInput
			v-model="formValue['advertising[name]']"
			:inputType="'text'"
			:inputPlaceholder="formOptions.name.attr?.placeholder"
			:input-required="formOptions.name.required"
			:autocomplete="'name'"
			:inputError="''"
		/>

		<FormInput
			v-model="formValue['advertising[company]']"
			:inputType="'text'"
			:inputPlaceholder="formOptions.company.attr?.placeholder"
			:input-required="formOptions.company.required"
			:autocomplete="'organization'"
			:inputError="''"
		/>

		<FormInput
			v-model="formValue['advertising[email]']"
			:inputType="'email'"
			:inputPlaceholder="formOptions.email.attr?.placeholder"
			:input-required="formOptions.email.required"
			:autocomplete="'email'"
			:inputError="''"
		/>

		<label class="form__pd">
			<CheckRadio v-model="userData" :inputType="'checkbox'" :input-required="formOptions.pd.required" />
			<span class="form__pd__text">{{ formOptions.pd.label }}</span>
		</label>

		<button class="form__submit btn" type="submit" :disabled="!userData">{{ formOptions.save.label }}</button>
	</form>
</template>

<style scoped lang="scss">
.form {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.form__pd {
	display: flex;
	align-items: center;
	gap: 6px;
}

.form__pd__text {
	font-size: 14px;
}

.form__submit {
	margin-top: 24px;
	align-self: center;
}

@media screen and (max-width: 768px) {
	.form__submit {
		margin-top: 0;
	}
}
</style>
