<script setup>
import { computed, ref } from "vue";

import TabBtn from "@app/ui/tab-btn/TabBtn.vue";

import { useDetailsStanding } from "../DetailsStandingStore.js";

import usei18n from "@app/i18n/i18n";

const { t } = usei18n.global;

// store
const storeDetailsStanding = useDetailsStanding();

const sortField = ref("");
const sortFieldNav = ref("asc");

const sortStandingsComputed = computed(() => {
	let result = [...storeDetailsStanding.standing.teams[1].container[storeDetailsStanding.coef]];
	if (sortField && result && result.length) {
		const type = sortField.value.indexOf("overUnderInfo") !== -1 ? "overUnderInfo" : sortField.value;
		result = result.sort((commandA, commandB) => {
			switch (type) {
				case "name":
					const checkBool =
						sortFieldNav.value === "asc"
							? commandA.competitor.name > commandB.competitor.name
							: commandA.competitor.name < commandB.competitor.name;
					return checkBool === true ? 1 : -1;
				case "overUnderInfo":
					const key = sortField.value.replace("overUnderInfo.", "");
					return sortFieldNav.value === "asc"
						? commandA.overUnderInfo[key] - commandB.overUnderInfo[key]
						: commandB.overUnderInfo[key] - commandA.overUnderInfo[key];
				default:
					return sortFieldNav.value === "asc"
						? commandA[sortField.value] - commandB[sortField.value]
						: commandB[sortField.value] - commandA[sortField.value];
			}
		});
	}
	return result;
});

const setCoef = (number) => {
	storeDetailsStanding.coef = number;
};

const setSort = (fieldName) => {
	if (fieldName === "") {
		return;
	}
	if (sortField.value === fieldName) {
		sortFieldNav.value = sortFieldNav.value === "asc" ? "desc" : "asc";
	} else {
		sortFieldNav.value = "asc";
	}
	sortField.value = fieldName;
};
const headersTitle = [
	{ id: "index", name: t("details_standing.headersTitle.name_index"), sort: true, title: t("details_standing.headersTitle.title_index") },
	{ id: "name", name: t("details_standing.headersTitle.name_name"), sort: true, title: t("details_standing.headersTitle.title_name") },
	{ id: "played", name: t("details_standing.headersTitle.name_played"), sort: true, title: t("details_standing.headersTitle.title_played") },
	{ id: "overUnderInfo.over", name: t("details_standing.headersTitle.name_over"), sort: true, title: t("details_standing.headersTitle.title_over") },
	{ id: "overUnderInfo.under", name: t("details_standing.headersTitle.name_under"), sort: true, title: t("details_standing.headersTitle.title_under") },
	{ id: "goals_against", name: t("details_standing.headersTitle.name_goals_against"), sort: true, title: t("details_standing.headersTitle.title_goals_against") },
	{ id: "overUnderInfo.percentGoalOnMatch", name: t("details_standing.headersTitle.name_goal"), sort: true, title: t("details_standing.headersTitle.title_goal") },
	{ id: "last", name: t("details_standing.headersTitle.name_last"), sort: false, title: t("details_standing.headersTitle.title_last") },
];

const coefValue = ref("0.5");
</script>

<template>
	<div class="tabs-wrap _secondary">
		<ul class="tabs">
			<li
				v-for="(_, coef) in storeDetailsStanding.standing.teams[1].container"
				@click="setCoef(coef)"
				:key="`coefItem_${coef}`"
				class="tab__item"
			>
				<TabBtn v-model="coefValue" :tab-value="coef">{{ coef }}</TabBtn>
			</li>
		</ul>
	</div>
	<div class="standing-table__wrapper">
		<table class="standing main-table">
			<tbody>
				<tr class="standing-table__title">
					<th
						v-for="headerTitle in headersTitle"
						@click="setSort(headerTitle.sort ? headerTitle.id : '')"
						:key="headerTitle.id"
						:title="headerTitle.title"
						:class="{
							cursorPointer: headerTitle.sort,
							active: headerTitle.sort && sortField === headerTitle.id,
						}"
					>
						{{ headerTitle.name }}
						<span
							v-if="headerTitle.sort && sortField === headerTitle.id"
							class="arrow"
							:class="{
								arrowRotate: sortFieldNav === 'desc',
							}"
							>▲</span
						>
					</th>
				</tr>
				<tr v-for="team in sortStandingsComputed" :key="team.competitor.id" class="standing-row">
					<td>{{ team.index }}</td>
					<td>{{ team.competitor.name }}</td>
					<td>{{ team.played }}</td>
					<td>{{ team.overUnderInfo.over }}</td>
					<td>{{ team.overUnderInfo.under }}</td>
					<td>{{ team.goals_for }}:{{ team.goals_against }}</td>
					<td>
						{{ team.overUnderInfo.percentGoalOnMatch !== 0 ? team.overUnderInfo.percentGoalOnMatch : "" }}
					</td>
					<td>
						<div class="h2h-container">
							<div
								class="h2h-status"
								:class="`h2h-status__${match.overUnderCheck ? 'winner' : 'lose'}`"
								v-for="match in team.matchesNew"
								v-tooltip="!!match.title ? { content: match.title, html: true } : false"
								:key="`${team.competitor.id}_${match.id}`"
							>
								{{ match.overUnderCheck ? "+" : "-" }}
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<style scoped lang="scss">
.standing-table__wrapper {
	overflow: auto;
}
.standing-coef {
	display: flex;
	gap: 10px;
	padding: 10px;
	font-size: 14px;
	background: var(--color-dop-cta);
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	margin-bottom: 2px;
	li {
		padding: 5px 10px;
		width: 40px;
		text-align: center;
		border-radius: 5px;
		color: hsl(220, 4%, 14%);
		&._active,
		&:hover {
			background-color: var(--color-dop-back);
			cursor: pointer;
			color: var(--color-main-text);
		}
	}
}
th {
	color: var(--color-dop-text);
	font-weight: 400;
	position: relative;
	&.active {
		color: #fff;
	}
}
.standing-table__title {
	&:hover {
		background: transparent;
	}
}
.standing {
	width: 100%;
	font-size: 12px;
	border-collapse: collapse;
}
.standing-title {
	background: var(--color-dop-cta);
	border-radius: 5px;
	color: hsl(220, 4%, 14%);
	th:first-child {
		border-bottom-left-radius: 5px;
	}
	th:last-child {
		border-bottom-right-radius: 5px;
	}
	th {
		padding: 5px;
	}
}
.standing td {
	text-align: center;
	// border-bottom: 1px solid var(--color-light-grey);
	padding: 5px;
	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6),
	&:nth-child(7),
	&:nth-child(8),
	&:nth-child(1) {
		width: 50px;
	}

	&:nth-child(2) {
		width: 350px;
		text-align: left;
	}

	&:nth-child(8) {
		width: 200px;
	}
}

.standing-row {
	span {
		border-radius: 5px;
		width: 25px;
		height: 25px;
		color: white;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.h2h-status__winner {
		background-color: var(--color-dop-cta);
		color: #000;
	}
	.h2h-status__lose {
		background-color: var(--color-error);
	}
	.h2h-status__draw {
		background-color: #f3a000;
	}
}
.h2h-container {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
	color: white;
}
.h2h-status {
	width: 20px;
	height: 20px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.arrow {
	font-size: 0px;
	width: 2px;
	height: 4px;
	position: absolute;
	color: transparent;
	border: 4px solid transparent;
	border-top-color: #fff;
	margin-left: 5px;
	top: 15px;
}
.arrowRotate {
	transform: rotate(180deg);
	display: inline-block;
	top: 10px;
}
.cursorPointer {
	cursor: pointer;
	&:hover {
		color: #fff;
	}
}
</style>
