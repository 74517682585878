<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
	modelValue: boolean;
	inputId: string;
	inputIdName: string;
}>();

const emit = defineEmits(["update:modelValue"]);
const value = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit("update:modelValue", value);
	},
});
</script>

<template>
	<div :class="{ _active: props.modelValue }" class="toggle">
		<input type="checkbox" v-model="value" :id="props.inputId" :name="props.inputIdName" />
	</div>
</template>

<style scoped lang="scss">
.toggle {
	position: relative;
	display: block;
	width: 33px;
	height: 18px;
	background: var(--color-deep-grey);
	border-radius: 30px;
	flex-shrink: 0;
	transition: left 200ms ease-in-out;
	transition-property: background;

	&::before {
		content: "";
		position: absolute;
		top: 3px;
		left: 3px;
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 30px;
		background: var(--color-dop-back);
		transition: inherit;
		transition-property: left;
	}

	&._active {
		background: var(--color-dop-cta);

		&::before {
			left: 18px;
		}
	}
}

input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}
</style>
