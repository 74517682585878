<script setup>
import { inject } from "vue";

import Tournament from "@app/modules/tournament/Tournament.vue";

const api = inject("api", ""),
	sportType = inject("sportType", "football");
</script>

<template>
	<Tournament
		:value="{
			event_url: api,
			sport: sportType,
		}"
	/>
</template>

<style lang="scss">
#tournament {
	width: 100%;
}
</style>
