<script setup>
const nav = 3;
</script>

<template>
	<div class="head">
		<div class="skeleton-line _league"></div>
		<div class="skeleton-line _league"></div>

		<div class="wrap">
			<div class="team">
				<div class="skeleton-line _img"></div>
				<div class="skeleton-line _name"></div>
			</div>

			<div class="info">
				<div class="skeleton-line"></div>
				<div class="skeleton-line _score"></div>
				<div class="skeleton-line"></div>
				<div class="skeleton-line"></div>
			</div>

			<div class="team">
				<div class="skeleton-line _img"></div>
				<div class="skeleton-line _name"></div>
			</div>
		</div>

		<div class="nav">
			<div v-for="(line, key) in nav" :key="'lineKeyHead' + key" class="skeleton-line"></div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.head {
	display: flex;
	flex-direction: column;
	width: 100%;
}

._league {
	width: 70%;
	margin-bottom: 18px;
}

.wrap {
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
	justify-items: center;
	grid-gap: 12px;
	margin-bottom: 56px;
}

.team {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
}

._img {
	width: 180px;
	height: 180px;
}

._name {
	width: 70%;
}

.info {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100px;
	gap: 12px;

	> .skeleton-line {
		width: 100px;

		&._score {
			width: 60%;
			height: 30px;
		}
	}
}

.nav {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 12px;

	> .skeleton-line {
		height: 36px;
		width: 62px;

		&:nth-child(2n + 2) {
			width: 136px;
		}
	}
}

@media screen and (max-width: 570px) {
	.wrap {
		grid-template-columns: auto auto;
	}

	.info {
		grid-column: 1 / -1;
		grid-row-start: 1;
	}

	._img {
		width: 160px;
		height: 160px;
	}
}
</style>
