const COEF_TYPE = {
		_1X2: "1x2",
		EXACT_SCORE: "exactScore",
		BOTH_TEAMS_WILL_SCORE: "bothTeamsWillScore",
		HANDICAP: "handicap",
		LESS_AND_GROSS: "lessAndGross",
		HTFT: "HTFT",
		DOUBLE_OUTCOME: "doubleOutcome",
		EVEN_ODD: "evenOdd",
	}

export { COEF_TYPE };
