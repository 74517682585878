import type { ConstObject } from "@app/interface";

const SORT_PATH: ConstObject = {
	PLAYER_NAME_CUT: "playerNameCut",
	COMPETITOR_NAME: "competitorName",
	STATISTICS_GOALS_SCORED: "statistics.goals_scored",
	STATISTICS_ASSISTS: "statistics.assists",
};

export { SORT_PATH };
