import { defineStore } from "pinia";
import { ref } from "vue";

export const useDetailsGrid = defineStore("DetailsGrid", () => {
	const nav = ref(),
		isReady = ref(),
		isError = ref(),
		grid = ref();

	// reset
	const reset = () => {
		isReady.value = false;
		isError.value = false;
		grid.value = {
			gridColumn: {
				round_of_64: null,
				round_of_32: null,
				round_of_16: null,
				quarterfinal: null,
				semifinal: null,
				qualification_round_1: null,
				qualification_round_2: null,
				qualification_round_3: null,
				qualification_final: null,
			},
			finalColumn: {
				final: null,
				"3rd_place_final": null,
				"3rd_place_playoff": null,
			},
		};
	};

	reset();

	const createdColumn = (column, result) => {
		for (const key in column) {
			if (!key.match(/qualification_round_\d+/)) {
				if (Object.hasOwnProperty.call(column, key)) {
					column[key] = result.grid.groups[0].cup_rounds.filter((item) => {
						return key === item.name;
					});

					if (column[key].length === 0) {
						delete column[key];
					} else {
						// score
						for (const game of column[key]) {
							let away_score = game.sport_events[0].sport_event_status.away_score,
								home_score = game.sport_events[0].sport_event_status.home_score;

							// penalties
							const isScore =
								(away_score || away_score === 0) &&
								(home_score || home_score === 0) &&
								away_score === home_score;

							if (isScore) {
								const penalties = game.sport_events[0].sport_event_status.period_scores.find(
									(item) => item.type === "penalties"
								);

								if (penalties) {
									if (penalties.home_score > penalties.away_score) {
										home_score += 1;
									} else {
										away_score += 1;
									}
								}
							}

							// add score in time
							for (const time of game.sport_events[0].competitors) {
								if (time.id === game.sport_events[0].sport_event_status.winner_id) {
									time.score = away_score >= home_score ? away_score : home_score;
									time.isWine = true;
								} else {
									time.score = away_score >= home_score ? home_score : away_score;
									time.isWine = false;
								}
							}
						}
					}
				}
			}
		}
		// qualification_round
		for (const key in column) {
			if (key.match(/qualification_round_\d+/)) {
				if (Object.hasOwnProperty.call(column, key)) {
					column[key] = result.grid.groups[0].cup_rounds.filter((item) => {
						return key === item.name;
					});

					if (column[key].length === 0) {
						delete column[key];
					} else {
						// add 2nd competitor
						for (let index = 0; index < column[key].length; index++) {
							let game = column[key][index + 1];
							if (column[key][index + 1] && game.id === column[key][index + 1].id) {
								game.sport_events[0].competitor_2nd = column[key][index].sport_events[0].competitors;
							}
						}
						// score
						for (const game of column[key]) {
							if (game.sport_events) {
								let away_score = game?.sport_events?.[0].sport_event_status.away_score,
									home_score = game?.sport_events?.[0].sport_event_status.home_score;
								// add score in team
								for (const team of game?.sport_events?.[0]?.competitors) {
									if (team.id === game?.sport_events[0].sport_event_status.winner_id) {
										team.score = away_score >= home_score ? away_score : home_score;
										team.isWine = true;
									} else {
										team.score = away_score >= home_score ? home_score : away_score;
										team.isWine = false;
									}
								}
							}
						}
						// dellet duble game
						column[key] = column[key].filter((game, index, array) => {
							if (index === array.length - 1) {
								return true;
							}
							return game.id !== array[index + 1].id && game.linked_cup_rounds !== null;
						});
					}
				}
			}
		}
		for (const key in column) {
			if (key.match(/qualification_round_\d+/)) {
				if (key === "qualification_round_3") {
					qualificationRound3(column)
				}
			}
		}
		for (const key in column) {
			if (key.match(/qualification_round_\d+/)) {
				if (key === "qualification_round_2") {
					qualificationRound2(column)
				}
			}
		}
		if (column["qualification_round_1"]) {
			qualificationRound1(column) 
		}
	};

	
	const qualificationRound1 = (column) => {
		column["qualification_round_1"].sort((a, b) => {
			const numA = parseInt(a.num_list, 10);
			const numB = parseInt(b.num_list, 10);
			if (!isNaN(numA) && !isNaN(numB)) {
				return numA - numB;
			}
			return 0;
		});
	}

	const qualificationRound2 = (column) => {
		column["qualification_round_2"].sort((a, b) => {
			return (
				a.linked_cup_rounds[a.linked_cup_rounds.length - 1].order -
				b.linked_cup_rounds[b.linked_cup_rounds.length - 1].order
			);
		});
		// is child in round 1
		for (let index = 0; index < column["qualification_round_2"].length; index++) {
			let game2 = column["qualification_round_2"][index];
			game2.num_list = index + 1;
			for (const game1 of column["qualification_round_1"]) {
				for (const round of game2.linked_cup_rounds) {
					if (round.id === game1.id) {
						game1.num_list = index + 1;
					}
				}
			}
		}

		for (let index = 0; index < column["qualification_round_2"].length; index++) {
			let game2 = column["qualification_round_2"][index];
			if (game2.linked_cup_rounds.length === 2) {
				const competitor = Object.assign({}, game2.sport_events?.[0]?.competitors?.[1]);
				delete competitor.score;
				for (const game1 of column["qualification_round_1"]) {
					for (const round of game2.linked_cup_rounds) {
						if (round.id === game1.id) {
							column["qualification_round_1"].push({
								sport_events: [
									{
										competitors: [competitor],
									},
								],
								linked_cup_rounds: [{ order: index, type: "perent" }],
								name: "qualification_round_1",
								num_list: index,
							});
						}
					}
				}
			}
			if (game2.linked_cup_rounds.length === 1) {
				const competitor = { ...game2.sport_events?.[0]?.competitors?.[0] };
				const competitor1 = { ...game2.sport_events?.[0]?.competitors?.[1] };

				delete competitor.score;
				delete competitor1.score;

				const newQualificationRound1Entries = [
					{
						sport_events: [{ competitors: [competitor1] }],
						linked_cup_rounds: [{ order: index + 1, type: "perent" }],
						name: "qualification_round_1",
						num_list: game2.num_list,
					},
					{
						sport_events: [{ competitors: [competitor] }],
						linked_cup_rounds: [{ order: index + 1, type: "perent" }],
						name: "qualification_round_1",
						num_list: game2.num_list,
					},
				];

				column["qualification_round_1"].push(...newQualificationRound1Entries);
			}
		}
	}

	const qualificationRound3 = (column) => {
		column["qualification_round_3"].sort((a, b) => {
			return (
				a.linked_cup_rounds[a.linked_cup_rounds.length - 1].order -
				b.linked_cup_rounds[b.linked_cup_rounds.length - 1].order
			);
		});
		for (let index = 0; index < column["qualification_round_3"].length; index++) {
			let game3 = column["qualification_round_3"][index];
			game3.num_list = index + 1;
			processQualificationRound2(game3, index, column);
		}
	}

	const processQualificationRound2 = (game3, index, column) => {
		if (game3.linked_cup_rounds !== null && game3.linked_cup_rounds.length === 1) {
			let num = 0;
			for (const game2 of column["qualification_round_2"]) {
				if (game2?.sport_events?.[0].competitors?.[1]?.name === game3.sport_events[0].competitors[0].name) {
					num = 1;
				}
				if (game2?.sport_events?.[0].competitors?.[0]?.name === game3.sport_events[0].competitors[0].name) {
					num = 1;
				}
			}
			const competitor = game3.sport_events[0].competitors[num];
			delete competitor.score;
			column["qualification_round_2"].push({
				sport_events: [{ competitors: [competitor] }],
				linked_cup_rounds: [{ order: index + 1, type: "perent" }],
				name: "qualification_round_2",
			});
		}
	};

	const createGrid = (result) => {
		console.log(result.grid.groups[0].cup_rounds.length);
		if (result.grid.groups[0].cup_rounds.length) {
			createdColumn(grid.value.gridColumn, result);
			createdColumn(grid.value.finalColumn, result);
			isReady.value = true;
		} else {
			isError.value = true;
		}
	};

	return {
		isReady,
		isError,
		grid,
		createGrid,
	};
});
