import {
	IS_OPEN_ITEM_LEAGUE,
	STATIC_PATH,
	FETCH_PATH,
	HEADERS_LIST,
	SPORT_TYPE_NAME,
	FAVORITE_TYPE,
	LANGUAGE,
} from "@app/consts.ts";

/**
 * Возвращает ссылку на иконку флага страны
 * @param countryCode Код страны
 * @returns staticmy.devrepo2.ru/flags/`countryCode`.svg
 */
const flagIconUrl = (countryCode?: string): string | undefined => {
	if (countryCode) return `${STATIC_PATH}/flags/${countryCode}.svg`;
	return undefined;
};
/**
 * Возвращает ссылку на картинку по виду спорта
 * @param sportType вид спорта
 * @returns staticmy.devrepo2.ru/broadcast/`sportType`.png
 */
const picBroadcastUrl = (sportType?: string): string | undefined => {
	if (sportType) return `${STATIC_PATH}/broadcast/${sportType}.png`;
	return undefined;
};

/**
 * Возвращает ссылку для логотипов букмекеров
 * @param name Имя букмекера
 * @returns "/bookie/`name`.svg"
 */
const bookieLogoUrl = (name: string): string => `/bookie/${name}.svg`;

/**
 * Возвращает объект со списком всех раскрытых / скрытых лиг пользователем на страницах https://myscore.club/sport/football/ (и других видах спорта)
 * @param sportType Тип спорта
 * @returns `{[key: id лиги]: true | false}`
 */
const getIsOpenItemLeague = (sportType: string): Record<string, boolean> => {
	const openStatus = window.localStorage.getItem(sportType + IS_OPEN_ITEM_LEAGUE);
	return openStatus ? JSON.parse(openStatus) : {};
};

/**
 * Сокращает имя игрока
 * @param name Имя игрока (обязательно с запятой)
 * @returns "Месси, Лионель" -> "Месси Л."
 */
const formatNameCut = (name: string | null): string => {
	if (name) {
		const splitName = name.split(/,\s*/g);
		if (splitName.length <= 1) return splitName[0];
		return `${splitName[0]} ${splitName[1]?.match(/^[a-яёA-ЯЁ]/g)?.[0]}.`;
	}
	return "";
};

/**
 * Уберет запятую из имени игроков
 * @param name Имя игрока
 * @returns "Месси, Лионель" -> "Месси Лионель"
 */
const formatNameRemoveComma = (name: string): string => {
	const splitName = name.split(/,\s*/g);
	if (splitName.length <= 1) return splitName[0];
	return `${splitName[0]} ${splitName[1]}`;
};

/**
 * Возвращает число полных лет от указанной даты
 * @param dateOfBirth Дата начала (как правило дата рождения)
 * @param dateToCalculate Дата до которой нужно расчищать. Если не указывать берётся текущая дата
 */
const getAgeFullYear = (dateOfBirth: number | string | Date, dateToCalculate: number | Date = new Date()): number => {
	const dob = new Date(dateOfBirth).getTime();
	const dateToCompare = new Date(dateToCalculate).getTime();
	return Math.floor((dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000));
};

/**
 * Формирует title для ссылок которые ведут на матч
 * @param homeName Имя домашней команды
 * @param awayName Имя команды гостей
 * @param leagueName Имя лиги
 * @returns "`homeName` против `awayName` (`leagueName`)"
 */
const linkTitle = (homeName: string, awayName: string, leagueName: null | string = null): string => {
	let title = `${homeName} против ${awayName}`;
	return leagueName ? (title += ` (${leagueName})`) : title;
};

/**
 * Стандартная функция для получения api с учетом locale
 * @param api путь к api
 */
const getApi = async <T>(api: string): Promise<T | undefined> => {
	const locale = getCookie("locale") || "?locale=ru";
	try {
		const response = await fetch(FETCH_PATH() + api + locale, {
			method: "GET",
			headers: HEADERS_LIST(),
		});
		return response.json() as Promise<T>;
	} catch (error) {
		console.error("GET error", error);
	}
};

/**
 * Добавляет / удаляет избранную команду / лигу для зарегистрированного пользователя
 * @param type команду или лигу
 * @param id id команду / лигу
 * @param method **"DELETE"** -> удалить, **"POST"** -> добавить
 */
const addRemoveUserFavorite = async (
	type: FAVORITE_TYPE.LEAGUE | FAVORITE_TYPE.TEAM,
	id: string | number,
	method: "DELETE" | "POST" = "POST"
): Promise<void> => {
	try {
		// FIXME: "tournament" : "team"
		await fetch(`/account/favorites/${FAVORITE_TYPE.LEAGUE === type ? "tournament" : "team"}/${id}`, {
			method: method,
		});
	} catch (error) {
		console.error("POST error", error);
	}
};

/**
 * Формирует ключ для избранных команд / лиг который используется в localStorage
 * @param sportType тип спорта
 * @param favoriteType команда или лига
 */
const localStorageFavoriteKey = (sportType: string, favoriteType: string): string => {
	return `${sportType}_favorite_${favoriteType}`;
};

/** Удаляет все ранее записанные команду и лиги из localStorage */
const removeFavoriteLocalStorage = (): void => {
	for (const key in SPORT_TYPE_NAME) {
		if (Object.hasOwnProperty.call(SPORT_TYPE_NAME, key)) {
			window.localStorage.removeItem(localStorageFavoriteKey(SPORT_TYPE_NAME[key], FAVORITE_TYPE.LEAGUE));
			window.localStorage.removeItem(localStorageFavoriteKey(SPORT_TYPE_NAME[key], FAVORITE_TYPE.TEAM));
		}
	}
};

/**
 * Устанавливает куки
 * @param name Имя куки
 * @param value Значение куки
 * @param options Опции для куки (expires, path и т.д.)
 */
const setCookie = (name: string, value: string, options: Record<string, any> = {}): void => {
	options = {
		path: "/",
		...options,
	};

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}

	document.cookie = updatedCookie;
};

/**
 * Получает значение куки по имени
 * @param name Имя куки
 * @returns Значение куки или undefined, если куки не существует
 */
const getCookie = (name: string): string | undefined => {
	let matches = document.cookie.match(
		new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
};

/**
 * Получает по значению куки и LANGUAGE
 * @returns Значение текущего locale
 */
const currentLocale = (): any | undefined => {
	const locale = getCookie('locale') || '?locale=ru';
	return LANGUAGE[locale];
}

export {
	flagIconUrl,
	picBroadcastUrl,
	bookieLogoUrl,
	getIsOpenItemLeague,
	formatNameCut,
	formatNameRemoveComma,
	getAgeFullYear,
	linkTitle,
	getApi,
	localStorageFavoriteKey,
	removeFavoriteLocalStorage,
	addRemoveUserFavorite,
	setCookie,
	getCookie,
	currentLocale,
};
