import { createRouter, createWebHistory } from "vue-router";
import DetailsStanding from "@app/modules/details/details-standing/DetailsStanding.vue";
import DetailsGrid from "@app/modules/details/details-grid/DetailsGrid.vue";

import TeamReview from "./components/TeamReview.vue";
import TeamResults from "./components/TeamResults.vue";
import TeamTransfers from "./components/TeamTransfers.vue";
import TeamSquadVue from "./components/TeamSquad.vue";
import { ROUTE_NAME_TEAM } from "./teamConsts";

const createTeamRouter = (basePath: string) => {
	return createRouter({
		history: createWebHistory(basePath),
		routes: [
			// { path: "/", redirect: `/${ROUTE_NAME_TEAM.REVIEW}/` },
			{ path: `/${ROUTE_NAME_TEAM.REVIEW}/`, name: ROUTE_NAME_TEAM.REVIEW, component: TeamReview },
			{ path: `/${ROUTE_NAME_TEAM.CALENDAR}/`, name: ROUTE_NAME_TEAM.CALENDAR, component: TeamResults },
			{ path: `/${ROUTE_NAME_TEAM.RESULTS}/`, name: ROUTE_NAME_TEAM.RESULTS, component: TeamResults },
			{ path: `/${ROUTE_NAME_TEAM.TRANSFERS}/`, name: ROUTE_NAME_TEAM.TRANSFERS, component: TeamTransfers },
			{ path: `/${ROUTE_NAME_TEAM.SQUAD}/`, name: ROUTE_NAME_TEAM.SQUAD, component: TeamSquadVue },
			{ path: `/${ROUTE_NAME_TEAM.STANDING}/`, name: ROUTE_NAME_TEAM.STANDING, component: DetailsStanding },
			{ path: `/${ROUTE_NAME_TEAM.GRID}/`, name: ROUTE_NAME_TEAM.GRID, component: DetailsGrid },
		],
	});
};

export default createTeamRouter;
