<script setup>
import { computed, watch, ref } from "vue";
import { linkTitle, currentLocale } from "@app/helpers.js";

import TableStatusTime from "@app/ui/table/status-time/TableStatusTime.vue";
import TableGameTeams from "@app/ui/table/game-teams/TableGameTeams.vue";
import TableScore from "@app/ui/table/score/TableScore.vue";
import TableCoefficientTooltip from "@app/ui/table/coefficient/TableCoefficientTooltip.vue";

const props = defineProps({
	itemGame: Object,
	isCoef: Boolean,
	showDate: {
		type: Boolean,
		default: false,
	},
});

const LOCALE = currentLocale().locale;

// linkTu
const linkTu = (url) => {
	window.location = url;
};

// showDate
const date = computed(() => {
	const startTime = new Date(props.itemGame.start_time * 1000);
	return new Intl.DateTimeFormat(LOCALE, {
		month: "short",
		day: "numeric",
	}).format(startTime);
});

// isGol
const isGol = ref({
	home: false,
	away: false,
});

const removeIsGol = () => {
	setTimeout(() => {
		isGol.value = {
			home: false,
			away: false,
		};
	}, 60000);
};

watch(
	() => props.itemGame.team_home?.score,
	(n, o) => {
		if (n !== o) {
			isGol.value.home = true;
			removeIsGol();
		}
	}
);
watch(
	() => props.itemGame.team_away?.score,
	(n, o) => {
		if (n !== o) {
			isGol.value.away = true;
			removeIsGol();
		}
	}
);
const formatScore = (score) => {
	if (props.itemGame?.team_home?.[1]?.name && props.itemGame?.team_away?.[1]?.name) {
		return score[0].name + "/" + score[1].name;
	} else return score[0].name;
};
if (props.itemGame?.cards?.team_home) {
  props.itemGame.team_home[0].cards = props.itemGame.cards.team_home;
}

if (props.itemGame?.cards?.team_away) {
  props.itemGame.team_away[0].cards = props.itemGame.cards.team_away;
}
</script>

<template>
	<tr
		@click="linkTu(itemGame.event_url)"
		@keyup.enter="linkTu(itemGame.event_url)"
		:class="{ _active: isGol.home || isGol.away }"
		:href="itemGame.event_url"
		:title="linkTitle(formatScore(itemGame?.team_home ?? '-'), formatScore(itemGame?.team_away ?? '-'))"
		tabindex="0"
		role="link"
		class="tr-click"
	>	
		<!-- <td class="td__star">
					<button class="table__star">
						<svg width="20" height="20">
							<use xlink:href="#20-star" />
						</svg>
					</button>
				</td>-->
		<td class="td__time">
			<span class="td__time__date" v-if="showDate">
				{{ date }}
				<br />
			</span>

			<TableStatusTime v-if="itemGame.status && itemGame.start_time && itemGame?.clock" :status="itemGame.status" :start-time="itemGame.start_time" :clock="itemGame?.clock" />
			<TableStatusTime v-else :status="itemGame.status" :start-time="itemGame.start_time" :clock="itemGame?.clock" />		</td>

		<td class="td__team">
			<TableGameTeams
				:team-home="itemGame.team_home ?? [{ name: itemGame.teams[0] }]"
				:team-away="itemGame.team_away ?? [{ name: itemGame.teams[1] }]"
				:event-url="itemGame.event_url"
			/>
		</td>

		<td class="td__score">
			<TableScore
				:team-home="itemGame.team_home"
				:team-away="itemGame.team_away"
				:period-scores="itemGame?.period_scores"
				:score="itemGame?.score"
				:is-gol="isGol"
			/>
		</td>

		<td v-if="isCoef" class="td__coef">
			<TableCoefficientTooltip
				v-if="itemGame?.coefficient"
				:coefficient="itemGame.coefficient[1]"
				:bookie="itemGame.coefficient.bookie"
			/>
		</td>
		<td v-if="isCoef" class="td__coef">
			<TableCoefficientTooltip
				v-if="itemGame?.coefficient"
				:coefficient="itemGame.coefficient.x"
				:bookie="itemGame.coefficient.bookie"
			/>
		</td>
		<td v-if="isCoef" class="td__coef">
			<TableCoefficientTooltip
				v-if="itemGame?.coefficient"
				:coefficient="itemGame.coefficient[2]"
				:bookie="itemGame.coefficient.bookie"
			/>
		</td>
	</tr>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";
tr.tr-click {
	position: relative;
	cursor: pointer;
	transition: background ease-in-out 250ms;

	&:hover {
		background: var(--color-dop-back);
	}

	&:first-child {
		> td {
			&:first-child {
				border-top-left-radius: 6px;
			}

			&:last-child {
				border-top-right-radius: 6px;
			}
		}
	}

	&:last-child {
		> td {
			&:first-child {
				border-bottom-left-radius: 6px;
			}

			&:last-child {
				border-bottom-right-radius: 6px;
			}
		}
	}

	td {
		padding-bottom: 1px;

		&:first-child {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				width: 720px;
				height: 1px;
				background: var(--color-border);
			}
		}
	}

	&:last-child {
		td {
			&::after {
				content: none;
			}
		}
	}

	&._active {
		> td {
			background: hsla(var(--accent-hsl), 0.25);
		}
	}
}

.td__time {
	width: 95px;
	text-align: center;
	table-layout: fixed;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
}

.td__time__date {
	display: block;
	font-size: 12px;
}

.td__score {
	width: 58px;
	text-align: right;
}

.td__coef {
	width: 60px;
	text-align: center;
	table-layout: fixed;
	padding: 0 4px;
}

@media screen and (max-width: 1120px) {
	tr.tr-click {
		td {
			&:first-child {
				&::after {
					width: calc(100vw - 360px - (#{$block-padding-hor} * 2));
				}
			}
		}
	}
}

@media screen and (max-width: 1020px) {
	.td__time {
		width: 90px;
	}

	.td__score {
		width: 30px;

		&:last-child {
			padding-right: 20px;
		}
	}

	.td__coef {
		width: 50px;

		&:last-child {
			padding-right: 4px;
		}
	}
}

@media screen and (max-width: 910px) {
	tr.tr-click {
		td {
			&:first-child {
				&::after {
					width: calc(100vw - 188px - (#{$block-padding-hor} * 2));
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	tr.tr-click {
		td {
			&:first-child {
				&::after {
					width: calc(100vw - 8px - (#{$block-padding-hor-m} * 2));
				}
			}
		}
	}
}

@media screen and (max-width: 570px) {
	.td__team {
		width: 100%;
	}
	td.td__time {
		width: 0;
		padding: 0;
	}

	td.td__score {
		padding: 0 2px;
	}

	td.td__coef {
		// width: 0;
		display: flex;
		width: auto;
		margin: 0;
		padding: 0 4px;
	}
}
</style>
