import { defineStore } from "pinia";
import { shallowRef, ref, watch } from "vue";

export const usePopUp = defineStore("popUp", () => {
	const value = ref({}),
		component = shallowRef({}),
		isOpen = ref(false);

	// body hidden
	const toggleClassBody = (toggle) => {
		document.body.classList.toggle("_hidden", toggle);
	};
	watch(isOpen, (toggle) => toggleClassBody(toggle));

	return { value, component, isOpen };
});
