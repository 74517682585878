import { formatNameRemoveComma, formatNameCut } from "@assets/app/helpers.js";

const formatPlayerName = (competitors, timeline, statistics) => {
		for (const team of competitors) {
			if (team?.manager?.name) {
				const manager = team.manager?.name;
				team.manager.name = formatNameRemoveComma(manager);
				team.manager.nameCut = formatNameCut(manager);
			}

			for (const player of team.players) {
				const name = player.name;
				player.name = formatNameRemoveComma(name);
				player.nameCut = formatNameCut(name);
			}
		}

		for (const event of timeline) {
			if (event?.players) {
				for (const player of event.players) {
					const name = player.name;
					player.name = formatNameRemoveComma(name);
					player.nameCut = formatNameCut(name);
				}
			} else if (event?.player) {
				const name = event.player.name;
				event.player.name = formatNameRemoveComma(name);
				event.player.nameCut = formatNameCut(name);
			}
		}

		for (const competitor of statistics) {
			if (competitor.players) {
				for (const player of competitor.players) {
					const name = player.name;
					player.name = formatNameRemoveComma(name);
					player.nameCut = formatNameCut(name);
				}
			}
		}
	},
	addPlayersEvent = (competitors, timeline) => {
		const playerEvents = timeline.filter((event) => {
			return (
				!!event.players &&
				["yellow_card", "red_card", "score_change", "substitution", "yellow_red_card"].includes(event.type)
			);
		});

		for (const event of playerEvents) {
			let playerId = event.players[0].id;

			if (event.type === "score_change") {
				playerId = event.players.find((i) => i.type === "scorer").id;
			} else if (event.type === "substitution") {
				playerId = event.players.find((i) => i.type === "substituted_out").id;
			}

			for (const team of competitors) {
				for (const player of team.players) {
					if (player.id === playerId) {
						if (!player?.events) player.events = [];
						player.events.push(event);
					}
				}
			}
		}
	};

export { formatPlayerName, addPlayersEvent };
